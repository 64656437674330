@use "../../utils/mediaQuerys" as *;;

.workOffer {

    &_logoContainer {
        width: 100%;
        display: flex;
        background: transparent;

        @include respond(phone) {
            background: #234ba2;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
            ;

        }
    }

    &_main {
        display: flex;
        width: 100%;
        height: 100%;
        background: rgb(249 250 251);
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        @include respond(phone) {
            padding: 5%;
        }
    }

    &_headerContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 3rem;
    }

    &_pill {
        width: 10rem;
        background-color: rgb(37 99 235);
        height: 0.5rem;
        margin-top: 1rem;
    }

    &_logo {
        width: 120px;
        align-self: center;
    }

    &_title {
        font-size: 25px;
        font-weight: 600;
        text-align: left;
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;

        @include respond(phone) {
            margin-top: 0;
        }
    }

    &_subTitlePhoneView {
        padding: 10px 20px;
        background: #fff;
        width: fit-content;
        align-self: center;
        opacity: 0.95;
        border-radius: 11px;
        color: #889abe;
        border: 1px solid;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        position: absolute;
        top: 20%;
        z-index: 1;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        &_withOffers {
            &_button {
                padding: 1rem;
                background: transparent;
                border-radius: 0.75rem;
                width: max-content;
                border: 1px solid #004085;
                color: #004085;
                font-size: 1.4rem;
                font-weight: 500;
                cursor: pointer;
                display: flex;
                justify-content: center;
            }
        }
    }

    &_container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: rgb(255 255 255);
        flex-direction: column;
        border-radius: 1rem;
        padding: 3rem;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

        @include respond(phone) {
            flex-direction: column;
            position: relative;
        }
    }

    &_searchIcon {
        background-color: rgb(219 234 254);
        padding: 2.4rem;
        border-radius: 50%;
        color: rgb(37 99 235);
        margin-bottom: 2.4rem;
    }

    &_cardHeader {
        display: flex;
        padding: 20px;
        justify-content: space-between;

        &_imageContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;


            &_image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
            }

            &_info {
                font-size: 14px;
                font-weight: 600;

            }
        }

        &_button {
            padding: 5px 30px;
            border-radius: 5px;
            height: 100%;
            font-size: 14px;
            font-weight: 600;
            transition: 0.2s ease-out all;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            background: #004085;
            color: #fff;

            &:hover {
                background: #234ba2;
                color: white;
            }

            svg path {
                stroke: #fff;
            }
        }
    }

    &_card {
        box-shadow: 0px 0px 27px -13px #3f66b3;
        width: 100%;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;

        &_title {
            font-size: 2.4rem;
            margin-bottom: 3.5rem;
            text-align: center;
            font-weight: 600;
            color: rgb(31 41 55);
            line-height: 1.5;
        }

        &_list {
            list-style: none;
            list-style-position: inside;
            display: flex;
            gap: 2.4rem;
            flex-direction: column;
            max-width: 80%;
            

            @include respond(phone) {
                margin-left: 0px;
                font-size: 14px;
                gap: 20px;
                padding-top: 20px;
                padding-bottom: 20px;

            }

            &_item {
                display: flex;
                gap: 2rem;
                align-items: flex-start;
                font-size: 16px;
                color: rgb(75 85 99); 
                line-height: 1.3;

                &_icon {
                    min-width: 24px;
                    min-height: 24px;
                    max-width: 24px;
                    max-height: 24px;
                    color: rgb(37 99 235);
                }

                &_lastChild {
                    display: inline;
                }
            }
        }
    }

    &_cardTitleContainer {
        display: flex;
        gap: 10px;
        align-items: baseline;
        padding: 10px 20px;

        &_title {
            font-size: 16px;
            font-weight: 500;
        }

        &_date {
            font-size: 14px;
            color: #959ba2;
        }
    }

    &_cardSkillsContainer {
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex: 1;

        &_title {
            font-size: 24px;
            font-weight: 700;
        }

        &_skills {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            padding-top: 10px;

            &_skill {
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 600;
                border: 1px solid #004085;
                color: #004085;
            }

        }
    }

    &_cardDescriptionContainer {
        color: #4A5568;
        font-size: 14px;
        font-weight: 600;

    }

    &_cardFooterContainer {
        display: flex;
        padding: 20px;
        width: 100%;
        flex-direction: column;
        gap: 30px;
        justify-content: flex-end;
        border-bottom-right-radius: 11px;
        border-bottom-left-radius: 11px;
        background: #E4ECF7;

        @include respond(phone) {
            gap: 20px;
        }

        &_location {
            &_container {
                display: flex;
                gap: 10px;
                font-size: 14px;
                font-weight: 400;
                align-items: center;
                color: #4A5568;

                &_icon {
                    min-width: 30px;
                    min-height: 30px;
                    max-width: 30px;
                    max-height: 30px;
                }

            }
        }

        &_startDate {
            display: flex;
            font-size: 14px;
            font-weight: 400;
            gap: 10px;
            align-items: center;
            color: #4A5568;
        }

        &_salary {
            display: flex;
            font-size: 14px;
            font-weight: 400;
            align-items: center;
            gap: 10px;
            color: #4A5568;
        }

        &_duration {
            display: flex;
            font-size: 14px;
            font-weight: 400;
            gap: 10px;
            align-items: center;
            color: #4A5568;

            svg path {
                stroke: #4A5568;
            }
        }
    }
}


.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.buttons {
    padding-top: 2rem;

    &_button {
        outline: none;
        border: none;
        -webkit-user-select: none;
        user-select: none;
        padding: 10px 12px;
        border-radius: 20px;
        background-color: #889abe;
        font-size: inherit;
        color: white;
        transition: 0.07s ease-out transform;
        font-size: 14px;
        font-weight: 600;

        &:first-of-type {
            margin-right: 15px;
        }

        &:hover {
            cursor: pointer;
        }

        &:active {
            transform: scale(.85) skewY(-5deg) rotate(5deg);
        }
    }
}