.loaderOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 401;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loaderImage {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #234ba3;
    border-radius: 50%;
    opacity: 0;
    animation: blink 1.5s infinite;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes blink {
    0%, 80%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}
