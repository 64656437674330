@use "../../utils/mediaQuerys" as *;;

.workBag {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &_main {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        width: 100%;
        height: 100%;

        @include respond(phone) {
            padding: 5%;
        }
    }


    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #959ba3;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &_viewInPhone {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: overlay;
    }

    &_sideComponent {
        height: 100%;
        width: 100%;
        background: linear-gradient(to bottom right, #2563eb, #1e40af);

        &_body {
            display: flex;
            flex-direction: column;
            padding: 5%;
            width: 100%;
            height: 100%;

        }

        &_image {
            width: 30rem;
            height: 20rem;
            border-radius: 0.5rem;
            align-self: flex-end;
            object-fit: cover;
            margin-bottom: 5rem;
        }

        &_title {
            color: rgb(255 255 255);
            font-size: 3rem;
            line-height: 4rem;
            font-weight: 600;
            text-align: right;

        }

        &_text {
            color: rgb(219 234 254);
            font-size: 1.8rem;
            line-height: 2rem;
            font-weight: 500;
            text-align: right;
            margin-top: 2rem;
            line-height: 2.5rem;
        }

        &_btn {
            background-color: rgb(255 255 255);
            color: rgb(37 99 235);
            border-radius: 3rem;
            padding: 1rem 2rem;
            width: max-content;
            font-weight: 600;
            align-self: flex-end;
            margin-top: 3rem;
            font-size: 1.6rem;
        }
    }


    &_logoContainer {
        width: 100%;
        display: flex;
        background: transparent;

        @include respond(phone) {
            background: #234ba2;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
            ;

        }
    }

    &_logo {
        width: 120px;
        align-self: center;
    }

    &_error {
        font-size: 12px;
        color: #d6174a;
        font-weight: 600;
    }

    &_formIcon {
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        color: rgb(37 99 235);
    }

    &_infoContainer {
        border-radius: 5px;
        background-color: black;
        padding: 10px;
        color: #fff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        position: absolute;
        z-index: 1;
        font-size: 14px;
        bottom: 100%;
        left: 0;
        opacity: 0.9;
        margin-bottom: 10px;

        &::after {
            content: "";
            position: absolute;
            bottom: -19px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 10px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }
    }



    &_title {
        font-size: 25px;
        font-weight: 700;
        text-align: left;

        @include respond(phone) {
            font-size: 3rem;
            line-height: 4rem;
            margin-top: 10px;
        }

        &_text {
            font-size: 16px;
            color: rgb(75 85 99);
            padding-top: 1rem;
            line-height: 1.5;
        }
    }

    &_subtitle {
        font-size: 2rem;
        font-weight: 600;
        padding-top: 2rem;
        opacity: 0.9;
        display: flex;
        color: rgb(31 41 55);
        gap: 1rem;
        align-items: center;

        &_phoneView {
            padding: 10px 20px;
            background: transparent;
            border-radius: 0.5rem;
            width: 100%;
            border: 1px solid #889abe;
            color: #889abe;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            justify-content: center;
        }
    }

    &_input {
        border: 1px solid #bfbfbf;
        padding: 10px;
        width: 100%;
        font-size: 14px;
        border-radius: 0.5rem;
        font-weight: 500;
        margin-top: 0.5rem;

        &::placeholder {
            color: #bfbfbf;

            @include respond(phone) {
                font-size: 12px;
            }


        }

        &.error {
            border: 2px solid var(--color-error);

            &::placeholder {
                color: var(--color-error);
            }
        }

        &:focus {
            border: 1px solid #234ba2;
        }

        &.error:focus {
            &::placeholder {
                color: #234ba2;
            }
        }

        @include respond(phone) {
            font-size: 12px;
        }
    }

    &_inputDate {
        border: none;
        border-bottom: 1px solid #ebebeb;
        padding: 10px;
        font-size: 14px;
        text-align: center;

        &::placeholder {
            color: #bfbfbf;

            @include respond(phone) {
                font-size: 12px;
            }
        }

        @include respond(phone) {
            font-size: 12px;
        }
    }

    &_workZoneContainer {
        position: relative;
        display: flex;
        border: 1px solid #bfbfbf;
        background-color: #fff;
        border-radius: 0.5rem;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #bfbfbf;
        font-size: 14px;

        &:focus-within {
            border: 2px solid #234ba2;
        }

        &.error {
            border: 2px solid var(--color-error);

            &::placeholder {
                color: var(--color-error);
            }
        }

        &_input {
            border: none;
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            color: #000;

            &::placeholder {
                color: #bfbfbf;

                @include respond(phone) {
                    font-size: 12px;
                }
            }

            &.error {
                &::placeholder {
                    color: var(--color-error);
                }
            }
        }
    }

    &_workZoneInput {
        border: none;
        width: 100%;

        &.disabledInput {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }

        &::placeholder {
            color: #959ba3;

            @include respond(phone) {
                font-size: 12px;
            }
        }
    }

    &_workZoneList {
        position: absolute;
        z-index: 1;
        background-color: #fff;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        max-height: 150px;
        top: 100%;
        left: 0;
        overflow-y: auto;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0.5rem;

        &_item {
            padding: 5px;
            cursor: pointer;
            transition: all 0.3s;
            font-size: 14px;
            display: flex;
            gap: 1rem;
            text-align: center;
            color: #000;

            &:hover {
                background-color: rgb(239, 246, 255);
            }

        }
    }

    &_workCityList {
        position: absolute;
        z-index: 1;
        background-color: #fff;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        max-height: 150px;
        top: 100%;
        width: 100%;
        left: 0;
        overflow-y: auto;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

        &_item {
            padding: 5px;
            cursor: pointer;
            transition: all 0.3s;
            font-size: 14px;
            display: flex;
            gap: 1rem;

            &:hover {
                background-color: rgb(239, 246, 255);
            }

        }
    }

    &_workZoneArrowUp {
        cursor: pointer;
        transition: all 0.3s;
        transform: rotate(0deg);

        &.disabledInput {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }

    }

    &_workZoneArrowDown {
        cursor: pointer;
        transition: all 0.3s;
        transform: rotate(180deg);

        &.disabledInput {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &_workZoneCityContainer {
        position: relative;
        display: flex;
        border: 1px solid #bfbfbf;
        border-radius: 0.5rem;
        padding: 1rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &.disabledInput {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &_workZoneSelected {
        display: flex;
        gap: 5px;
        padding-bottom: 10px;
        flex-wrap: wrap;

        &_item {
            background: #234ba2;
            color: #fff;
            padding: 5px 10px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            color: #edf0f7;
            text-align: center;
            transition: all 0.3s;

            &:hover {
                background: #edf0f7;
                color: #234ba2;
            }

        }
    }

    &_skillsSelected {
        display: flex;
        gap: 5px;
        padding-bottom: 10px;
        flex-wrap: wrap;

        &_item {
            background: #234ba2;
            color: #fff;
            padding: 5px 10px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            color: #edf0f7;

            &:hover {
                background: #edf0f7;
                color: #234ba2;
            }


        }

        &_list {
            position: absolute;
            z-index: 1;
            background-color: #fff;
            border: 1px solid #ebebeb;
            border-radius: 5px;
            max-height: 150px;
            top: 100%;
            overflow-y: auto;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            gap: 1rem;
            padding: 0.5rem;
        }

        &_itemList {
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: all 0.3s;
            font-size: 14px;
            text-align: center;
            font-weight: 400;
            color: rgb(55 65 81);
            border-radius: 5px;
            display: flex;
            align-items: center;
            gap: 1rem;

            &:hover {
                background-color: rgb(239 246 255);
            }

        }
    }
}

.formGroup {
    margin-top: 1.5rem;
    position: relative;
    display: flex;
    gap: 30px;
    font-size: 14px;

    @include respond(phone) {
        gap: 10px;
        flex-direction: column;
    }

    &_label {
        font-weight: 500;
        color: rgb(55 65 81);
    }

    &_workZone {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        position: relative;

        &_inputsContainer {
            display: flex;
            gap: 10px;
            width: 100%;
            position: relative;

            @include respond(phone) {
                gap: 1rem;
                flex-direction: column;
            }
        }

    }

    &_textInputContainer {
        position: relative;
        width: 100%;
    }

    &_dateInputContainer {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &_skills {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &_checkBox {
        margin-top: 1.5rem;
        position: relative;
        display: flex;
        gap: 1rem;
        font-size: 14px;
        font-weight: 500;
    }

    &_label {
        text-align: left;
        font-size: 14px;

        @include respond(phone) {
            font-size: 12px;
        }
    }
}

.termsContainer {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
    gap: 5px;
    font-size: 14px;


    &_text {
        font-size: 14px;
    }

}

.buttonsContainer {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    &_btn {
        background: rgb(37 99 235);
        color: #fff;
        padding: 10px 20px;
        border-radius: 0.5rem;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s;
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;

        &:disabled {
            background: #959ba3;
            color: #fff;
            cursor: not-allowed;

            &:hover {
                background: #959ba3;
            }
        }

        &:hover {
            background: #1a3a7a;
        }

    }
}