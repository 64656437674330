@use "../../utils/mediaQuerys" as *;

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.generalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem;
  overflow: hidden;

  @include respond(phone) {
    padding-top: 70px;
  }

}

.headerWrapper {
  overflow: hidden;
}

.header {
  position: relative;
  background: linear-gradient(to right,
      rgba(49, 46, 129, 0.9),
      rgba(30, 58, 138, 0.8));
  padding: 2rem;
  border-radius: 0.75rem;
  margin-bottom: 2.4rem;
  overflow: hidden;

  &_title {
    color: #fff;
    font-size: 2.6rem;
    margin-bottom: 1.6rem;
    display: flex;
    text-align: left;
  }

  &_subTitle {
    color: rgb(224 231 255);
    font-size: 1.8rem;
    line-height: 1.3;

  } 

  &_closeButton {
    position: absolute;
    top: 0;
    right: 2rem;
    background: none;
    border: none;
    font-size: 3.5rem;
    cursor: pointer;
    color: #fff;

    &:hover {
      color: #495057;
    }
  }
}


/* header {
  background: linear-gradient(to right,
      rgba(49, 46, 129, 0.9),
      rgba(30, 58, 138, 0.8));
  padding: 2rem;
  border-radius: 0.75rem;
  margin-bottom: 2.4rem;

  &_title {
    color: #fff;
    font-size: 2.6rem;
    margin-bottom: 1.6rem;
    display: flex;
    text-align: left;

  }

  &_subTitle {
    color: rgb(224 231 255);
    font-size: 1.8rem;
    line-height: 1.3;

  } 
}*/

.body {
  border: 1px solid var(--color-buttonBorder);
  border-radius: 0.75rem;
  padding: 2rem;
  background: #fff;
  margin-bottom: 2.4rem;

  &_container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 3rem;

    @include respond(phone) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &_section {
      display: flex;
      flex-direction: column;

      &_title {
        font-size: 2.4rem;
        color: rgb(17 24 39);
        font-weight: 600;
      }

      &_buttonContainer {
        margin-top: 2rem;
      }

      &_buttonPrimary {
        padding: 1.6rem 2.4rem;
        background-color: rgb(79 70 229);
        color: #fff;
        border-radius: 0.75rem;
        font-size: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;
        transition: all 0.3s;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

        &:hover {
          background-color: rgb(37 99 235);
          transform: scale(1.05);
        }
      }

      &_buttonSecondary {
        padding: 1.6rem 2.4rem;
        background-color: rgb(16 185 129);
        color: #fff;
        border-radius: 0.75rem;
        font-size: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;
        margin-top: 1.6rem;
        transition: all 0.3s;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

        &:hover {
          background-color: rgb(5 150 105);
          transform: scale(1.05);
        }
      }

      &_button {
        padding: 1.2rem 2.4rem;
        background-color: #fff;
        color: rgb(55 65 81);
        border: 2px solid rgb(229 231 235);
        border-radius: 0.75rem;
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;
        margin-top: 1.6rem;
        transition: all 0.3s;

        &:hover {
          background-color: rgb(249 250 251);
        }
      }

      &_buttonBadge {
        padding: 0.4rem 1rem;
        background-color: rgb(79 70 229);
        color: #fff;
        border-radius: 9999px;
        font-size: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
      }

      &_supplier {
        margin-top: 3rem;
        padding: 1.6rem;
        border-radius: 0.7rem;
        border: 1px solid rgb(224 231 255);
        background: linear-gradient(to right,
            rgba(239, 246, 255, 0.5),
            rgba(238, 242, 255, 0.5));

        &_title {
          color: rgb(17 24 39);
          font-size: 1.8rem;
          font-weight: 600;
          margin-bottom: 1.2rem;
        }

        &_description {
          color: rgb(75 85 99);
          margin-bottom: 1.6rem;
          line-height: 1.5;
          font-size: 1.6rem;
          font-weight: 500;
        }

        &_buttonContainer {
          color: rgb(79 70 229);
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 1.6rem;
          font-weight: 500;
        }
      }
    }
  }
}

.footer {
  background: linear-gradient(to right,
      #eff6ff,
      #eef2ff);

  border-radius: 0.75rem;
  border: 1px solid #edf0f7;
  box-shadow: var(--shadow);

  &_container {
    padding: 2rem;

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3.2rem;

      @include respond(phone) {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
      }

      &_title {
        display: flex;
        flex-direction: column;
        flex: 1;

        &_text {
          display: flex;
          gap: 1rem;
          margin-bottom: 0.8rem;
          font-size: 2rem;
          color: rgb(17 24 39);
          font-weight: 700;
          align-items: flex-start;
        }
      }

      &_description {
        color: rgb(75 85 99);
        font-size: 1.6rem;
        line-height: 1.3;
        max-width: 80%;

        @include respond(phone) {
          max-width: 100%;
        }
      }

      &_button {
        padding: 1.2rem 2.4rem;
        border: 2px solid rgb(79 70 229);
        background-color: #fff;
        color: rgb(79 70 229);
        display: flex;
        align-items: center;
        gap: 1rem;
        border-radius: 0.75rem;
        transition: all 0.3s;

        &:hover {
          transform: scale(1.05);
        }

      }
    }

    &_carousel {
      overflow: hidden;
      position: relative;

      &_container {
        display: flex;
        animation: scroll 10s linear infinite;
        width: max-content;

        &.paused {
          animation-play-state: paused;
        }
      }

      &_item {
        padding: 2.4rem;
        margin: 0 1.2rem;
        width: 20rem;
        background: #fff;
        border-radius: 0.75rem;
        cursor: pointer;
        flex: 0 0 auto;

        &_image {
          width: 100%;
          height: 12rem;
          object-fit: cover;
          aspect-ratio: 1/1;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgb(79 70 229);

          &_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.75rem;
          }
        }

        &_text {
          margin-top: 1.6rem;
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
          align-items: center;
        }

        &_name {
          color: rgb(17 24 39);
          font-size: 1.6rem;
          font-weight: 600;
          text-align: center;
          word-break: break-word;
        }

        &_email {
          color: rgb(107 114 128);
          text-align: center;
          word-break: break-word;
        }
      }
    }
  }
}