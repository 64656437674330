@use "../../utils/mediaQuerys" as *;;

.container {
  justify-self: flex-start;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
}

.stepsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.stepsProgress {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.ball {
  width: 24px;
  height: 24px;
  background-color: #e8e8e8;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
  transition: background-color 0.3s, border-color 0.3s;

  &.active {
    background-color: var(--color-mainBlue);
    border: 1px solid var(--color-mainBlueBorder);
  }

  span {
    font-size: 14px;
  }
}

.line {
  width: 40px;
  height: 2px;
  background-color: #d5d5d5;
  transition: background-color 0.3s;

  &.activeLine {
    background-color: var(--color-mainBlue);
  }
}


