@use "../../utils/mediaQuerys" as *;


.search {
    display: flex;
    flex-direction: column;
    width: 30vw;
    background-color: #fff;
    height: 100vh;
    animation: slideIn 0.5s ease-out forwards;

    @include respond(phone) {
        width: 80vw;
        height: 90vh;
        border-bottom-right-radius: 5px;
    }

    @keyframes slideIn {
        from {
            transform: translateX(-100%);
            opacity: 0;
        }

        to {
            transform: translateX(0);
            opacity: 1;
        }


    }

    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.6rem;
        border-bottom: 1px solid #e5e7eb;

        &_buttonClose {
            display: flex;
            background-color: transparent;
        }

    }

    &_title {
        color: rgb(17 24 39);
        font-size: 1.8rem;
        font-weight: 600;

    }

    &_topSkills {
        display: flex;
        flex-direction: column;
        padding: 1.6rem;

        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0;
            background-color: #fff;
            color: rgb(55 65 81);
            border-radius: 0.75rem;
            transition: background-color 0.3s;

            &:hover {
                background-color: rgb(243 244 246);
            }

            &_skill {
                display: flex;
                gap: 0.5rem;
                align-items: center;
            }

            &_iconContainer {
                display: flex;
                align-items: center;

            }

            &_icon {
                min-width: 2.4rem;

            }

            &_name {
                text-align: left;
            }

            &_count {
                background-color: rgb(243 244 246);
                border-radius: 9999px;
                padding: 0.4rem;
            }
        }
    }

    &_allSkills {
        padding: 1.6rem;

        &_container {
            display: flex;
            flex-direction: column;
        }

        &_item {
            background-color: #fff;
            padding: 1rem 0;
            border-radius: 0.75rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            transition: background-color 0.3s;

            &_name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:hover {
                background-color: rgb(243 244 246);
            }

        }

        &_pagination {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 0.5rem;
            margin-top: 2.4rem;

            &_button {
                background-color: transparent;
                border-radius: 9999px;
                align-items: center;
                justify-content: center;
                padding: 0.5rem;
                width: 2.4rem;
                height: 2.4rem;
                display: flex;
                color: rgb(107 114 128);

                &_active {
                    background-color: rgb(224 234 255);
                    color: rgb(37 99 235);
                }
            }
        }
    }

    &_alphabeticalSkills {
        display: flex;
        flex-direction: column;
        padding: 1.6rem;

        &_lettersContainer {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
        }

        &_letter {
            color: rgb(55 65 81);
            background-color: rgb(243 244 246);
            border-radius: 9999px;
            padding: 0.4rem;
            width: 2.4rem;
            height: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.1s;

            &:hover {
                background-color: rgb(229 231 235);
                color: rgb(55 65 81);
            }

            &_active {
                background-color: rgb(224 234 255);
                color: rgb(37 99 235);

                &:hover {
                    background-color: rgb(224 234 255);
                    color: rgb(37 99 235);
                }
            }


        }

        &_categoriesContainer {
            margin-top: 2.4rem;
        }

        &_categoriesGroup {
            display: flex;
            flex-direction: column;
            margin-top: 0.8rem;

            &:first-child {
                margin-top: 0;
            }
        }

        &_categoriesHeader {
            display: flex;
            align-items: center;
            gap: 1rem;
            background-color: transparent;

            &_letter {
                color: rgb(31 41 55);
                font-weight: 600;
                font-size: 1.6rem;
            }

        }

        &_chevron {
            min-width: 1.6rem;
            transition: transform 0.3s;

            &_expanded {
                transform: rotate(180deg);
            }
        }

        &_categoriesDivider {
            background-color: rgb(229 231 235);
            height: 1px;
            width: 100%;
        }

        &_categoriesCount {
            background-color: rgb(243 244 246);
            border-radius: 9999px;
            padding: 0.4rem;
            width: 2.4rem;
            height: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        &_categoryItem {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 1rem 0;
            background-color: #fff;
            border-radius: 0.75rem;
            transition: background-color 0.3s;

            &:hover {
                background-color: rgb(243 244 246);
            }

            &_name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    &_searchBar {
        overflow: auto;
        margin-bottom: 2.4rem;
    }

    &_selectedFilters {
        padding: 1.6rem;
        border-bottom: 1px solid #e5e7eb;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        &_filter {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem;
            background-color: rgb(219, 234, 254);
            border-radius: 9999px;
            color: rgb(30, 64, 175);
            font-weight: 500;
            transition: background-color 0.3s;

            &_icon {
                min-width: 1.6rem;
            }

            &:hover {
                background-color: rgb(224, 234, 255);
            }

        }

    }

    &_body {
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
        overflow: auto;
    }

    &_filter {
        padding: 1.6rem;
        background-color: transparent;
        border-bottom: 1px solid #e5e7eb;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_title {
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        &_icon {
            display: flex;
        
        }
    }

    &_footer {
        padding: 1.6rem;

        &_button {
            padding: 1.2rem 2.4rem;
            background-color: rgb(37 99 235);
            color: #fff;
            border-radius: 0.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 1.6rem;
            transition: all 0.3s;

            &:disabled {
                background-color: rgb(229 231 235);
                color: rgb(107 114 128);

                &:hover {
                    background-color: rgb(229 231 235);
                }
            }

            &:hover {
                background-color: rgb(37 99 235);
            }
        }
    }
}