@use "../../../utils/mediaQuerys" as *;

.modalContainer {
    display: flex;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.6);
}

.modal {
    width: 100%;
    height: 100%;
    display: flex;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(20px);

}

.centeredImage {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;

}

.modalContent {
    height: 100%;
    flex-direction: column;
    display: flex;
    overflow: hidden;
    border-top-left-radius: 11px;
    flex-basis: 70%;

    @include respond(phone) {
        border-top-left-radius: 0px;
        width: 100%;
        flex-basis: 100%;
    }

    &_carousel {
        position: relative;

        &_item {
            position: relative;
            min-width: 100%;
            max-width: 100%;
            min-height: 400px;
            max-height: 400px;

            @include respond(phone) {
                min-width: 100%;
                max-width: 100%;
                min-height: 200px;
                max-height: 200px;
            }
        }
    }

    
    &_img {
        min-height: 300px;
        max-height: 300px;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 11px;

        @include respond(phone) {
            min-width: 100%;
            max-width: 100%;
            min-height: 200px;
            max-height: 200px;
        }
    }

    &_body {
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &_info {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;

        @include respond(phone) {
            padding: 0px;
        }

        &_icons {
            display: flex;
            gap: 10px;
            margin-top: 5px;
            padding-left: 10px;
            align-items: center;
            margin-bottom: 10px;

            &_backGround {
                background-color: #edf0f7;
                border-radius: 50%;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        &_price {
            font-size: 16px;
            font-weight: 600;
        }

        &_pill {
            margin-top: 10px;
            margin-left: 10px;
            background-color: #234ba2;
            border-radius: 30px;
            min-width: 50px;
            max-width: 50px;
            min-height: 10px;
            max-height: 10px;
            margin-bottom: 10px;
        }

        &_location {
            position: absolute;
            background-color: #234ba2;
            bottom: 0;
            right: 0;
            color: #fff;
            padding: 5px 20px;
            font-size: 14px;
            font-weight: 600;
        }

        &_dates {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: #234ba2;
            color: #fff;
            border-radius: 30px;
            padding: 5px 20px;
            font-size: 14px;
            font-weight: 600;
        }

        &_title {
            font-size: 20px;
            font-weight: 700;
            padding: 0px 10px;
        }

        &_description {
            font-size: 14px;
            padding: 0px 10px;
            overflow: overlay;
            text-overflow: ellipsis;
            max-width: 100%;
            font-weight: 500;
            display: flex;
            white-space: normal;
            height: 100%;
            max-height: 200px;
        }
    }

    &_footer {
        display: flex;
        flex-direction: column;
        padding: 10px;
        background-color: #fff;
        width: 100%;
        flex-basis: 30%;

        @include respond(phone) {
            width: 100%;
            flex-basis: 0%;
            background-color: rgba(0, 0, 0, 0.6);
        }

        &_title {
            font-size: 20px;
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            cursor: pointer;
            color: #333333;
            padding: 10px 15px;

            @include respond(phone) {
                color: #fff;
                padding: 10px 0px;
            }

            svg g g {
                fill: #fff;
                stroke: #fff;
            }
        }

        &_answer {
            font-size: 14px;
            font-weight: 400;
            padding-top: 10px;
            display: flex;
            align-items: center;

            &_text {
                position: relative;
                padding-left: 10px;

                
            }

            &_image {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        &_button {
            padding: 10px;
            background-color: #234ba2;
            color: #fff;
            font-weight: 600;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            width: 100%;
            transition: background-color .3s;

            &:hover {
                background-color: #2b3b5b;
            }
        }

        &_questions {
            display: flex;
            height: 100%;
            flex-direction: column;

            @include respond(phone) {
                max-height: 200px;
                overflow: auto;
            }

            &_container {
                display: flex;
                flex-direction: column;
                overflow: overlay;
                height: 100%;
                width: 100%;
            }
        }

        &_question {
            font-size: 14px;
            font-weight: 600;
            padding: 10px 15px;

            @include respond(phone) {
                 color: #fff;   
                 padding: 10px 0px;  
            }
            
            &_text {
                font-size: 14px;
                font-weight: 600;
                display: flex;
                align-items: center;

                svg circle {
                    fill: #959CA3;
                }

                &_time {
                    font-size: 12px;
                    font-weight: 400;
                    color: #959CA3;
                }
            }

        }
    }

    .closeButton {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        z-index: 1;
    }

}

.arrowUp {
    transform: rotate(-180deg);
    transition: transform 0.3s ease;

}

.arrowDown {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}