.overLay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 6;
    backdrop-filter: blur(5px);
}

.body {
    background-color: #fff;
        border-radius: 11px;
        width: 500px;
        max-width: 80vw;
        position: relative;
        padding: 20px;
        max-height: 90%;
        overflow-y: auto;
}

.question {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 10px;
}

.sendButton {
    width: 100%;
    padding: 10px;
    background-color: #234ba2;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
        background-color: #2b3b5b;
    }
}
.closeButton {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-size: 20px;
    background-color: transparent;
    font-weight: 700;

}