@use "../../../../utils/mediaQuerys" as *;

.generalContainer {
  width: 100%;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: height 300ms ease-in-out;

  .buttonCambios {
    margin-top: 20px;
    width: 171px;
    height: 40px;
    color: white;
    background-color: #1e4776;
    border-radius: 10px;
    cursor: pointer;

    &:disabled {
      background-color: #e7e7e7;
      color: black;
      cursor: not-allowed;
    }
  }

  &.open {
    border: 2px solid #234BA2;
    box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.11);
    padding: 10px;
    border-radius: 8px;
  }

  .proyectActions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: solid 2px #3f66b3;
    background-color: #ffffff;
    height: 42.6px;
    border-radius: 5px;
    border: solid 2px #3f66b3;
    padding-left: 17.5px;
    cursor: pointer;
  }

  .proyectInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .inputFile {
      display: flex;
      border: 2px solid rgba(151, 151, 151, 0.49);
      border-radius: 10px;
      padding: 6.5px 15px;
      margin-top: auto;
    }
  }

  .description {
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: #3f66b3;
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.edit {
      &.open {
        background-color: #2d4ef5;
      }
    }
  }
}

.formContainer {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all 300ms ease-in-out;

  &.open {
    visibility: visible;
    opacity: 1;
    height: 100%;
    max-height: 500px;
  }
}

.fieldWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  position: relative;

  &_dateTitle {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    svg path {
      fill: #234ba2;
    }
  }

  &_hidden {
    display: none;
  }

  &_date {
    max-width: 200px;
    height: 42px;
    padding: 1px;
    color: #222222bd;
    background: #f7f7f7;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 13px;
    position: relative;
    cursor: pointer;

    ::placeholder {
      font-size: 12px;
      font-weight: 600;
    }
  }


  &_startDateInput {
    width: 100%;
    display: flex;
    padding: 1rem;
  }

  &_startDateInputPlaceholder {
    width: 100%;
    padding: 1rem;
    border: none;
    border-bottom: 1px solid #b7b7b7;
    text-align: left;
    font-weight: 500;
    resize: none;
    position: relative;
    cursor: pointer;
  }

  &_endDateInput {
    width: 100%;
    display: flex;
    padding: 1rem;
  }

  &_dateInputContainer {
    display: flex;
    width: 70%;
  }

  &_endDateInputPlaceholder {
    width: 100%;
    padding: 1rem;
    border: none;
    border-bottom: 1px solid #b7b7b7;
    text-align: left;
    font-weight: 500;
    resize: none;
    position: relative;
    cursor: pointer;
  }

  &_content {
    display: none;

    &.open {
      width: 100vw;
      height: 100vh;
      position: fixed;
      display: flex;
      justify-content: center;
      top: 0;
      align-items: center;
      left: 0;
      z-index: 2;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      background-color: rgba(0, 0, 0, 0.3);
    }

  }

  svg {
    width: 17px;
    height: 17px;
  }

  &_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &.detail {
      border-bottom: none;
    }
  }

  &_imageMask {
    width: 100%;
    top: 50%;
    left: 0;
    height: 37px;
    z-index: 100;
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
  }

  &.detail {
    border-right: 2px solid #ebebeb;
  }

  &_input {
    width: 100%;
    height: 42px;
    padding: 9px 9px 9px 30px;
    border-radius: 11px;
    border: none;
    background: #f7f7f7;
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: #222222bd;
    resize: none;
    margin-left: 13px;
    margin-bottom: 0;
  }

}

.fieldWrapperSubProyect {
  margin-bottom: 20px;

  &_hidden {
    display: none;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top: 20px;
  position: relative;
  border-top: 1px solid #d2d2d2;
  gap: 30px;
}

.subbutton {
  padding: 1rem;
  width: 100%;
  height: 100%;
  background: #234ba2;
  border-radius: 5px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  &_add {
    margin-right: 1rem;
    display: flex;
  }

  &_text {
    width: max-content;
    font-size: 1.6rem;
  }

  &_cancel {
    padding: 1rem;
    width: 100%;
    font-size: 1.6rem;
    color: #2b3b5b;
    font-weight: 500;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s, background-color 0.3s, border 0.3s;
    height: 100%;

    &:hover {
      background-color: #d6174a;
      color: white;
      border: none;

      svg path {
        fill: white;

      }
    }
  }
}

.trash {
  margin-right: 1rem;
  display: flex;

  svg path {
    fill: #2b3b5b;
  }

  &_rubroTitleTrash {
    margin-right: 0;
    margin-left: 10px;
    cursor: pointer;

    svg path {
      fill: #2b3b5b;
    }
  }
}

.tooltip {
  position: absolute;
  padding: 5px;
  background-color: black;
  color: white;
  border-radius: 5px;
}

.relative {
  position: relative;
  width: 100%;
}

.absolute {
  position: absolute;
  transform: translateY(50%);
  top: 20%;
  left: 20px;
}

.absolute2 {
  position: absolute;
  transform: translateY(50%);
  top: 8%;
  left: 20px;
}

.fieldWrapperLink {
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 1rem;
  text-align: left;
  color: rgba(34, 34, 34, 0.74);
  resize: none;
  display: flex;
  align-items: center;
}

.textAreaLink {
  height: 37px;
  padding: 10px 10px 10px 10px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #b7b7b7;
  text-align: left;
  color: #2b3b5b;
  font-weight: 500;
  cursor: text;

}

.fechaCierre {
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 0.5rem;
  width: 30%;
  font-size: 1.4rem;
}

.fileName {
  display: flex;

  @include respond(phone) {
    max-width: 230px;
  }
}

.fieldWrapperPlanilla {
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  resize: none;
  cursor: pointer;

  &_placeHolder {
    margin-left: 20px;
    width: 100%;
  }

  &_container {
    width: 100%;
  }
}

.tituloPlanilla {
  margin-left: 5px;
  font-size: 13px;
  color: #234ba2;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  h4 {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    color: #234BA2;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  svg path {
    fill: #234ba2;
  }
}

.fechasWrapper {
  display: flex;
  flex-direction: column;

  &_edit {
    display: flex;
  }
}

.titulosWrapper {
  display: flex;
  padding-bottom: 1px;
  padding-top: 20px;

  h4 {
    font-size: 14px;
    font-weight: bold;
    margin-left: -18px;
    color: #234BA2;
    text-align: center;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-top: 3px;
  }

  svg path {
    fill: #234ba2;
  }

  &_edit {
    display: flex;
    padding-top: 10px;

    h4 {
      font-size: 14px;
      font-weight: bold;
      margin-left: -12px;
      color: #234BA2;
      text-align: center;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-top: 3px;
    }

    svg path {
      fill: #234ba2;
    }
  }
}

.tituloDocu {
  color: #2b3b5b;
  font-weight: 600;
  font-size: 2.4rem;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 1rem;

  &_itemContainer {
    display: flex;
    padding-bottom: 10px;
    flex-direction: column;

    &_cover {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-left: 10px transparent solid;
      transition: border-left 0.3s;

      &:hover {
        border-left: 10px #2b3b5b solid;
      }
    }
  }

  &_arrow {
    cursor: pointer;
    display: flex;
    align-self: center;
  }

  &_link {
    color: #2b3b5b;
    font-weight: 500;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 5px;
  }

}

.tituloRubro {
  color: #2b3b5b;
  font-weight: 800;
  font-size: 11px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
}

.docusContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 0.75rem;
  min-width: 60rem;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.showDocus {
  border-radius: 0.75rem;
  padding: 2rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_tituloDocu {
    font-weight: 600;
    font-size: 2.4rem;
    padding: 0px 10px 20px 10px;
    line-height: 30px;
    text-align: center;
  }

  &_info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;

    &_text {
      font-size: 1.6rem;
      line-height: 1.5;
      text-align: center;
      color: rgb(75 85 99);
    }
  }

  &_buttonContainer {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 10px;
    margin-bottom: 10px;

    &_btnNo {
      width: 100%;
      color: #234ba2;
      font-weight: 500;
      border: 1px solid #234ba2;
      border-radius: 5px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #234ba2;
        color: white;
        border: none;

        svg path {
          fill: white;
        }
      }
    }

    &_btnSi {
      height: 100%;
      width: 100%;
      background: #234BA2;
      border-radius: 5px;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 10px;
      cursor: pointer;
      justify-content: center;

    }

  }
}

.tituloDescripcion {
  color: #2b3b5b;
  font-weight: 800;
  font-size: 11px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #ebebeb;

  &_textArea {
    padding: 1rem;
    border-radius: 5px;
    background: #fff;
    font-weight: 500;
    text-align: left;
    resize: none;
    min-height: 100px;
    width: 100%;
    border: 1px solid #d2d2d2;
  }

}

.tituloFechas {
  color: #2b3b5b;
  font-weight: 800;
  font-size: 11px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #ebebeb;
}

.formHidden {
  display: hidden;
}

.radioGroup {
  display: flex;
  padding: 1rem;

  &_label {
    width: 100%;
    color: #2b3b5b;
    display: flex;
    align-items: center;
    justify-content: center;

    &_input {
      margin-right: 5px;
      cursor: pointer;
    }

  }
}

.fileName[type="file"]::-webkit-file-upload-button {
  border: 1px solid #234ba2;
  background: #234ba2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
}

.arrowIconRight {
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}

.arrowIconDown {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}