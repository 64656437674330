@use "../../utils/mediaQuerys" as *;

.contentContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.content {
  width: 50%;
  min-height: 100vh;
  @include respond(tab-port) {
    width: 100%;
  }
}
.background {
  background: url("../../assets/images/foto-bienvenida.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.registerContainer {
  margin: 0 auto;
  display: flex;
  padding: 20px;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  @media (max-width: 1024px) {
    align-items: flex-start;
  }
}
.register-content {
  width: 100%;
  margin-top: 16px;
  max-width: 368px;
  .title {
    text-align: left;
  }
  .sub {
    font-size: 25px;
    font-weight: 400;
    line-height: 1.2;
    word-break: break-word;
    font-family: "Manrope", sans-serif;
  }
}
.logo {
  text-align: center;
  margin-bottom: 25px;
  & img {
    width: 120px;
  }
}
.title {
   color: #000000;
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 42.7px;
  margin-bottom: 6px;
  @include respond(tab-port) {
    font-size: 3rem;
  }
}
.content-data {
  width: 50%;
  padding-left: 180px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  min-height: 100vh;
}
.content-texts,
.content-footer {
  max-width: 400px;
}

.subtitle {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.31;
  max-width: 456px;
  opacity: 0.67;
  color: var(--color-black);
  @include respond(tab-port) {
    font-size: 1.8rem;
  }
}

input[name*="password"] ~ {
}

.forgotPassword {
  position: relative;
  color: #2f6aae;
  font-weight: 400;
  cursor: pointer;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: width 0.5s;
  width: 51px;
  height: 20px;
  overflow: hidden;
  width: 353px;
  opacity: 1;
  justify-content: center;

  @include respond(tab-port) {
    position: relative;
    width: 175px;
    top: initial;
    left: initial;
    transform: none;
    margin-top: 10px;
    .forgotPasswordText {
      opacity: 1;
    }
  }
  &:hover {
    transition: all 0.2s;
    transition: all 0.2s ease-in-out;
    position: relative;
    font-size: 15.5px;
    font-weight: 600;
    line-height: 17px;
    color: var(--color-mainBlue);
  }
}

.forgotPasswordText {
  margin-left: 1.1rem;
  opacity: 1;
  transition: opacity 0.5s;
}

.viewPasswordError svg {
  fill: var(--color-error);
}
.label {
  display: block;
  color: #4391cd;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 6px;
}

.invalid-feedback {
  padding-left: 15px;
  margin-top: 3px;
  & .text {
    font-weight: 300;
    color: #979797;
  }
}

.newUserContainer {
  margin-top: 28.5px;
  &::before {
    content: "";
    display: block;
    width: 75%;
    height: 2px;
    background-color: #bfbfbf;
    opacity: 0.67;
    margin: auto;
  }
}
.newUser {
  color: var(--color-mainBlue);
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  margin: 15px 0;
  display: block;
}
.btn_skip {
  background-color: var(--color-white);
}
.btn,
.registerButton {
  padding: 15px 30px;
  color: var(--color-white);
  outline: none;
  font-size: 15.5px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  width: 100%;
  display: block;
  border: 2px solid var(--color-mainBlueBorder);
  background-color: var(--color-mainBlue);
  transition: all 0.2s;
  border-radius: 7px;
  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    color: white;
    transform: translateX(1px);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
  &.forgot {
  }
}
.error {
  border: 2px solid var(--color-error);
  background-color: #e84f78;
}
.copyright {
  max-width: 268px;
  margin-top: 50px;
  color: #707070;
  opacity: 0.47;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.modaloverlay {
  background: rgba(0, 0, 0, 0.55);
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  margin: 50px auto 0 auto;
  overflow: auto;
  z-index: 101;
  width: 400px;
  max-width: 100vw;
  height: 300px;
  max-height: 90vh;
  padding: 35px;
  & .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    & img {
      max-width: 15px;
    }
  }
}
.modalImg {
  text-align: center;
  margin-bottom: 8px;
  & img {
    max-width: 70px;
  }
}

.modalH1 {
  text-align: center;
  font-size: 27px;
  font-weight: 700;
  line-height: 32.03px;
  @media (max-width: 1024px) {
    margin: 0;
  }
  & + .form-group {
    & button {
      margin-top: 25px;
    }
  }
}

.modalH4 {
  margin: 9px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}
@media (max-width: 1300px) {
  .content-data {
    padding-left: 80px;
  }

  .content-background {
    background-position: center;
  }
}
@media (max-width: 767px) {
  .content-data {
    padding-left: 0px;
  }
  .content-data {
    width: 100%;
    padding: 0 15px;
  }
  .content-background {
    display: none;
  }
  .content {
    width: 100%;
    &.background {
      display: none;
    }
  }
  .register-container {
    padding: 15px;
  }
}

.formTitle {
  color: var(--color-mainBlue);
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  margin-top: 45px;
}
.viewPassword {
  position: absolute;
  z-index: 2;
  top: 15%;
  left: 0%;
  transform: translate(75%, -50%);
  cursor: pointer;
  max-width: 20px;
  max-height: 20px;
  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-mainBlue);
    margin-top:-5px
  }
}
@media only screen and (max-width: 600px) {
  .viewPassword {
    position: absolute;
    z-index: 2;
    top: 16%;
    left: 0%;
    transform: translate(75%, -50%);
    cursor: pointer;
    max-width: 20px;
    max-height: 20px;
    svg {
      width: 20px;
      height: 20px;
      fill: var(--color-mainBlue);
    }
  }
  .forgotPassword {
    position: relative;
    width: 175px;
    top: 7px;
    left: 70px;
    transform: none;
    margin-top: 10px;
  }
}
.hover {
  transition: all 0.2s;
  padding: 20px 0 0 0;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
  position: relative;
  font-size: 17.5px;
  font-weight: 600;
  line-height: 21px;
  color: var(--color-mainBlue);

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    width: 0px;
    height: 2px;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    background-color: var(--color-mainBlueBorder);
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }

  &:hover {
    &:before {
      width: 150px;
    }
    &:after {
      width: 150px;
    }
  }
}
.passwordError {
  color: #e84f78;
  padding-top: 2rem;
  display:flex;
}
.emailError {
  color: #e84f78;
  display:flex;
  
}
