@use "../../../../utils/mediaQuerys" as *;

.navBar {
    background-color: #fff;
    margin-bottom: 2.4rem;
    width: 100%;
    display: flex;
    overflow: auto;


    &_wrapper {
        width: 100%;
     
    }

    &_nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-mainBorder);
    }

    &_button {
        color: rgb(107 114 128);
        font-weight: 500;
        padding: 1.6rem 2.4rem;
        background-color: transparent;
        border-bottom: 1px solid transparent;


        &:hover {
            color: rgb(55 65 81);
            border-bottom: 1px solid rgb(209 213 219 );
        }

        &_active {
            color: rgb(37 99 235);
            border-bottom: 2px solid rgb(37 99 235);

            &:hover {
                color: rgb(37 99 235);
                border-bottom: 2px solid rgb(37 99 235);
            }
        }
    }
}