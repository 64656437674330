@use "../../../utils/mediaQuerys" as *;


.container {
  display: flex;
  width: 100%;
  height: 100vh;

}

.selectType {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background-color: #f7f7f7;
  overflow: auto;

  &_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 700px;
    border: 1px solid #edf0f7;
    border-radius: 18px;
    padding: 20px;
    background-color: #fff;

    @include respond(tab-port) {
      width: 100%;
    }


  }

  &_formContainer {
    display: flex;
    flex-direction: column;
  }
}
.typeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 150ms;
  background-color: #f7f8f9;
  border: 2px solid #bfbfbf;
  border-radius: 7px;
  transition: all 300ms ease-in-out;
  padding: 1rem 4rem;

  @include respond(tab-port) {
    padding: 1rem 2rem;
  }

  &:first-child {
    opacity: 0.44 !important;
    transform: translateX(-15px) scale(1.15);
  }
  &:last-child {
    opacity: 0.44 !important;
    transform: translateX(15px) scale(1.15);
  }
}

.active {
  border: 3px solid var(--color-mainBlue);
  &:first-child {
    opacity: 1 !important;
    transform: scale(1.25) translateX(-15px);
  }
  &:last-child {
    opacity: 1 !important;
    transform: scale(1.25) translateX(15px);
  }
}


.copyright {
  max-width: 268px;
  margin-top: 20px;
  color: #707070;
  opacity: 0.47;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}


.selectTypeContainer {
  display: flex;
  gap: 10px;
  padding: 4rem;

  svg {
    width: 65px;
    margin: 1rem 0;

    @include respond(tab-port) {
      margin: 0.5rem 0;
    }
  }
}
.buttonType {
  margin: 1rem 0;
  color: var(--color-mainBlue);
  font-weight: bold;
  font-size: 2rem;

  @include respond(tab-port) {
    margin: 0.5rem 0;
  }
}


.title {
  @include respond(tab-port) {
    font-size: 3rem;
  }
}

.formTitle {
  color: var(--color-mainBlue);
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  margin-top: 25px;
  @include respond(tab-port) {
    margin-top: 20px;
  }
}

.formSubtitle {
  color: var(--color-black);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  padding: 1rem;
  opacity: 0.67;
  text-align: center;
}

.selectTypeContainerButtons {
  display: flex;
  justify-content: space-between;

}
.hover {
  transition: all 0.2s;
  transition: all 0.2s ease-in-out;
  position: relative;
  font-size: 17.5px;
  font-weight: 600;
  line-height: 21px;
  color: var(--color-mainBlue);

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    width: 0px;
    height: 2px;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    background-color: var(--color-mainBlueBorder);
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }

  &:hover {
    &:before {
      width: 40px;
    }
    &:after {
      width: 40px;
    }
  }
}
