.Faq {
    padding: 40px;
    text-align: center;

    &_title {
        font-size: 20px;
        font-weight: 600;
        color: #2b3b5b;

    }

    &_subtitle {
        width: 100%;
        padding: 20px;
        background: #004F98;
        border-radius: 11px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        word-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}