@use "../../../../../utils/mediaQuerys" as *;

.commentContainer {
  display: flex;
  border-radius: 0.75rem;
  border: 1px solid #e5e7eb;
  overflow-x: overlay;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &_userSender {
    display: flex;
    align-items: center;
    border-right: 1px solid #ebebeb;
    flex-direction: column;
    width: 100%;

    &_header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #ebebeb;

      @include respond(phone) {
        font-size: 12px;
      }

    }

    &_body {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 10px;
      flex-direction: column;
      width: 100%;

      @include respond(phone) {
        align-items: center;
      }

      &_title {
        padding-left: 10px;

        @include respond(phone) {
          padding-left: 0px;
        }
      }

      &_user {
        display: flex;
        flex-direction: column;
        align-items: center;

        &_validate {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 100%;

          @include respond(phone) {
            display: none;
          }

          &_txt {
            font-weight: 700;
            padding: 10px;
          }

          &_image {
            width: 50px;
            height: 50px;
          }
        }
      }

      &_imageContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px 0px 0px 10px;
        cursor: pointer;
        width: 100%;

        @include respond(phone) {
          padding: 10px 0px 0px 0px;
        }

        &_img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }

      &_data {
        font-weight: 700;
        padding: 10px;
      }
    }
  }

  &_commentData {
    display: flex;
    width: 100%;
  }

  &_comment {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;

    &_header {
      display: flex;
      align-items: center;
      padding: 10px;
      font-weight: 400;

      @include respond(phone) {
        font-size: 12px;
      }

    }

    &_body {
      font-size: 12px;
      height: 100%;
      color: #2b3b5b;
      position: relative;
      font-weight: 500;

      &_txt {
        padding: 30px;

        @include respond(phone) {
          padding: 0 30px 30px 30px;
        }
      }
    }
  }

  &_date {
    display: flex;
    flex-direction: column;

    &_body {
      font-size: 12px;
      color: #959ba3;
      display: flex;
      position: absolute;
      bottom: 10px;
      right: 10px;

      @include respond(phone) {
        font-size: 10px;
      }

    }
  }

  &_skills {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid #ebebeb;
    height: 100%;
    width: 100%;
    padding: 10px;

    @include respond(phone) {
      align-items: center;
    }

    &_header {
      display: flex;
      align-items: center;
      font-weight: 400;

      @include respond(phone) {
        font-size: 12px;
      }
    }

    &_body {
      font-size: 12px;
      font-weight: 500;
      color: #2b3b5b;
      padding: 20px;
      display: flex;

      @include respond(phone) {
        padding: 10px 0px 0px 0px;
      }
    }
  }

  &_score {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    justify-content: center;

    &_header {
      display: flex;
      align-items: center;
      padding: 10px;
      font-weight: 400;

      @include respond(phone) {
        font-size: 12px;
      }
    }

    &_body {
      font-size: 14px;
      padding: 10px;
      display: flex;
    }

    &_footer {
      font-size: 30px;
      font-weight: 600;
    }
  }
}