.boton {
    display: flex;
    width: 100%;
    align-items: center;
    color: black;
    height: 100%;

    &_iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 10px;

    }

    &_nombre {
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        color: #2b3b5b;
        transition: color 0.3s ease-in-out;

        &.isSelected {
            color: #234ba2;
        }
    }

    &_icon {
        height: 100%;
        display: flex;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
            fill: #2b3b5b;
        }

        svg path {
            fill: #2b3b5b;
        }

        &.isSelected {

            svg path {
                fill: #234ba2;
            }

            svg {
                fill: #234ba2;
            }
        }
    }
}