@use "../../../utils/mediaQuerys";

.adjudicadosItemCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.75rem;
  border: 1px solid #edf0f7;

  &_backGround {
    display: flex;
    position: relative;
    height: 18rem;

    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 70%);
      z-index: 1;
      width: 100%;
      height: 100%;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }

    &_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }

    &_data {
      position: absolute;
      display: flex;
      bottom: 1rem;
      left: 2rem;
      color: white;
      -webkit-box-align: center;
      align-items: center;
      z-index: 1;

      &_logo {
        min-width: 5rem;
        max-width: 5rem;
        min-height: 5rem;
        max-height: 5rem;
        border-radius: 1.5rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #fff;
        object-fit: cover;

        &_img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &_info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem;

        &_name {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          word-break: break-word;
        }
      }


    }

  }

  &_title {

    font-weight: 600;
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  &_content {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 1rem;
    padding: 1rem;
    flex: 1;

    &_zone {
      display: flex;
      align-items: flex-start;
      color: rgb(75, 85, 99);
      gap: 1rem;
      position: relative;
    }

    &_workType {
      display: flex;
      align-items: flex-start;
      color: rgb(75, 85, 99);
      gap: 1rem;
      position: relative;

      &_titles {
        font-size: 1.5rem;
        line-height: 2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        &_rubro {
          background-color: #edf0f7;
          padding: 0.5rem 1rem;
          border-radius: 0.75rem;
          color: #003366;
        }

      }
    }

    &_dates {
      display: flex;
      align-items: center;
      color: rgb(75, 85, 99);
      gap: 1rem;

      &_date {
        position: relative;
      }
    }

    &_budget {
      display: flex;
      align-items: center;
      color: rgb(75, 85, 99);
      gap: 1rem;

      &_price {
        position: relative;
      }
    }

    &_button {
      display: flex;
      width: 100%;
      padding: 2rem 1rem;

      &_btn {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        width: 100%;
        background-color: #004f98;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
        font-size: 1.5rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #003366;

        }
      }
    }
  }
}