@use "../../../utils/mediaQuerys" as *;

.container {
    min-height: 100%;
    min-width: 100%;
    padding: 2rem;

    @include respond(phone) {
      padding: 0;
  }
  }
  
  .content {
    width: 100%;

    @include respond(phone) {
      padding-top: 8rem;
      padding-left: 2rem;
      padding-right: 2rem;

  }
  }
  
  .header {
    margin-bottom: 1.5rem;
    display: flex;
    background-color: #fff;
    padding: 1rem 0;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: 1px solid var(--color-mainBorder);

  }
  
  .title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: flex;
    text-align: left;
  }
  
  .subtitle {
    font-size: 1.3rem;
    color: #6b7280;
  }
  
  .layout {
    display: grid;
    gap: 3rem;
  
    @media (min-width: 768px) {
      grid-template-columns: 1fr 300px;
    }
  }
  
  .mainContent {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 2rem;
    border-left: 1px solid var(--color-mainBorder);
  }
  
  .card {
    background-color: #ffffff;
    padding: 2rem 0;
  }
  
  .cardTitle {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .cardSubtitle {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 1.5rem;
  }
  
  .infoList {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .infoItem {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .infoItem1{
 
    flex-direction: column;
  }
  
  .icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #9ca3af;
    margin-top: 0.125rem;
  }
  
  .infoTitle {
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  

  
  .expandButton {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #2563eb;
    font-size: 1.2rem;
    padding: 0;
    margin-top: 0.5rem;
    cursor: pointer;
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  .buttonIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem;
  }
  
  .list {
    list-style-type: disc;
    padding-left: 1.25rem;
    color: #4b5563;
  }
  
  .downloadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    color: #374151;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #f3f4f6;
    }
  }
  
  .infoContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; 
    line-height: 1.5; 
  }
  
  .infoLine {
    display: flex;
    gap: 0.5rem;
  }
  
  .infoSubtitle {
    font-weight: bold;
    color: #000;
  }
  
  .infoValue {
    font-weight: normal;
    color: #555;
  }