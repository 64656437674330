.progressBarLoader {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #e0e0e0;
    z-index: 3; 
}

.progressBar {
    height: 100%;
    width: 0;
    background: linear-gradient(90deg, #234ba2, #4a6bb5, #8aa7e0); 
    animation: progress 2s infinite; 
}

@keyframes progress {
    0% {
        width: 0;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 100%;
    }
}