@use "../../../../utils/mediaQuerys" as *;

@keyframes loadingStatus {
  from {
    background-color: #b5b7b5;
  }
  to {
    background-color: rgb(226, 226, 226);
  }
}
.animationIsLoaded {
  animation: loadingStatus 1s ease-in-out infinite;
  transition: all 0.3 ease-in-out;
}

.MisCotizaciones {
  display: flex;
  flex-direction: column;
  
  .imageWrapper {
    max-width: 75px;
    .Logo {
      width: 100%;
    }
  }
  .emptyProyectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 3rem 0;
    h3 {
      color: #dddddd;
      margin: 1rem 0;
    }
  }

  .title {
    margin: 24px 0;
    text-align: center;
    h3 {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    .subtitle {
      text-align: center;
      margin: 0;
      margin-bottom: 2px;
      font-size: 12px;
      font-weight: 700;
      display: flex;
      flex-direction: column;
    }
  }
  .heading {
    width: 100%;
  }
  .headingContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
    a {
      padding-top: 1px;
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
      color: #2b3b5b;
    }
    svg {
      width: 12px;
      height: 12px;
    }
  }

  .skeletonCarousel {
    animation: loadingStatus 1s ease-in-out infinite;
    transition: all 0.3 ease-in-out;
    border-radius: 4.8px;
    width: 221px;
    height: 125px;
  }
  .skeletonInfo {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    div {
      animation: loadingStatus 1s ease-in-out infinite;
      transition: all 0.3 ease-in-out;
      width: 40%;
      height: 13px;
      &:last-of-type {
        width: 20%;
      }
    }
  }

  &.isLoaded {
    .heading {
      width: 80%;
      height: 16px;
      animation: loadingStatus 1s ease-in-out infinite;
      transition: all 0.3 ease-in-out;
      margin-bottom: 10px;
    }
  }
}

.iconDropdown {
  margin-left: auto;
  cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sortInfo {
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  &_list {
      display: grid;
      list-style: none;
      width: 100%;
      grid-template-columns: repeat(8, 1fr);
  
      &_item {
          font-size: 12px;
          color: black;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;

          svg path {
              fill: black;
          }

      }
  }
}

.emptyState {
    display: flex;
    cursor: pointer;
}