@use "../../utils/mediaQuerys" as *;

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.75rem;

    &_imgContainer {
        width: 100%;
        height: 100%;
        background: #889abe;

        &_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.3;
        }
    }

    &_searchInput {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding-top: 2rem;


        &_container {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 1rem;
            width: 100%;
            background-color: #fff;
            border-radius: 0.75rem;

            @include respond(phone) {
                flex-direction: column;
                background-color: transparent;
                align-items: center;
            }

            @include respond(tab-port, true) {
                flex-direction: column;
                background-color: transparent;
                align-items: center;
            }

        }

        &_title {
            font-size: 1.6rem;
            font-weight: 500;
            color: rgb(55 65 81);
            text-align: left;
            width: 100%;
            margin-bottom: 1.6rem;
        }

        &_buttonFilters {
                background-color: #fff;
                border-radius: 0.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                padding: 1rem;
                border: 1px solid rgb(229 231 235 );
                font-weight: 500;
                transition: all 0.3s;

                &:hover {
                    background-color: rgb(243 244 246);
                }
        
        }

        &_buttonContainer {
            display: flex;
            width: 20%;
            gap: 1rem;
            padding-bottom: 1.6rem;

            @include respond(phone) {
                width: 100%;
            }

            @include respond(tab-port, true) {
                width: 100%;
            }
        }

        &_containerSkill {
            position: relative;
            padding: 1.6rem;
            background-color: rgb(249 250 251);
            border-radius: 0.75rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 0.8rem;
            font-size: 1.6rem;
            width: 35%;

            @include respond(phone) {
                width: 100%;
            }

            @include respond(tab-port, true) {
                width: 100%;
            }
        }

        &_label {
            font-size: 1.4rem;
            text-align: left;
            display: flex;
            gap: 0.5rem;
            color: rgb(75 85 99);
            font-weight: 600;
            width: 100%;
        }

        &_divider {
            background-color: rgb(243 244 246);
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            width: 4rem;
            color: rgb(156 163 175);
            height: 4rem;
            margin-bottom: 1.6rem;
        }

        &_selectContainer {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 1.6rem;
            background-color: rgb(249, 250, 251);
            border-radius: 0.75rem;
            color: rgb(55 65 81);
            gap: 0.5rem;
            font-size: 1.6rem;
            width: 35%;

            @include respond(phone) {
                width: 100%;;
            }

            @include respond(tab-port, true) {
                width: 100%;
            }
        }

        &_selectContainerPhone {
            display: flex;
            margin-top: 1.2rem;
            gap: 1rem;
        }

        &_select {
            cursor: pointer;
            font-weight: 500;
            border: 1px solid rgb(229 231 235);
            padding: 0.8rem 1.2rem;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #fff;

        }

        &_button {
            padding: 1.2rem 2.4rem;
            border-radius: 0.75rem;
            background-color: #2563eb;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            font-size: 1.6rem;
            width: 100%;

        }


        &_input {
            background-color: #fff;
            border-radius: 0.75rem;
            width: 100%;
            border: 1px solid rgb(229 231 235);
            outline: none;
            transition: 0.5s ease;
            text-align: left;
            position: relative;
            padding: 0.8rem 1.2rem;


            &::placeholder {
                color: rgb(55, 65, 81);
                font-weight: 500;
            }
        }

    }
}

.suggestions {
    display: flex;
    position: absolute;
    background-color: #fff;
    box-shadow: 2px 1px 9px 2px rgba(0, 0, 0, 0.1);
    width: 100%;
    flex-wrap: wrap;
    top: 100%;
    border-radius: 5px;
    list-style-type: none;
    max-height: 300px;
    overflow-y: overlay;

    &_item {
        padding: 1rem;
        width: 100%;
        cursor: pointer;
        border-bottom: 1px solid #e0e0e0;
        display: flex;


        &:hover {
            background-color: #f2f2f2;
        }

        &:last-child {
            border-bottom: none;
        }

        &_icon {
            background-color: rgb(219 234 254);
            border-radius: 50%;
            padding: 1rem;
            color: rgb(37 99 235);
        }

        &_container {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        &_name {
            font-weight: 500;
            display: flex;
            flex-direction: column;
        }

        &_subTitle {
            color: rgb(107 114 128);
            font-weight: 400;
        }
    }
}
.toolTipWrapper {
    width: 100%;
    overflow: hidden;
}

.toolTipContainer {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &_closeButton {
        position: absolute;
        top: 0;
        right: 2rem;
        background: none;
        border: none;
        font-size: 3.5rem;
        cursor: pointer;
        color: #fff;
    
        &:hover {
          color: #495057;
        }
      }

    &_text {
        display: flex;
        font-size: 1.4rem;
        font-weight: 600;
        flex-direction: column;
        color: #7a7f85;
        text-align: left;
        background: linear-gradient(to bottom right, #2563eb, #1e40af); 
        padding: 2rem;
        border-radius: 0.75rem;

        @include respond(phone) {
            font-size: 16px;
        }

        &_header {
            font-weight: 700;
            font-size: 2.6rem;
            text-align: left;
            color: #fff;
            margin-bottom: 1.6rem;
        }

        &_description {
            font-size: 1.8rem;
            color: rgb(219 234 254);
            line-height: 1.3;
            font-weight: 400;
        }

    }
}


.selectedSkillsContainer {
    display: flex;
    width: 100%;
    gap: 5px;
    flex-wrap: wrap;

    &_skill {
        border-radius: 3rem;
        display: flex;
        width: fit-content;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        background-color: rgb(219 234 254);
        color: rgb(30 64 175);

        &_close {

            cursor: pointer;
            font-size: 14px;

            &_text {
                display: flex;
            }
        }
    }
}