@use "../../utils/mediaQuerys" as *;;

.upload-container {
    text-align: center;
    padding: 20px;

    @include respond(phone) {
      padding: 0;
    }
  
    h2 {
      font-size: 1.4em;
    margin-bottom: 40px;
    text-align: left;
    color: #234ba2;
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #edf0f7;

    @include respond(phone) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  
    .upload-section {
      display: flex;
      justify-content: space-around;
      margin-bottom: 20px;

      @include respond(phone) {
        flex-direction: column;
      }
    }
  
    .upload-item {
      width: 30%;

      @include respond(phone) {
        width: 100%;
      }
      .dropzone {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed #000;
        border-radius: 10px;
        padding: 20px;
        height: 100%;
        cursor: pointer;
        transition: background-color 0.3s ease;
        position: relative;
        height: 250px;

        @include respond(phone) {
          
          margin: 20px;
          padding: 5px;
        }
        
        &:hover {
          background-color: #edf0f7;
        }
  
        .dropzone-content {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          p {
            margin-top: 10px;
            color: #333;
          }

          @include respond(phone) {
            margin-top: 0;
          }
        }
  
        .uploaded-file {
          white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          .preview {
            width: 100px;
            height: auto;
            margin-bottom: 10px;
          }
  
          .file-name {
            font-size: 1em;
            margin-bottom: 10px;
            color: #333;
            font-weight: 500;
            

            @include respond(phone) {
              margin-bottom: 0;
              display: flex;
              text-align: center;
            }

          }
  
          .remove-button {
            background: none;
            border: none;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 15px;
            color: #333;
          }
        }
      }
    }

    .submit-button-container {
      display: flex;
      justify-content: center;  

    }
  
    .submit-button {
      background-color: #234ba2;
      color: white;
      padding: 10px 20px;
      font-size: 1.2em;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
  
      &:hover {
        background-color: #1a3a7d;
      }

      &:disabled {
        background-color: #889abe;;
        cursor: not-allowed;
      }
    }
  }

  .icon {
    height: 80px;
  }

  .prev-button, .next-button {
    background-color: transparent;
    color: #889abe;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

  }

  .steps-navigation {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  