.generalContainer {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--color-mainBorder);

}

.overLayer {
    position: relative;
}

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 10px;
}

.feed {
    display: flex;
    padding: 20px 20px 10px 20px;
    flex-direction: column;
    height: 100%;

    &_itemContainer {
        display: flex;
        flex-direction: column;
        border-radius: 0.75rem; 
        background: #fff;
        transition: all 0.3s ease-in-out;
        margin: 0.5rem 0;
        border: 1px solid transparent;

        &:hover {
            background-color: #fbfbfb;

            &_itemCommentBody_item_content {
                background-color: #fff;
            }
        }
    }

    &_itemComments {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;

        &_txt {
            cursor: pointer;
            color: #959CA3;
            margin-left: 5px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &_itemLikes {
        display: flex;
        margin-left: 3px;
        position: relative;

        &_txt {
            color: #959CA3;
        }
    }

    &_itemLikesNoComments {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
        cursor: pointer;
        position: relative;
        font-size: 12px;

        &_showUsers {


            &_menu {
                background-color: #0009;
                border: 1px solid #ccc;
                color: #fff;
                padding: 0;
                margin: 0;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                position: absolute;
                opacity: 0.8;
                top: 18px;

                &_item {
                    display: flex;
                    padding: 5px;

                    &:hover {
                        background-color: #0009;
                        opacity: 1;
                    }

                    &_img {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                }
            }
        }

        &_txt {
            color: #959CA3;
        }
    }

    &_itemHeader {
        display: flex;
        justify-content: space-between;

        &_actionsMenu {

            &_container {
                cursor: pointer;
                display: flex;
            }

            &_menu {
                display: flex;
                gap: 1.5rem;
                align-items: center;
                width: 100%;

                &_item {
                    display: flex;
                    align-items: center;
                    position: relative;
                    color: rgb(75 85 99);
                    font-size: 14px;
                    gap: 10px;
                    opacity: 0.8;

                    &:hover {
                        opacity: 1;
                    }

                    &_text {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }

                    &_iconRotated {
                        transition: all 0.2s;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &_optionsHeader {
            display: flex;
            flex-direction: column;
        }

        &_optionsContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.3);

            svg path {
                fill: #fff;
            }

            svg {
                fill: #fff;
                margin-left: 3px;
            }

            &_likes {
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;
            }

            &_comments {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-left: 3px;
            }
        }

        &_footerContainer {
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
            padding: 0 1.6rem;

            &_reactions {
                display: flex;
                gap: 10px;
                width: 100%;
                justify-content: space-between;
            }

        }

        &_profileContainer {
            display: flex;
            padding: 1.6rem 1.6rem 0 1.6rem;
            cursor: pointer;

            &_nameAndImg {
                display: flex;
            }
        }

        &_optionsDots {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            margin-top: 10px;
        }

        &_optionsDotsComments {
            display: flex;
            justify-content: flex-end;
            align-items: end;
            cursor: pointer;
            width: 30px;
        }

        &_options {
            display: flex;
            position: relative;

            &_dropdownMenu {
                background-color: #0009;
                list-style: none;
                padding: 0;
                position: absolute;
                top: 20px;
                right: 0px;
                width: 100px;
                margin: 0;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                z-index: 1;

                &_item {
                    padding: 10px;
                    font-size: 14px;
                    cursor: pointer;
                    transition: background-color 0.2s;
                    color: #fff;

                    &:hover {
                        background-color: #000000;
                        border-radius: 5px;
                    }
                }

                &_close {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 22px;
                    font-size: 12px;
                    font-weight: 700;
                    border-radius: 5px;
                    color: #fff;

                }
            }

            &_container {
                display: flex;
                position: absolute;
                z-index: 1;

                &_item {
                    display: flex;
                    align-items: center;

                    &_txt {
                        display: flex;
                    }

                    &_span {
                        color: #fff;
                        font-size: 15px;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                    }


                }
            }
        }

        &_imgContainer {
            min-width: 50px;
            max-width: 50px;
            min-height: 50px;
            max-height: 50px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;

            &_img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &_initials {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ccc;
                color: #fff;
                font-size: 1.5rem;
                min-width: 50px;
                min-height: 50px;
                border-radius: 50%;
                text-transform: uppercase;
            }
        }

        &_text {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            &_profession {
                color: #4167B3;
                font-size: 11px;
                font-weight: 700;
                opacity: 0.78;
            }

            &_name {
                font-size: 16px;
                color: #2b3b5b;
                font-weight: 600;
            }

            &_timeContainer {
                display: flex;
                color: #2b3b5b;
                font-size: 12px;
                align-items: center;

                svg {
                    fill: #2b3b5b;
                }
            }

            &_time {
                color: #000000;
                font-weight: 900;
                margin-left: 2px;
            }
        }
    }

    &_itemBody {
        display: flex;
        height: 100%;
        margin-top: 10px;
        color: #000000;
        flex-direction: column;
        font-weight: 500;

        &_videoContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.6rem;

            &_editButton {
                position: absolute;
                border: 1px solid #234Ba2;
                padding: 5px;
                border-radius: 6px;
                color: #234Ba2;
                background: #ebebeb;
                opacity: 0.8;
                cursor: pointer;
                font-size: 12px;
            }
        }

        &_videoPost {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }

        &_imgContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            padding: 1.6rem;

            &_editButton {
                position: absolute;
                border: 1px solid #234Ba2;
                padding: 20px 30px;
                border-radius: 6px;
                color: #234Ba2;
                background: #ebebeb;
                opacity: 0.8;
                cursor: pointer;
                font-weight: 600;
                font-size: 18px;
                transition: all 0.2s;

                &:hover {
                    background: #234Ba2;
                    color: #fff;
                    opacity: 1;
                }
            }
        }

        &_imgPost {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
        }

        &_txtContainer {
            display: flex;
            margin-top: 10px;
            white-space: pre-line;
            position: relative;

        }

        &_txt {
            color: rgb(75 85 99);
            font-weight: 400;
            font-size: 1.6rem;
            overflow: hidden;
            white-space: pre-line;
            padding: 1.6rem;
            line-height: 1.5;

        }

        &_inputContainer {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            padding: 1.6rem;
        }

        &_input {
            width: 100%;
            display: flex;


            &_item {
                background: transparent;
                border-radius: 11px;
                width: 100%;
                color: rgb(75 85 99);
                border: 1px solid rgb(226, 239, 255);
                resize: none;
                padding: 0.8rem 1.6rem;
                cursor: text;
                display: flex;
                flex-direction: column;
                height: 40px;
                overflow: hidden;
                font-size: 16px;

                &:focus {
                    border: 1px solid rgb(37, 99, 235);
                }

                &_iconContainer {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    cursor: pointer;
                    gap: 1.5rem;

                    &_cancelBtn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: transparent;
                        gap: 5px;
                        font-size: 1.6rem;
                    }

                    &_sendBtn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0.6rem 1.2rem;
                        gap: 5px;
                        background-color: rgb(37, 99, 235);
                        color: #fff;
                        border-radius: 0.5rem;
                        font-size: 1.6rem;
                        transition: all 0.3s;

                        &:hover {
                            background-color: rgb(29, 78, 216);
                        }


                    }
                }
            }
        }
    }

    &_itemFooter {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-top: 10px;
        border-top: 1px solid #F7F7F7;
        padding-top: 10px;
        font-size: 12px;
    }

    &_itemFooterRecommend {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        position: relative;

        &:hover {
            background: #F7F7F7;
            border-radius: 11px;
        }

        &_txt {
            color: #000000;
            margin-left: 5px;
            font-weight: bold;
        }

        &_txtEmpty {
            color: #959CA3;
            margin-left: 5px;
        }
    }

}

.heartIcon {
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;

    svg {
        transition: inherit;

        &:hover {
            transform: scale(1.1);
        }
    }

    &_active {
        svg {
            fill: red;
            color: red;
        }
    }

    @keyframes heartbeat {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    &.heartbeat {
        svg {
            animation: heartbeat 0.3s infinite;
        }
    }
}