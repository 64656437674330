@use "../../utils/mediaQuerys" as *;;

.contentContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.content {
  width: 50%;
  min-height: 100vh;
}

.registerContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  text-align: center;

  @media (max-width: 1024px) {
    align-items: flex-start;
  }
}

.registerContent {
  margin: 16px 0;
  width: 100%;
  max-width: 392px;
}

.logo {
  text-align: center;
  margin-bottom: 25px;

  & img {
    width: 120px;
  }
}

.title {
  color: #000000;
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 42.7px;
  padding: 20px 0;
}

.phoneInputContainer {
  margin-top: 10px;
  display: flex;

  & input {
    min-width: 100%;
    padding: 10px;
    border: 0.7px solid rgba(151, 151, 151, 0.2);
    outline: none;
    border-radius: 7px;
  }
}

.content-data {
  width: 50%;
  padding-left: 180px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  min-height: 100vh;
}

.content-texts,
.content-footer {
  max-width: 400px;
}

.subtitle {
  font-size: 18px;
    font-weight: 500;
    line-height: 1.31;
    max-width: 456px;
    opacity: 0.67;
    color: var(--color-black);
    text-align: center;

  &_highlighted {
    font-weight: 800;
    display: inline;
    color: #4CE2A7;
  }
}

.inputContainer {
  display: inline-block;
  position: relative;
  width: 100%;
}

.label {
  display: block;
  color: #3b85c4;
  font-size: 15.5px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin-bottom: 6px;
}

.form-group {
  margin: 15px 0;

  & input {
    border: 0.7px solid rgba(151, 151, 151, 0.2);
    padding: 10px 15px;
    outline: none;
    width: 100%;

    &:focus,
    &:active,
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }
}


.googleDivider {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(phone) {
    padding: 20px 0;
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1.3px;
    background-color: #bfbfbf;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1.3px;
    background-color: #bfbfbf;
  }

  &_text {
    margin: 0 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #000;
    opacity: 0.67;
    min-width: fit-content;
  }
}



.confirmationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #bfbfbf;
  font-size: 14px;
  gap: 5px;

  &_radio {
    margin-right: 5px;
  }

}

.googleLogin {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  &_txt {
    margin-bottom: 20px;
    opacity: 0.67;
    color: var(--color-black);
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
  }
}

.form-group {
  & input {
    &.is-invalid {
      border: 1px solid rgb(206, 15, 15);
    }

    &.ng-invalid.ng-dirty {
      border: 1px solid rgb(206, 15, 15);
    }

    &.ng-valid {
      border: 1px solid rgb(26, 167, 26);
    }
  }
}

.invalid-feedback {
  padding-left: 15px;
  margin-top: 3px;

  & .text {
    font-weight: 300;
    color: #979797;
  }
}

.btn {
  padding: 15px 30px;
  color: var(--color-white);
  outline: none;
  font-size: 15.5px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  width: 100%;
  display: block;
  border: 2px solid var(--color-mainBlueBorder);
  background-color: var(--color-mainBlue);
  transition: all 0.2s;
  border-radius: 0.75rem;

  &:hover {
    transform: translateY(-3px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.formContainer {
  padding-top: 20px;
}

.termsContainer {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type="checkbox"] {
    display: none;
  
    &:checked+label>span {
      color: #000000;
      opacity: 0.75;
    }
  
    &:checked+label:before {
      border: 2px solid #3f66b3;
      background: #3f66b3;
    }
  
    &:checked+label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 6px;
      opacity: 1;
      position: absolute;
      top: 8px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      width: 12px;
    }
  }

}

.inputHelper {
  position: relative;
  display: inline-block;
  line-height: 1.7;

  &:before {
    content: "";
    display: block;
    position: absolute;
  }

  &:hover>span {
    color: #000000;
  }
}

.inputHelperCheckbox {
  color: #bfbfbf;
  padding-left: 35px;

  &:before {
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    border-radius: 7px;
    opacity: 1;
    border: 2px solid #bfbfbf;
  }
}

.goBack {
  text-align: center;
  text-decoration: unset;
  color: #3f66b3;
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  padding-top: 30px;

  a:visited {
    color: #3f66b3;
  }
}


.hover {
  transition: all 0.2s;
  padding: 20px 0 0 0;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: var(--color-mainBlue);
  padding-left: 5px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    width: 0px;
    height: 2px;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    background-color: var(--color-mainBlueBorder);
  }

  &:before {
    left: 50%;
  }

  &:after {
    right: 50%;
  }

  &:hover {
    &:before {
      width: 50px;
    }

    &:after {
      width: 50px;
    }
  }
}

.copyright {
  max-width: 268px;
  margin-top: 1.5rem;
  color: #707070;
  opacity: 0.47;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

@media (max-width: 1300px) {
  .content-data {
    padding-left: 80px;
  }

  .content-background {
    background-position: center;
  }
}

@media (max-width: 767px) {
  .content-data {
    padding-left: 0px;
  }

  .content-data {
    width: 100%;
    padding: 0 15px;
  }

  .content-background {
    display: none;
  }

  .content {
    width: 100%;
  }

  .content-container {
    flex-direction: column;
  }

  .background {
    display: none;
  }
}

.error {
  border: 2px solid var(--color-error);
  background-color: #e84f78;
}