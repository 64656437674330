@use "../../utils/mediaQuerys" as *;;

.userPanelContainer {
  display: flex;
  justify-content: center;
  min-width: 263px;
  max-width: 263px;
}

.infoWrapper {
  width: 100%;
}
.projectsContainer {
  width: 100%;
  padding: 20px;
  display: flex;
      flex-direction: column;

  @include respond(phone) {
    padding-top: 80px;
  }
}
.buttonContainer {
  width: 100%;
}
.sectionDivider {
  display: flex;
    line-height: 24.59px;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 12px;
    white-space: nowrap;
}
.divider {
  width: 80%;
  border-bottom: 1px solid #b3b5ba;
  align-self: center;
  margin-left: 20px;
}

.mainTitle {
  font-size: 20px;
  font-weight: 600;
  color: #234ba2;
  letter-spacing: 0.1rem;
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);

  @include respond (phone) {
    padding: 10px;
    font-size: 16px;
  }

  &_faq {
    cursor: pointer;
    display: flex;
    
    svg path {
      fill: var(--color-mainBlue);
    }

    @include respond(phone) {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.AllProjects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  margin-bottom: 10px;
  height: 100%;

}

.generalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 16.5px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.5px;
    margin-bottom: 25px;
  }
}
.activeFilter {
  color: var(--color-mainBlue);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.19px;
  line-height: 13px;
  margin: 3rem;
}
