@use "../../../utils/mediaQuerys" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  width: 100%;

  @include respond(phone) {
    padding-top: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: var(--shadow);
  padding: 2rem;

  @include respond(phone) {
    flex-direction: column;
    align-items: stretch;
    gap: 2rem;
  }

  .title {
    display: flex;
    font-size: 1.4rem;
    font-weight: 600;
    flex-direction: column;
    color: #7a7f85;
    text-align: left;
  }

  .text {
    display: flex;
    font-size: 2.5rem;
    color: #333;
  }

  .user-infoWrapper {
    display: flex;
    gap: 1rem;
    font-size: 1.4rem;
    font-weight: 600;

    @include respond(phone) {
      flex-direction: column;
    }
  }

  .user-info {
    display: flex;
    gap: 2rem;
    align-items: center;
    border-radius: 0.75rem;
    padding: 1rem;
    background-color: #edf0f7;
    word-break: break-word;

    .user-info-avatar {
      min-width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #edf0f7;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .user-info-item {
      display: flex;
      flex-direction: column;

      &-value {
        font-size: 1.4rem;
        font-weight: 600;
        color: #333;
      }
    }

  }


}

.body {
  display: flex;
  gap: 2rem;
  position: relative;

  @include respond(phone) {
    flex-direction: column;
  }

  @include respond(tab-port, true) {
    flex-direction: column
  }
}

.general-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.summary {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);

}