@use "../../utils/mediaQuerys" as *;

.stepsContainer {
    padding: 3rem;
    background-color: #fff;
    border-radius: 0.75rem;
    border: 1px solid #edf0f7;
    box-shadow: var(--shadow);
}

.stepsTitle {
    color: rgb(17 24 39 );
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 4.8rem;
}

.steps {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 3rem;
    overflow: hidden;

    @include respond(phone) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.stepRelative {
    position: relative;
    display: flex;
}

.stepLine {
    position: absolute;
    top: 5.5rem;
    left: 50%;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, #234ba2 0%, #3461c1 100%);
    transform-origin: left;
    opacity: 0.3;

}

.step {
    background-image: linear-gradient(
        to bottom right,
        #eef3ff, 
        #fff     
    );
    padding: 2.4rem;
    border: 1px solid rgb(224 234 255 );
    z-index: 1;
    border-radius: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
}

.stepIconContainer {
    background-color: rgb(224 234 255 );
    border-radius: 1rem;
    margin-bottom: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.4rem;
    height: 6.4rem;
}

.stepIcon {
    display: flex;
    color: rgb(35 75 162 );
}

.stepTitle {
    font-size: 1.8rem;
    color: rgb(17 24 39);
    font-weight: 600;
    margin-bottom: 1.2rem;
}

.stepDescription {
    color: rgb(75 85 99);
    font-size: 1.6rem;
    line-height: 1.3;
}