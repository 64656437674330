.container {
    background-color: #ffffff;
    padding: 24px;
    max-width: 313px;
    min-width: 313px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .title {
        font-size: 1.5rem;
        font-weight: bold;
        color: #1f2937;
    }

    .icons {
        display: flex;
        gap: 8px;

        .iconBlue {
            color: #3b82f6;
        }

        .iconGreen {
            color: #22c55e;
        }

        .iconPurple {
            color: #8b5cf6;
        }
    }
}

.charts {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .chartCard {
        background-color: #f9fafb;
        padding: 16px;
        border-radius: 8px;

        .chartTitle {
            font-size: 1.125rem;
            font-weight: 600;
            margin-bottom: 16px;
            color: #1f2937;
        }

        .chart {
            height: 20rem;
        }
    }
}

.latestValuesGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    .valueCard {
        background-color: #f9fafb;
        padding: 16px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        .valueName {
            font-size: 1rem;
            color: #6b7280;
            flex: 1;
        }

        .valueAmount {
            font-size: 1.5rem;
            font-weight: bold;
            color: #1f2937;
        }

        .valueDate {
            font-size: 0.875rem;
            color: #9ca3af;
        }
    }
}