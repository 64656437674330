.contentContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  
  .content {
    width: 50%;
    min-height: 100vh;
  }
  
  .formContainer {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .disabled{
    color:gray;
    cursor:not-allowed
      }
  .registerContainer {
    margin: 0 auto;
    display: flex;
    padding: 20px;
    max-width: 400px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    @media (max-width: 1024px) {
      align-items: flex-start;
    }
  }
  .logo {
    text-align: center;
    margin-bottom: 25px;
    & img {
      width: 120px;
    }
  }

  .btn {
  padding: 15px 30px;
  color: var(--color-white);
  outline: none;
  font-size: 15.5px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  width: 100%;
  display: block;
  background-color: var(--color-mainBlue);
  transition: all 0.2s;
  border-radius: 7px;

  &:hover {
    transform: translateY(-3px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

}

  .formTitle {
    color: var(--color-mainBlue);
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    margin-bottom: 2rem;
  }
  .formSubtitle{
      color: var(--color-black);
      margin-bottom: 3rem;
      font-weight: 500;
      text-align: center;
      line-height: 1.5;
      font-size: 1.6rem;
  }
  .copyright {
    max-width: 268px;
    margin-top: 50px;
    color: #707070;
    opacity: 0.47;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    @media (max-width: 1024px) {
      margin-left: 0;
    }
  }
  .goBack {
    text-align: center;
    text-decoration: unset;
    color: #3f66b3;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    a:visited {
      color: #3f66b3;
    }
  }

  .error {
    margin-top: 1rem;
    margin-left: auto;
    text-align: right;
    color: var(--color-error);
  }
