@use "../../../../utils/mediaQuerys" as *;

@keyframes loadingStatus {
  from {
    background-color: #b5b7b5;
  }

  to {
    background-color: rgb(226, 226, 226);
  }
}

.userInfoContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background-color: #fff;
  width: 100%;

  @include respond(phone) {
    width: 100%;
    flex-direction: column;
    padding: 2rem 0;
    z-index: 1;
  }

  
  &_stars {
    display: flex;
    gap: 5px;
  }

  &_data {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &_sendNotification {
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    backdrop-filter: blur(5px);

    &_content {
      background: #fff;
      width: 60vw;
      height: 60vh;

    }

  }

  &_imageAndName {
    display: flex;
    align-items: center;

    @include respond(phone) {
      margin-right: 10px;
    }

    &_image {
      min-width: 90px;
      max-width: 90px;
      min-height: 90px;
      max-height: 90px;
      border-radius: 0.75rem;
      margin-right: 15px;
      display: flex;
      align-items: center;

      &_img {
        width: 9rem;
        object-fit: cover;
        height: 9rem;
        border-radius: 0.75rem;
      }
    }

    &_name {
      width: max-content;
      color: #2b3b5b;
      font-weight: 700;
      font-size: 18px;

    }
 
  }
  &_contact {
      display: flex;
      gap: 2rem;
      margin-top: 0.5rem;
    }

  &_buttonsContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    gap: 20px;

    @include respond(phone) {
      margin-left: 0;
      margin-top: 1.6rem;
      justify-content: flex-start;
    }

    &_myProfile {
      display: flex;
      align-items: center;
      gap: 2rem;

      @include respond(phone) {
        flex-direction: row-reverse;
      }
    }

    &_visits {
      font-size: 1.6rem;
      color: rgb(75 85 99);
      gap: 0.5rem;
      display: flex;
      align-items: center;
    }

    &_editProfile {
      border: 1px solid rgb(209 213 219);
      color: rgb(55 65 81);
      padding: 0.8rem 1.6rem;
      border-radius: 0.75rem;
      gap: 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1.6rem;
      transition: all 0.3s;

      &:hover {
        background-color: rgb(249 250 251);
        color: rgb(55 65 81);
      }

    }

    &_webSite {
      position: relative;
      display: flex;
      cursor: pointer;
      color: rgb(75 85 99);
      gap: 0.5rem;
      align-items: center;

    }

    &_webSiteButton {
      justify-content: center;
      align-items: center;
      display: flex;
      gap: 0.5rem;
      color: rgb(75 85 99);
    }

    &_contact {
      background-color: rgb(37 99 235 );
      color: #fff;
      display: flex;
      font-size: 1.6rem;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      padding: 0.8rem 1.6rem;
      border-radius: 0.75rem;
      cursor: pointer;
      font-weight: 500;
    }

    &_rate {
      border: 1px solid rgb(209 213 219 );
      background-color: #fff;
      border-radius: 0.75rem;
      display: flex;
      align-items: center;
      padding: 0.8rem 1.6rem;
      gap: 0.5rem;
      font-weight: 500;
      font-size: 1.6rem;
    }
  }
}

.initialsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  color: #fff;
  font-size: 2rem;
  width: 90px;
  height: 90px;
  border-radius: 0.7rem;
  text-transform: uppercase;
}