@use "../../../../utils/mediaQuerys" as *;

.container {
  width: 100%;

}

.title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.4rem;
  border-bottom: 1px solid #e5e7eb;
}

.planInfo {
  background-color: rgb(239 246 255);
  border: 1px solid rgb(191 219 254);
  border-radius: 0.75rem;
  padding: 1.6rem;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    &_title {
      color: rgb(30 64 175);
      font-weight: 600;
      font-size: 1.6rem;

      display: flex;
      align-items: center;
      gap: 1.6rem;
    }
  }

  &_activeStatus {
    color: rgb(22 101 52);
    padding: 0.4rem 1.2rem;
    background-color: rgb(220 252 231);
    border-radius: 9999px;
    display: flex;
    align-items: center;

  }

  &_listedItems {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  &_item {
    color: rgb(75, 85, 99);
  }

}

.planDescription {
  margin-top: 2.4rem;

  &_title {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  &_list {
    list-style: none;

    &_item {
      display: flex;
      gap: 1.2rem;
      align-items: center;
      margin-top: 0.8rem;

      &:first-child {
        margin-top: 0;
      }

      &_text {
        color: rgb(75 85 99);
        font-size: 1.6rem;
      }
    }
  }

  &_bullet {
    display: flex;
    border-radius: 9999px;
    width: 0.8rem;
    height: 0.8rem;
    background-color: rgb(37 99 235);
  }

  &_comparison {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;

    @include respond(phone) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 1rem;
    }

    &_basicPlan {
      border: 1px solid #e5e7eb;
      border-radius: 0.75rem;
      padding: 1.6rem;

      &_title {
        font-size: 1.6rem;
        margin-bottom: 1.2rem;
        font-weight: 600;
      }
    }

    &_premiumPlan {
      border: 1px solid rgb(191 219 254);
      border-radius: 0.75rem;
      padding: 1.6rem;
      background-color: rgb(239 246 255);

      &_title {
        font-size: 1.6rem;
        margin-bottom: 1.2rem;
        font-weight: 600;
        color: rgb(30 64 175);
      }
    }
  }
}

.basicPlan {
  background-color: rgb(249 250 251);
  border: 1px solid rgb(229 231 235);
  border-radius: 0.7rem;
  padding: 1.6rem;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    &_title {
      color: rgb(75 85 99);
      font-weight: 600;
      font-size: 1.6rem;

      display: flex;
      align-items: center;
      gap: 1.6rem;
    }
  }

  &_activeStatus {
    background-color: rgb(219 234 254);
    color: rgb(30 64 175);
    padding: 0.4rem 1.2rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;

  }

  &_description {

    &_subtitle {
      color: rgb(75 85 99);
      margin-bottom: 1.6rem;
    }

    &_disclaimer {
      background-color: rgb(254 252 232);
      border-radius: 0.75rem;
      border: 1px solid rgb(254 240 138);
      padding: 1.2rem;
      margin-bottom: 1.6rem;

      &_text {
        color: rgb(133 77 14);
      }
    }
  }
}

.subscriptionButton {
  color: #fff;
  background-color: rgb(37 99 235);
  border-radius: 0.75rem;
  padding: 0.8rem 1.6rem;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  transition: all 0.2s;

  &:hover {
    background-color: rgb(30 64 175);
  }
}