@use "../../../utils/mediaQuerys";
.navContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  svg  {
    fill: #fff;
  }
}

.menuItem {
  display: flex;
}

