.response {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &_item {
        display: flex;
        gap: 1rem;
        align-items: flex-start;

        &_imgContainer {
            min-width: 25px;
            max-width: 25px;
            min-height: 25px;
            max-height: 25px;
            border-radius: 50%;
            overflow: hidden;
            margin-top: 0.5rem;
            border: 1px solid rgb(226, 239, 255);

            &_img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &_initials {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ccc;
                color: #fff;
                font-size: 1.5rem;
                min-width: 32px;
                min-height: 32px;
                border-radius: 50%;
                text-transform: uppercase;
            }
        }
        &_contentContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &_content {
            display: flex;
            gap: 0.4rem;
            background-color: rgb(249, 250, 251);
            flex-direction: column;
            border-radius: 8px;
            padding: 0.8rem 1.6rem;

            &_hovered {
                transition: all 0.3s;
                background-color: #fff;
            }

            &_header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.4rem;

                &_name {
                    color: #000000;
                    font-weight: 600;
                    display: flex;
                    align-items: flex-end;
                    gap: 0.7rem;
                }

                &_time {
                    color: rgb(107 114 128);
                    font-size: 1.2rem;
                    font-weight: 400;
                }
            }
        }

        &_text {
            color: rgb(75, 85, 99);
        }
    }
}