
.menu {
    background-color: #0009;
    color: #fff;
    padding: 0;
    margin: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: absolute;
    top: 20px;
    z-index: 1;
    opacity: 0.9;

    &_item {
        display: flex;
        padding: 5px;
        font-size: 14px;


        &:hover {
            background-color: #000000;
            opacity: 1;
            border-radius: 5px;
        }

        &_img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
}