@use "../../utils/mediaQuerys" as *;;

.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    @include respond(phone) {
        height: 100%;
    }
}

.workBagContainer {
    display: grid;
    position: relative;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;

    @include respond(phone) {
        display: flex;
        flex-direction: column;
    }

    &_body {
        display: flex;
        flex-direction: column;
        padding: 10%;
        width: 100%;
        height: 100%;
        background-color: rgb(249, 250, 251);;

        @include respond(phone) {
            padding: 10px;
        }
    }
}

.workBag {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10%;

    &_main {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        width: 100%;
        height: 100%;

        @include respond(phone) {
            padding: 10px;
        }
    }


    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #959ba3;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &_viewInPhone {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: overlay;
    }

    &_rightSideComponent {
        height: 100%;
        width: 100%;
        background: linear-gradient(to bottom right, #2563eb, #1e40af);

        &_body {
            display: flex;
            flex-direction: column;
            padding: 10%;
            width: 100%;
            height: 100%;

        }

        &_image {
            width: 30rem;
            height: 20rem;
            border-radius: 0.5rem;
            align-self: flex-end;
            object-fit: cover;
            margin-bottom: 5rem;
        }

        &_title {
            color: rgb(255 255 255);
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 600;
            text-align: right;

        }

        &_text {
            color: rgb(219 234 254);
            font-size: 1.8rem;
            line-height: 2rem;
            font-weight: 500;
            text-align: right;
            margin-top: 2rem;
            line-height: 2.5rem;
        }

        &_btn {
            background-color: rgb(255 255 255);
            color: rgb(37 99 235);
            border-radius: 3rem;
            padding: 1rem 2rem;
            width: max-content;
            font-weight: 600;
            align-self: flex-end;
            margin-top: 3rem;
            font-size: 1.6rem;
            transition: all 0.3s;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    &_leftSideComponent {
        height: 100%;
        width: 100%;
        background: linear-gradient(to bottom left, #2563eb, #1e40af);

        &_body {
            display: flex;
            flex-direction: column;
            padding: 10%;
            width: 100%;
            height: 100%;

        }

        &_image {
            width: 30rem;
            height: 20rem;
            border-radius: 0.5rem;
            align-self: flex-start;
            object-fit: cover;
            margin-bottom: 5rem;
        }

        &_title {
            color: rgb(255 255 255);
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 600;
            text-align: left;

        }

        &_text {
            color: rgb(219 234 254);
            font-size: 1.8rem;
            line-height: 2rem;
            font-weight: 500;
            text-align: left;
            margin-top: 2rem;
            line-height: 2.5rem;
        }

        &_btn {
            background-color: rgb(255 255 255);
            color: rgb(37 99 235);
            border-radius: 3rem;
            padding: 1rem 2rem;
            width: max-content;
            font-weight: 600;
            align-self: flex-start;
            margin-top: 3rem;
            font-size: 1.6rem;
            transition: all 0.3s;

            &:hover {
                transform: scale(1.05);
            }
        }
    }


    &_logoContainer {
        width: 100%;
        display: flex;
        background: transparent;

        @include respond(phone) {
            background: #234ba2;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
            padding: 10px;
            justify-content: center;

        }
    }

    &_logo {
        width: 120px;
        align-self: center;
    }
  
}

.workOffersContainer {
    display: flex;
    align-self: center;
    width: 100%;
    height: 100%;
}