@use "../../../utils/mediaQuerys";

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: translateY(0);
        opacity: 0.3;
    }
    40% {
        transform: translateY(-8px);
        opacity: 1;
    }
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.spinnerEdify {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;

    &.smallSpinner {
        width: 50px;
        height: 50px;
    }
}

.loadingText {
    font-size: 24px;
    font-weight: bold;
    color: #555;
    margin-bottom: 40px;
    animation: fadeInOut 1.5s ease-in-out infinite;
}

.spinner {
    display: flex;
    gap: 8px;

    span {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #888;
        border-radius: 50%;
        animation: bounce 0.8s infinite ease-in-out;

        &:nth-child(1) {
            animation-delay: 0s;
        }
        &:nth-child(2) {
            animation-delay: 0.2s;
        }
        &:nth-child(3) {
            animation-delay: 0.4s;
        }
        &:nth-child(4) {
            animation-delay: 0.6s;
        }
        &:nth-child(5) {
            animation-delay: 0.8s;
        }
        &:nth-child(6) {
            animation-delay: 1s;
        }
    }
}
