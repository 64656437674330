.skeletonImageContainer {
    width: 100%;
    height: 200px;
    background-color: #e0e0e0;
    margin-bottom: 20px;
}

.skeletonImage {
    width: 100%;
    height: 100%;
    background-color: #ccc;
    animation: skeleton-loading 1.5s infinite;
}

.skeletonTextContainer {
    margin-left: 20px;
    grid-column: 2;
    grid-row: 1 / span 2;

    .skeletonDescription,
    .skeletonItem {
        height: 60px;
        background-color: #e0e0e0;
        margin-bottom: 10px;
        border-radius: 4px;
        animation: skeleton-loading 1.5s infinite;
    }


    .skeletonDescription {
        width: 80%;
        height: 14px;
    }

    .skeletonItem {
        width: 60%;
        height: 14px;
    }
}

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.sectionWrapper {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skeletonCards {

    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
        gap: 40px;

        .skeletonButton {
            width: 20%;
            height: 60px;
            background-color: #e0e0e0;
            border-radius: 4px;
            animation: skeleton-loading 1.5s infinite;
        }
    }

    &_datos {
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-rows: 1fr 1fr;
        margin: 20px;
        width: 100%;
    }

    &_user {
        grid-column: 1;
        grid-row: 2;
    }
}

.skeletonTitle {
    height: 60px;
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: skeleton-loading 1.5s infinite;
    width: 100%;
}

.skeletonBox {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.skeletonUserContainer {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .skeletonUserImage {
        width: 40px;
        height: 40px;
        background-color: #ccc;
        border-radius: 50%;
        margin-right: 10px;
        animation: skeleton-loading 1.5s infinite;
    }

    .skeletonUserName {
        width: 150px;
        height: 20px;
        background-color: #e0e0e0;
        animation: skeleton-loading 1.5s infinite;
    }
}

.skeletonQuestionContainer {
    .skeletonQuestion {
        height: 20px;
        width: 90%;
        background-color: #e0e0e0;
        margin-bottom: 15px;
        border-radius: 4px;
        animation: skeleton-loading 1.5s infinite;
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }

    50% {
        background-color: #f0f0f0;
    }

    100% {
        background-color: #e0e0e0;
    }
}