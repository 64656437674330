@use "../../utils/mediaQuerys" as *;

.ValidateSuccess {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 2rem;

  .content {

    @include respond(tab-port) {
      width: 90vw
    }

    .imgContainer {
      height: 58.51px;
      width: 210.59px;

      img {
        width: 100%;
      }
    }

    .textContainer {
      .title {
        width: 100%;
        color: #2e4255;
        font-size: 30px;
        font-weight: 700;
        line-height: 55px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      .subtitle {
        margin-top: 20px;
        color: #4a4a4a;
        font-size: 24px;
        font-weight: 400;
        width: 100%;
        line-height: 37px;
        text-align: center;

        @include respond(tab-port) {
          font-size: 20px;
          line-height: 29px;
        }
      }
    }

    .btnFinish {
      margin-top: 40px;
      display: flex;
      justify-content: center;

      a {
        background-color: #2F6AAE;
        width: 100%;
        border-radius: 0.75rem;
        height: 47.38px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 16px;
        font-weight: 600;

      }
    }

    .socialsContainer {
      padding-top: 20px;

      .followUs {
        color: #4a4a4a;
        font-size: 13px;
        font-weight: 400;
        line-height: 37px;
      }

      .icons {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        height: 48px;

        @include respond(tab-port) {
          height: 35px;
        }

        &_img {
          display: flex;
          justify-self: center;
          margin-top: 2rem;
          height: 3rem;
          width: 3rem;
        }

      }
    }

    .copy {
      margin-top: 20px;
      color: #9b9b9b;
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      width: 235px;
    }
  }
}