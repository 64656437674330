@use "../../utils/mediaQuerys" as *;
;

.contentContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.content {
  width: 50%;
  min-height: 100vh;

  @include respond(tab-port) {
    width: 100%;
  }
}

.registerContainer {
  margin: 0 auto;
  display: flex;
  padding: 20px;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;

  @media (max-width: 1024px) {
    align-items: flex-start;
  }
}

.register-content {
  width: 100%;
  margin-top: 16px;
  max-width: 368px;

  .title {
    text-align: left;
  }

  .sub {
    font-size: 25px;
    font-weight: 400;
    line-height: 1.2;
    word-break: break-word;
    font-family: "Manrope", sans-serif;
  }
}

.logo {
  text-align: center;
  margin-bottom: 25px;

  & img {
    width: 120px;
  }
}

.title {
  color: #000000;
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 42.7px;
  padding: 20px;

  @include respond(tab-port) {
    font-size: 3rem;
  }
}

.content-data {
  width: 50%;
  padding-left: 180px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  min-height: 100vh;
}

.content-texts,
.content-footer {
  max-width: 400px;
}

.subtitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.31;
  max-width: 456px;
  opacity: 0.67;
  color: var(--color-black);
  text-align: center;

  @include respond(tab-port) {
    font-size: 1.8rem;
  }
}

.forgotPassword {
  color: #000;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: width 0.5s;
  height: 20px;
  width: max-content;
  margin-left: auto;
  opacity: 1;
  justify-content: flex-end;

  @include respond(tab-port) {
    transform: none;
  }
}

.forgotPasswordText {
  opacity: 0.67;
  transition: opacity 0.5s;
}

.viewPasswordError svg g path {
  stroke: var(--color-error);
}

.viewPasswordError svg g g {
  fill: var(--color-error);
}

.label {
  display: block;
  color: #4391cd;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 6px;
}

.invalid-feedback {
  padding-left: 15px;
  margin-top: 3px;

  & .text {
    font-weight: 300;
    color: #979797;
  }
}

.newUserContainer {
  margin-top: 28.5px;

  &::before {
    content: "";
    display: block;
    width: 75%;
    height: 2px;
    background-color: #bfbfbf;
    opacity: 0.67;
    margin: auto;
  }
}

.newUser {
  color: var(--color-mainBlue);
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  margin: 15px 0;
  display: block;
}

.btn,
.registerButton {
  padding: 15px 30px;
  color: var(--color-white);
  outline: none;
  font-size: 15.5px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  width: 100%;
  display: block;
  background-color: var(--color-mainBlue);
  transition: all 0.2s;
  border-radius: 0.75rem;

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    color: white;
    transform: translateX(1px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.passwordField {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 10px;
}

.emailField {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 20px;
  margin-top: 40px;

  @include respond(phone) {
    margin-top: 30px;
  }
}

.buttonField {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 10px;

}

.registerField {
  padding-top: 30px;
}

.error {
  background-color: #e84f78;
  cursor: not-allowed;
}

.copyright {
  max-width: 268px;
  margin-top: 50px;
  color: #707070;
  opacity: 0.47;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.googleDivider {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(phone) {
    margin: 30px 0;
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1.3px;
    background-color: #bfbfbf;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1.3px;
    background-color: #bfbfbf;
  }

  &_text {
    margin: 0 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #000;
    opacity: 0.67;
    min-width: fit-content;
  }

}

.googleLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_txt {
    font-size: 15.5px;
    font-weight: bold;
    line-height: 17px;
    text-align: center;
    margin-bottom: 10px;

    &_p {
      font-weight: 800;
      display: inline;
      color: var(--color-success);
    }
  }
}

.formTitle {
  opacity: 0.67;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  margin-top: 10px;

  @include respond(phone) {
    font-size: 16px;
  }
}

.viewPassword {
  cursor: pointer;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  z-index: 1;
  top: 13px;
  right: 30px;

  svg {
    width: 20px;
    height: 20px;
  }

}

.hover {
  transition: all 0.2s;
  margin-left: 5px;
  transition: all 0.2s ease-in-out;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: var(--color-mainBlue);

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    width: 0px;
    height: 2px;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    background-color: var(--color-mainBlueBorder);
  }

  &:before {
    left: 50%;
  }

  &:after {
    right: 50%;
  }

  &:hover {
    &:before {
      width: 45px;
    }

    &:after {
      width: 45px;
    }
  }
}

.passwordError {
  color: #e84f78;
  display: flex;
  position: absolute;
  bottom: 0;

  @include respond(phone) {
    font-size: 12px;
  }
}

.emailError {
  color: #e84f78;
  display: flex;
  position: absolute;
  bottom: 0;

  @include respond(phone) {
    font-size: 12px;
  }
}