@use "../../../utils/mediaQuerys";
.generalContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container{
    background-color: #FFFFFF;
    width: 400px;
    height: 100%;
    padding: 20px;
    border-radius: 11px;
}
.edifyImg{
    width: 85px;
    top: 30px;
    left: 20px;
    position: absolute;;

}
.vector{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 30px;
}
.title{
    color: #234BA2;
    text-align: center;
    margin: 15px;
    font-size: 30px;
    font-weight: 800;
}
.containerHidden{
    overflow: hidden;
}
.description {
    color:#959CA3;
    margin: auto;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}
.btnSend{
    background-color: #245094;
    color: #FFFFFF;
    text-align: center;
    width: 76%;
    border-radius: 5px;
    margin: auto;
    margin-top: 20px;
    padding: 15px;
    font-weight: 600;
    cursor:pointer;
    font-size:18px;
}

.btnBack{
 color:#B3B5BA;
 text-align: center;
 padding-top: 30px;
 font-weight: 600;
 cursor: pointer;
 font-size: 18px;
}