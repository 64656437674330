@use "../../utils/mediaQuerys" as *;


.header {
    background: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

    @include respond (phone) {
        padding: 10px;
    }

    &_title {
        font-size: 20px;
        font-weight: 600;
        color: #234ba2;

        @include respond (phone) {
            font-size: 16px;
        }
    }

    &_faqIcon {
        cursor: pointer;
        display: flex;

        svg path {
            fill: #234BA2;
        }

        @include respond (phone) {
            svg {
                width: 15px;
                height: 15px;
            }
        }
    }
}

.componentContainer {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2rem;

    @include respond (phone) {
        padding-top: 8rem;
        padding-left: 0;
        padding-right: 0;
    }

    @include respond (tab-port, true) {
        padding-top: 8rem;
        padding-left: 0;
        padding-right: 0;
    }
}

.filterContainer {
    position: relative;

}

.listContainer {
    display: flex;
    width: 100%;
    height: calc(100% - 220px);
    padding-top: 10px;

    @include respond (phone) {
        flex-direction: column;

    }
}

.mapContainer {
    min-height: 100%;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;

    &_jobOffer {
        position: absolute;
        top: 30%;
        left: 20px;
        max-width: 320px;
        background: #fff;
        border-left: 15px solid #234ba2;
        z-index: 401;
        border-radius: 5px;
        box-shadow: 5px 1px 8px 1px rgba(0, 0, 0, 0.1);

        &_titleContainer {
            display: flex;
            margin-bottom: 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 10px;
            justify-content: space-between;
            align-items: start;
            border-bottom: 1px solid #ebebeb;
        }

        &_title {
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
        }

        &_closeButton {
            background: transparent;
            font-size: 14px;
            font-weight: 800;
            display: flex;
        }

        &_text {
            font-size: 14px;
            font-weight: 500;
            line-height: 25px;
            padding: 10px;

        }

        &_buttonContainer {
            padding: 20px;
        }

        &_button {
            width: 100%;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            background: #EDF0F7;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            align-items: center;
            gap: 10px;
            display: flex;
            color: #4167B3;

            svg path {
                stroke: #4167B3;
                transition: stroke 0.3s ease-in-out;
            }

            &:hover {
                background-color: #4167B3;
                color: #fff;

                svg path {
                    stroke: #fff;
                    transition: stroke 0.3s ease-in-out;
                }
            }
        }
    }
}

.selectedUser {
    right: 0;
    margin: 20px;
    width: fit-content;
    max-width: 300px;
    max-height: 400px;
    position: absolute;
    z-index: 401;
    bottom: 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 0px 9px 8px rgba(0, 0, 0, 0.1);

    @include respond (phone) {
        margin: 0;
        margin-top: 10px;
        min-height: 0;
        max-height: 100%;
        z-index: 101;
    }

    &_title {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f7b801;
        color: #fff;
        padding: 10px 20px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        &_closeButton {
            right: 10px;
            top: 12px;
            background-color: transparent;
            color: #fff;
            border: none;
            font-size: 14px;
            position: absolute;
        }
    }

    &_name {
        opacity: 0.9;
    }

    &_emptyList {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;

        svg {
            opacity: 0.5;
        }

        &_text {
            font-size: 14px;
            color: #6c757d;
            line-height: 1.5;
            margin-top: 10px;
            text-align: center;
        }
    }

    &_info {
        gap: 3px;
        display: flex;
        flex-direction: column;
        overflow-y: overlay;
        max-height: 300px;
    }

    &_infoText {
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 5px;

        @include respond (phone) {
            font-size: 12px;
        }

        &_subTitle {
            font-weight: 800;

        }

        &_svgContainer {
            svg path {
                fill: #000;
            }

            svg {
                fill: #000;
            }
        }

        &_zone {
            font-size: 13px;
            font-weight: 500;
            display: flex;
            gap: 5px;

            @include respond (phone) {
                font-size: 12px;
            }
        }
    }

    &_infoTextList {
        display: flex;
        flex-direction: column;
        gap: 3px;
        border-bottom: 1px solid #ebebeb;
        padding: 10px;

        &:last-child {
            border-bottom: none;
        }
    }
}

.smallToast {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include respond(phone) {
        padding: 10px;
        font-size: 14px;
    }

}

.firstToast {
    border-left: 10px solid #234ba2;
}

.secondToast {
    border-left: 10px solid #4ce2a7;
}

.thirdToast {
    border-left: 10px solid #ffc107;
}