@use "../../utils/mediaQuerys" as *;;

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &.hideApplyModal {
        display: none;
    }
}

.modal {
    background: #fff;
    border-radius: 5px;
    min-width: 40vw;
    padding: 20px;

    @include respond(phone) {
        max-width: 90vw;
    }
}

.header {
    display: flex;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #EDF0F7;

    &_title {
        color: #234ba2;
        font-size: 20px;
        font-weight: 600;

        @include respond(phone) {
            font-size: 16px;
        }
    }

    &_button {
        background: none;
        border: none;
        color: #889abe;
        font-size: 20px;
        cursor: pointer;
    }
}

.main {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #2b3b5b;

    @include respond(phone) {
        gap: 10px;
        padding: 0;
        padding-top: 10px;
        text-align: center;
    }
}

.form {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include respond(phone) {
        flex-direction: column;
        gap: 10px;
    }

    &_input {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;

        &::placeholder {
            color: #ccc;
        }

        @include respond(phone) {
            text-align: center;
            &::placeholder {
                text-align: center;
            }
        }
    }

    &_button {
        background: #234ba2;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;

        &:disabled {
            background: #ccc;
            cursor: not-allowed;
        }
    }
}

