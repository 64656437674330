@use "../../../utils/mediaQuerys" as *;

.RegisterFormContainer {
  display: flex;
  width: 100%;
  height: 100%;

  @include respond(tab-port) {
    height: auto;
  }

}

.background {
  background: url("../../../assets/images/foto-bienvenida.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  box-shadow: -20px 0px 125px -42px rgba(65, 103, 179, 0.15);
  
  @include respond(tab-port) {
    display: none;
  }

}

.RegisterForm {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 2% 5%;
  background-color: #f7f7f7;

  &_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 75rem;
    border: 1px solid #edf0f7;
    border-radius: 18px;
    padding: 2rem;
    background-color: #fff;
  
    @include respond(tab-port) {
      max-width: 100%;
    }

  }
}

.copyright {
  max-width: 268px;
  margin-top: 20px;
  color: #707070;
  opacity: 0.47;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.button {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: 700;
  background-color: #979797;
  border-radius: 16.88px;
  width: 255px;
  height: 58px;
  line-height: 58px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.active {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: 700;
  background-color: #1e4776;
  border-radius: 16.88px;
  width: 255px;
  height: 58px;
  line-height: 58px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 25px 0;

  @include respond(tab-port) {
    display: none;
  }
}

.change {
  color: #2f6aae;
  border-bottom: 1px solid #2f6aae;
  display: none;
  cursor: pointer;
}

.title {
  color: #000000;
  font-size: 35px;
  font-weight: 700;
  line-height: 42.7px;
  text-align: center;

  @include respond(tab-port) {
    font-size: 3rem;
    margin-top: 15px;
  }
}

.formTitle {
  color: var(--color-mainBlue);
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;

}

.formSubtitle {
  color: var(--color-black);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  margin: 1.5rem 0 3rem;
  opacity: 0.67;
}

.RegisterFormContainer {

  div[class*="RegisterStepsPage_container"] {
    margin-top: 15px;
  }
}