@use "../../../utils/mediaQuerys" as *;

.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 0;
        background-color: #fff;
        border-bottom: 1px solid var(--color-mainBorder);
        gap: 2rem;

        &_title {
            display: flex;
            font-size: 1.4rem;
            font-weight: 600;
            flex-direction: column;
            color: #7a7f85;
            text-align: left;

            &_txt {
                display: flex;
                font-size: 2rem;
                color: #333;
                text-align: left;
            }
        }

        @include respond(phone) {
            flex-direction: column;
            align-items: stretch;
            gap: 2rem;
        }

        &_buttonContainer {
            display: flex;
            gap: 2rem;
            align-items: center;

            @include respond(phone) {
                flex-direction: column;
                gap: 1rem;
                align-items: stretch;
            }

            
        }

        &_goBackBtn {
            background-color: transparent;
            font-size: 1.6rem;
            color: rgb(75 85 99 );
        }
    }
}

.searchBar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
    padding-top: 2rem;

    &_inputContainer {
        position: relative;
        flex: 1;
    }

    &_icon {
        position: absolute;
        top: 1.3rem;
        left: 1rem;

    }

    &_input {
        border-radius: 0.75rem;
        padding: 1rem 3rem;
        width: 100%;
        border: 1px solid var(--color-buttonBorder);
        font-size: 1.4rem;
    }

    &_suggestions {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        border-radius: 0.5rem;
        box-shadow: 0px 11px 10px 0 rgba(0, 0, 0, 0.1);
        z-index: 1001;
        max-height: 300px;
        overflow-y: auto;

        &_item {
            padding: 1rem;
            font-size: 1.4rem;
            border-bottom: 1px solid #e0e0e0;

            &:hover {
                background-color: #ebebeb;
                cursor: pointer;
            }
        }
    }
}

.mainFilter {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;

    @include respond (phone) {
        box-shadow: none;
    }

    &_searchResults {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        &_title {
            font-size: 1.4rem;
            font-weight: 500;
        }

    }

    &_buttonsContainer {
        display: flex;
        gap: 2rem;
    }

    &_views {
        background-color: rgb(230 246 241);
        padding: 0.5rem;
        border-radius: 0.5rem;
        display: flex;

        &_button {
            padding: 1rem;
            background-color: transparent;
            color: rgb(17, 24, 39);
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 1.4rem;

            &.isActive {
                background-color: #fff;
                border-radius: 0.75rem;
                color: rgb(27 140 107);
            }

        }

        &_title {
            padding-left: 10px;

        }
    }

    &_filterMenu {
        position: absolute;
        top: 120%;
        right: 0;
        z-index: 2;
        min-width: 180px;
        background: #fff;
        box-shadow: 0px 11px 10px 0 rgba(0, 0, 0, 0.1);
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        color: #333;

        &_item {
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;

            &_itemContent {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
            }

            &_infoIcon {
                display: flex;
                padding-top: 5px;
                padding-right: 5px;

                svg path {
                    fill: #ebebeb;
                }
            }

            &_infoContainer {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                padding-left: 10px;

                &:hover {
                    cursor: pointer;
                    background-color: #ebebeb;
                    border-radius: 8px;

                    svg path {
                        fill: #fff;
                    }
                }
            }

            &_info {
                display: flex;
                padding-left: 10px;
                font-size: 14px;

                @include respond (phone) {
                    font-size: 12px;
                    padding-left: 0;
                }
            }

            &_options {
                position: absolute;
                top: 100%;
                z-index: 100;
                background-color: #fff;
                width: 100%;
                border-radius: 12px;
                box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
                display: flex;
                flex-direction: column;
                border: 1px solid #e0e0e0;
                max-height: 350px;
                overflow-y: auto;

                &_item {
                    padding: 10px;
                    font-size: 14px;

                    &:hover {
                        cursor: pointer;
                        background-color: #ebebeb;
                        border-radius: 8px;
                    }

                    @include respond (phone) {
                        font-size: 12px;
                    }
                }
            }

            &_title {
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding: 10px;
                gap: 30px;
                align-items: center;
                border-right: 10px solid transparent;
                transition: all 0.3s;

                &:hover {
                    cursor: pointer;
                    border-right: 10px solid rgb(27 140 107 );

                }

                &.isSelected {
                    border-right: 10px solid rgb(27 140 107 );
                }
            }
        }

        &_subMenu {
            position: absolute;
            right: 101%;
            top: 0;
            max-height: 300px;
            overflow-y: overlay;
            overflow-x: hidden;
            background: #fff;
            border: 2px solid rgb(27 140 107 );
            min-width: 150px;
            max-width: 150px;
            box-shadow: 5px 1px 8px 1px rgba(0, 0, 0, 0.2);


            &_item {
                padding: 10px;
                font-size: 13px;
                border-bottom: 1px solid #e0e0e0;
                font-weight: 400;

                &:hover {
                    cursor: pointer;
                    background-color: #ebebeb;
                }

            }
        }
    }

    &_buttons {
        display: flex;
        gap: 30px;
        height: 100%;
        align-items: center;

        @include respond(phone) {
            gap: 0px;
            justify-content: space-between;
            width: 100%;
            padding-right: 0px;
        }
    }

    &_openFilters {
        display: flex;
        gap: 10px;
        cursor: pointer;
        position: relative;
        height: 100%;
        align-items: center;
        padding: 1rem;
        background: rgb(249 250 251);
        border-radius: 0.75rem;
        border: 1px solid rgb(209 213 219);
    }

    &_createWorkOffer {
        display: flex;
        gap: 10px;
        cursor: pointer;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: rgb(27 140 107 );
        color: #fff;
        padding: 1rem;
        font-weight: 500;
        font-size: 1.6rem;
        border-radius: 0.75rem;
        transition: all 0.3s;

        &:hover {
            background: rgb(20 105 80 );
            color: #fff;

            svg path {
                stroke: #fff;
            }
        }

        &_title {
            display: flex;
            align-items: center;
            width: max-content;
        }
    }

    &_buttonReset {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: transparent;
        color: #234ba2;
        font-weight: 600;


        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
            color: #959ba3;
        }
    }

    &_filterNumber {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #234ba2;
        background-color: #fff;
        font-weight: 600;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 12px;

    }


    &_rubro {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        padding: 3px;

        &_itemContent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }

        &_infoIcon {
            display: flex;
            padding-top: 5px;
            padding-right: 5px;

            svg path {
                fill: #ebebeb;
            }
        }

        &_infoContainer {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            padding-left: 10px;

            &:hover {
                cursor: pointer;
                background-color: #ebebeb;
                border-radius: 8px;

                svg path {
                    fill: #fff;
                }
            }
        }

        &_info {
            display: flex;
            padding-left: 10px;
            font-size: 14px;

            @include respond (phone) {
                font-size: 12px;
                padding-left: 0;
            }
        }

        &_options {
            position: absolute;
            top: 100%;
            z-index: 100;
            background-color: #fff;
            width: 100%;
            border-radius: 12px;
            box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            border: 1px solid #e0e0e0;
            max-height: 350px;
            overflow-y: auto;

            &_item {
                padding: 10px;
                font-size: 14px;

                &:hover {
                    cursor: pointer;
                    background-color: #ebebeb;
                    border-radius: 8px;
                }

                @include respond (phone) {
                    font-size: 12px;
                }
            }
        }

        &_title {
            padding-left: 10px;
            display: flex;
            width: 100%;
            align-items: center;
            font-size: 12px;
            font-weight: 600;

            &:hover {
                cursor: pointer;
                background-color: #ebebeb;
                border-radius: 8px;
            }

            @include respond (phone) {
                padding-left: 0;
            }
        }
    }
}

.selectedFilters {
    display: flex;
    width: 100%;
    flex-direction: column;
    z-index: 1;
    padding: 10px;

    &_boxes {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
    }

    &_title {
        border-top: 1px solid #ebebeb;
        font-size: 14px;
        padding: 10px;
    }

    &_item {
        background: #234ba3;
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        gap: 5px;
        justify-content: center;

        &_x {
            background-color: transparent;
            color: #fff;
        }
    }
}

.downloadButtons {
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    width: max-content;

    &_button {
        background: transparent;
    }

    svg g {
        fill: #000;
    }
}