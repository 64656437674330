@use "../../utils/mediaQuerys" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow: hidden;

  @include respond(phone) {
    padding-top: 80px;
    padding-left: 5%;
    padding-right: 5%;
  }

  @include respond(tab-port, true) {
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 80px;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 4rem;

  @include respond(phone) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem;
  }

  &_titleContainer {
    display: flex;
    flex-direction: column;
  }

  &_buttonIcon {
    display: flex;
    align-items: center;
  }

  &_title {
    color: rgb(17 24 39);
    font-size: 4.8rem;
    line-height: 1.3;
    text-align: left;
  }

  &_subtitle {
    color: rgb(75 85 99 );
    font-size: 2rem;
    line-height: 1.3;
    margin-top: 2.4rem;
    font-weight: 500;
    flex: 1;
  }

  &_buttonContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    margin-top: 2.4rem;
    padding-top: 1.6rem;
  }

  &_buttonPrimary {
    background-color: #234ba2;
    color: #fff;
    border-radius: 0.75rem;
    padding: 1.4rem 2.8rem;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.3s;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    &:hover {
      background-color: #1a3e8c;
      transform: scale(1.05);
    }
  }

  &_button {
    background-color: rgb(31 41 55);
    color: #fff;
    border-radius: 0.75rem;
    padding: 1.4rem 2.8rem;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.3s;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    &:hover {
      background-color: rgb(17 24 39);
      transform: scale(1.05);
    }

    &_text {
      display: flex;
      align-items: center;
    }
  }

  &_buttonCounter {
    display: flex;
    align-items: center;
    background-color: #fff;
    font-weight: 600;
    font-size: 1.4rem;
    padding: 0.4rem 1rem;
    border-radius: 9999px;
    color: rgb(107 114 128);
    margin-left: 1rem;
  }
  
}

.steps {
  margin-top: 4.8rem;
}

.footer {
  margin-top: 4.8rem;
}

.AllProjects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 16.5px;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.5px;
    margin-bottom: 25px;
  }
}
