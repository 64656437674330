.MainContentWrapper {
  background-color: #fff;
  border-radius: 7px;
  margin: 0 13px;
  width: 48%;
  &.onboarding {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 700px;
    z-index: 1000;
  }
  &.visualizer {
    position: absolute;
    min-width: 700px;
    z-index: 1000;
  }
  .title {
    width: 100%;
    background-color: #2f6aae;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    h1 {
      color: #ffffff;
      font-size: 20px;
      font-weight: 400;
      line-height: 61px;
      text-align: left;
      margin-left: 40px;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    &.onboarding {
      padding: 0 60px;
    }
  }
}
