@use "../../../utils/mediaQuerys" as *;

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;

  @include respond(phone) {
    background-color: #fff;
    margin: 10px 0;
    border-radius: 11px;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  .topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nameContainer {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      h4 {
        color: #2b3b5b;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        margin-left: 1rem;
      }

    }
  }

  .itemContent {
    margin-bottom: 4rem;

    p {
      color: #707070;
      font-size: 13px;

      span {
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .notificationTitle {
    display: flex;

    &_title {
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }

  .notificationContainer {
    display: flex;
    padding: 10px;
    width: 100%;

    &_header {
      display: flex;
      align-items: center;

    }

    &_headerProject {
      display: flex;
      align-items: center;

      svg circle {
        fill: #fff;
      }

      svg path {
        fill: #3f66b3;
      }
    }

    &_time {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 14px;
      justify-content: flex-end;
      font-weight: 500;
    }

    &_checked {
      width: 15px;
      height: 15px;
      position: absolute;
      bottom: -5px;
      right: -5px;
    }


    &_image {
      width: 100%;
      height: 100%;
      border-radius: 50%;

      &_blur {
        filter: blur(4px);
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &_profile {
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
      border-radius: 50%;
      margin-right: 20px;
      border: 4px solid #ebebeb;
      position: relative;

    }

    &_info {
      display: flex;
      align-items: center;
    }

    &_rubro {
      font-size: 12px;
      color: #959Ca3;
      font-weight: 600;
    }

    &_txtContainer {
      display: flex;
      margin-left: 5px;
      align-items: center;
    }

    &_txt {
      font-size: 12px;
      font-weight: 500;
      color: #2b3b5b;

    }

    &_blurredText {
      filter: blur(4px);
    }

    &_notPremium {
      text-decoration: underline;
      font-weight: 900;
      cursor: pointer;
    }
  }

  .button {
    -webkit-appearance: none;
    color: #3f66b3;
    display: flex;
    align-items: center;
    background-color: unset;
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
    margin-left: 135px;
    transition: all 0.1s;

    &:hover {
      transform: scale(1.02);
    }

    &::after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: url("../../../assets/svg/arrowBlue.svg") no-repeat;
      background-position: center;
      background-size: 75%;
      margin-left: 1rem;
    }
  }

  .buttonWithOutArrow {
    -webkit-appearance: none;
    color: #46c39e;
    display: flex;
    align-items: center;
    background-color: unset;
    font-weight: 600;
    font-size: 12px;
    margin-top: 10px;
    margin-left: auto;
  }

  .buttonWithOutArrowNegated {
    -webkit-appearance: none;
    color: #e84f78;
    display: flex;
    align-items: center;
    background-color: unset;
    font-weight: 600;
    font-size: 12px;
    margin-top: 10px;
    margin-left: auto;
  }
}

.alert {
  display: flex;
  text-align: center;
  opacity: 0.47;
  box-sizing: border-box;
  margin-bottom: 10px;

  svg {
    width: 30px;
    margin-right: 1rem;
  }
}

.newRequest {
  font-weight: 300;
}

.projectNameStyle {
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  cursor: pointer;
  padding-left: 2px;
  color: #4167b3;
}

.flex {
  display: flex;
  padding-top: 15px;
}

.sendSolicitud {
  color: #2b3b5b;
  font-weight: 600;
  font-size: 12px;
  margin-left: 45px;
  margin-top: -15px;
}

.spanClass {
  margin-right: 5px;
}

.userName {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: #2b3b5b;
}

.solicitudTitle {
  font-weight: bolder;
  font-size: 12px;
  color: #2b3b5b;
  margin-left: 5px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 5px;

  &_solicitudViewContainer {
    cursor: pointer;

  }

  &_solicitudView {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: #3f66b3;

  }

  &_acceptReject {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  @include respond(small-phone) {
    justify-content: space-around;
  }

  &_accept {
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    background: #46c39e;

    svg {
      fill: #fff;
    }

  }

  &_reject {
    display: flex;
    cursor: pointer;
    background: #e84f78;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;

    svg g {
      stroke: #fff;
    }
  }

  &_delete {
    display: flex;
    cursor: pointer;
  }
}