.SuscriptionModal {
    width: 50%;
    border-radius: 30px;
    border: solid 2px #ebebeb;
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    z-index: 2000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    &_FreeTrialContainer {
      display: flex;
    }
    &_ImageContainer {
        border-radius: 30px 0 0 30px;
        background: url("../../../assets/images/foto-bienvenida.jpg");
        background-size: cover;
        width: 50%;
        flex: none;
        align-self: stretch;
        flex-grow: 1;
    }
    &_FreeTrial{
        padding: 50px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }
    h3 {
      font-size: 30px;
      font-weight: 800;
      text-align: center;
      color: #000000;
    }
    &_title {
      display: flex;
      align-items: center;
      gap: 10px;
  
      &alertWarning {
          width: 40px;
          height: 40px;
      }
    }
    &_PlanContainer {
     
      display: flex;
      padding: 0px;
      gap: 50px;
      &Plan {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          gap: 15px;
          width: 300px;
  
          background: #FFFFFF;
          box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
          border-radius: 30px;
          &title {
              display: flex;
              flex-direction: column;
              align-items: center;
          }
          &Price {
              font-size: 26px;
              font-weight: 800;
              text-align: center;
              color: #000000;
          }
          &ItemList {
              display: flex;
              flex-direction: column;
              gap: 5px;
          }
          &Item {
              display: flex;
              padding: 0px;
              gap: 5px;
              align-items: flex-start;
              &FreeTrial {
                  font-weight: bold;
              }
          }
          &Divider {
              border: 1px solid #000000;
              transform: rotate(180deg);
              align-self: stretch;    
          }
          &Checkmark {
              margin: 5px;
              margin-top: 0px;
          }
      }

      &item_warning {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
      }
      
      &item,
      &item_decline,
      &item_success {
          align-self: stretch;
          height: 4rem;
  
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background-color: #234BA2;
        cursor: pointer;
        font-size: 13px;
        font-weight: 600;
        color: var(--color-white);
        text-align: center;
        margin-top: 15px;
        transition: all 100ms ease-in-out;
        &:hover:not([disabled]) {
          transform: scale(1.05);
        }

        &:disabled {
          cursor: not-allowed;
          background-color: gray;
          opacity: 0.5;
          margin-top: 0;
        }
  
        &_success {
          text-transform: uppercase;
        }
        &_decline {
          font-weight: 600px;
          background-color: var(--color-white);
          color: #B3B5BA;
          margin-top: 0px;
        }
      }
    }
    &_overlay {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      // border: solid 1px #707070;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .margin{
    margin-right: 5px;
  }
  .edifyIcon{
      position: absolute;
      left: 54px;
      top: 30px;
  }
  .trialHeader {
    font-size: 14px;
  }
  .trialItems {
    font-size: 13px;
  }
  .trialAclaracion {
    font-size: 11px;
  }
  .trialTarjeta {
    font-size: 14px;
    font-weight: 800;
  }
  .important {
    color: red;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    .SuscriptionModal {
      width: 90%;
      flex-direction: column;
      h3 {
        font-size: 26px;
        font-weight: 800;
        text-align: center;
        color: #000000;
      }
      &_ImageContainer {
        width: 100%;
        border-radius: 30px 30px 0 0;
      }
      &_FreeTrial {
        padding: 30px 20px;
        height: 500px;
      }
      &_PlanContainer {
        flex-direction: column;
        gap: 30px;
        &Plan {
          width: 100%;
          padding: 20px;
          gap: 10px;
          &Price {
            font-size: 20px;
          }
          &ItemList {
            gap: 3px;
          }
          &Divider {
            display: none;
          }
        }
      }
    }
    .trialItems {
      font-size: 10px;
    }
  }