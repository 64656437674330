.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 0.75rem;
  background-color: #fff;
  border: 1px solid #edf0f7;
  box-shadow: var(--shadow);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-0.5rem);
  }
}

.header {
  background: #234ba2;
  padding: 1rem;

}

.category {
  font-weight: 600;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;

  &_profileRedirect {
    text-align: center;
    font-weight: 600;
    font-size: 1.4rem;
    color: white;
    cursor: pointer;
  }
}

.content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profileInfo {
  display: flex;
  align-items: flex-start;
  padding-bottom: 1.5rem;

  &_image {
    display: flex;

    &_img {
      object-fit: cover;
      min-width: 8rem;
      max-width: 8rem;
      min-height: 8rem;
      max-height: 8rem;
      border-radius: 0.75rem;
    }
  }

  &_dataContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1rem;

    &_type {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &_text {
        font-weight: 500;
        color: rgb(107 114 128);
      }

    }
  }
}

.avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.name {
  font-weight: 700;
  font-size: 1.8rem;
  color: rgb(17 24 39 );
  text-transform: capitalize;
}

.verifiedText {
  font-size: 1.2rem;
  color:  rgb(22 101 52);
  background-color: rgb(220 252 231);
  padding: 0.2rem 0.8rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: max-content;
}

.rating {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
}

.star {
  width: 2rem;
  height: 2rem;
 

  &.filled {
    color: #fbbf24;
    fill: currentColor;
  }

  &.empty {
    color: #d1d5db;
  }
}

.ratingValue {
  font-weight: 500;
  color: #4b5563;
  margin-left: 0.5rem;
}

.infoItem {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: 1.4rem;
  color: #4b5563;
  padding-bottom: 1rem;
  word-break: break-word;

  &_skills {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.icon {
  min-width: 1.8rem;
  min-height: 1.8rem;
  max-width: 1.8rem;
  max-height: 1.8rem;
  color: #234ba2;
  margin-top: 0.2rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 1rem 0;
}

.tag {
  background-color: rgb(219 234 254);
  color: rgb(30 64 175);
  padding: 0.2rem 1rem;
  border-radius: 3rem;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    background-color: #bfdbfe;
  }
}

.showMoreButton {
  background: transparent;
  color: #234ba2;
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

}

.button {
  width: 100%;
  color: #fff;
  background-color: rgb(37 99 235);
  font-weight: 300;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.8rem 1.6rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.contactButton {
  width: 100%;
  background-color: white;
  color: rgb(37 99 235);
  border: 1px solid rgb(37 99 235);
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;

  &:hover {
    background-color: #f0f7ff;
  }
}

.moreTags {
  color: #2563eb;
  font-size: 2rem;
  font-weight: bold;
}

.initialsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  color: #fff;
  font-size: 1.8rem;
  width: 8rem;
  height: 8rem;
  border-radius: 0.75rem;
  text-transform: uppercase;
}