@use "../../utils/mediaQuerys" as *;

.finalBanner {
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: var(--shadow);
    overflow: hidden;
    margin-top: 4.8rem;
}

.finalBannerContent {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 4rem;

    @include respond(phone) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 0;
    }
}

.finalBannerInfo {
    padding: 3rem;
}

.finalBannerTitle {
    font-size: 3rem;
    color: rgb(17 24 39);
    font-weight: 700;
    margin-bottom: 2.4rem;
    text-align: left;
}

.finalBannerDescription {
    color: rgb(75 85 99 );
    line-height: 1.5;   
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 3.2rem;
}

.finalBannerButton {
    background-color: #234ba2;
    color: #fff;
    padding: 1.6rem 3.2rem;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 600;
    gap: 1rem;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: #1a3561;
        transform: scale(1.05);
    }
}

.finalBannerImage {
    background-image: url('../../assets/images/contactFormBackground.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 0 0.75rem 0.75rem 0;
}