.workerMapContainer {
    height: 100%;
    position: relative;
    display: flex;
    width: 100%;

    &_map {
        position: relative;
        width: 100%;
        height: 100%;

        &.reducedMap {
            width: 60%;
        }
    }

    &_loader {
        width: 100%;
        height: 100%;
    }
}

.info {
    background: white;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    position: absolute;
    bottom: 0px;
    right: 0;
    z-index: 101;
    font-size: 12px;
    font-weight: 500;

    &_header {
        font-size: 14px;
    }
}

.leaflet-div-icon {
    background: none;
    border: none;
}

.clusterMarker {
    color: #fff;
    background: #1978c8;
    border-radius: 50%;
    padding: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallMap {
    width: 30%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;

    &_card {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        position: relative;
        flex: 1;
        height: 50%;
        font-size: 12px;
        font-weight: 400;
        gap: 5px;

        &_container {
            height: 100%;
            overflow: overlay;
            display: flex;
            flex-direction: column;
        }

        &_name {
            font-size: 14px;
            font-weight: 600;
            opacity: 0.9;
        }

        &_title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        &_uniqueCity {
            font-size: 13px;
            font-weight: 500;
            display: flex;
            align-items: center;
            opacity: 0.8;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &_locationIcon {
            width: 12px;
            height: 13px;
            margin-right: 5px;
        }

        &_isAvailable {
            display: flex;
            align-items: center;
            font-size: 14px;
            padding-top: 10px;

            &_icon {
                width: 12px;
                height: 12px;
                margin-right: 5px;
                border-radius: 50%;
                background-color: #4CE2A7;
            }

            &_iconNo {
                background-color: #ffc107;
                width: 12px;
                height: 12px;
                margin-right: 5px;
                border-radius: 50%;

            }

        }

        &_work {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

        }
    }

    &_contactButton {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 10px;
        background: #4167B3;
        color: white;
        font-weight: 500;
        font-size: 15px;
        border-radius: 5px;
    }

    &_closeMap {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-weight: 500;
        font-size: 14px;
        padding: 5px;
        background: #4167B3;
        color: #fff;
    }
}

.skillTag {
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: #EDF0F7;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    color: #4167B3;
}

.smallContactButtonMultiple {
    width: 100%;
    margin-top: 10px;
    background: #1978c8;
    color: white;
    border-radius: 5px;
    font-weight: 500;
    margin-bottom: 20px;
}