.tutorialBackDrop {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    backdrop-filter: blur(5px);
}

.tutorialModalSide {
    position: absolute;
    background: linear-gradient(to right, white 75%, #003667 30%);
    width: 1000px;
    max-width: 90%;
    height: 600px;
    display: flex;
    align-items: center;
    z-index: 250;
    border-radius: 11px;
}

.tutorialModal {
    height: 500px;
    border-radius: 11px;
    z-index: 500;
    text-align: center;
    width: 75%;

    &_title {
        font-size: 30px;
        font-weight: 700;
        color: #003667;
        padding-bottom: 20px;
    }

    &_contentList {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &_item {
            margin: 10px;
            padding: 10px;
            border-radius: 5px;
            transition: all 0.3s ease;
            box-shadow: -2px 1px 20px -6px rgba(0, 0, 0, 0.1);
            cursor: pointer;

            &:hover {
                transform: translateY(-5px);
                box-shadow: -2px 1px 20px -6px rgba(0, 0, 0, 0.4);
            }

            &_header {
                display: flex;
                width: 100%;
                justify-content: space-between;

                svg path {
                    fill: #003667;
                }
            }

            &_headerTxt {
                font-size: 18px;
                text-align: left;
                color: #003667;
                font-weight: 700;


            }

            &_subText {
                padding-top: 20px;
                font-size: 12px;
                color: #2b3b5b;
                font-weight: 500;
                text-align: left;
            }

            &_seeMore {
                color: #003667;
                font-size: 12px;
                font-weight: 500;
                text-align: left;
                padding-top: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                    margin-left: 10px;
                }
            }
        }
    }
}

.closeTutorial {
    position: absolute;
    top: 30px;
    right: 30px;
    background: none;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

@media (max-width: 768px) {
    .tutorialModal {
        &_contentList {
            display: flex;
            flex-direction: column;          
        }

        &_title {
            font-size: 18px;
        }
    }

    .tutorialBackDrop {
        z-index: 1000;
    }

    .tutorialModalSide {
        width: 100vw;
        overflow: overlay;
        
    }
}