@use "../../utils/mediaQuerys" as *;

.mercadoDeTrabajo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem;
    overflow: hidden;

    @include respond(phone) {
        padding-top: 8rem;
        padding-left: 0;
        padding-right: 0;
    }

    @include respond(tab-port, true) {
        padding-top: 8rem;
        padding-left: 0;
        padding-right: 0;
    }

    &_overflowContainer {
        overflow: hidden;
    }

    &_headerWrapper {
        width: 100%;
        overflow: hidden;
    }

    &_header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 3rem;
        background-image: linear-gradient(to right,
                #22af86,
                #1b8c6b);
        padding: 2rem;
        border-radius: 0.75rem;
        position: relative;

        &_title {
            font-size: 3rem;
            color: #fff;
            font-weight: 700;
            margin-bottom: 1.6rem;
        }

        &_subtitle {
            color: rgb(230 246 241);
            font-size: 1.8rem;
        }

        &_closeButton {
            position: absolute;
            top: 0;
            right: 2rem;
            background: none;
            border: none;
            font-size: 3.5rem;
            cursor: pointer;
            color: #fff;
        
            &:hover {
              color: #495057;
            }
          }
    }

    &_content {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 4rem;
        margin-bottom: 3rem;

        @include respond(phone) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &_item {
            position: relative;
            overflow: hidden;
            border-radius: 0.75rem;
            box-shadow: var(--shadow);
            padding: 3.4rem;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: translateY(-0.5rem);
                box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
            }

            &_body {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            &_iconContainer {
                background-color: rgb(204 237 227);
                border-radius: 0.75rem;
                width: 8rem;
                height: 8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgb(27 140 107);
                margin-bottom: 2.4rem;
            }

            &_title {
                font-size: 2.4rem;
                color: rgb(17 24 39);
                font-weight: 700;
                margin-bottom: 1.6rem;
            }

            &_description {
                color: rgb(75 85 99);
                font-size: 1.6rem;
                margin-bottom: 2.4rem;
                line-height: 1.5;
            }

            &_list {
                list-style: none;
                gap: 1.2rem;
                display: flex;
                flex-direction: column;
                margin-bottom: 3.2rem;
                flex: 1;

                &_item {
                    display: flex;
                    gap: 1.2rem;
                    align-items: center;
                    color: rgb(55 65 81);
                    font-size: 1.6rem;


                    &_icon {
                        color: rgb(27 140 107);
                        min-width: 2rem;
                    }
                }
            }

            &_button {
                padding: 1.2rem 2.4rem;
                border-radius: 0.75rem;
                border: 2px solid rgb(27 140 107);
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgb(27 140 107);
                gap: 1.2rem;
                width: 100%;
                font-size: 1.6rem;
                font-weight: 500;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: rgb(230 246 241);
                }
            }

            &_buttonPrimary {
                background-color: rgb(34 175 134);
                color: #fff;
                padding: 1.2rem 2.4rem;
                border-radius: 0.75rem;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1.2rem;
                width: 100%;
                font-size: 1.6rem;
                font-weight: 500;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: rgb(27 140 107);
                }


            }
        }

        &_corner {
            position: absolute;
            border-radius: 9999px;
            background-color: rgb(230 246 241);
            right: -6rem;
            top: -6rem;
            width: 12rem;
            height: 12rem;
        }
    }

    &_footer {
        background-color: #fff;
        padding: 3.4rem;
        display: flex;
        flex-direction: column;
        width: 100%;

        &_header {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-bottom: 3.2rem;

            &_title {
                font-size: 2.4rem;
                color: rgb(17 24 39);
                font-weight: 700;
                margin-bottom: 0.8rem;
            }

            &_description {
                color: rgb(75 85 99);
                font-size: 1.6rem;
                font-weight: 500;
            }
        }

        &_steps {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 3rem;
            margin-bottom: 3.2rem;

            @include respond(phone) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }

        &_step {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            &_iconContainer {
                background-color: rgb(204 237 227);
                width: 8rem;
                height: 8rem;
                border-radius: 0.75rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgb(34 175 134);
                margin-bottom: 1.6rem;
            }

            &_title {
                font-size: 1.8rem;
                font-weight: 600;
                color: rgb(17 24 39);
                margin-bottom: 0.8rem;
            }

            &_description {
                color: rgb(75 85 99);
                line-height: 1.4;
                text-align: center;
            }

            &_line {
                position: absolute;
                width: 100%;
                height: 3px;
                background-color: rgb(204 237 227);
                left: calc(100% - 2rem);
                top: 4rem;

                @include respond(phone) {
                    display: none;
                }

                &_dot {
                    position: absolute;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-color: rgb(34, 175, 134);
                    border-radius: 9999px;
                    top: -6px;
                    right: 0;
                }
            }
        }

        &_help {
            border-top: 1px solid rgb(243 244 246);
            padding-top: 2.4rem;

            &_title {
                font-size: 1.6rem;
                color: rgb(75 85 99);
                margin-bottom: 1.6rem;
                text-align: center;
            }

            &_contact {
                display: flex;
                gap: 1.2rem;
                justify-content: center;
                align-items: flex-start;
                color: rgb(34 175 134);
                font-weight: 600;
                font-size: 1.6rem;

                &:hover {
                    color: rgb(27 140 107 );
                }
            }
        }
    }
}