@use "../../../utils/mediaQuerys" as *;

.container {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    border: 1px solid #e5e7eb;
    border-radius: 0.75rem;

    &_header {
        display: flex;
        justify-content: space-between;

        &_location {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            color: rgb(107 114 128 );
            line-height: 2;
        }

        &_leftSide {
            display: flex;
            flex-direction: column;
            
        }

        &_title {
            font-size: 1.6rem;
        }

        &_dateSent {
            color: rgb(107 114 128 );
            line-height: 1.5;
        }

        &_rightSide {
            display: block;

        }

        &_status {
            padding: 0.4rem 1.2rem;
            border-radius: 9999px;
            display: flex;
            align-items: center;

            &_allowed {
                color: rgb(22 101 52);
                background-color: rgb(220 252 231);
            }

            &_negated {
                color: rgb(185 28 28);
                background-color: rgb(254 226 226);
            }

            &_send {
                color: rgb(251 191 36);
                background-color: rgb(255 244 229);
            }

        }
    }
    &_body {
        display: flex;
        flex-direction: column;

        &_description {
            font-size: 1.6rem;
            margin-top: 1.6rem;
            line-height: 1.5;
            font-weight: 500;
            color: rgb(75, 85, 99);
        }
    }

    &_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.6rem;
        width: 100%;
    }

    &_button {
        color: #fff;
        background-color: rgb(37 99 235);
        padding: 0.8rem 1.6rem;
        border-radius: 0.75rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-left: auto;
        
        transition: all;

        &:hover {
            background-color: rgb(30 64 175);
        }
    }
}
