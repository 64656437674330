 @use "../../../utils/mediaQuerys" as *;

 .filterContainer {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     width: 100%;

     &_toggleContainer {
         background-color: #fff;
         padding: 2rem 0;
         width: 100%;
         display: flex;
         justify-content: space-between;
         align-items: center;
         border-bottom: 1px solid var(--color-mainBorder);

         &_titleContainer {
             display: flex;
             flex-direction: column;
             text-align: left;

         }

         &_title {
             font-size: 1.4rem;
             font-weight: 600;
             color: #7a7f85;
         }

         &_txt {
             font-size: 2rem;
             color: #333;
             font-weight: 600;
         }

         &_buttons {
             background-color: #edf0f7;
             padding: 0.5rem;
             border-radius: 0.5rem;
             display: flex;
         }

         &_button {
             padding: 1rem 2rem;
             background-color: transparent;
             color: rgb(17, 24, 39);

             &.isSelected {
                 background-color: #234ba2;
                 color: #fff;
                 border-radius: 0.75rem;
                 color: #fff;
             }

             @include respond(phone) {
                 padding: 0.75rem;
             }
         }

     }

     &_mainInfo {
         display: flex;
         flex-direction: column;
         width: 100%;
         background-color: #fff;
         border-bottom: 1px solid var(--color-mainBorder);
         padding: 2rem 0;

     }

     &_searchType {
         display: flex;
         width: 100%;
         justify-content: flex-end;

         &_txt {
             display: flex;
             cursor: pointer;
             font-size: 14px;
             gap: 1rem;
             align-items: center;
             transition: all 0.3s ease-in-out;
             border-bottom: 1px solid transparent;

             &:hover {
                 border-bottom: 1px solid;

             }
         }
     }

     &_searchInfo {
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: space-between;
         gap: 2rem;
         background-color: #fff;
         padding: 2rem 0;

         &_sortContainer {
             display: flex;
             align-items: center;
         }

         &_sort {
             background-color: #fff;
             font-size: 1.4rem;
             width: 100px;
             height: 100%;
             position: relative;
             display: flex;
             justify-content: space-between;

             &_txt {
                 font-size: 1.4rem;
                 font-weight: 600;
             }

             &_iconContainer {
                 display: flex;
                 height: 100%;
                 align-items: center;
                 cursor: pointer;
             }

             &_title {
                 display: flex;
                 cursor: pointer;
                 font-size: 14px;
                 margin-right: 1rem;

             }

             &_options {
                 position: absolute;
                 top: 100%;
                 z-index: 100;
                 background-color: #fff;
                 width: 130px;
                 border-radius: 8px;
                 box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
                 display: flex;
                 flex-direction: column;
                 border: 1px solid #e0e0e0;

                 &_item {
                     padding: 10px;

                     &:hover {
                         cursor: pointer;
                         background-color: #ebebeb;
                         border-radius: 8px;
                     }
                 }
             }
         }

         &_txtContainer {
             display: flex;
             flex-direction: column;

             &_clearFilter {
                 display: flex;
                 width: 100%;
                 justify-content: space-between;
                 align-items: center;
                 margin-bottom: 2rem;

                 &_txt {
                     cursor: pointer;
                     color: #234ba2;
                     font-size: 1.4rem;
                     line-height: 1.25rem;
                 }

                 &_iconContainer {
                     display: flex;
                     align-items: center;
                     gap: 1rem;
                     font-size: 1.6rem;
                     font-weight: 600;
                 }
             }
         }

         &_txt {
             font-size: 1.4rem;
         }
     }

     &_sortInfo {
         display: flex;
         width: 100%;
         background-color: #fff;
         border-radius: 5px;

         &_list {
             display: grid;
             list-style: none;
             width: 100%;
             grid-template-columns: repeat(9, 1fr);
             text-align: center;

             &_item {
                 font-size: 14px;
                 color: black;
                 font-weight: 600;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 padding: 10px;

                 svg path {
                     fill: black;
                 }

             }
         }
     }
 }


 .mainFilter {
     display: flex;
     justify-content: space-around;
     width: 100%;
     margin-bottom: 1rem;
     background-color: #fff;
     gap: 1rem;

     @include respond(phone) {
         flex-direction: column;
         gap: 1rem;
     }

     &_projectType {
         display: flex;
         flex-direction: column;
         width: 100%;
         align-items: center;
         padding-bottom: 1rem;

         &_itemContent {
             display: flex;
             justify-content: space-between;
             width: 100%;
             position: relative;
             border: 1px solid var(--color-buttonBorder);
             transition: all 0.3s;
             border-radius: 0.75rem;
             padding: 0.4rem 0.8rem;
             cursor: pointer;

             &:hover {
                 border: 1px solid #45a7ff;
                 
             }
         }

         &_infoIcon {
             display: flex;
             padding-top: 5px;
             padding-right: 5px;

             svg path {
                 fill: #ebebeb;
             }
         }

         &_infoContainer {
             display: flex;
             flex-direction: column;
             width: 100%;
             justify-content: center;
             border: 1px solid transparent;
             transition: all 0.3s;
         }

         &_info {
             display: flex;
             font-size: 1.4rem;
         }

         &_options {
             position: absolute;
             top: 100%;
             z-index: 100;
             background-color: #fff;
             width: 100%;
             overflow: auto;
             border-radius: 12px;
             box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
             display: flex;
             flex-direction: column;
             border: 1px solid #e0e0e0;
             max-height: 30rem;

             &_item {
                 padding: 10px;
                 font-size: 14px;

                 &:hover {
                     cursor: pointer;
                     background-color: #ebebeb;
                     border-radius: 8px;
                 }
             }
         }

         &_title {
             display: flex;
             width: 100%;
             align-items: center;
             font-size: 1.5rem;
             font-weight: 600;
             padding-bottom: 1rem;

         }
     }

     &_button {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         width: 300px;
         background-color: #234ba2;
         border-top-right-radius: 12px;
         border-bottom-right-radius: 12px;
         cursor: pointer;

         &_title {
             display: flex;
             padding-right: 20px;
             color: #fff;
             font-weight: 600;
         }
     }
 }

 .selectedFilter {
     border-radius: 8px;
     border: solid 2px #234ba2;
     padding-left: 8px;
 }

 .advancedFilter {
     margin-top: 1rem;
     display: flex;
     width: 100%;
     margin-bottom: 10px;
     flex-wrap: wrap;
     gap: 2rem;
     background: #edf0f7;
     padding: 1rem;
     border-radius: 0.75rem;

     &_filterDate {
         position: relative;
         min-width: 15rem;

         &_header {
             display: flex;
             font-size: 1.4rem;
             font-weight: 600;
             margin-bottom: 5px;
         }

         &_options {
             background-color: #fff;
             border: 1px solid;
             border-radius: 18px;
             position: relative;
             font-size: 14px;

             &_iconContainer {
                 display: flex;
                 border-left: 1px solid;
                 width: 4rem;
                 height: 100%;
                 align-items: center;
                 justify-content: center;

                 svg path {
                     fill: #000;
                 }
             }

             &_placeHolder {
                 padding-left: 10px;
                 display: flex;

                 &_txt {
                     width: 100%;
                     display: flex;
                     align-items: center;
                 }
             }

             &_menu {
                 position: absolute;
                 top: 100%;
                 z-index: 100;
                 background-color: #fff;
                 width: 100%;
                 border-radius: 8px;
                 box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
                 display: flex;
                 flex-direction: column;
                 border: 1px solid #e0e0e0;
             }

             &_item {
                 padding: 10px;

                 &:hover {
                     cursor: pointer;
                     background-color: #ebebeb;
                     border-radius: 8px;
                 }
             }
         }
     }

     &_filterWorkType {
         display: flex;
         flex-direction: column;
         width: 30rem;

         &_header {
             display: flex;
             font-size: 1.4rem;
             font-weight: 600;
             margin-bottom: 5px;
         }

         &_options {
             background-color: #fff;
             border: 1px solid;
             border-radius: 18px;
             position: relative;
             font-size: 14px;

             &_iconContainer {
                 display: flex;
                 border-left: 1px solid;
                 width: 40px;
                 height: 100%;
                 align-items: center;
                 justify-content: center;

                 svg path {
                     fill: #000;
                 }
             }

             &_placeHolder {
                 padding-left: 10px;
                 display: flex;

                 &_txt {
                     width: 100%;
                     display: flex;
                     align-items: center;
                 }
             }

             &_menu {
                 position: absolute;
                 top: 100%;
                 z-index: 100;
                 background-color: #fff;
                 width: 100%;
                 border-radius: 8px;
                 box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
                 display: flex;
                 flex-direction: column;
                 border: 1px solid #e0e0e0;
                 max-height: 300px;
                 overflow-y: auto;
             }

             &_item {
                 padding: 10px;

                 &:hover {
                     cursor: pointer;
                     background-color: #ebebeb;
                     border-radius: 8px;
                 }
             }
         }

     }

     &_filterAmount {
         display: flex;
         flex-direction: column;
         width: 36rem;

         &_header {
             display: flex;
             font-size: 1.4rem;
             font-weight: 600;
             margin-bottom: 5px;
         }

         &_options {
             background-color: #fff;
             border: 1px solid;
             border-radius: 18px;
             position: relative;
             font-size: 1.4rem;

             &_iconContainer {
                 display: flex;
                 border-left: 1px solid;
                 width: 40px;
                 height: 100%;
                 align-items: center;
                 justify-content: center;

                 svg path {
                     fill: #000;
                 }
             }

             &_placeHolder {
                 padding-left: 10px;
                 display: flex;

                 &_txt {
                     width: 100%;
                     display: flex;
                     align-items: center;
                 }
             }

             &_container {
                 display: flex;
                 flex-direction: column;
                 padding: 10px;
             }

             &_header {
                 font-size: 16px;
                 font-weight: 600;
                 margin-bottom: 10px;
             }

             &_headerSub {
                 font-size: 12px;
                 font-weight: 600;
                 margin-bottom: 5px;
             }

             &_menu {
                 position: absolute;
                 top: 100%;
                 z-index: 100;
                 background-color: #fff;
                 width: 340px;
                 border-radius: 8px;
                 box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
                 display: flex;
                 flex-direction: column;
                 border: 1px solid #e0e0e0;
             }

             &_item {
                 display: flex;
                 justify-content: center;
                 padding: 10px;

                 &:hover {
                     cursor: pointer;
                     background-color: #ebebeb;
                     border-radius: 8px;
                 }

                 &_number {
                     font-size: 12px;
                     font-weight: 600;
                 }
             }
         }
     }

     &_filterCurrency {
         display: flex;
         flex-direction: column;
         width: 11rem;

         &_header {
             display: flex;
             font-weight: 600;
             margin-bottom: 5px;
         }

         &_options {
             background-color: #fff;
             border: 1px solid;
             border-radius: 18px;
             position: relative;
             font-size: 14px;

             &_iconContainer {
                 display: flex;
                 border-left: 1px solid;
                 width: 40px;
                 height: 100%;
                 align-items: center;
                 justify-content: center;

                 svg path {
                     fill: #000;
                 }
             }

             &_placeHolder {
                 padding-left: 1rem;
                 display: flex;

                 &_txt {
                     width: 100%;
                     display: flex;
                     align-items: center;
                 }
             }

             &_menu {
                 position: absolute;
                 top: 100%;
                 z-index: 100;
                 background-color: #fff;
                 width: 100%;
                 border-radius: 8px;
                 box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
                 display: flex;
                 flex-direction: column;
                 border: 1px solid #e0e0e0;
             }

             &_item {
                 padding: 10px;

                 &:hover {
                     cursor: pointer;
                     background-color: #ebebeb;
                     border-radius: 8px;
                 }
             }
         }
     }

     &_filterAsociatedWords {
         display: flex;
         flex-direction: column;
         width: 110px;

         &_header {
             display: flex;
             font-size: 1.4rem;
             font-weight: 600;
             margin-bottom: 5px;
         }

         &_options {
             &_input {
                 width: 100%;
                 height: 100%;
                 border: none;
                 outline: none;
                 padding-left: 15px;
                 border-radius: 18px;
                 padding-bottom: 5px;
                 padding-right: 5px;
                 background-color: #fff;
                 border: 1px solid;
             }
         }
     }
 }