// Variables
$color-primary: #4167B3;
$color-secondary: #f97316;
$color-gray-100: #f3f4f6;
$color-gray-200: #e5e7eb;
$color-gray-300: #d1d5db;
$color-gray-700: #374151;
$color-gray-500: #6b7280;
$color-white: #ffffff;

$font-size-lg: 1.5rem;
$font-size-sm: 1.5rem;
$font-size-xs: 1.2rem;

.cotizador {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 2rem;
  min-height: 40rem;
  min-width: 35rem;
  max-width: 90%;
  max-height: 90%;


  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;


    .title {
      display: flex;
      justify-content: space-between;
      font-size: 1.4rem;
      font-weight: 600;
      padding-bottom: 2rem;
      gap: 4rem;

      &_text {
        display: flex;
        font-size: 1.6rem;
        font-weight: 600;
        color: #333;
      }
    }

    .user {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.5rem;

      .avatar {
        width: 5rem;
        height: 5rem;
        background-color: $color-secondary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        font-size: 3rem;
        margin-bottom: 1rem;
      }

      .name {
        font-size: $font-size-lg;
        font-weight: 600;
      }
    }

    .details {

      .detailRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;

        &_total {
          font-size: $font-size-lg;
          font-weight: bold;
        }
      }

      .checkboxRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;

        input[type="checkbox"] {
          appearance: none; // Oculta el checkbox nativo
          width: 1.5rem;
          height: 1.5rem;
          background-color: $color-gray-200;
          border: 2px solid $color-gray-300;
          border-radius: 0.25rem;
          cursor: pointer;
          position: relative;
        }
        
        input[type="checkbox"]:checked {
          background-color: $color-primary;
          border-color: #333
        }
        
        input[type="checkbox"]:checked::after {
          content: "✔"; 
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $color-white;
          font-size: 1rem;
        }
      }
      }

      .buttons {
        display: flex;
        gap: 0.5rem;
        height: 40px;

        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          font-size: $font-size-sm;
          flex: 1;

          &_send {
            background-color: $color-primary;
            color: $color-white;
            transition: all 0.3s ease;
            font-weight: 500;

            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;

              &:hover {
                background-color: $color-primary;
              }
            }

            &:hover {
              background-color: darken($color-primary, 10%);
            }
          }

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .note {
      padding-bottom: 1rem;
      padding-top: 1rem;
      text-align: right;
      font-size: 1.4rem;
      color: #333;
      opacity: 0.8;
      font-weight: 500;
    }
  }

  .date {
    display: flex;

    &:focus-within {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    }
  }

  .dateInput {
    background-color: #f9fafb;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 1.4rem;
    color: #333;
    font-weight: 500;
    width: 100%;
    text-align: center;
    position: relative;

  }

  .bodyContainer {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .detailNote {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;

    &_title {
      display: flex;
      font-size: 1.4rem;
      font-weight: 500;
      padding-bottom: 1rem;
      color: #333;
    }

    &_noteInput {
      background-color: #f9fafb;
      border: 1px solid #d1d5db;
      border-radius: 0.5rem;
      padding: 1rem;
      font-size: 1.4rem;
      color: #333;
      font-weight: 500;
      resize: none;
      width: 100%;
      height: 100px;
    }
  }

  .closeButton {
    background-color: transparent;
    border: none;
    color: #234ba2;
    font-size: 1.5rem;
  }