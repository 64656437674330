@use "../../../utils/mediaQuerys" as *;

.MainModal {
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50rem;
  height: 50rem;
  border-radius: 0.75rem;
  max-width: 90%;
  max-height: 90%;

  &_overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    align-items: center;
    left: 0;
    z-index: 2;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.3);
  }

  &_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_options {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  &_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #234ba2;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;

    &_background {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

    }

  }

  &_userInfo {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    z-index: 1;
    font-size: 20px;
    font-weight: 400;
    color: #fff;

    &_img {
      min-width: 60px;
      max-width: 60px;
      min-height: 60px;
      max-height: 60px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
      border: 4px solid #fff;

    }

    &_data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      width: 100%;

      &_name {
        font-weight: 600;
      }
    }
  }

  &_closeHeader {
    z-index: 1;
    padding: 20px 20px 0px 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
  }

  &_close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0.5rem;

    svg g {
      stroke: #fff;
    }
  }
}

.initialsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  margin-right: 2rem;
  color: #fff;
  font-size: 1.5rem;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  text-transform: uppercase;
  border: 4px solid #ebebeb;
}