.SuscriptionModal {
  border-radius: 5px;
  border: solid 2px #ebebeb;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 100;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 80vh;
  overflow: hidden;

  &_transferModal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: overlay;
    justify-content: center;

    &_dataContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &_mp {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      gap: 20px;

      &_data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        gap: 5px;
        font-weight: 500;
        color: #2b3b5b;
      }
    }

    &_bank {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &_footer {
      font-weight: 500;
      font-size: 14px;
      margin-top: 20px;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      color: #2b3b5b;
    }
  }

  &_cardForm {
    width: 100%;
    height: 100%;
    overflow: overlay;

    &Hint {
      font-weight: bolder;
      font-size: 12px;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  &_PlanContainerGrid {
    margin-top: 25px;
    overflow: overlay;
    width: 100%;
  }

  &_planTableHeader {
    cursor: pointer;
    min-width: 150px;
    position: relative;

    &_price {
      font-size: 20px;
      font-weight: 800;
      text-align: center;
      color: #000000;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &_recommendPlan {
      height: 25px;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      bottom: 75px;
      background: #3f66b3;
      width: 100%;
      color: #fff;
      border-top-left-radius: 11px;
      border-top-right-radius: 11px;
    }

    &_basicPlan {
      position: absolute;
      bottom: 75px;
      width: 100%;
      height: 25px;
      justify-content: center;
      align-items: center;
      display: flex;
      background: #d2d2d2;
      color: #fff;
      border-top-left-radius: 11px;
      border-top-right-radius: 11px;

    }
  }



  &_planTableItems {
    text-align: center;
    font-size: 12px;
    padding: 0;
    background: #ebebeb;
    height: 60px;

    &_buttonsContainer {
      position: relative;
      display: flex;
      justify-content: center;
    }

    &_buttons {
      position: relative;
      width: 144px;
      font-size: 12px;
      border: 1px solid #959ba3;
      border-radius: 8px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;


      svg path {
        fill: #000000;
      }

      &_menu {
        position: absolute;
        bottom: 100%;
        width: 160px;
        background: #fff;
        border: 1px solid #959ba3;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 5px;
        z-index: 100;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
        font-size: 12px;

        &_item {
          display: flex;
          align-items: center;
          padding: 5px;
          background-color: #fff;
          cursor: pointer;
          transition: all 100ms ease-in-out;

          &:hover {
            background: #ebebeb;
          }
        }
      }
    }


    &_row {
      font-size: 14px;
      font-weight: 600;
    }
  }


  h3 {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    color: #000000;
  }

  &_title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    grid-gap: 10px;

    &alertWarning {
      width: 40px;
      height: 40px;
    }

    &_error {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      grid-gap: 10px;
    }

    &_accepted {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      grid-gap: 10px;
    }
  }



  &_subTitle {
    display: flex;
    width: 100%;
    margin-left: 15px;
    font-size: 14px;
    color: #959ba3;
    font-weight: 500;
  }

  &_ResultDescription {
    text-align: center;
    width: 70%;
    line-height: 30px;
    font-size: 16px;
    font-weight: 500;
  }

  &_PlanContainer {

    display: flex;
    padding: 0px;
    gap: 30px;

    &Plan {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      gap: 15px;
      width: 270px;

      background: #FFFFFF;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
      border-radius: 30px;

      &title {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &PriceContainer {
        display: flex;
        align-items: center;
      }

      &Price {
        font-size: 26px;
        font-weight: 800;
        text-align: center;
        color: #000000;
      }

      &Price_aditional {
        font-size: 10px;
        text-align: center;
      }

      &ItemList {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 12px;
      }

      &Item {
        display: flex;
        padding: 0px;
        gap: 5px;
        align-items: flex-start;

        &FreeTrial {
          font-weight: bold;
        }
      }

      &Divider {
        border: 1px solid #000000;
        transform: rotate(180deg);
        align-self: stretch;
      }

      &Checkmark {
        margin: 5px;
        margin-top: 0px;
      }
    }

    &item,
    &item_decline {
      font-weight: 600;
      background-color: var(--color-white);
      color: #B3B5BA;
      position: absolute;
      right: 20px;
      top: 20px;
      margin-top: 0px;
      cursor: pointer;
    }

    &item_success {
      align-self: stretch;
      padding: 10px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      background-color: #234BA2;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      color: var(--color-white);
      text-align: center;
      transition: all 100ms ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      &_success {
        text-transform: uppercase;
      }
    }
  }

  &_overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    // border: solid 1px #707070;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.resultModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.margin {
  margin-right: 5px;
}

.edifyIcon {
  position: absolute;
  left: 20px;
  top: 30px;
}

.subTitle {
  text-align: center;
}

.errorMsg {
  text-align: center;
  width: 70%;
  line-height: 30px;
  font-size: 16px;
  font-weight: 500;
}