.question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 6;
    backdrop-filter: blur(5px);

    &_body {
        background-color: #fff;
        border-radius: 11px;
        width: 500px;
        max-width: 80vw;
        position: relative;
    }

}

.questionTitle {
    font-size: 20px;
    font-weight: 600;
    padding: 10px;

}

.suggestions {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px;
}

.suggestion {
    display: flex;
    background-color: #234ba2;
    padding: 10px 15px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    opacity: 0.8;
    font-weight: 400;
    transition: all .3s;

    &:hover {
        background-color: #1a3a7d;
        opacity: 1;
    }
}

.inputContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 10px;
}

.sendButton {
    width: 100%;
    padding: 10px;
    background-color: #234ba2;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
        background-color: #2b3b5b;
    }
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    background-color: transparent;
    font-weight: 700;

}