@use "../../../utils/mediaQuerys" as *;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.75rem;
  border: 1px solid var(--color-buttonBorder);

  @include respond(phone) {
    overflow-x: auto;
  }

  .title {
    padding-bottom: 2.4rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(phone) {
      flex-direction: column;
      align-items: stretch;
      gap: 1rem;
    }
  }

  .note {
  
    .addNoteButton {
      display: flex;
      align-items: center;

      .noteInput {
        flex: 1;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        font-size: 1rem;

        &:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
        }
      }
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.75rem;
  background-color: #fff;

  &_header {
    th {
      text-align: center;
      padding: 1.2rem 1.6rem;
      color: #333;
      font-weight: 600;
      border-bottom: 2px solid #ccc;

      &:nth-child(1) {
        border-top-left-radius: 0.75rem;
        text-align: left;
      }

      &:last-child {
        border-top-right-radius: 0.75rem;
      }
      
    }
  }

  .categoryRow {
    td {
      font-weight: 500;
      color: #555;
      padding: 1rem;
      border-bottom: 1px solid #ddd;
    }
  }

  .itemRow {
    td {
      padding: 1.6rem;
      border-bottom: 1px solid #ddd;
      text-align: center;
      line-height: 1.5;

      &:nth-child(1) {
        font-weight: 600;
        text-align: left;
      
      }

    }

    .input_table {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      text-align: center;

      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
      }
    }
  }
}

.categoryName {
  text-align: left;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #333;
  background-color: #f7f9fc;
  border-bottom: 1px solid #ddd;
}

.itemName {
  font-weight: bold;
  color: #444;
}

tfoot {

  tr.totalRow {
    font-weight: bold;
    color: #333;
  }

  td.totalText {
    text-align: right; 
    padding-right: 4rem;
    text-transform: uppercase;
  }

  td.totalValue {
    color: #2a9d8f; 
    text-align: center; 
    padding: 1.2rem 1.6rem;
  }
}

.button-wrapper {
  position: relative;
}

.send-button {
  padding: 0.8rem 1.2rem;
  border-radius: 0.5rem;
  border: 1px solid #234ba2;
  color: #234ba2;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 1.4rem;
  transition: all 0.3s ease-in-out;
  gap: 1rem;
  height: 100%;
  width: 100%;

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
      color: #234ba2;
      border: 1px solid #234ba2;
    }
  }

  &:hover {
    background-color: #234ba2;
    color: white;
    border: 1px solid #234ba2;
  }
}

.iva {
  color: rgb(107, 114, 128);
  font-weight: 500;
  padding: 1rem;
}





