.hover {
    transition: all 0.2s;
    padding: 20px 0 0 0;
    margin: 0 auto;
    transition: all 0.2s ease-in-out;
    position: relative;
    font-size: 17.5px;
    font-weight: 600;
    line-height: 21px;
    color: var(--color-mainBlue);
}

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-size: 34px;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
    padding-bottom: 20px;
}

.subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 21px;
    color: #333;
    text-align: center;
    padding: 20px;
}

.NotFound {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    
  &_h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
    padding-bottom: 20px;
  }
}