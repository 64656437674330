@use "../../utils/mediaQuerys" as *;;

.container {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url(../../assets/images/underConstruction.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        filter: blur(5px);
        z-index: -1;
    }

    &_header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 20px;

        &_user {
            display: flex;
            align-items: center;
            justify-content: center;

            &_img {
                min-width: 50px;
                max-width: 50px;
                min-height: 50px;
                border-radius: 50%;
                max-height: 50px;
                border: 2px solid #234ba2;
            }
        }
    }

    &_body {
        position: relative;
        background-color: #fff;
        border-radius: 12px;
        padding: 2rem;
        width: 50%;
        height: auto;
        z-index: 1;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        margin-top: 2rem;
        margin-bottom: 2rem;
        overflow: overlay;
        max-height: 90%;

        scrollbar-width: thin;
        scrollbar-color: #2b3b5b #f1f1f1;

        @include respond(phone) {
            width: 90%;
        }

        &_skills,
        &_description {
            display: flex;
            padding-top: 30px;
            width: 100%;
            flex-direction: column;

            @include respond(phone) {
                padding-top: 20px;
            }

            &_title {
                font-size: 16px;
            }

            &_text {
                opacity: 0.8;
                font-weight: 500;
                padding-top: 10px;
            }

        }

        &_postulation {
            padding-top: 30px;

            &_title {
                padding-bottom: 10px;
                font-size: 16px;
            }

            &_form {
                display: flex;
                flex-direction: column;
                gap: 20px;

                &_group {
                    position: relative;
                }

                &_input {
                    width: 100%;
                    background: none;
                    border: none;
                    outline: none;
                    border-bottom: 1px solid #000;
                    padding: .6em .3em;
                    cursor: pointer;
                    resize: none;
                    height: 40px;

                    &::-webkit-file-upload-button {
                        display: none;
                    }

                }

                &_input[type="file"] {
                    font-size: 14px;
                    font-weight: 500;
                    color: #2b3b5b;
                    cursor: pointer;
                    padding: .6em .3em;
                }


                :focus+&_label {
                    transform: translateY(-12px) scale(.7);
                    transform-origin: left top;
                    color: #000;
                }

                :not(:placeholder-shown)+&_label {
                    transform: translateY(-12px) scale(.7);
                    transform-origin: left top;
                    color: #000;
                }

                &_label {
                    font-weight: 400;
                    color: #959ba2;
                    cursor: pointer;
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 5px;
                    transform: translateY(10px);
                    transition: transform .5s, opacity .3s;
                }

                &_button {
                    width: 100%;
                    height: 40px;
                    background-color: #234ba2;
                    color: #fff;
                    font-weight: 600;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color .3s;

                    &:hover {
                        background-color: #2b3b5b;
                    }

                    &:disabled {
                        background-color: #f1f1f1;
                        color: #959ba2;
                        cursor: not-allowed;
                    }
                }
            }

            &_success {
                &_title {
                    font-size: 24px;
                    font-weight: 600;
                    text-align: center;
                    padding-bottom: 20px;
                }

                &_subTitle {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    padding-top: 10px;
                }

                &_joinUs {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    padding-bottom: 10px;
                    opacity: 0.8;

                    &_btnContainer {
                        display: flex;
                        justify-content: center;
                        padding-top: 10px;

                        &_button {
                            padding: 10px 40px;
                            background-color: #178feb;
                            color: #fff;
                            font-weight: 600;
                            border: none;
                            border-radius: 5px;
                            cursor: pointer;
                            transition: background-color .3s;

                            &:hover {
                                background-color: #2b3b5b;
                            }
                        }
                    }
                }
            }
        }
    }
}

.successIconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px
}

.error {
    position: absolute;
    right: 0;
    top: 105%;
    color: var(--color-error);
    font-weight: 500;
    font-size: 13px;
}

.socialsContainer {

    .followUs {
        color: #4a4a4a;
        font-size: 13px;
        font-weight: 400;
        line-height: 37px;
        margin-top: 30px;
        border-top: 1px solid #ebebeb;
    }

    .icons {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-around;

        img {
            height: 100%;
        }
    }
}