@use "../../utils/mediaQuerys" as *;;

.workBagSuccess {
    background-color: rgb(249 250 251);
    min-height: 100vh;

    &_header {
        background-color: rgb(37 99 235);



        &_container {
            max-width: 110rem;
            margin-left: auto;
            margin-right: auto;
            padding: 3.2rem
        }

        &_text {
            margin-top: 4rem;
            line-height: 1.7;
            font-size: 1.8rem;
            color: #fff;
        }
    }

    &_title {
        color: #fff;
        display: flex;
        gap: 2rem;
        align-items: center;

        &_icon {
            background-color: rgb(59 130 246);
            border-radius: 9999px;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &_text {
            display: flex;
            flex-direction: column;

            &_h1 {
                font-size: 3rem;
                font-weight: 700;
                text-align: left;
            }

            &_p {
                color: rgb(219 234 254);
                font-size: 1.6rem;
                line-height: 1.5;
                margin-top: 0.5rem;
            }
        }
    }

    &_body {
        max-width: 110rem;
        margin-left: auto;
        margin-right: auto;
        padding: 3.2rem;

        &_infoContainer {
            background-color: #fff;
            border: 1px solid rgb(229 231 235);
            border-radius: 0.75rem;
            margin-bottom: 2rem;

            &_container {
                padding: 2.4rem;
            }

            &_title {
                font-size: 2rem;
                color: rgb(17 24 39);
                font-weight: 600;
                margin-bottom: 2.4rem;
            }

            &_stepContainer {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                gap: 1.6rem;

                @include respond(phone) {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }
            }

            &_step {
                padding: 1.6rem;
                display: flex;
                flex-direction: column;
                align-items: center;


                &_icon {
                    background-color: rgb(219 234 254);
                    border-radius: 9999px;
                    padding: 1rem;
                    margin-bottom: 1.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgb(37 99 235);
                }

                &_title {
                    color: rgb(17 24 39);
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin-bottom: 0.8rem;
                    text-align: center;
                }

                &_text {
                    color: rgb(75 85 99);
                    line-height: 1.5;
                    text-align: center;
                }

            }


        }

        &_workOfferContainer {
            background-color: #fff;
            border: 1px solid rgb(229 231 235);
            border-radius: 0.75rem;

            &_container {
                padding: 2.4rem;
            }

            &_title {
                font-size: 2rem;
                color: rgb(17 24 39);
                font-weight: 600;
                margin-bottom: 2.4rem;
            }

            &_disclaimer {
                background-color: rgb(254 252 232);
                border: 1px solid rgb(254 240 138);
                border-radius: 0.75rem;
                padding: 1.6rem;
                display: flex;
                gap: 1.6rem;
                margin-bottom: 2.4rem;

                &_icon {
                    margin-top: 0.4rem;
                    color: rgb(202 138 4);
                }

                &_text {
                    display: flex;
                    flex-direction: column;
                    gap: 0.7rem;
                    color: rgb(161 98 7);

                    &_title {
                        color: rgb(133 77 14);
                        font-weight: 500;
                    }
                }
            }

            &_workOffer {
                display: flex;
                flex-direction: column;
                gap: 1.6rem;

                &_card {
                    border: 1px solid rgb(229 231 235);
                    border-radius: 0.75rem;
                    padding: 1.6rem;

                    &_container {
                        display: flex;
                        gap: 1.6rem;

                        @include respond(phone) {
                            flex-direction: column;
                            align-items: center;
                        }

                        &_icon {
                            background-color: rgb(219 234 254);
                            border-radius: 9999px;
                            padding: 1rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: rgb(37 99 235);
                            width: 5rem;
                            height: 5rem;
                        }
                    }

                    &_header {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;

                        @include respond(phone) {
                            flex-direction: column;
                            align-items: center;
                        }

                        &_info {
                            display: flex;
                            flex-direction: column;
                            gap: 0.4rem;
                        }

                        &_timeLeft {
                            background-color: rgb(239 246 255 );
                            border-radius: 9999px;
                            display: flex;
                            align-items: center;
                            padding: 0.4rem 1.2rem;
                            justify-content: center;

                            @include respond(phone) {
                                margin-top: 1.6rem;
                            }

                            &_text {
                                color: rgb(29 78 216);
                                font-weight: 500;
                            }
                        }

                        &_title {
                            font-size: 1.8rem;
                            color: rgb(17 24 39);
                            font-weight: 500
                        }

                        &_text {
                            color: rgb(75 85 99);
                            font-size: 1.6rem;

                            @include respond(phone) {
                                text-align: center;
                            }
                        }
                    }

                    &_body {
                        display: flex;
                        flex-direction: column;
                        flex: 1;

                        &_info {
                            display: flex;
                            gap: 0.25rem;
                            margin-top: 1.6rem;
                            align-items: center;
                            color: rgb(75 85 99);

                            @include respond(phone) {
                                flex-direction: column;
                                align-items: center;
                            }

                            &_icon {
                                display: flex;
                            }

                            &_text {
                                margin-right: 1.5rem;

                                @include respond(phone) {
                                    margin-right: 0;
                                    margin-top: 0.5rem;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    &_btn {
                        background-color: rgb(37 99 235 );
                        color: #fff;
                        border-radius: 0.75rem;
                        padding: 0.8rem 1.2rem;
                        margin-top: 1.6rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.6rem;
                        font-weight: 500;
                        gap: 1rem;
                    }
                }
            }
            &_noWorkOffer {
                display: flex;
                flex-direction: column;
                gap: 1.6rem;
                align-items: center;
                padding: 2.4rem;

                &_icon {
                    background-color: rgb(219 234 254);
                    border-radius: 9999px;
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgb(37 99 235);
                    width: 5rem;
                    height: 5rem;
                }

                &_title {
                    font-size: 1.8rem;
                    color: rgb(17 24 39);
                    font-weight: 500;
                }

                &_text {
                    color: rgb(75 85 99);
                    font-size: 1.6rem;
                    text-align: center;
                    line-height: 1.5;
                    max-width: 40rem;
                    
                }

            }
        }
        
    }


}

.confettiContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
}