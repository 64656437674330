@use "../../../../../utils/mediaQuerys";

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nameContainer {
      display: flex;
      align-items: center;

      h4 {
        color: #2b3b5b;
        font-size: 14px;
        display: flex;

        .separate {
          display: block;
          width: 2px;
          height: 25px;
          background-color: #ebebeb;
          margin: 0 1rem;
        }
      }

      svg circle {
        fill: #fff;
      }

      svg path {
        fill: #3f66b3;
      }
    }
  }

  .itemContent {
    margin-bottom: 4rem;

    p {
      color: #707070;
      font-size: 13px;

      span {
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .buttonAllowed {
    -webkit-appearance: none;
    color: #46c39e;
    display: flex;
    align-items: center;
    background-color: unset;
    font-weight: 600;
    margin-left: auto;
    margin-top: -29px;
  }

  .buttonNegated {
    -webkit-appearance: none;
    color: #db615c;
    display: flex;
    align-items: center;
    background-color: unset;
    font-weight: 600;
    margin-left: auto;
    margin-top: -29px;
  }

  .button {
    -webkit-appearance: none;
    color: #3f66b3;
    display: flex;
    align-items: center;
    background-color: unset;
    font-weight: 600;
    margin-left: auto;
    margin-top: -29px;

    &::after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: url("../../../../../assets/svg/arrowBlue.svg") no-repeat;
      background-position: center;
      background-size: 75%;
      margin-left: 1rem;
    }
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  margin-top: -29px;
  margin-left: auto;

  .accept {
    color: white;
    font-size: 13px;
    text-align: center;
    border-radius: 7px;
    padding: 4px 8px 4px 8px;
    border: solid 2px #3259a7;
    background-color: #3f66b3;
    font-weight: 600;

    cursor: pointer;
  }

  .reject {
    color: #858585;
    font-size: 13px;
    text-align: center;
    margin-right: 10px;
    font-weight: 600;
    cursor: pointer;
  }
}

.alert {
  display: flex;
  color: #707070;
  opacity: 0.47;
  box-sizing: border-box;
  padding: 0rem 4rem 1rem;

  svg {
    width: 30px;
    margin-right: 1rem;
  }
}

.download {
  cursor: pointer;
    display: flex;

    svg path {
      stroke-width: 2px;
    }
}

.newPresupuesto {
  display: flex;
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

.projectName1 {
  font-size: 14px;
  font-weight: 600;
  margin-left: 4px;
}

.userName {
  cursor: pointer;
}

.projectNameStyle {
  font-weight: 600;
  font-size: 12px;
  text-decoration-line: underline;
  cursor: pointer;
  padding-left: 5px;
  color: #4167b3;

}

.downloadContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.container {

  &_isViewed {
    cursor: pointer;

    &_checked {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #3f66b3;
    }
  }

  &_buttons {
    display: flex;
    align-items: center;
    gap: 10px;

    &_trash {
      display: flex;
      cursor: pointer;

      svg path {
        stroke: #000;
      }
    }
  }
}

.notificationContainer {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  padding: 10px;

  &_checked {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: -5px;
    right: -5px;
  }

  &_subProjectName {
    margin-left: 5px;
    cursor: pointer;
    color: #4167b3;
  }

  &_time {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    justify-content: flex-end;
    font-weight: 500;
  }

  &_body {
    display: inline;
    align-self: center;
    font-size: 12px;
    font-weight: 500;
    color: #2b3b5b;
  }

  &_image {
    width: 100%;
    min-height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  &_profile {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    margin-right: 20px;
    border: 4px solid #ebebeb;
    position: relative;
  }

  &_info {
    display: flex;
    align-items: center;

  }

  &_txtContainer {
    display: flex;
    margin-left: 5px;
  }

  &_txt {
    font-size: 14px;
    font-weight: 500;
  }
}
