@use "../../../utils/mediaQuerys" as *;

.EmptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  flex-direction: column;
  padding: 4rem;

  svg path {
    fill: #000;
  }

  &.emptyStatePortfolio {
    padding-top: 20px;
    padding-bottom: 10px;

  }

}

.title {
  font-size: 20px;
  font-weight: 600;
  margin: 1rem 0;
  text-align: center;

  @include respond(phone) {
    font-size: 16px;
    text-align: center;
  }
}

.isPortfolio {
  font-size: 14px;
  padding: 10px;
  font-weight: 600;
  color: #333333;

}

.icon {
  width: 30px;
  height: 30px;
  path {
    fill: #000;
  }
}