@use "../../../../utils/mediaQuerys" as *;

.mainHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #fff;
    padding: 2rem 0;
    border-bottom: 1px solid var(--color-mainBorder);
    margin-bottom: 20px;

    @include respond(phone) {
        margin-top: 2rem;
        flex-direction: column;
        padding: 0;
    }

    &_titleContainer {
        padding: 20px;
        display: flex;
        align-items: center;

        &_title {
            font-size: 26px;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;

            @include respond(phone) {
                font-size: 20px;
            }
        }
    }

    &_buttons {
        display: flex;
        align-items: center;
        gap: 2rem;

        @include respond(phone) {
            flex-direction: column-reverse;
            padding-left: 0;
        }
    }

    &_status {


        @include respond(phone) {
            width: 100%;
            border-bottom: 1px solid #ebebeb;
            padding: 10px;

        }

        &_title {
            padding: 10px 20px;
            background: transparent;
            border-radius: 5px;
            color: #234ba2;
            border: 1px solid #234ba2;
            cursor: pointer;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 500;
            transition: all 0.3s ease;

            svg path {
                stroke: #234ba2;
            }

            &:hover {
                background: #234ba2;
                color: #fff;

                svg path {
                    stroke: #fff;
                }
            }


            @include respond(phone) {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 5px 10px;
                font-size: 14px;
            }

            &.sent {
                background: rgb(255 251 235);
                border: 1px solid rgb(253 230 138);
                color: rgb(180 83 9);
                pointer-events: none;

                svg path {
                    stroke: rgb(180 83 9);
                }
            }

            &.allowed {
                border: 1px solid #4CE2A7;
                color: #4CE2A7;
                background: #d3ffe4;

                svg path {
                    stroke: #4CE2A7;
                }
            }

            &.denied {
                background: #ffd9e5;
                color: #ff3d71;
                border: 1px solid #ff3d71;

                svg path {
                    stroke: #ff3d71;
                }
            }
        }

        &_buttons {
            display: flex;
            gap: 5px;

            @include respond(phone) {
                width: 100%;
                padding: 2rem 0;
            }

            &_edit {
                background: transparent;
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: center;
                padding: 8px 12px;
                font-size: 16px;
                border-radius: 5px;
                border: 1px solid #234ba2;
                color: #234ba2;
                font-weight: 600;
                transition: all 0.3s ease;

                svg g path {
                    transition: all 0.3s ease;
                }

                @include respond(phone) {
                    width: 100%;
                    padding: 5px 10px;
                }

                &:hover {
                    background: #234ba2;
                    color: #fff;

                    svg g path {
                        fill: #fff;
                    }
                }
            }


            &_delete {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background: transparent;
                padding: 8px 12px;
                font-size: 16px;
                border-radius: 5px;
                border: 1px solid #234ba2;
                color: #234ba2;
                transition: all 0.3s ease;

                &:hover {
                    background: var(--color-error);
                    border: 1px solid var(--color-error);
                    color: #fff;
                }

                @include respond(phone) {
                    width: 100%;
                    padding: 5px 10px;
                }

            }
        }

    }

    &_subProyectos {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        padding: 0 20px;
        transition: box-shadow 0.3s ease;

        @include respond(phone) {
            width: 100%;
            padding: 10px
        }

        &.isActive {
            background-color: #ebecf7;
        }

        &:hover {
            background-color: #ebecf7;
        }

        &_title {
            display: flex;
            height: 100%;
            align-items: center;

            @include respond(phone) {
                width: 100%;
                padding-left: 10px;
            }
        }

        &_container {
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            width: max-content;
            max-width: 80vw;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            max-height: 400px;
            overflow: auto;

            @include respond(phone) {
                min-width: 100%;
            }

            &_noResults {
                padding: 10px;
                font-size: 12px;
            }

            &_subProject {
                display: flex;
                width: 100%;
                flex-direction: column;

                &_title {
                    color: #2b3b5b;
                    font-weight: 600;
                    font-size: 16px;

                    @include respond(phone) {
                        font-size: 14px;
                    }
                }

                &_header {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    gap: 3rem;
                    padding: 15px;
                    transition: all 0.3s ease;
                    position: relative;
                    border-left: 10px solid transparent;

                    &:hover {
                        cursor: pointer;
                        border-left: 10px solid #2b3b5b;

                    }

                    &.isActive {
                        background-color: #ebecf7;
                    }

                    @include respond(phone) {
                        padding: 10px;
                    }
                }

                &_description {
                    color: #2b3b5b;
                    font-weight: 400;
                }

                &_arrow {
                    display: flex;
                    align-items: center;

                    svg g {
                        stroke: #2b3b5b;
                    }
                }

                &_info {
                    padding: 10px;
                    background-color: #fff;
                    border-bottom: 1px solid #ebebeb;
                    border-radius: 5px;
                    width: fit-content;
                }

                &_item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;
                    border-top: 1px solid #ebebeb;

                    &:first-child {
                        border-top: none;
                        padding-top: 0;
                    }
                }

                &_label {
                    color: #2b3b5b;
                    font-weight: 600;
                }


                &_value {
                    color: #2b3b5b;
                    font-weight: 400;
                    align-items: center;
                }
            }
        }

    }

    &_postulaciones {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        height: 100%;
        transition: box-shadow 0.3s ease;

        @include respond(phone) {
            width: 100%;
            padding: 0;
        }

        &.isActive {
            background-color: #ebecf7;
        }

        &:hover {
            background-color: #ebecf7;
        }

        &_title {
            display: flex;
            height: 100%;
            align-items: center;
            gap: 1rem;

            @include respond(phone) {
                width: 100%;
            }
        }

        &_container {
            position: absolute;
            z-index: 1;
            top: 100%;
            min-width: 300px;
            left: 0;
            width: 100%;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;

            &_header {
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding: 20px;
                transition: all 0.3s ease;
                color: #2b3b5b;
                font-weight: 600;
                font-size: 16px;
                border-left: 10px solid transparent;

                svg g {
                    stroke: #2b3b5b;
                }

                &:hover {
                    cursor: pointer;
                    border-left: 10px solid #2b3b5b;

                }

                @include respond(phone) {
                    padding: 10px;
                    font-size: 14px;
                }

                &_countContainer {
                    display: flex;
                    align-items: center;

                    svg g {
                        stroke: #2b3b5b;
                    }
                }

                &_count {
                    background-color: #d6174a;
                    color: #fff;
                    border-radius: 50%;
                    font-size: 14px;
                    font-weight: 800;
                    width: 20px;
                    text-align: center;
                    height: 20px;
                    margin-right: 5px;

                }
            }

            &_userPostulation {
                display: flex;
                justify-content: space-between;
                padding: 1rem;
            }

            &_user {
                display: flex;
                align-items: center;
                font-weight: 600;

                &_image {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    margin: 5px;
                    border-radius: 50%;

                }

                &_name {
                    font-weight: 500;
                }
            }

            &_status {
                display: flex;
                align-items: center;
                padding: 10px;

                &_accept {
                    border-radius: 50%;
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 20px;
                    background-color: #4CE2A7;
                    margin-right: 5px;
                    cursor: pointer;


                    svg path {
                        fill: #fff;
                    }
                }

                &_deny {
                    border-radius: 50%;
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 20px;
                    background: #d6174a;
                    cursor: pointer;

                }

                &_acceptedTxt {
                    color: #4CE2A7;
                    font-size: 12px;
                    font-weight: 600;
                }

                &_deniedTxt {
                    color: #d6174a;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

    }

    &_cotizaciones {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        height: 100%;
        padding: 0 20px;
        transition: box-shadow 0.3s ease;

        @include respond(phone) {
            width: 100%;
            padding: 0;
        }

        &.isActive {
            background-color: #ebecf7;
        }

        &:hover {
            background-color: #ebecf7;
        }

        &_container {
            position: absolute;
            z-index: 1;
            top: 100%;
            min-width: 300px;
            left: 0;
            width: 100%;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;

            &_noResults {
                padding: 10px;
                font-size: 12px;
            }

            &_header {
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding: 10px;
                transition: all 0.3s ease;

                &:hover {
                    cursor: pointer;
                    background-color: #ebecf7;

                }

                &_countContainer {
                    display: flex;
                    align-items: center;
                }

                &_count {
                    background-color: #d6174a;
                    color: #fff;
                    border-radius: 50%;
                    font-size: 14px;
                    font-weight: 800;
                    width: 20px;
                    text-align: center;
                    height: 20px;
                    margin-right: 5px;

                }
            }
        }

        &_title {
            display: flex;
            height: 100%;
            align-items: center;
            gap: 1rem;

            @include respond(phone) {
                width: 100%;
            }
        }
    }
}

.arrowIconUp {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.arrowIconDown {
    transform: rotate(-180deg);
    transition: transform 0.3s ease;
}