@use "../../../utils/mediaQuerys";

.generalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  &_itemContainer {
    display: flex;
    gap: 20px;
  }

  &_item {
    width: 100%;

    &_title {
      font-weight: 600;
      color: rgb(55 65 81);
      padding-bottom: 10px;
      padding-top: 10px;
    }

    &_rubroTitle {
      font-weight: 600;
      color: rgb(55 65 81);
      padding-bottom: 1.6rem;
      font-size: 1.8rem;
      margin-top: 3rem;
      padding-top: 2.4rem;
      border-top: 1px solid #ebebeb;
    }

    &_inputContainer {
      background-color: #fff;
      border-radius: 0.75rem;
      border: 1px solid #d2d2d2;
      height: 40px;
      position: relative;
      display: flex;
      align-items: center;

    }

    &_input {
      width: 100%;
      height: 100%;
      border: none;
      position: absolute;
      background-color: transparent;
      padding-left: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
      border: 2px solid transparent;
      transition: all 0.25s ease-out;

      &:autofill,
      &:autofill:hover,
      &:autofill:focus,
      &:autofill:active {
        box-shadow: 0 0 0 30px #fff inset !important;
        border-radius: 0.75rem;
        border: 0;
      }

      &::placeholder {
        padding-right: 10px;
      }

      &.error {
        border: 2px solid #889abe;
        border-radius: 0.75rem;
      }

      &:focus {
        border: 2px solid #234ba2;
        border-radius: 0.75rem;
      }

    }

    &_inputDate {
      width: 100%;
      height: 100%;
      border: none;
      position: absolute;
      background-color: transparent;
      padding-left: 30px;
      border: 2px solid transparent;
      transition: all 0.25s ease-out;

      &.error {
        border: 2px solid #889abe;
        border-radius: 0.75rem;
      }

      &:focus {
        border: 2px solid #234ba2;
        border-radius: 0.75rem;
      }
    }

    &_icon {
      width: 15px;
      height: 15px;
      margin-left: 10px;
    }

    &_iconPencil {
      width: 15px;
      height: 15px;
      margin-top: 10px;
      margin-left: 10px;

      path {
        fill: #959ca3;
      }
    }

    &_inputAreaContainer {
      background-color: #fff;
      border-radius: 0.75rem;
      border: 1px solid #d2d2d2;
      height: 96px;
      position: relative;
      display: flex;
      transition: all 0.25s ease-out;

      &.error {
        border: 2px solid #889abe;
      }

      &:focus-within {
        border: 2px solid #234ba2;
        border-radius: 11px;
      }

      &_inputArea {
        width: 100%;
        height: 96px;
        padding: 10px;
        border: none;
        position: relative;
        resize: none;
        background-color: transparent;

      }
    }

    &_button {
      width: 100%;
      border-radius: 0.75rem;
      padding: 1.6rem 2.4rem;
      background-color: rgb(37 99 235);
      color: #fff;
      transition: all 0.3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      font-size: 1.6rem;
      font-weight: 500;

      &:disabled {
        color: rgb(156 163 175);
        background-color: rgb(243 244 246);
      }
    }
  }
}

.subProjectEmptyMessage {
  width: 100%;
  border: 1px solid rgb(253 230 138);
  border-radius: 0.75rem;
  background-color: rgb(255 251 235);
  padding: 1rem;
  margin-bottom: 2rem;

  &_body {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
  }

  &_title {
    color: rgb(146 64 14);
    font-weight: 500;

  }

  &_text {
    color: rgb(180 83 9);
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0.75rem;
  }

  &_icon {
    margin-top: 0.3rem;
    min-width: 17px;
    min-height: 17px;
    max-width: 17px;
    max-height: 17px;
    color: rgb(245 158 11);
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.cityOptionList {
  list-style: none;
  position: absolute;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 10px;
  z-index: 11;
  max-height: 200px;
  overflow-y: auto;
  top: 40px;

  &_item {
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5px;
    transition: 0.25s;
    font-size: 12px;
    font-weight: 500;

    &:hover {
      background-color: #f7f8f9;
    }
  }
}

.customImgContainer {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;


  &_img {
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
    height: 100%
  }
}

.carouselContainer {
  display: flex;
  border-radius: 18px;
  height: 250px;
  cursor: pointer;

}

.deleteButton {
  font-size: 11px;
  position: absolute;
  color: #fff;
  background-color: #f41079;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 20px;
  z-index: 10;
  cursor: pointer;
}

.emptyContainer {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border: 2px solid #d2d2d2;
  border-style: dashed;
  border-radius: 5px;
  flex-direction: column;
  background-color: #fff;



  &_imageMask {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid rgb(37 99 235);
    color: rgb(37 99 235);
    border-radius: 11px;
    transition: all 0.25s ease-out;
    font-size: 1.6rem;

    &.withImage {
      border: 1px solid #fff;
      gap: 1rem;

      &:hover {
        border: 1px solid #234ba2;
        background-color: #234ba2;
        color: #fff;
      }

      svg path {
        fill: #fff;
      }
    }

  }

  &_text {
    color: #234BA2;
    text-align: center;

    &.withImage {
      color: #fff;
      font-size: 1.6rem;
    }
  }

  &_subText {
    color: rgb(31 41 55);
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  &_withImage {
    position: absolute;
    z-index: 1;
  }

  &_icon {
    width: 50px;
    height: 50px;
    color: rgb(156 163 175);
    margin-bottom: 1.6rem;
  }

  &_infoText {
    color: rgb(107 114 128);
    line-height: 1.5;
    margin-bottom: 1.6rem;
    text-align: center;
  }
}

.subProjectModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}