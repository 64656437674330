@use "../../../../../utils/mediaQuerys" as *;

.proyectItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  transition: height 300ms ease-in-out;

  .buttonCambios {
    padding: 0.8rem 1.6rem;
    border-radius: 5px;
    background-color: #234ba2;
    font-weight: 600;
    color: #fff;
    width: 100%;

    &_cancelar {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid;
      background-color: #fff;
      font-weight: 700;
      font-size: 14px;
      width: 100%;
      transition: all 300ms ease-in-out;

      svg {
        margin-right: 5px;
      }

      &:hover {
        background-color: #003667;
        border: 1px solid #003667;
        color: white;

        svg path {
          fill: white;
        }
      }
    }

    &:disabled {
      color: rgb(156, 163, 175);
      background-color: rgb(243, 244, 246);
      margin-right: 2px;
      cursor: not-allowed;
    }
  }

  .proyectActions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: solid 1px #ebebeb;
    background-color: rgb(249 250 251);
    padding: 1.6rem;
  }

  .proyectInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .inputFile {
      display: flex;
      border: 2px solid rgba(151, 151, 151, 0.49);
      border-radius: 10px;
      padding: 6.5px 15px;
      margin-top: auto;
    }
  }

  .description {
    font-weight: 500;
    height: 100%;
    display: flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &.isHovered {
      border-left: 10px solid #234BA2;
      transition: all 300ms ease-in-out;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    @include respond(phone) {
      font-size: 10px;
    }
  }

  .borderIconDelete {
    display: flex;

    &:hover {
      color: #d6174a;
      transition: all 200ms ease-in-out;

      svg path {
        fill: #d6174a;
      }

      svg {
        transition: all 200ms ease-in-out;
      }
    }
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &_text {
      color: #2b3b5b;
      font-weight: 400;
      margin-right: 5px;
    }

    &_openEditText {
      p {
        color: #234BA2;
        font-weight: 700;
      }
    }
  }
}

.editContainer {
  display: flex;
}

.iconDelete {
  svg {
    min-width: 2.8rem;
    min-height: 2.8rem;
  }
}

.formContainer {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all 300ms ease-in-out;

  &.open {
    visibility: visible;
    opacity: 1;
    height: 100%;
  }
}

.fieldWrapper {
  width: 70%;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  position: relative;

  &_addLinkInEdit {
    padding: 10px 10px 10px 10px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #b7b7b7;
    text-align: left;
    color: #2b3b5b;
    cursor: text;
  }

  &_textArea {
    resize: none;
    min-height: 80px;
    width: 100%;
    border: 1px solid #d2d2d2;
    padding: 10px;
    border-radius: 5px;
  }

  &_descriptionHeader {
    color: #2b3b5b;
    font-weight: 800;
    font-size: 11px;
    width: 100%;
    padding-top: 10px;

  }

  &_documentacionHeader {
    font-size: 1.6rem;
    font-weight: 600;
    width: 100%;
    padding-top: 10px;
    border-top: 1px solid #ebebeb;
    margin-top: 10px;

    &_body {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      gap: 10px;
    }
  }

  &_documentationFileField {
    width: 70%;
    font-size: 11px;
    display: flex;
    align-items: center;

    &_txt {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }

    &_button {
      background: #d2d2d2;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      font-size: 11px;
    }
  }

  &_documentationField {
    height: 37px;
    padding: 10px 10px 10px 10px;
    width: 70%;
    border: none;
    border-bottom: 1px solid #b7b7b7;
    text-align: left;
    color: #2b3b5b;
    font-weight: 500;
    font-size: 11px;
    cursor: text;
  }

  &_linkHeader {
    display: flex;
    align-items: center;

    &_subTitle {
      padding-bottom: 10px;
      padding-top: 10px;
      width: 30%;
    }

  }

  &_dateHeader {
    font-weight: 800;
    font-size: 11px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-top: 1px solid #ebebeb;

    &_field {
      width: 70%;
      display: flex;
      padding-bottom: 10px;
      flex-direction: column;
    }

    &_value {

      font-weight: 500;
      width: 100%;
    }

    &_valueInput {
      height: 37px;
      padding: 10px 10px 10px 10px;
      border: none;
      border-bottom: 1px solid #b7b7b7;
      text-align: left;
      font-weight: 500;
      resize: none;
      position: relative;
      cursor: pointer;
      width: 100%;
    }

    &_subTitle {
      align-items: center;
      padding-bottom: 10px;
      display: flex;
      padding-top: 10px;
      width: 100%;
    }
  }

  &_dateHeaderText {
    font-size: 14px;
    font-weight: 600;
    color: #234BA2;
    display: flex;
    width: 100%;
    flex-direction: column;

  }

  &_dateHeaderAndFile {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #234BA2;
  }

  &_fileHeader {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #234BA2;

  }

  &_buttonContainer {
    display: flex;
    gap: 30px;
  }

  &_datesContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 10px;

    @include respond(phone) {
      flex-direction: column;
    }

    &.detail {
      border-bottom: none;
    }
  }

  &_imageMask {
    width: 100%;
    top: 50%;
    left: 0;
    height: 37px;
    z-index: 100;
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
  }

  &.detail {
    border-right: 2px solid #ebebeb;
  }

}

.iconEdit {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &_open {
    color: #234BA2;
    font-weight: 700;
  }

  &:hover {
    color: #234BA2;
    transition: all 200ms ease-in-out;
  }

}

.radioGroup {
  display: flex;

  &_label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &_input {
      margin-right: 5px;
      cursor: pointer;
    }
    
  }
}

.fileInput[type="file"]::-webkit-file-upload-button {
  border: 1px solid #234ba2;
  background: #234ba2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}