@use "../../../utils/mediaQuerys" as *;

.filterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &_searchInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        background-color: #fff;
        padding: 2rem 0;

        &_sortContainer {
            display: flex;
            align-items: center;
        }

        &_sort {
            background-color: #fff;
            font-size: 1.4rem;
            width: 100px;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: space-between;

            &_txt {
                font-size: 1.4rem;
                font-weight: 600;
            }

            &_iconContainer {
                display: flex;
                height: 100%;
                align-items: center;
                cursor: pointer;
            }

            &_title {
                display: flex;
                cursor: pointer;
                font-size: 14px;
                margin-right: 1rem;

            }

            &_options {
                position: absolute;
                top: 100%;
                z-index: 100;
                background-color: #fff;
                width: 130px;
                border-radius: 8px;
                box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
                display: flex;
                flex-direction: column;
                border: 1px solid #e0e0e0;

                &_item {
                    padding: 10px;

                    &:hover {
                        cursor: pointer;
                        background-color: #ebebeb;
                        border-radius: 8px;
                    }
                }
            }
        }

        &_txtContainer {
            display: flex;
            flex-direction: column;

            &_clearFilter {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2rem;

                &_txt {
                    cursor: pointer;
                    color: #234ba2;
                    font-size: 1.4rem;
                    line-height: 1.25rem;
                }

                &_iconContainer {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-size: 1.6rem;
                    font-weight: 600;
                }
            }
        }

        &_txt {
            font-size: 1.4rem;
        }
    }

    &_mainInfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid var(--color-mainBorder);
        padding: 2rem 0;

    }
}

.mainFilter {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 1rem;
    background-color: #fff;
    gap: 1rem;

    @include respond(phone) {
        flex-direction: column;
        gap: 1rem;
    }

    &_filterAsociatedWords {
        display: flex;
        flex-direction: column;
        width: 160px;

        &_header {
            display: flex;
            padding-left: 10px;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        &_options {
            position: absolute;
            top: 100%;
            z-index: 100;
            background-color: #fff;
            width: 100%;
            border-radius: 12px;
            box-shadow: 5px 1px 8px 1px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;

            &_input {
                width: 100%;
                height: 100%;
                font-size: 12px;
                border: none;
                outline: none;
                padding-left: 15px;
                border-radius: 18px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 5px;
                background-color: #fff;
                border: 1px solid #ebebeb;
            }
        }
    }

    &_projectType {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        flex-direction: column;
        justify-content: space-between;

        &_itemContent {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &_infoIcon {
            display: flex;
            padding-top: 5px;
            padding-right: 5px;

            svg path {
                fill: #ebebeb;
            }
        }

        &_infoContainer {
            display: flex;
            width: 100%;
            justify-content: center;
            border: 1px solid var(--color-mainBorder);
            transition: all 0.3s;
            border-radius: 0.75rem;
            padding: 0.4rem 0.8rem;
            cursor: pointer;

            &:hover {
                border: 1px solid #234ba2;
            }
        }

        &_info {
            display: flex;
            font-size: 1.4rem;
        }

        &_options {
            position: absolute;
            top: 100%;
            z-index: 100;
            background-color: #fff;
            width: 100%;
            height: 300px;
            border-radius: 12px;
            overflow: overlay;
            box-shadow: 5px 1px 8px 1px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            border: 1px solid #e0e0e0;

            &_item {
                padding: 10px;
                font-size: 14px;

                &:hover {
                    cursor: pointer;
                    background-color: #ebebeb;
                    border-radius: 8px;
                }
            }
        }

        &_title {
            display: flex;
            width: 100%;
            align-items: center;
            font-size: 1.5rem;
            font-weight: 600;
            padding-bottom: 1rem;

        }
    }

    &_organism {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        flex-direction: column;
        justify-content: space-between;

        &_itemContent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }

        &_infoIcon {
            display: flex;
            padding-top: 5px;
            padding-right: 5px;

            svg path {
                fill: #ebebeb;
            }
        }

        &_infoContainer {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            border: 1px solid var(--color-mainBorder);
            transition: all 0.3s;
            cursor: pointer;
            border-radius: 0.75rem;
            padding: 0.4rem 0.8rem;

            &:hover {
                border: 1px solid #234ba2;
            }

        }

        &_info {
            display: flex;
            font-size: 1.4rem;
            justify-content: space-between;
        }

        &_options {
            position: absolute;
            top: 100%;
            z-index: 100;
            background-color: #fff;
            width: 100%;
            border-radius: 12px;
            box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            border: 1px solid #e0e0e0;

            &_item {
                padding: 10px;

                &:hover {
                    cursor: pointer;
                    background-color: #ebebeb;
                    border-radius: 8px;
                }
            }
        }

        &_title {
            display: flex;
            width: 100%;
            align-items: center;
            font-size: 1.5rem;
            font-weight: 600;
            padding-bottom: 1rem;

        }
    }

    &_jurisdiction {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        flex-direction: column;
        justify-content: space-between;

        &_itemContent {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }

        &_infoIcon {
            display: flex;
            padding-top: 5px;
            padding-right: 5px;

            svg path {
                fill: #ebebeb;
            }
        }

        &_infoContainer {
            display: flex;
            width: 100%;
            justify-content: center;
            border: 1px solid var(--color-mainBorder);
            transition: all 0.3s;
            padding: 0.4rem 0.8rem;
            border-radius: 0.75rem;
            cursor: pointer;

            &:hover {
                border: 1px solid #234ba2;
            }
        }

        &_info {
            display: flex;
            font-size: 14px;
        }

        &_options {
            position: absolute;
            top: 100%;
            z-index: 100;
            background-color: #fff;
            width: 100%;
            border-radius: 12px;
            box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            border: 1px solid #e0e0e0;
            max-height: 350px;
            overflow-y: auto;

            &_item {
                padding: 10px;

                &:hover {
                    cursor: pointer;
                    background-color: #ebebeb;
                    border-radius: 8px;
                }
            }
        }

        &_title {
            display: flex;
            width: 100%;
            align-items: center;
            font-size: 1.5rem;
            font-weight: 600;
            padding-bottom: 1rem;

        }
    }
}

.advancedFilter {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 10px;

    &_filterDate {
        position: relative;
        width: 100%;
        margin-right: 10px;

        &_options {
            background-color: #fff;
            border: 1px solid #ebebeb;
            border-radius: 18px;
            position: relative;
            font-size: 14px;

            &_menu {
                position: absolute;
                top: 100%;
                z-index: 100;
                background-color: #fff;
                width: 100%;
                border-radius: 8px;
                box-shadow: 5px 1px 8px 1px rgba(0.2, 0, 0, 0.2);
                display: flex;
                flex-direction: column;
                border: 1px solid #e0e0e0;
            }

            &_item {
                padding: 10px;
                font-size: 14px;

                &:hover {
                    cursor: pointer;
                    background-color: #ebebeb;
                    border-radius: 8px;
                }
            }
        }
    }

    &_filterAsociatedWords {
        display: flex;
        flex-direction: column;
        width: 160px;

        &_header {
            display: flex;
            padding-left: 10px;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        &_options {
            &_input {
                width: 100%;
                height: 100%;
                font-size: 12px;
                border: none;
                outline: none;
                padding-left: 15px;
                border-radius: 18px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 5px;
                background-color: #fff;
                border: 1px solid #ebebeb;
            }
        }
    }
}