.OnboardingContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  min-height: 100vh;
  .content {
    width: 50%;
    min-height: 100vh;
  }
  .onboardingLeft {
    background: #f9f9f9;
  }
  .onboardingRight {
    background: url("../../../assets/images/foto-bienvenida.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.4;
  }
}
