.sectionContainer {
  width: 100%;
}
.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .buttonContainer {
    margin: 50px 0;
    a {
      background-color: #1e4776;
      border-radius: 16.88px;
      width: 351px;
      height: 42px;
      line-height: 42px;
      vertical-align: middle;
      color: #ffffff;
      font-size: 13px;
      font-weight: 600;
      display: block;
      text-align: center;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    }
  }

  .textContainer {
    border: 1px dashed #979797;
    border-radius: 7.5px;
    padding: 15px 30px;
    margin-top: 10px;
  }
}
.content {
  margin-top: 40px;
  width: 100%;
  margin-bottom: 20px;
}
.suscriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .imgContainer {
    max-width: 212px;

    img {
      width: 100%;
      max-width: 212px;
    }
  }
}
.suscriptionContent {
  margin-top: 16px;
  & .subtitle {
    max-width: 450px;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }
  &_header {
  color: #000000;
  font-size: 35px;
  font-weight: 700;
  line-height: 42.7px;
  text-align: center;
  margin-bottom: 6px;
}
}

.promosContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
