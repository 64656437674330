.skillsContainer {
  display: flex;
  flex-direction: column;
}

.skillsInputContainer {
  position: relative;
}

.skillsInput {
  border: 1px solid rgb(209 213 219);
  border-radius: 0.75rem;
  padding: 1rem 1rem 1rem 4.3rem;
  width: 100%;

  &_searchIcon {
    position: absolute;
    top: 1.05rem;
    left: 1.5rem;
    color: rgb(156 163 175);
  }

  &:focus {
    box-shadow: inset 0 0 0 2px rgb(59 130 246)
  }
}

.userSkill {
  margin-top: 2.4rem;

  &_header {
    display: flex;
    gap: 1rem;
    align-items: center;

    &_text {
      color: rgb(17 24 39);
      font-weight: 600;
      font-size: 1.6rem;
    }
  }

  &_selectedSkills {
    margin-top: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &_selectedSkill {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(239 246 255);
    padding: 1.6rem 1.2rem;
    border-radius: 0.75rem;
    color: rgb(29 78 216);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    &:hover {
      cursor: pointer;
      background-color: rgb(219 234 254);
    }

    &_skill {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: 600;

    }
  }
}

.allSkills {
  margin-top: 2.4rem;

  &_header {
    position: relative;
    display: flex;
    justify-content: center;

    &_divider {
      display: flex;
      align-items: center;
      position: absolute;
      inset: 0;

      &_line {
        border: 1px solid rgb(229 231 235);
        width: 100%;
      }
    }

    &_text {
      color: rgb(107 114 128);
      font-weight: 400;
      font-size: 1.4rem;
    }

    &_textContainer {
      position: relative;
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: #fff;
      width: fit-content;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &_skills {
    margin-top: 1.6rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    &_selectedSkill {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgb(249 250 251);
      padding: 1.6rem 1.2rem;
      border-radius: 0.75rem;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;


      &:hover {
        cursor: pointer;
        background-color: rgb(243 244 246);
      }
    }

    &_skill {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: 600;
    }
  }
}
