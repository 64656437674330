@use "../../utils/mediaQuerys" as *;;

.container {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    @include respond(phone) {
        padding-top: 80px;
      }

    @include respond(tab-port, true) {
        padding-top: 80px;
    }
}