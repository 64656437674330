@use "../../../utils/mediaQuerys" as *;

.containerList {
  display: flex;
  width: 100%;

  @include respond(phone) {
    padding-top: 60px;
  }

  @include respond(tab-port, true) {
    padding-top: 60px;
  }

  &_buttonsContainer {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
}

.cotizacionDeMaterialesContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 200px;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  button {
    background-color: #234ba2;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s;

    &:hover {
      background-color: #1e3c7d;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  font-weight: 600;
}

.noResultsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  svg {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    color: #666;
  }
}

.list{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
}

