@use "../../../utils/mediaQuerys" as *;

.backDrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.modalContainer {
    border-radius: 0.75rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 70rem;
    height: 100%;
    max-height: 90%;
}

.modalHeader {
    padding: 1.6rem 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(229 231 235);

    &_mobile {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    &_backButton {
        background-color: transparent;
        display: flex;
    }

    &_title {
        font-size: 2rem;
        font-weight: 600;
        color: rgb(17 24 39);
    }

    &_close {
        color: rgb(107 114 128);
        background: transparent;
        padding: 0.4rem;
        display: flex;
    }
}

.modalContent {
    display: flex;
    overflow: hidden;
    flex: 1;

    @include respond(phone) {
        min-height: auto;
    }

    @include respond(tab-port, true) {
        min-height: auto;
    }

    &_left {
        border-right: 1px solid rgb(229 231 235);
        display: flex;
        flex-direction: column;

        &.phoneWidth {
            width: 100%;
        }
        

        &_data {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2.4rem;

            @include respond(phone) {
                padding: 1.6rem;
            }

            &_image {
                width: 10rem;
                height: 10rem;
                border-radius: 1.5rem;
                margin-bottom: 1.6rem;
                position: relative;

                &_img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 1.5rem;
                }

                &_overlay {
                    position: absolute;
                    bottom: -1rem;
                    right: -1rem;
                    background-color: rgb(37 99 235);
                    color: rgb(255 255 255);
                    padding: 1rem;
                    display: flex;
                    border-radius: 9999px;
                    font-weight: 500;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            &_name {
                font-weight: 600;
                color: rgb(17 24 39);
                line-height: 1.5;
            }

            &_mail {
                font-size: 1.2rem;
                color: rgb(107 114 128);
            }
        }

        &_nav {
            display: flex;
            flex-direction: column;
            padding: 0 1.2rem 1.6rem;

            &_button {
                background-color: transparent;
                padding: 1.2rem 1.6rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                font-weight: 500;
                transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;

                &:hover {
                    background-color: rgb(249 250 251);
                    color: rgb(75 85 99);
                    
                }

                &_active {
                    background-color: rgb(239 246 255);
                    padding: 1.2rem 1.6rem;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    border-radius: 0.75rem;
                    color: rgb(29 78 216);
                    font-weight: 500;

                    @include respond(phone) {
                        background-color: transparent;
                        color: rgb(55 65 81);
                    }
                }
            }
        }
    }

    &_right {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 2.4rem;
        overflow-y: auto;
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2.4rem;

    &:first-child {
        margin-top: 0;
    }

    &_label {
        color: rgb(55 65 81);
        font-weight: 600;
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    &_buttons {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
        margin-top: 1.2rem;

        &_button {
            border: 2px solid rgb(229 231 235);
            border-radius: 0.75rem;
            padding: 1.2rem 1.6rem;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            font-size: 1.6rem;
            font-weight: 500;
            transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;

            &:hover {
                border: 2px solid rgb(209 213 219);
            }

            &_active {
                color: rgb(29 78 216);
                border: 2px solid rgb(37 99 235);
                background-color: rgb(239 246 255);

                &:hover {
                    border: 2px solid rgb(37 99 235);
                }
            }
        }
    }

    &_input {
        border: 1px solid rgb(209 213 219);
        padding: 0.8rem 1.6rem;
        border-radius: 0.5rem;

        &:focus {
            box-shadow: inset 0 0 0 2px rgb(59 130 246);
        }
    }

    &_checkBoxContainer {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1.6rem;
    }

    &_checkbox {
        color: rgb(37 99 235);
        border: 1px solid rgb(209 213 219);
        border-radius: 0.25rem;
        width: 1.6rem;
        height: 1.6rem;
    }

    &_workZone {
        border: 1px solid rgb(229 231 235);
        border-radius: 0.5rem;
        padding: 1.6rem;
        margin-top: 1.6rem;

        &_select {
            border: 1px solid rgb(209 213 219);
            padding: 0.8rem 1.2rem;
            border-radius: 0.75rem;
            width: 100%;
            font-weight: 500;
            color: rgb(55, 65, 81);
            background-color: transparent;

            &:focus {
                box-shadow: inset 0 0 0 2px rgb(59 130 246);
            }
        }

        &_citySelected {
            background-color: rgb(249 250 251);
            padding: 1.2rem;
            border-radius: 0.75rem;
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            align-items: baseline;
            margin-top: 0.8rem;

            &:first-child {
                margin-top: 0;
            }

            &_info {
                display: flex;
                flex-direction: column;

                &_province {
                    font-weight: 500;
                    color: rgb(17 24 39);
                    font-size: 1.6rem;
                    line-height: 1.5;
                }

                &_city {
                    color: rgb(107 114 128);
                    line-height: 1.5;
                }
            }

            &_button {
                background-color: transparent;
                color: rgb(156 163 175);
            }
        }

        &_title {
            color: rgb(55 65 81);
            font-weight: 500;
            line-height: 1.5;
            margin-bottom: 0.8rem;
        }

        &_cityList {
            display: flex;
            flex-direction: column;
            margin-top: 1.2rem;

            &_container {
                display: flex;
                gap: 0.5rem;
                flex-direction: column;
            }

            &_item {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                cursor: pointer;

                &_checkbox {
                    border: 1px solid rgb(209 213 219);
                    border-radius: 0.25rem;
                    width: 1.6rem;
                    height: 1.6rem;
                    color: rgb(37 99 235);
                    cursor: pointer;
                }
            }
        }
    }
    &_textArea {
        border: 1px solid rgb(209 213 219 );
        padding: 0.8rem 1.6rem;
        border-radius: 0.75rem;
        resize: none;
        height: 20rem;

        &_counter {
            margin-top: 1rem;
            color: rgb(107 114 128 );
        }
    }
}

.labelHidden {
    display: none;

    svg {
        fill: #fff;
    }
}

.modalFooter {
    border-top: 1px solid rgb(229 231 235);
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 2.4rem;
    gap: 1rem;

    &_buttonContainer {
        display: flex;
        gap: 1rem;
        margin-left: auto;
    }

    &_btnClose {
        background-color: transparent;
        color: rgb(55 65 81);
        font-weight: 500;
        padding: 0.8rem 1.6rem;
    }

    &_submit {
        background-color: rgb(37 99 235);
        color: #fff;
        padding: 0.8rem 1.6rem;
        border-radius: 0.5rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 100ms ease-in-out;

        &:hover {
            transform: scale(1.05);
        }

        &:disabled {
            background-color: rgb(229 231 235);
            color: rgb(107 114 128);
            cursor: not-allowed;
        }
    }
}

.error {
    box-shadow: 0 0 0px 1px rgb(239 68 68);
}

.toolTipError {
    width: max-content;
    color: rgb(239 68 68);
    font-size: 1.2rem;
    display: flex;
    gap: 2rem;
    align-items: center;
    position: relative;

    &_errorMenu {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 30rem;
    }

    &_icon {
        cursor: pointer;
    }

    &_list {
        display: flex;
        gap: 1rem;


        &_item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }
}