@use "../../utils/mediaQuerys" as *;;
.container {
  display: flex;
  flex-direction: column;
}
.stepsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    color: #1e4776;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 20px;
  }
}

.stepsProgress {
  width: 135.47px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ball {
  width: 12px;
  height: 12px;
  background-color: #e8e8e8;
  border: solid 1px #d5d5d5;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.6rem;
  &.active {
    background-color: var(--color-mainBlue);
    border: solid 1px var(--color-mainBlueBorder);
  }
}

.meter {
  display: none;
  height: 10px;
  position: sticky;
  background: #d8d8d8;
  width: 100%;
  top: 68px;
  left: 0;
  z-index: 1000;
  span {
    display: block;
    height: 10px;
    background-color: #4a90e2;
    position: sticky;
    overflow: hidden;
    top: 68px;
    left: 0;
    z-index: 1000;
  }
}
