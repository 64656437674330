.pagination {
    margin-top: 3.2rem;
    display: flex;
    justify-content: center;
}

.paginationItems {
    display: flex;
    gap: 1rem;
}

.pageButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.2rem;
    height: 3.2rem;
    border: 1px solid rgb(209 213 219);
    border-radius: 0.5rem;
    background-color: #fff;
    color: rgb(107 114 128);
    font-size: 1.6rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:disabled {
        cursor: not-allowed;
        color: rgb(209 213 219);
    }

    &:hover {
        background-color: var(--color-mainBlueDark);
    }

    &_active {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.2rem;
        height: 3.2rem;
        background-color: rgb(35 75 162);
        color: #fff;
        border-radius: 0.5rem;
    }
}



.arrowButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.2rem;
    height: 3.2rem;
    border: 1px solid rgb(209 213 219);
    border-radius: 0.5rem;
    background-color: #fff;
    color: rgb(107 114 128);
    font-size: 1.6rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:disabled {
        cursor: not-allowed;
        color: rgb(209 213 219);
    }

    &:hover {
        background-color: var(--color-mainBlueDark);
    }
}