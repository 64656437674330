.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

// 0 - 600px:      Phone
// 600 - 900px:    Tablet portrait
// 900 - 1200px:   Tablet landscape
// [1200 - 1800] is where our normal styles apply
// 1800px + :      Big desktop
