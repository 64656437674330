.QuePasaEnEdify {
  min-width: 313px;
  max-width: 313px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 0;
  margin: 20px 0 0 0;
  border-radius: 0.75rem;
  margin-bottom: 20px;
  background-color: #fff;

  &_container {
    width: 100%;
    height: 100%;

    &_header {
      padding: 20px;
      font-size: 1.5rem;
      font-weight: 800;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      border-bottom: 1px solid #edf0f7;
    }

    &_arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      border-radius: 50%;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #edf0f7;
      }
    }

    &_content {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      gap: 1.5rem;

      &_item {
        display: flex;
        flex-direction: column;
        color: #2b3b5b;
        width: 100%;
      }
    }
  }

  &_title {
    display: flex;
    padding: 10px;
    justify-content: center;

  }

}

.obrasCantidad,
.cotizacionCantidad,
.userCantidad {
  display: flex;
  color: #2b3b5b;
  font-weight: 700;
  align-items: center;
  font-size: 2.4rem;
  padding-bottom: 1rem;

}


.cantidadContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 1rem;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

}

.cantidadContainerFirst {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 1rem;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
}

.highlight {
  background-color: #d6174a;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
  margin-left: 10px;
}

.totalNewUsers,
.totalNewProjects,
.totalNewQuotations {
  display: inline-block;
  margin-left: 10px;
}

.totalNewUsers_number,
.totalNewProjects_number,
.totalNewQuotations_number {
  background-color: #f41079;
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 92px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.totalNewUsers_label,
.totalNewProjects_label,
.totalNewQuotations_label {
  font-style: italic;
  font-size: 10px;
}

.highlightedContainer {
  display: flex;
  margin: 0;
}

.notificationContainer {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  padding-left: 20px;
  background-color: #fff;
  position: relative;
  border-radius: 1rem;
  border: 1px solid #edf0f7;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f9fafb;
  }

  &_header {
    position: absolute;
    top: 0;
    right: 0;
    background: #234ba2;
    padding: 0px 5px;
    border-top-right-radius: 5px;

  }

  &_title {
    font-size: 12px;
    text-align: center;
    color: #fff;
    font-weight: 600;

  }

  &_profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &_image {
      margin-right: 10px;
      position: relative;

    }

    &_img {
      max-width: 40px;
      min-width: 40px;
      max-height: 40px;
      min-height: 40px;
      border-radius: 50%;
      margin-right: 1rem;
      align-self: center;
      object-fit: cover;
    }

    &_imageChecked {
      position: absolute;
      bottom: 3px;
      right: 7px;
      width: 15px;
      height: 15px;
    }
  }

  &_info {
    display: flex;
    flex-direction: column;

  }

  &_rubro {
    font-size: 12px;
    color: #959Ca3;
    font-weight: 600;
  }

  &_userName {
    font-size: 1.6rem;
    cursor: pointer;
    font-weight: 600;
    line-height: 2rem;

  }

  &_sub {
    color: rgb(107 114 128);
    line-height: 2rem;
  }

  &_publicProject {
    display: flex;
    align-items: center;
    padding-top: 8px;
    cursor: pointer;

    &_icon {
      width: 40px;
      height: 40px;
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      svg path {
        fill: rgb(245, 176, 72);
      }
    }

    &_info {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &_name {
      cursor: pointer;
      font-weight: 500;
      display: flex;

      span {
        text-transform: lowercase;
    
        &:first-letter {
          text-transform: uppercase;
        }
      }

    }

    &_factory {
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;

      span {
        text-transform: lowercase;
    
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    &_zone {
      display: flex;
      gap: 10px;
      align-items: center;

    }
  }

  &_publicQuotation {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 8px;

    &_icon {
      width: 40px;
      height: 40px;
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_info {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &_name {
      cursor: pointer;
      font-weight: 500;
      display: flex;

      span {
        text-transform: lowercase;
    
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    &_zone {
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;

      span {
        text-transform: lowercase;
    
        &:first-letter {
          text-transform: uppercase;
        }
      }

      svg g {
        stroke: #2b3b5b;
      }

    }
  }
}