
.featuresContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
}

.feature {
    padding: 2.4rem;
    background-color: #fff;
    border-radius: 0.75rem;
    transition: all 0.3s ease-in-out;
    cursor: default;

    &:hover {
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        transform: translateY(-0.4rem);
    }

}

.featureIconContainer {
    background-color: rgb(224 234 255);
    border-radius: 0.75rem;
    margin-bottom: 1.6rem;
    width: 4.8rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.featureIcon {
    display: flex;
    color: rgb(35 75 162 );
}

.featureTitle {
    font-size: 1.6rem;
    color: rgb(17 24 39);
    font-weight: 600;
}

.featureDescription {
    color: rgb(75 85 99);
    margin-top: 0.8rem;
    line-height: 1.5;
}