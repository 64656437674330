@use "../../../../utils/mediaQuerys";

.container {
  margin: 0 auto;

  .wrapper {
    width: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.active {
      display: flex;
   
    }
    &_headerTitle {
      font-weight: 600;
      color: rgb(55 65 81);
      padding-bottom: 1.6rem;
      font-size: 1.8rem;
      margin-top: 3rem;
      padding-top: 2.4rem;
      border-top: 1px solid #ebebeb;
      width: 100%;
      }
  }

  .titleContainer {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
  }
}