@use "../../utils/mediaQuerys" as *;;
.section-container1 {
  margin: 0 auto;
  background: #fff;
  min-height: 160px;
  max-height: 160px;
  border-top: 1px solid #ebebeb;
  bottom: 0;
  width: 100%;
}

.footer-logo {
  & img {
    margin-left: 30px;
    width: 115px;
  }
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  @include respond(tab-port) {
    margin-top: 6rem;
  }
}

.footer-navigation__container {
  display: flex;
  margin: 0 31px;
  @include respond(tab-port) {
    justify-content: center;
    text-align: left;
    margin: 4rem auto;
  }
}
.footer-navigation__content {
  margin-right: 7rem;
  @include respond(tab-port) {
    margin: 0 auto;
    width: 33.33%;
    text-align: left;
  }
}
.footer-navigation__title {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  // line-height: 16px;
  cursor: pointer;
  & li {
    margin: 5px 0;
    color: rgba(0, 0, 0, 0.37);
    font-size: 14px;
    font-weight: 600;
    line-height: 26.25px;
    text-align: left;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.footer-navigation__edify {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #000;
  font-size: 14px;
  font-weight: 600;
    cursor:auto
}
.footer-social__container {
  display: flex;
  align-items: center;
  padding-left: 161px;
  @include respond(tab-port) {
    justify-content: center;
  }
}
.footer-img {
  margin: 0 8px;
  & a {
    background: #EDF0F7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem;
    border-radius: 50%;
  }
  & :hover {
    cursor: pointer;
    transition:all 2s ;
    & img {
      max-height: 24px;
    }
  }
 
}
.designers {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.01px;
  line-height: 18px;
  text-align: left;
  & a {
    color: #000;
  }
}
.columna-chica {
  width: 26%;
  @include respond(tab-port) {
    width: 100%;
    text-align: center;
  }
}
.columna-grande {
  width: 48%;
  @include respond(tab-port) {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}
.paisanos-container {
  width: 100%;
  @include respond(tab-port) {
    margin: 1rem 0;
    text-align: center;
  }
}

