@use "../../utils/mediaQuerys" as *;;

.backoffice-layout {
    display: flex;
    height: calc(100vh - 60px);
    padding: 2rem;
    width: 100%;

    @include respond(phone) {
      height: 100%;
      padding-top: 6rem;
      padding-left: 0;
      padding-right: 0;
    }
  
    .sidebar {
      width: 250px;
      background-color: #ffffff;
      border-right: 1px solid #e0e0e0;
      display: flex;
      flex-direction: column;
      transition: transform 0.3s ease;
  
      @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        transform: translateX(-100%);
        z-index: 1000;
  
        &.open {
          transform: translateX(0);
        }
      }
  
      .sidebar-header {
        padding: 20px;
        background-color: #1a202c;
        color: white;
  
        h2 {
          margin: 0;
          font-size: 1.5rem;
        }
      }
  
      nav {
        flex-grow: 1;
      }
  
      .sidebar-button {
        width: 100%;
        padding: 15px 20px;
        text-align: left;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #4a5568;
        transition: background-color 0.2s;
  
        &:hover {
          background-color: #f7fafc;
        }
  
        svg {
          margin-right: 10px;
        }
      }
  
      .sidebar-footer {
        padding: 20px;
      }
    }
  
    .main-content {
      flex-grow: 1;
      overflow-y: auto;

      @include respond(phone) {
        height: 100%;
      }
  
      header {
        background-color: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
        .header-content {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          h1 {
            margin: 0;
            font-size: 1.5rem;
            color: #2d3748;
          }
  
          .menu-button {
            display: none;
            background: none;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
  
            @media (max-width: 768px) {
              display: block;
            }
          }
        }
      }
  
      main {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        height: calc(100% - 61px);
  
        .table-container {
          background-color: white;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          overflow: auto;
  
          table {
            width: 100%;
            border-collapse: collapse;
  
            th, td {
              padding: 12px 15px;
              text-align: left;
              border-bottom: 1px solid #e2e8f0;
            }
  
            th {
              background-color: #f7fafc;
              font-weight: 600;
              color: #4a5568;
            }
  
            .verified-icon {
              color: #48bb78;
            }
  
            .view-image-button, .verify-button {
              padding: 8px 12px;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              transition: background-color 0.2s;
  
              &:hover {
                background-color: #edf2f7;
              }
  
              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
  
              svg {
                margin-right: 5px;
              }

              @include respond(phone) {
                padding: 5px 5px;

                svg {
                  margin-right: 0;
                }
              }
            }
          }
        }
  
        .pagination {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          flex-wrap: wrap;
  
          button {
            padding: 8px 12px;
            margin: 0 5px;
            border: 1px solid #e2e8f0;
            background-color: white;
            cursor: pointer;
            transition: background-color 0.2s;
  
            &:hover {
              background-color: #f7fafc;
            }
  
            &.active {
              background-color: #4299e1;
              color: white;
            }
  
            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  
    .modal {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
  
      .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        text-align: center;
  
        h2 {
          margin-top: 0;
        }
  
        img {
          max-width: 100%;
          height: auto;
          border-radius: 4px;
          margin: 10px 0;
        }
  
        button {
          padding: 8px 16px;
          background-color: #4299e1;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.2s;
  
          &:hover {
            background-color: #3182ce;
          }
        }
      }
    }
  }
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 80%; 
    max-width: 600px; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .modal-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .carousel .slider-wrapper {
    width: 100%;
    height: 100%;
  }
  
  .carousel .slide img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
  }
  
  .modal button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .modal button:hover {
    background-color: #0056b3;
  }
  
  .modal-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
  }
  
  .close-button:hover {
    color: #007bff;
  }
  
  .fullscreen-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
  }
  
  .fullscreen-button:hover {
    color: #007bff;
  }
  
  :fullscreen .modal {
    width: 100vw;
    height: 100vh;
  }
  
  :fullscreen .modal-content {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  
  :fullscreen img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .verify-button {
    background-color: #4caf50; 
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #45a049; 
    }

    &:disabled {
      background-color: #c7c7c7; 
      cursor: not-allowed;
    }
  }

  .reject-button {
    background-color: #f44336; 
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #e53935; 
    }

    &:disabled {
      background-color: #c7c7c7; 
      cursor: not-allowed;
    }
  }

  .verified-icon {
    color: green;
  }
  
  .rejected-icon {
    color: red;
  }
  
  .pending-icon {
    color: orange;
  }
  
  .verify-button {
    background-color: green;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
  
    &:disabled {
      background-color: grey;
      cursor: not-allowed;
    }

    @include respond(phone) {
      padding: 5px 5px;
      margin-right: 0;
    }
  }
  
  .reject-button {
    background-color: red;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }

    @include respond(phone) {
      padding: 5px 5px;
      margin-top: 5px;

      svg {
        margin-right: 0;
      }
    }
  }
  