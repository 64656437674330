@keyframes loadingStatus {
    from {
        background-color: #b5b7b5;
    }

    to {
        background-color: rgb(226, 226, 226);
    }
}

@keyframes loadingStatusWhite {
    from {
        background-color: rgb(226, 226, 226);
    }

    to {
        background-color: rgb(241, 241, 241);
    }
}

.animationIsLoaded {
    animation: loadingStatus 1s ease-in-out infinite;
    transition: all 0.3 ease-in-out;
}

.feedCardSkeleton {
    background-color: #f0f0f0;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    animation: loadingStatus 1s ease-in-out infinite;

}

.feedCardSkeletonHeader {
    animation: loadingStatus 1s ease-in-out infinite;
    height: 200px;
}