.rubroInputSelect {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

    &_inputRubro {
        border: none;
        width: 100%;


        &_label {
            font-size: 11px;
            color: #fff;
            padding: 5px;
            width: fit-content;
            border-radius: 5px;
            background: #234ba2;

            &_btn {
                margin-left: 5px;
                cursor: pointer;
                background: transparent;
                color: #fff;
            }

        }
    }

    &_suggestions {
        width: 100%;
        background-color: white;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        border-top: none;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        max-height: 20rem;
        overflow-y: auto;
        cursor: pointer;

        &_item {
            padding: 10px;
            border-bottom: 1px solid #ebebeb;
            transition: all 0.3s;
            cursor: pointer;
            display: flex;
            font-weight: 500;
            line-height: 1.5;

            &:hover {
                background-color: #E6F3FF;
            }

            &_count {
                display: contents;
                color: #6c757d;
            }
        }
    }

    &_buttonsContainer {
        background: #234ba2;
        margin-top: 2rem;
        padding: 10px;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        align-items: center;
        font-size: 1.6rem;


        &_btn {
            background: transparent;
            border: none;
            color: #fff;
            font-weight: 500;

        }
    }

    &_inputRubroContainer {
        position: relative;
        width: 100%;
        width: 100%;
        border: none;
        resize: none;
        border: 1px solid #d2d2d2;
        padding: 10px;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        gap: 1rem;
    }
}

.arrow {
    position: absolute;
    right: 10px;
    display: flex;
    top: 12px;
    cursor: pointer;

    &.arrowIconUp {
        transform: rotate(-180deg);
        transition: transform 0.3s ease;
    }

    &.arrowIconDown {
        transform: rotate(0deg);
        transition: transform 0.3s ease;
    }
}