@use "../../../../utils/mediaQuerys" as *;

.resumen {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
  
    @include respond(phone) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 0;
    }
  
    &_leftSide {
      grid-column: span 1 / span 1;

    }
  
    &_rightSide {
      grid-column: span 2 / span 2;
      border-left: 1px solid var(--color-mainBorder);
      padding-left: 2rem;

      @include respond(phone) {
        border-left: none;
        padding-left: 0;
      }
  
      @include respond(phone) {
        grid-column: span 1 / span 1;
        margin-top: 2.4rem;
      }
    }
  
    &_section {
      padding: 2.4rem 0;
      background-color: #fff;
      margin-bottom: 2.4rem;
  
      &:last-child {
        margin-bottom: 0;
      }
  
      &_title {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 2rem;
      }
  
      &_text {
        color: rgb(75 85 99);
        line-height: 1.8;
        font-size: 1.6rem;
      }
  
      &_info {
        &_item {
          display: flex;
          align-items: flex-start;
          gap: 1.2rem;
          font-size: 1.6rem;
          margin-top: 1.6rem;
  
          &:first-child {
            margin-top: 0;
          }
  
          &_icon {
            min-width: 2rem;
            color: rgb(37 99 235);
            margin-top: 0.2rem;
          }
  
          &_data {
            word-break: break-word;
          }
        }
        &_allCountry {
          background-color: rgb(239 246 255);
          border-radius: 0.75rem;
          padding: 1.6rem;
          display: flex;
          align-items: center;
          gap: 2rem;
  
          &_icon {
            color: rgb(37 99 235);
            margin-left: auto;
          }
  
          &_data {
            &_title {
              font-weight: 600;
              font-size: 1.6rem;
            }
  
            &_text {
              color: rgb(75 85 99);
            }
          }
        }
  
        &_zones {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 1.2rem;
  
          &_item {
            background-color: rgb(239 246 255);
            border-radius: 0.75rem;
            padding: 1.2rem;
            display: flex;
            gap: 1rem;
          }
        }
      }
    }
  
  }