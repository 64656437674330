.sendNotification {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    backdrop-filter: blur(5px);

    &_container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_content {
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 5px;
        width: 60rem;
        height: 60rem;
        max-width: 90%;
        max-height: 90%;

        &_body {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 10px 20px;
        }
    }

    &_header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;

        svg g {
            stroke: #fff
        }

        &_body {
            display: flex;
            font-size: 16px;
            font-weight: 600;
            flex-direction: column;
            width: 100%;
            background: #004F98;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            color: #fff;

        }

        &_txt {
            display: flex;
            justify-content: space-between;
        }
    }

    &_userInfo {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-size: 20px;
        font-weight: 400;

        &_img {
            min-width: 60px;
            max-width: 60px;
            min-height: 60px;
            max-height: 60px;
            border-radius: 50%;
            margin-right: 20px;
            border: 4px solid #ebebeb;
        }

        &_initials {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ccc;
            margin-right: 2rem;
            color: #fff;
            font-size: 1.5rem;
            min-width: 60px;
            min-height: 60px;
            border-radius: 50%;
            text-transform: uppercase;
            border: 4px solid #ebebeb;
        }

        &_txt {
            display: flex;
            flex-direction: column;
            width: 100%;

            &_name {
                font-weight: 600;
            }

        }

        &_stars {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 16px;

            &_icons {
                display: flex;
                gap: 2px;
            }

            &_number {
                margin-right: 5px;
            }

            svg path {
                fill: #fff;
            }
        }

    }

    &_subTitle {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 20px;
        padding-top: 10px;
    }

    &_textarea {
        resize: none;
        width: 100%;
        height: 100%;
        padding: 10px;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        font-weight: 500;

        &:focus {
            outline: none;
            border: 1px solid #234ba2;
        }
    }

    &_buttons {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
        padding-bottom: 10px;

        &_send {
            background-color: rgb(37 99 235);
            display: flex;
            align-items: center;
            gap: 5px;
            color: #fff;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1.6rem;
            transition: all 0.25s ease-out;

            &:hover {
                background-color: #234ba2;
            }

        }
    }
}

.sendNotification_content_body::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}

.sendNotification_content_body::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 2px;
    border-radius: 5px;

}

.sendNotification_content_body::-webkit-scrollbar-thumb {
    background: #003667;
    border-radius: 5px;
    width: 5px;
}