.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 2;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
}

.container {
    background-color: #fff;
    border-radius: 0.75rem;
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    flex-direction: column;

    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.6rem;
        border: 1px solid #e5e7eb;
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;

        &_closeIcon {
            color: rgb(156 163 175);
            cursor: pointer;
        }
    }

    &_tableContainer {
        padding: 1.5rem;
        overflow: auto;
    }

    &_table {
        color: rgb(75 85 99);
        text-align: left;
        width: 100%;
        text-indent: 0;
        border-collapse: collapse;

        &_head {
            background-color: rgb(249 250 251);
            color: rgb(75 85 99);
            text-transform: uppercase;
            line-height: 1.5;

            &_item {
                padding: 2.4rem 1.2rem;
            }
        }

        &_item {
            padding: 2.4rem 1.6rem;
            color: rgb(75 85 99);
            font-weight: 500;
        }

        &_borderBottom {
            border-bottom: 1px solid #e5e7eb;
        }
    }

    &_footer {
        color: rgb(31 41 55);
        font-weight: 600;


        &_total {
            text-align: right;
            padding: 2.4rem 1.6rem;
        }

        &_number {
            padding: 2.4rem 1.6rem;
        }
    }

    &_buttonsContainer {
        padding: 1.6rem;
        border-top: 1px solid #e5e7eb;
        display: flex;
        justify-content: flex-end;
        gap: 0.75rem;

        &_buttonCancel {
            border: 1px solid rgb(209 213 219);
            color: rgb(55 65 81);
            border-radius: 0.5rem;
            background-color: transparent;
            padding: 0.8rem 1.6rem;
            font-weight: 500;
        }

        &_buttonSend {
            background-color: rgb(37 99 235);
            color: #fff;
            border-radius: 0.5rem;
            padding: 0.8rem 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            gap: 0.5rem;

            &:disabled {
                opacity: 0.2;
                cursor: not-allowed;
            }
        }
    }


}