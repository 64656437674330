@use "../../utils/mediaQuerys" as *;

.body {
    display: flex;
    flex-direction: column;
    margin-top: 2.4rem;

    &_formGroup {
            padding: 15px 20px;
            display: flex;
            flex-direction: column;
            position: relative;
    
            &_inputsContainer {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                gap: 2rem;

                @include respond(phone) {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    gap: 1rem;
                }
            }
    }

    &_workZoneContainer {
        position: relative;
        display: flex;
        flex-direction: column;

        &_inputContainer {
            position: relative;

            &_button {
                border: 1px solid rgb(209 213 219);
                padding: 0.8rem 1.6rem;
                background-color: #fff;
                border-radius: 0.75rem;
                display: flex;
                align-items: center;
                width: 100%;
            }
        }

    }

    &_workZoneLabel {
        font-weight: 500;
        color: rgb(55 65 81);
        margin-bottom: 0.4rem;
    }

    &_workZoneInput {
        border: none;
        width: 100%;
        text-align: left;

        &.disabledInput {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }

        &::placeholder {
            color: #bfbfbf;

        }
    }

    &_workZoneList {
        position: absolute;
        z-index: 1;
        background-color: #fff;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        max-height: 150px;
        top: 100%;
        overflow-y: auto;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        width: 100%;

        &_item {
            padding: 0.8rem 1.6rem;
            cursor: pointer;
            transition: all 0.3s;
            font-size: 1.6rem;

            &:hover {
                background-color: #f5f5f5;
            }

        }
    }

    &_workZoneArrowUp {
        cursor: pointer;
        transition: all 0.3s;
        transform: rotate(0deg);

        &.disabledInput {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }

    }

    &_workZoneArrowDown {
        cursor: pointer;
        transition: all 0.3s;
        transform: rotate(180deg);

        &.disabledInput {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &_workZoneCityContainer {
        position: relative;
        display: flex;
        border-radius: 30px;
        padding: 10px 15px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 16px;
        font-weight: 600;

        &.disabledInput {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &:focus-within {
            border: 2px solid #234ba3;
        }
    }

    &_workZoneSelected {
        display: flex;
        gap: 5px;
        margin-top: 10px;
        flex-direction: column;

        &_item {
            background: rgb(239 246 255);
            color:rgb(29 78 216);
            padding: 1.2rem;
            border-radius: 0.75rem;
            gap: 1rem;
            display: flex;
            align-items: center;
            font-size: 1.6rem;
            cursor: pointer;
            font-weight: 600;
            transition: all 0.3s;

            &:hover {
                background: #234ba2;
                color: #edf0f7;
            }

            &_icon {
                margin-left: auto;
            }

        }
    }

    &_allCountryContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        font-size: 15px;
        font-weight: 500;
        gap: 20px;

        &_check {
            cursor: pointer;
        }
    }

}

.emptyState {
    background-color: rgb(249 250 251);
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: rgb(107 114 128);
    padding: 1.6rem;
    margin-top: 1rem;
  }

