@use "../../../utils/mediaQuerys" as *;

.container {
    width: 100%;
    border-radius: 11px;
    display: flex;
    height: 50vh;

    @include respond (small-phone) {
        flex-direction: column;
        height: 100%;
    }
}

.imageContainer {
    width: 50%;

    &_image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 12px;
        box-shadow: 2px 5px 2px 0px rgba(0, 0, 0, 0.1)
    }

    @include respond (small-phone) {
        display: none;
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 50%;

    @include respond (small-phone) {
        width: 100%;
    }

    &_image {
        display: flex;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
    }

    &_text {
        font-size: 13px;
        color: #d2d2d2;
        margin-bottom: 20px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            text-decoration: underline;
        }
    }
}

.title {
    text-align: center;
    margin-bottom: 40px;
}