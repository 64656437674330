.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.userPanelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 263px;
  max-width: 263px;
}

.AllProjects {
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 16.5px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.5px;
    margin-bottom: 25px;
  }
}
.activeFilter{
  color: var(--color-mainBlue);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.19px;
    line-height: 13px;
    margin:3rem

}
.sectionDivider{
  display: flex;
  line-height: 24.59px;
  font-size: 18px;
  font-weight: 700;
  width: 94%;
  margin-bottom: 12px;
  white-space: nowrap;
  }
  
  .divider {
    width: 73%;
    border-bottom: 1px solid #B3B5BA;
    align-self: center;
    margin-left: 20px;
  }
 