
.faqQuestions {
    width: 100%;
}

.questionList {
    list-style: none;

    &_answer {
        display: none;
        padding: 20px;
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 10px;
        margin-top: 20px;
        color: #959ba3;
        font-size: 14px;

        &_bullet {
            border-radius: 50%;
            width: 5px;
            display: inline-block;
            height: 5px;
            margin-right: 10px;
            background-color: #797d81;
        }

        svg {
            margin-right: 5px;
        }

        &.show {
            display: block;
        }

        &_subtitle {
            display: flex;
            font-weight: 800;
            padding-top: 20px;
            color: #797d81;

            svg path {
                fill: #797d81;
            }
        }
    }

    &_question {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;
        box-shadow: 0px 3px 107px 0px rgba(65, 103, 179, 0.1);
        flex-direction: column;
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 0.75rem;

        &_icon {
            cursor: pointer;
            height: 100%;
        }

        &_title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            word-spacing: 1px;
            text-transform: uppercase;
        }

    }
}

.SuscriptionModal {
    &_PlanContainerPlantitle {
        text-align: center;
        font-size: 11px;
    }

    &_PlanContainerGrid {
        margin-top: 45px;
    }

    &_planTableHeader {
        cursor: pointer;
        min-width: 110px;
        position: relative;

        &_price {
            font-size: 16px;
            font-weight: 800;
            text-align: center;
            color: #000000;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &_recommendPlan {
            height: 25px;
            justify-content: center;
            align-items: center;
            display: flex;
            position: absolute;
            bottom: 55px;
            background: #3f66b3;
            width: 100%;
            color: #fff;
            border-top-left-radius: 11px;
            border-top-right-radius: 11px;
            font-size: 12px;
        }
    }

    &_planTableItems {
        text-align: center;
        font-size: 10px;
        padding: 0;
        background: #ebebeb;
        height: 60px;

        &_buttons {
            display: flex;
            justify-content: center;
        }


        &_row {
            font-size: 11px;
            font-weight: 600;
        }
    }
}