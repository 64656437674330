@use "../../utils/mediaQuerys" as *;

.contentContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.content {
  width: 50%;
  min-height: 100vh;

  @include respond(tab-port) {
    width: 100%;
  }
}

.background {
  background: url("../../assets/images/foto-bienvenida.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include respond(tab-port) {
    display: none;
  }
}

.registerContainer {
  margin: 0 auto;
  display: flex;
  padding: 20px;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;

}

.logo {
  text-align: center;
  margin-bottom: 25px;

  & img {
    width: 120px;
  }
}

.btn {
  padding: 15px 30px;
  color: var(--color-white);
  outline: none;
  font-size: 15.5px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  width: 100%;
  display: block;
  background-color: var(--color-mainBlue);
  transition: all 0.2s;
  border-radius: 7px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:hover {
    transform: translateY(-3px);
  }
}

.formTitle {
  color: var(--color-mainBlue);
  font-size: 2.4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  margin-top: 45px;
  margin-bottom: 3rem;

}

.formSubtitle {
  color: var(--color-black);
  margin-bottom: 2.4rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.5;
}

.copyright {
  max-width: 268px;
  margin-top: 50px;
  color: #707070;
  opacity: 0.47;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;

}

.goBack {
  text-align: center;
  text-decoration: unset;
  color: #3f66b3;
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;

  a:visited {
    color: #3f66b3;
  }
}

.viewPassword {
  position: absolute;
  z-index: 2;
  top: 1.2rem;
  right: 2rem;
  cursor: pointer;
}

.error {
  margin-top: 1rem;
  margin-left: auto;
  text-align: right;
  color: var(--color-error);
}

.list {
  list-style: none;
  margin-bottom: 2.4rem;

  &_item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
  }
}