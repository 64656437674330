.container {
  width: 100%;
  padding: 0 60px;
  .title {
    padding: 10px 0;
    border-bottom: 1px dashed #979797;
    h2 {
      font-size: 27px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
    }
  }

  .presupuesto {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    .presupuestoAction {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 30px;
      button {
        padding: 10px 30px;
        border-radius: 14.63px;
        color: white;
        font-size: 12px;
      }
      .aceptar {
        background-color: #2f6aae;
        margin-left: 15px;
      }
      .rechazar {
        background-color: #f01f38;
      }
    }
    .presupuestoItem {
      background-color: #dfe0e2;
      border: 0.75px solid #303337;
      border-radius: 7.5px;
      width: 175.5px;
      height: 265.5px;
      margin: 15px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 35px;
      a {
        margin-top: 50px;
        background-color: #2f6aae;
        border-radius: 10px;
        padding: 10px 10px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        margin-right: 5px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        outline: none;
        font-size: 10px;
        cursor: pointer;
        &:disabled {
          background-color: #808080;
        }
        img {
          width: 17px;
          height: 17px;
          margin-right: 8px;
        }
      }
    }
    .presupuestoTitle {
      width: fit-content;
      span {
        color: #4b9dd5;
      }
      h3,
      h4 {
        font-weight: 400;
        line-height: 16px;
        text-align: left;
      }
    }
    h3 {
      margin-bottom: 5px;
    }
  }
}
