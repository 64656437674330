@use "../../utils/mediaQuerys" as *;;

.licitacionesItemCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.75rem;
  border: 1px solid #edf0f7;

  &_backGround {
    display: flex;
    position: relative;
    height: 18rem;

    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 25%);
      z-index: 1;
      width: 100%;
      height: 100%;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }

    &_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }

    &_data {
      position: absolute;
      display: flex;
      bottom: 1rem;
      left: 2rem;
      color: white;
      -webkit-box-align: center;
      align-items: center;
      z-index: 1;

      &_logo {
        min-width: 5rem;
        max-width: 5rem;
        min-height: 5rem;
        max-height: 5rem;
        border-radius: 1.5rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #fff;
        object-fit: cover;

        &_img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &_info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem;

        &_name {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          word-break: break-word;
        }
      }


    }

  }

  &_title {

    font-weight: 600;
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  &_content {
    display: flex;
    flex-direction: column;
    padding: 1.6rem;
    flex: 1;

    &_title {
      color: rgb(17 24 39);
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 0.4rem;
    }

    &_description {
      color: rgb(75 85 99);
      line-height: 1.5;
      margin-bottom: 1.2rem;
    }

    &_user {
      background-color: rgb(249 250 251);
      padding: 0.8rem;
      border-radius: 0.75rem;
      margin-bottom: 1.2rem;
      gap: 1rem;
      display: flex;
      align-items: center;

      &_icon {
        background-color: rgb(219 234 254);
        width: 3rem;
        height: 3rem;
        border-radius: 9999px;
        color: rgb(37 99 235);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_info {
        display: flex;
        flex-direction: column;

        &_name {
          color: rgb(17 24 39);
          font-weight: 500;
        }

        &_email {
          color: rgb(107 114 128);
          font-size: 1.2rem;
        }
      }
    }

    &_zone {
      display: flex;
      align-items: flex-start;
      color: rgb(75, 85, 99);
      gap: 0.5rem;
      position: relative;
      margin-bottom: 0.8rem;
    }

    &_workType {
      flex: 1;

      &_category {
        color: rgb(29, 78, 216);
        background-color: rgb(239, 246, 255);
        border-radius: 0.3rem;
        padding: 0.25rem 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 1.2rem;
      }

      &_type {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        margin-bottom: 1.2rem;

      }

      &_titles {
        font-size: 1.5rem;
        line-height: 2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        &_rubro {
          background-color: #edf0f7;
          padding: 0.5rem 1rem;
          border-radius: 0.75rem;
          color: #003366;
        }

      }
    }

    &_dates {
      display: flex;
      align-items: center;
      color: rgb(75, 85, 99);
      gap: 0.5rem;
      margin-bottom: 0.8rem;
      

      &_date {
        position: relative;
      }
    }

    &_budget {
      display: flex;
      align-items: center;
      color: rgb(75, 85, 99);
      gap: 0.5rem;
      margin-bottom: 0.8rem;

      &_price {
        position: relative;
      }
    }

    &_button {
      display: flex;
      width: 100%;

      &_btn {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        width: 100%;
        background-color: rgb(37 99 235);
        color: #fff;
        cursor: pointer;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #003366;

        }
      }
    }
  }
}