@use "../../../../utils/mediaQuerys" as *;

.container {
    width: 100%;
    display: flex;


    &_infoContainer {
        padding: 10px;
    }

    &_body {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 3rem;

        @include respond(tab-port) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

}

.emptyState {
    display: flex;
    width: 100%;
}