.inputContainer {
    display: inline-block;
    position: relative;
    width: 100%;
}

.input {
    padding: 10px 15px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: rgb(17 24 39);
    border-radius: 0.75rem;
    border: 1px solid rgb(209 213 219);
    text-align: center;

    &:focus,
    &:active {
        box-shadow: inset 0 0 0 2px var(--color-mainBlue);
    }

    &::placeholder {
       font-size: 1.4rem;
       font-weight: 400;
    }

    &.error {
        

        &::placeholder {
            
        }
    }

    &:-webkit-autofill {
        -webkit-text-fill-color: #707070;
    }
}