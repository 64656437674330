@use "../../../utils/mediaQuerys" as *;

.container {
    display: flex;
    width: 100%;

    &_title {
        color: #2b3b5b;
        font-weight: 600;
        font-size: 16px;

        @include respond(phone) {
            font-size: 14px;
        }

    }

    &_header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 20px;
        transition: all 0.3s ease;
        border-left: 10px solid transparent;

        &:hover {
            cursor: pointer;
            border-left: 10px solid #2b3b5b;

        }

        @include respond(phone) {
            padding: 10px;
        }
    }

    &_arrow {
        display: flex;
        align-items: center;

        &_count {
            background-color: #d6174a;
            color: #fff;
            border-radius: 50%;
            font-size: 12px;
            font-weight: 800;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            margin-right: 5px;
            ;

        }

        svg g {
            stroke: #2b3b5b;
        }
    }

    &_body {
        width: 100%;

        &_budgetItem {
            background: #edf0f7;
            display: flex;
            padding: 10px;
            flex-direction: column;
            width: 100%;
            position: relative;

            &_overLay {
                opacity: 0;
                background-color: #fff;
                color: #2b3b5b;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: opacity 0.3s ease-in-out;
                display: flex;
                align-items: center;
                font-size: 18px;
                justify-content: center;
                font-weight: 700;

                &:hover {
                    opacity: 0.8;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    justify-content: center;
                    font-weight: 700;
                    border-radius: 11px;
                }
            }

            &_profession {
                font-size: 12px;
                color: #959ca3;
            }

            &_name {
                font-size: 14px;
                color: #2b3b5b;
                font-weight: 700;
            }

            &_noBudget {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #2b3b5b;
                font-weight: 700;
                height: 100%;
            }
        }

        &_header {
            font-size: 12px;
            padding: 5px 10px;
        }

        &_budgetFile {
            display: flex;
            width: 100%;
            cursor: pointer;
            padding: 10px;

            &_link {
                padding-left: 5px;
            }
        }

        &_budgetFileContainer {
            display: flex;
            margin-left: 10px;
            align-items: center;
            font-size: 12px;
        }
    }
}

.noBudgets {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 11px;
    background: #edf0f7;

    &_title {
        font-size: 12px;
    }
}

.arrowIconRight {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.arrowIconDown {
    transform: rotate(-180deg);
    transition: transform 0.3s ease;
}