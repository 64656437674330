@use "../../utils/mediaQuerys" as *;

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.featuredContractors {
    padding: 3rem;
    border-radius: 0.75rem;
    background-image: linear-gradient(to bottom right,
            #1d3d85,
            #1a346d);
    box-shadow: var(--shadow);

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;

        @include respond(phone) {
            flex-direction: column;
            gap: 1rem;
            align-items: flex-start;
        }

        &__icon {
            min-width: 3.2rem;
            color: rgb(199 215 254);
            margin-top: 0.3rem;

            @include respond(phone) {
                margin-top: 0;
            }


        }

        &__title {
            display: flex;
            flex-direction: column;

            &__txt {
                display: flex;
                align-items: center;
                font-size: 3rem;
                gap: 1rem;
                color: #fff;
                margin-bottom: 1.2rem;

                @include respond(phone) {
                    align-items: flex-start;
                    font-size: 2rem;
                }
            }
        }

        &__subtitle {
            font-size: 1.6rem;
            color: rgb(199 215 254);
        }

        &__button {
            font-size: 1.6rem;
            color: rgb(29 61 133);
            padding: 1.2rem 2.4rem;
            background-color: #fff;
            border-radius: 0.75rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            transition: all 0.3s;

            &:hover {
                background-color: #f7f7f7;
                transform: scale(1.05);
            }
        }
    }

    &__carousel {
        overflow: hidden;
        position: relative;

        &__container {
            display: flex;
            width: max-content;
            animation: scroll 100s linear infinite;
            gap: 3rem;

            &.paused {
                animation-play-state: paused;
            }

        }
    }


    &__card {
        background-color: #fff;
        border-radius: 0.75rem;
        flex: 0 0 auto;
        transition: all 0.3s;

        &:hover {
            transform: translateY(-0.5rem);
        }


        &__borderTop {
            background-image: linear-gradient(to right,
                    #819afa,
                    #234ba2);
            height: 1.5rem;
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
        }

        &__header {
            display: flex;
            gap: 1.6rem;
            align-items: center;
            margin-bottom: 2rem;

            &__imageContainer {
                width: 8rem;
                height: 8rem;
                border-radius: 9999px;
                overflow: hidden;
                display: flex;
                object-fit: cover;
                justify-content: center;
                align-items: center;
                background-color: rgb(224 234 255);
                color: rgb(35 75 162);
                aspect-ratio: 1/1;

                &__img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                gap: 0.8rem;

                &__txt {
                    font-size: 2rem;
                    color: rgb(17 24 39);
                }
            }

            &__stars {
                display: flex;
                gap: 0.4rem;
            }
        }

        &__info {
            margin-bottom: 1.6rem;
            flex: 1;

            &__contact {
                display: flex;
                gap: 1rem;
                font-size: 1.6rem;
                color: rgb(75 85 99);
                align-items: flex-start;
                word-break: break-word;
                margin-bottom: 1.2rem;

            }

            &__skills {
                display: flex;
                align-items: flex-start;
                gap: 1rem;
                font-size: 1.6rem;
                color: rgb(75 85 99);
                margin-bottom: 1.2rem;
            }
        }

        &__buttons {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 1.6rem;

            &__buttonPrimary {
                padding: 1.2rem 2.4rem;
                background-color: #234ba2;
                color: #fff;
                border-radius: 0.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.6rem;
                gap: 1rem;
                transition: all 0.3s;

                &:hover {
                    background-color: #1a346d;
                    transform: scale(1.05);
                }
            }

            &__button {
                padding: 1.2rem 2.4rem;
                background-color: #f7f7f7;
                color: #234ba2;
                border-radius: 0.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                font-size: 1.6rem;
                transition: all 0.3s;

                &:hover {
                    background-color: #e5e5e5;
                    transform: scale(1.05);
                }
            }
        }
    }

    &__cardBody {
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}