.itemCommentHeader {
    display: flex;
    padding: 1.6rem;
    align-items: center;
    border-radius: 0rem 0rem 0.75rem 0.75rem;

    &_imgContainer {
        max-width: 40px;
        min-width: 40px;
        min-height: 40px;
        max-height: 40px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-right: 10px;
        align-items: center;
        border: 1px solid rgb(226, 239, 255);

        &_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &_initials {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ccc;
            color: #fff;
            font-size: 1.5rem;
            min-width: 50px;
            min-height: 50px;
            border-radius: 50%;
            text-transform: uppercase;
        }
    }
}

.itemCommentBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    padding: 0 20px;

    &_menuButton {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        position: relative;
        background-color: transparent;
    }

    &_menu {
        background-color: #0009;
        color: #fff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: absolute;
        top: 20px;
        z-index: 1;
        opacity: 0.9;

        &_item {
            display: flex;
            padding: 5px;
            font-size: 14px;

            &:hover {
                background-color: #000000;
                opacity: 1;
                border-radius: 5px;
            }

        }
    }

    &_input {
        width: 100%;
        display: flex;

        &_item {
            background: #fff;
            border-radius: 11px;
            width: 100%;
            color: rgb(75, 85, 99);
            border: 1px solid rgb(226, 239, 255);
            resize: none;
            padding: 10px;
            cursor: text;
            height: 40px;
            overflow: hidden;

            &:focus {
                border: 1px solid rgb(37, 99, 235);
            }

            &_iconContainer {
                display: flex;
                align-items: center;
                margin-left: 10px;
                cursor: pointer;
            }

            &_buttonsContainer {
                display: flex;
                justify-content: flex-end;
                gap: 1rem;
                margin-top: 1.6rem;
            }
        }
    }

    &_item {
        display: flex;
        padding: 1.2rem 1.6rem;

        &_imgContainer {
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            margin-top: 10px;
            border: 1px solid rgb(226, 239, 255);

            &_img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &_initials {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ccc;
                color: #fff;
                font-size: 1.5rem;
                min-width: 40px;
                min-height: 40px;
                border-radius: 50%;
                text-transform: uppercase;
            }
        }

        &_contentContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &_content {
            display: flex;
            width: 100%;
            background-color: rgb(249 250 251);
            flex-direction: column;
            border-radius: 8px;
            padding: 0.8rem 1.6rem;

            &_hovered {
                transition: all 0.3s;
                background-color: #fff;
            }

            &_header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.4rem;

                &_name {
                    color: #000000;
                    font-weight: 600;
                    display: flex;
                    align-items: flex-end;
                    gap: 0.7rem;
                }

                &_time {
                    color: rgb(107 114 128);
                    font-size: 1.2rem;
                    font-weight: 400;
                }
            }

            &_contentContainerEdit {
                display: flex;
                flex-direction: column;
                margin-top: 5px;

            }
        }

        &_text {
            color: rgb(75 85 99);

            &_contentEditDate {
                margin-left: 5px;
                color: #959CA3;
                font-size: 10px;
            }
        }

        &_likesAndRespond {
            display: flex;
            margin-top: 0.4rem;
            margin-left: 1.6rem;
            gap: 1.5rem;
            width: 100%;
            justify-content: flex-start;
            font-size: 12px;
            color: rgb(107 114 128);

            &_btn {
                background-color: transparent;
                transition: all 0.3s;
                display: flex;
                gap: 0.5rem;

                &:hover {
                    color: rgb(55 65 81);
                }

                &_active {
                    color: red;

                    &:hover {
                        color: red;
                    }
                    
                    svg path {
                        fill: red;
                    }
                }
            }

        }

        &_responseContainer {
            display: flex;
            margin-top: 1rem;

            &_imgContainer {
                min-width: 25px;
                max-width: 25px;
                min-height: 25px;
                max-height: 25px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;

                &_img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &_inputContainer {
                border: 1px solid rgb(226 239 255);
                border-radius: 11px;
                padding: 0.6rem 1.2rem;
                display: flex;
                width: 100%;

                &:focus-within {
                    border: 1px solid rgb(37 99 235);
                }
            }

            &_input {
                width: 100%;
                display: flex;
                background-color: #fff;
                border: none;
                resize: none;
            }

            &_iconContainer {
                display: flex;
                align-items: center;
                cursor: pointer;
                color: rgb(107 114 128);

                &_active {
                color: rgb(37 99 235);
                }
            }
        }
    }
}

.input {
    width: 100%;
    display: flex;
    border: 1px solid rgb(226 239 255);
    background-color: #fff;
    border-radius: 11px;

    &:focus-within {
        border: 1px solid rgb(37 99 235);
    }

    &_item {
        border: none;
        width: 100%;
        color: rgb(75 85 99);
        resize: none;
        background-color: transparent;
        padding: 10px;
        cursor: text;
        overflow: hidden;
        font-size: 14px;

        &_iconContainer {
            display: flex;
            align-items: center;
            padding: 0 1rem;
            cursor: pointer;
            color: rgb(107 114 128);

            &_active {
                color: rgb(37 99 235);
            }
        }

        &::placeholder {
            color: rgb(75 85 99);
        }
    }
}

.itemFooterComment {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;

    &:hover {
        background: #F7F7F7;
        border-radius: 11px;
    }

    &_txt {
        color: #959CA3;
        margin-left: 5px;
    }
}