.popupOverlay {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    backdrop-filter: blur(5px);
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  height: 100%;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;

    &_title {
      font-size: 1.8rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    &_cancelBtn {
      background-color: transparent;
      color: #889abe;
    }
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 2rem;
    padding-right: 1rem;
    overflow: auto;
    height: 100%;
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    font-weight: 600;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    position: relative;
  }

  textarea {
    resize: vertical;
    min-height: 100px;
    resize: none;
  }
}

.datePickerWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
  position: relative;
}

.datePicker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.sendBtn {
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.cancelBtn {
  background-color: #e2e8f0;
  color: #2d3748;

  &:hover {
    background-color: #cbd5e0;
  }
}

.sendBtn {
  background-color: #2c5282;
  color: white;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  &:disabled {
   opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: #2c5282
    }
  }
}