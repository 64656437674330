@use "../../../utils/mediaQuerys" as *;

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 0.75rem;
    padding: 1rem;
    border: 1px solid #e5e7eb;
    transition: all 0.3s;

    &:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgb(37, 99, 235);
    }


    &_image {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;

        &_img {
            min-width: 100%;
            max-width: 100%;
            min-height: 200px;
            max-height: 200px;
            object-fit: cover;
            border-radius: 0.75rem;
        }
    }

    &_info {
        width: 100%;
        display: flex;
        flex-direction: column;

        &_text {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            &_pill {
                margin-top: 10px;
                margin-left: 10px;
                background-color: #234ba2;
                border-radius: 30px;
                width: 50px;
                height: 10px;
                margin-bottom: 10px;
            }

            &_title {
                font-size: 20px;
                font-weight: 700;
                padding: 0px 10px;

            }

            &_description {
                font-size: 14px;
                padding: 0px 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
                opacity: 0.8;
                font-weight: 500;
                border: none;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }

        &_location {
            position: absolute;
            background-color: #234ba2;
            bottom: 0;
            right: 0;
            color: #fff;
            padding: 5px 20px;
            font-size: 14px;
            font-weight: 600;
        }

        &_dates {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: #234ba2;
            color: #fff;
            border-radius: 30px;
            padding: 5px 20px;
            font-size: 14px;
            font-weight: 600;
        }

        &_footer {
            display: flex;
            padding: 10px;
            opacity: 0.6;
            gap: 10px;

            &_clock {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 12px;
                font-weight: 600;
                opacity: 0.4;
                padding: 0 1rem;
                margin-bottom: 1.6rem;
            }

            &_comments {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }

    &_buttons {
        padding: 0.5rem 1rem;
        display: flex;
        background-color: rgb(37 99 235 );
        color: #fff;
        border-radius: 0.75rem;
        font-weight: 500;
        margin-top: 1.6rem;
        margin-left: auto;

    }
}