@use "../../../../../utils/mediaQuerys" as *;

.infoGeneralContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 1.6rem 2.4rem;


  &_customImg {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond(phone) {
      height: 120px;
    }

    &_img {
      width: 100%;
      border-radius: 5px;
      object-fit: cover;
      height: 100%
    }
  }
}

.infoContainer {
  display: flex;
  background-color: #f7f8f9;
  border-radius: 18px;
  margin-top: 20px;
  padding: 0 2rem;

}

.button {
  text-align: center;
}

.deleteButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @include respond(phone) {
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    transform: scale(1.2);
    
    background-color: #d6174a;
    border: none;
  }
}

.itemInfo {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  position: relative;
  cursor: pointer;
  gap: 10px;

  &_column {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #2b3b5b;
    font-size: 11px;
    font-weight: 700;
    gap: 5px;

    @include respond(phone) {
      font-size: 10px;
    }
  }

  &_imageMask {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 11px;
    gap: 10px;
    margin-top: 1.6rem;
    color: rgb(37 99 235 );
    background-color: rgb(239 246 255);
    border-radius: 0.75rem;

    @include respond(phone) {
      padding: 10px 10px;
    }

    &.withImage {
      border: 1px solid #fff;
      box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      background-color: transparent;

      svg path {
        fill: #fff;
      }
    }

  }

  &.detail {
    margin-right: 10px;
    padding-right: 15px;
  }

  &_input {
    width: 100%;
    padding: 0.8rem 1.2rem;
    border-radius: 0.75rem;
    border: 1px solid rgb(209 213 219 );
    font-weight: 600;
    text-align: left;
    position: relative;
    font-size: 1.4rem;

    &::placeholder {
      font-weight: 500;
    }

    &:focus {
      border: 1px solid #234ba2;
    }

  }

  &_area {
    width: 100%;
    max-width: 100%;
    height: 96px;
    resize: none;
    border: 1px solid #d2d2d2;

    &:focus {
      border: 1px solid #234ba2;
    }
  }

  &_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

  }

  &_containerThumb {
    display: flex;
    padding-top: 10px;
    padding-bottom: 20px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;

    @include respond(phone) {
      padding-top: 0;
      padding-bottom: 0;
      flex-wrap: nowrap;
    }

    &_imageContainer {
      position: relative;
      height: 40px;
      width: 50px;

      @include respond(phone) {
        height: 30px;
        width: 40px;
      }

      &_addImage {
        width: 50px;
        height: 40px;
        cursor: pointer;
        font-size: 11px;
        font-weight: 600;
        color: #234BA2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        align-items: center;
        text-align: center;
      }
    }
  }

  &_image {
    height: 100%;
    display: flex;
    width: 100%;
    border-radius: 8px;
    border: 1px solid;
    object-fit: fill;
    opacity: 0.6
  }

  &_imageCount {
    border-radius: 50%;
    color: white;
    background: #234ba2;
    display: flex;
    position: absolute;
    top: 0;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
    z-index: 1;
  }
}


.carouselContainer_empty {
  display: flex;
  flex-direction: column;
  background-color: #f7f8f9;
  border: 2px solid #234BA2;
  box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.11);
  border-radius: 18px;
  margin-top: 20px;
  padding: 0 2rem;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgb(209 213 219);
  gap: 5px;
  flex-direction: column;
  position: relative;
  border-radius: 0.75rem;
  padding: 3.2rem;

  &_withImage {
    position: absolute;
    z-index: 1;
  }

  &_subText {
    font-weight: 600;
    text-align: center;
  }

  &_fileType {
    margin-top: 0.4rem;
  }
}

.emptyContainer_text {
  font-weight: 600;
  font-size: 14px;
  text-align: center;

  &.withImage {
    color: #fff;

  }
}

.deleteThumbImg {
  font-size: 11px;
  position: absolute;
  color: #fff;
  background-color: #f41079;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 7px;
  z-index: 1;
  cursor: pointer;
  right: -5px;
  bottom: -5px;
}

.isPortfolio {
  margin-top: 10px;

}