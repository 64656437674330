@keyframes loadingStatus {
  from {
    background-color: #b5b7b5;
  }

  to {
    background-color: rgb(226, 226, 226);
  }
}

@keyframes loadingStatusWhite {
  from {
    background-color: rgb(226, 226, 226);
  }

  to {
    background-color: rgb(241, 241, 241);
  }
}

.animationIsLoaded {
  animation: loadingStatus 1s ease-in-out infinite;
  transition: all 0.3 ease-in-out;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.reduced {
    max-width: 283px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #b5b7b5;

}

.user {
  display: flex;
  width: 150px;
  align-items: center;
}

.info {
  animation: loadingStatus 1s ease-in-out infinite;
  transition: all 0.3 ease-in-out;
  border-radius: 5px;
  width: 100px;
  height: 30px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  width: 140px;
  gap: 10px;

  &_title {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    animation: loadingStatus 1s ease-in-out infinite;
    transition: all 0.3 ease-in-out;

    &.reduced {
      width: 100%;
      height: 30px;
      border-radius: 5px;
      animation: loadingStatus 1s ease-in-out infinite;
      transition: all 0.3 ease-in-out;
    }
  }

  &_input {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    animation: loadingStatus 1s ease-in-out infinite;
    transition: all 0.3 ease-in-out;

    &.reduced {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      animation: loadingStatus 1s ease-in-out infinite;
      transition: all 0.3 ease-in-out;
    }

  }
}

.userImg {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  animation: loadingStatus 1s ease-in-out infinite;
  transition: all 0.3 ease-in-out;

  &.reduced {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: loadingStatus 1s ease-in-out infinite;
    transition: all 0.3 ease-in-out;
  }

}

.AllProjects {
  width: 590px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.reduced {
    max-width: 283px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .img {
    // background-color: #b5b7b5;
    animation: loadingStatus 1s ease-in-out infinite;
    transition: all 0.3 ease-in-out;
    width: 590px;
    height: 300px;

    &.reduced {
      max-width: 283px;
      animation: loadingStatus 1s ease-in-out infinite;
      transition: all 0.3 ease-in-out;
    }
  }


}

.AllProjectsMasonry {
  display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-top: 20px;
    grid-gap: 10px;
    gap: 10px;

  &.reduced {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  &_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    animation: loadingStatus 1s ease-in-out infinite;
    transition: all 0.3 ease-in-out;
  }

  .img {
    // background-color: #b5b7b5;
    animation: loadingStatus 1s ease-in-out infinite;
    transition: all 0.3 ease-in-out;
    width: 283px;
    height: 150px;

    &.reduced {
      max-width: 283px;
      animation: loadingStatus 1s ease-in-out infinite;
      transition: all 0.3 ease-in-out;
    }
  }

  .info {
    animation: loadingStatusWhite 1s ease-in-out infinite;
    transition: all 0.3 ease-in-out;
    // background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    box-shadow: 0 6px 12px 3px rgba(71, 72, 73, 0.2);
    width: 283px;
    height: 91px;
  }
}