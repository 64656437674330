@use "../../../utils/mediaQuerys" as *;

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2rem;

  @include respond(phone) {
    gap: 0;
  }

  &_header {
    background-color: #fff;
    border-bottom: 1px solid var(--color-mainBorder);
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(phone) {
      flex-direction: column;
      align-items: stretch;
      gap: 2rem;

    }

    &_title {
      display: flex;
      font-size: 1.4rem;
      font-weight: 600;
      flex-direction: column;
      color: #7a7f85;
      text-align: left;

      @include respond(phone) {
        font-size: 1.4rem;
      }

      &_text {
        font-weight: 600;
        font-size: 2rem;
        text-align: left;
        color: #333;
      }
    }

    &_buttons {
      display: flex;
      gap: 1rem;
    }

    &_addCustomBtn {
      background: rgb(37 99 235);
      border: none;
      border-radius: 0.5rem;
      cursor: pointer;
      font-weight: 500;
      color: #fff;
      display: flex;
      padding: 0.5rem 1rem;
      align-items: center;
      gap: 1rem;
      width: 100%;
    }

    &_download {
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      background-color: #3182ce;
      color: #fff;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 1.4rem;
      transition: all 0.3s ease-in-out;
      gap: 1rem;

      &:hover {
        background-color: #003366;
      }
    }

    &_quoteBtn {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      border: 1px solid #234ba2;
      background-color: transparent;
      color: #234ba2;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 1.4rem;
      transition: all 0.3s ease-in-out;
      gap: 1rem;
      width: 100%;

      @include respond(phone) {
        padding: 0.5rem;
        width: 100%;

      }

      &:hover {
        background-color: #234ba2;
        color: white;
        border: 1px solid #234ba2;
      }

      &:disabled {
        opacity: 0.2;
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
          color: #234ba2;
          border: 1px solid #234ba2;
        }
      }
    }
  }

  &_bodyContainer {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 2rem;

    @include respond(phone) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

  }

  &_body {
    grid-column: span 8 / span 8;
    background-color: #fff;

    @include respond(phone) {
      grid-column: span 12 / span 12;
    }

    &_header {
      padding: 2rem 0;
      border-bottom: 1px solid #d2d2d2;
      display: flex;
      align-items: center;


      &_inputs {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: 100%;

        @include respond(phone) {
          flex-direction: column;
          gap: 2rem;
        }
      }



      &_searchContainer {
        flex: 1;
        display: flex;
        position: relative;
        align-items: center;
        border: 1px solid #d2d2d2;
        border-radius: 0.5rem;
        padding: 1rem;
        width: 100%;

        &_active {

          &:focus-within {
            box-shadow: 0 0 0 2px rgb(59 130 246);
          }
        }

        &_filteredResults {
          position: absolute;
          width: 100%;
          background-color: #f9f9f9;
          border: 1px solid #ddd;
          border-radius: 5px;
          max-height: 300px;
          overflow-y: auto;
          top: 100%;
          left: 0;
          z-index: 1;
          list-style: none;

          &_item {
            flex-direction: column;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            transition: background-color 0.3s ease;
            cursor: pointer;

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              background-color: #edf0f7;
            }

            &_category {
              font-size: 1.4rem;
              font-weight: 600;
              text-align: left;
              width: 100%;

            }

            &_material {
              width: 100%;
              font-size: 1.2rem;
            }
          }
        }

        &_input {
          border: none;
          font-size: 1.6rem;
          font-weight: 500;
          padding-left: 0.5rem;
        }
      }

      &_categories {
        width: auto;

        &_select {
          width: 100%;
          border: 1px solid #d2d2d2;
          border-radius: 0.5rem;
          padding: 1rem;
        }
      }
    }

    &_categoryMaterials {
      display: flex;
      flex-direction: column;

      &_empty {
        text-align: center;
        padding: 2rem;

        &_icon {
          margin-bottom: 1.6rem;
          color: rgb(156 163 175);
        }

        &_title {
          font-size: 1.8rem;
          font-weight: 500;
          color: rgb(17 24 39);
          margin-bottom: 1rem;
        }

        &_text {
          color: rgb(75 85 99);
          line-height: 1.5;
          margin-bottom: 2.4rem;
        }

        &_searchContainer {
          display: flex;
          gap: 2rem;

          @include respond(phone) {
            flex-direction: column;
            gap: 1rem;
          }
        }
        
          &_searchBtn {
            background-color: rgb(37 99 235);
            color: #fff;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            cursor: pointer;
            width: 100%;
            font-size: 1.6rem;
            font-weight: 500;
          }
      }

      &_list {
        list-style: none;
        list-style-position: inside;

        &_item {
          padding: 2rem;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #d2d2d2;
          gap: 2rem;

          &:last-child {
            border-bottom: none;
          }

          &_text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          &_material {
            font-size: 1.6rem;
            font-weight: 500;
            color: rgb(17 24 39);
          }

          &_category {
            font-size: 1.4rem;
            color: rgb(75 85 99);
          }

          &_price {
            font-size: 1.6rem;
            font-weight: 500;
            color: rgb(17 24 39);
          }

          &_buttonContainer {
            display: flex;
            gap: 1rem;

            @include respond(phone) {
              flex-direction: column;
            }

            &_estimacionDeCostos {
              align-items: center;
            }
          }

          &_quantity {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
          }

          &_button {
            background-color: rgb(37 99 235);
            color: #fff;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            font-size: 1.6rem;

            &_checked {
              background-color: var(--color-error);
            }
          }
        }
      }
    }
  }

  &_summaryContainer {
    grid-column: span 4 / span 4;

    @include respond(phone) {
      grid-column: span 12 / span 12;
    }
  }

  &_summary {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 0.75rem;
    box-shadow: var(--shadow);
    border: 1px solid var(--color-mainBorder);
    max-height: calc(-16rem + 100vh);
    overflow: hidden;

    &_stickyContainer {
      position: sticky;
      top: 8rem;
    }

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
     margin-bottom: 3rem; 
    }
    &_title {
      font-size: 1.8rem;
      color: rgb(17 24 39);
      font-weight: 500;
    }

    &_showPreviewBtn {
      background-color: transparent;
      border: none;
      color: rgb(37 99 235);
      padding: 0.6rem;
      border-radius: 0.5rem;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: rgb(239 246 255);
      }
    }

    &_info {
      display: flex;
      flex-direction: column;
      border-top: 1px solid #d2d2d2;
      overflow-y: auto;
      scrollbar-width: thin;

      &_category {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 1rem 0;
        border-bottom: 1px solid #d2d2d2;
        padding-bottom: 1rem;
        justify-content: space-between;
        

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        &_item {
          display: flex;
          justify-content: space-between;
          gap: 1rem;
        }

        &_title {
          color: rgb(17 24 39);
          font-size: 1.6rem;
          font-weight: 500;
          padding-top: 1rem;
        }

        &_text {
          font-size: 1.4rem;
          color: rgb(75 85 99);
        }

        &_actionsContainer {
          display: flex;
          flex-direction: column;
          height: 100%; 
          gap: 1rem;
        }

        &_actions {
          display: flex;
          gap: 1rem;
          
          align-items: center;

          &_removeBtn {
            background-color: transparent;
            color: var(--color-error);
            height: fit-content;
          }
        }

        &_quantityInput {
          width: 5rem;
          height: fit-content;
          padding: 0.75rem;
          border: 1px solid #ccc;
          border-radius: 0.75rem;
          text-align: center;
        }

        &_total {
          margin-top: 2rem;
          border-top: 1px solid #d2d2d2;
          padding-top: 1.6rem;
          display: flex;
          flex-direction: column;

          &_text {
            color: rgb(75 85 99);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 1rem;

          }

          &_price {
            font-size: 1.8rem;
            font-weight: 500;
            color: rgb(17 24 39);
          }

          &_disclaimer {
            font-size: 1.2rem;
            color: rgb(107 114 128);
            margin: 1rem 0;
            text-align: right;
          }

          &_materials {
            font-size: 1.6rem;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 2rem;
            color: rgb(75 85 99);
            font-weight: 500;
          }

        }
      }


    }

    &_empty {
      text-align: center;
      padding: 2rem;

      &_icon {
        margin-bottom: 1.6rem;
        color: rgb(156 163 175);
      }

      &_title {
        font-size: 1.6rem;
        font-weight: 500;
        color: rgb(17 24 39);
        margin-bottom: 1rem;
      }

    }
  }
}
