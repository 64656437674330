@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}

.img-container {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.main-user__image {
  background-color: rgb(249 250 251);
  border-radius: 50%;
  border: 2px solid #bfbfbf;
  width: 87px;
  height: 87px;
  margin: 0px 0 0px 0;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: inline-block;
   cursor: pointer;

   &:hover {
    border: 2px solid rgb(59 130 246);
   }

  @include respond(tab-port) {
    width: 87px;
    height: 87px;
  }
  @media (max-width: 1024px) {
    margin-right: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); 
    backdrop-filter: blur(2px); 
    opacity: 0;
    transition: opacity 0.3s ease; 
    border-radius: 50%; 
    z-index: 2; 
   
  }

  &::after {
    content: ""; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    opacity: 0; 
    transition: opacity 0.3s ease; 
    z-index: 3; 
  }

  &:hover {
    &::before {
      opacity: 1; 
    }

    &::after {
      opacity: 1; 
      
    }
  }
}

.camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3; 
  background-color: #fff;
  border-radius: 9999px;
  padding: 1rem;
  display: flex;
}

.cloud-upload {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 17px;
}

.main-user__image img {
  position: relative;
  top: 50%;
  right: 50%;
  margin: auto;
  transform: translate(50%, -50%);
  z-index: 1;
  width: 101%;
  height: 101%;
  margin: 0;
  padding: 0;
  border: none;
  line-height: normal;
  vertical-align: middle;
  object-fit: cover;
  transition: all 0.3s ease;
}

.lable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
}
.main-user__label {
  padding: 15px 5px 15px 30px;
  margin-left: 10px;
  color: #9b9b9b;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  display: block;
  position: relative;
  width: 190x;
  transition: all 0.3s;
  background-color: #ffffff;
  border: 1.5px solid #979797;
  border-radius: 7.5px;
  @include respond(tab-port) {
    display: none;
  }
  &.active {
    border: 1.5px solid #000;
    color: #000000;
  }
  &:hover label {
    background-color: #2d4ef5;
  }
}
.main-user__span {
  position: absolute;
  top: -22px;
  left: 0;
  width: 140px;
  text-align: left;
  color: #2f6aae;
  font-size: 15.5px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
.main-user__load {
  display: block;
  width: 49px;
  height: 49px;
  background: #9b9b9b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  @include respond(tab-port) {
    display: none;
  }
  & img {
    max-width: 30px;
  }
  &.active {
    background: #2d4ef5;
  }
}

.user-svg--absolute {
  position: absolute;
  width: 36.5px;
  height: 36.5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.main-user__input {
  width: 100%;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  opacity: 0;
  height: 100%;
  cursor: pointer;
}