.carousel {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 100%;
    margin: auto;
    border-radius: 18px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .slide {
        min-width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;

        &_imgContainer {
            position: relative;
        }


        &_img {
            height: 100%;
            border-radius: 18px;
            object-fit: cover;

        }


    }

    .indicators {
        position: absolute;
        bottom: 18px;
        display: flex;
        gap: 8px;

        .dot {
            width: 12px;
            height: 12px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.3s;

            &.active {
                background: white;
            }
        }
    }
}

.sliderWrapper {
    width: 100%;
    overflow: hidden;
    height: 100%;
    padding-top: 20px;
    border-radius: 18px;
    display: flex;
}

.slider {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.textContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    top: 0;
    padding: 20px;

    &_h2 {
        font-size: 34px;
        padding-bottom: 30px;
        line-height: 60px;
        text-align: center;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    &_p {
        font-size: 20px;
        max-width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        text-align: center;
        word-spacing: 1px;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
}