@use "../../utils/mediaQuerys" as *;;

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;

}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include respond(phone) {
        height: 100dvh;
        width: 100%;
    }

}

.containerBody {
    border-radius: 0.75rem;
    width: 600px;
    background-color: #fff;

    @include respond(phone) {
        max-width: 100vw;
        min-width: 100vw;
        border-radius: 0;
        overflow: auto;
        padding: 10px;
    }

    &_header {
        display: flex;
        gap: 10px;
        font-size: 2.4rem;
        align-items: center;

        &_title {
            font-weight: 600;
            width: 100%;
            display: flex;
            justify-content: space-between;
            text-align: left;
            border-bottom: 1px solid #EDF0F7;
            background-color: #fff;
            padding: 2rem;
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;

        }

        &_button {
            background: transparent;
            font-size: 18px;
            font-weight: 800;
            color: #2b3b5b;

            @include respond(phone) {
                font-size: 16px;
                padding-right: 10px;
            }
        }
    }

    &_form {
        padding: 2rem;
    }

    &_steps {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: 0.5rem;
    }

    &_step {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        font-size: 1.2rem;
        font-weight: 500;
        color: rgb(34 175 134 );
        cursor: pointer;
        transition: background-color 0.3s ease;

        @include respond(phone) {
            font-size: 1rem;
        }

        &:hover {
            background-color: rgb(230 246 241);
        }

        &_title {
            text-align: center;
        }

        &_icon {
            background-color: rgb(230 246 241);
            border-radius: 50%;
            padding: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &_stepActive {
        background-color: rgb(34 175 134);
        color: #fff;
    }

    &_stepActiveTitle {
        color: rgb(34 175 134);
        font-weight: 800;
    }

    &_progressBar {
        width: 100%;
        height: 5px;
        background-color: rgb(230 246 241);
        position: relative;

        &_fill {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: rgb(34 175 134);
            transition: width 0.3s ease;
        }
    }

    &_footer {
        display: flex;
        justify-content: space-between;
        padding-top: 3rem;

        &_buttonBack {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            font-size: 1.6rem;
            color: rgb(75 85 99);
        }

        &_buttonNext {
            padding: 0.8rem 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            background-color: rgb(34 175 134);
            transition: all 0.3s ease;
            color: #fff;
            gap: 0.5rem;
            font-size: 1.6rem;
            margin-left: auto;

            &:hover {
                background-color: rgb(27, 140, 107);
            }

        }

        &_buttonFinish {
            padding: 0.8rem 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            background-color: rgb(34 175 134);
            transition: all 0.3s ease;
            color: #fff;
            gap: 0.5rem;
            font-size: 1.6rem;

            &:hover {
                background-color: rgb(27, 140, 107);
            }

            &:disabled {
                background-color: #ebebeb;
                color: #959ba3;
                cursor: not-allowed;
            }
        }
    }

}

.body {
    max-height: 75vh;
    overflow: overlay;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &_formGroup {

        &_workZone {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            position: relative;

            &_inputsContainer {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
                position: relative;
            }

        }
    }

    &_item {


        &_title {
            padding: 10px 20px;
            border-radius: 12px;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            transition: background-color 0.3s ease;
            color: #2b3b5b;
            font-weight: 500;

            &:hover {
                background-color: #EDF0F7;

            }

            &.backgroundSelected {
                background: #EDF0F7;
            }

            &_arrow {
                display: flex;
                align-items: center;
            }

            &_txt {
                font-size: 16px;
                display: flex;
                color: #2b3b5b;
                font-weight: 800;
            }

            &_required {
                font-size: 16px;
                display: flex;
                padding-left: 3px;
                font-weight: 600;
                color: var(--color-error);
            }
        }

        &_workZoneContainer {
            position: relative;
            display: flex;
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
            border-radius: 5px;
            padding: 5px 10px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 1rem;

            &:focus-within {
                border: 1px solid #234ba3;
            }
        }

        &_workZoneInput {
            border: none;
            width: 100%;

            &.disabledInput {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
            }

            &::placeholder {
                color: #959ba3;

                @include respond(phone) {
                    font-size: 12px;
                }
            }
        }

        &_workZoneList {
            position: absolute;
            z-index: 1;
            background-color: #fff;
            border: 1px solid #ebebeb;
            border-radius: 5px;
            max-height: 150px;
            top: 100%;
            width: 100%;
            overflow-y: auto;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

            &_item {
                padding: 5px;
                cursor: pointer;
                transition: all 0.3s;
                font-size: 14px;
                text-align: left;
                border-bottom: 1px solid #ebebeb;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background-color: #f5f5f5;
                }

            }
        }

        &_workCityList {
            position: absolute;
            z-index: 1;
            background-color: #fff;
            border: 1px solid #ebebeb;
            border-radius: 5px;
            max-height: 150px;
            top: 100%;
            width: 100%;
            overflow-y: auto;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

            &_item {
                padding: 5px;
                cursor: pointer;
                transition: all 0.3s;
                font-size: 14px;
                text-align: left;
                border-bottom: 1px solid #ebebeb;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background-color: #f5f5f5;
                }

            }
        }

        &_workZoneArrowUp {
            cursor: pointer;
            transition: all 0.3s;
            transform: rotate(0deg);

            &.disabledInput {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
            }

        }

        &_workZoneArrowDown {
            cursor: pointer;
            transition: all 0.3s;
            transform: rotate(180deg);

            &.disabledInput {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        &_workZoneCityContainer {
            position: relative;
            display: flex;
            border-radius: 5px;
            padding: 5px 10px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

            &.disabledInput {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        &_workZoneSelected {
            display: flex;
            gap: 5px;
            padding-bottom: 10px;
            flex-wrap: wrap;

            &_item {
                background: #edf0f7;
                color: #2b3b5b;
                padding: 5px 10px;
                border-radius: 18px;
                display: flex;
                align-items: center;
                font-size: 12px;
                cursor: pointer;
                font-weight: 500;

            }
        }

        &_skillsSelected {
            display: flex;
            gap: 5px;
            padding-bottom: 10px;
            flex-wrap: wrap;

            &_item {
                background: #edf0f7;
                color: #2b3b5b;
                padding: 5px 10px;
                border-radius: 18px;
                display: flex;
                align-items: center;
                font-size: 12px;
                cursor: pointer;
                font-weight: 500;

            }

            &_list {
                position: absolute;
                z-index: 1;
                background-color: #fff;
                border: 1px solid #ebebeb;
                border-radius: 5px;
                max-height: 150px;
                top: 100%;
                overflow-y: auto;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
                width: 100%;
            }

            &_itemList {
                padding: 5px;
                cursor: pointer;
                transition: all 0.3s;
                font-size: 14px;
                text-align: left;
                border-bottom: 1px solid #ebebeb;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background-color: #f5f5f5;
                }

            }
        }

        &_dateContainer {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 500;

            &:last-child {
                padding-top: 10px;
            }

            &_start {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-top: 10px;
                font-size: 14px;
                font-weight: 400;
                padding-left: 10px;

            }

            &_exactDate {
                padding-top: 10px;
                padding-left: 10px;
                font-size: 14px;
                font-weight: 400;
                display: flex;
                justify-content: space-between;
            }

            &_endDate {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-top: 10px;
                font-size: 14px;
                font-weight: 400;
                padding-left: 10px;
            }

            &_input {
                position: relative;
                border: none;
                border-bottom: 1px solid #ebebeb;
                text-align: center;
            }
        }

        &_salaryOfferContainer {
            display: flex;
            flex-direction: column;
        }

        &_salaryOffer {
            display: flex;
            padding-top: 1rem;
            align-items: center;

            &_currency {
                font-size: 14px;
                font-weight: 500;
                padding-right: 10px;
                padding-left: 10px;
            }

            &_input {
                font-size: 14px;
                font-weight: 500;
                padding: 0.5rem;
                border: none;
                box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
                width: 100%;

                &::placeholder {
                    color: #959ba3;
                }

                @include respond(phone) {
                    width: 100%;
                    text-align: center;
                }
            }

            &_title {
                font-size: 14px;
                font-weight: 400;
                min-width: fit-content;
            }

            &_select {
                padding: 5px;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                background: #fff;
                border: none;
                border-radius: 5px;
                text-align: center;
                color: #000;

                &:focus {
                    border: 1px solid #000;
                }
            }

            &_option {
                padding: 5px;
                cursor: pointer;
                transition: all 0.3s;
                font-size: 14px;
                text-align: left;

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }

        &_description {
            padding-top: 1rem;

            &_textarea {
                width: 100%;
                border: none;
                border: 1px solid #ebebeb;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                padding: 10px;
                resize: none;
                margin-top: 1rem;
                min-height: 10rem;

                &::placeholder {
                    color: #959ba3;
                }
            }
        }

        &_contact {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        &_contactMethod {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: 500;

            &_title {
                font-size: 14px;
                font-weight: 500;
                min-width: fit-content;
            }

            &_select {
                border: none;
                padding: 5px;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                background: #fff;
                border: 1px solid #ebebeb;
                border-radius: 5px;
                color: #000;
                width: 200px;

                &:focus {
                    border: 1px solid #000;
                }
            }

            &_option {
                padding: 5px;
                cursor: pointer;
                transition: all 0.3s;
                font-size: 14px;
                text-align: center;

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }



        &_contactAlternative {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: 500;

            &_input {
                border: none;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                padding: 5px;
                text-align: center;
                width: 200px;
                box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

                &::placeholder {
                    color: #959ba3;
                }
            }

            &_label {
                width: 30%;
            }
        }

    }
}

.arrowIconRight {
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
}

.arrowIconDown {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.stepTitle {
    font-size: 1.4rem;
    font-weight: 600;
}