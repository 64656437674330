@use "../../../utils/mediaQuerys" as *;

@keyframes onSelected {
  from {
    height: 0;
  }
  to {
    height: 822px;
  }
}

.container {
  overflow-y: overlay;
    max-height: 300px;
    min-width: 600px;
    max-width: 600px;
    background-color: #fff;
    box-shadow: 0px 10px 51px 7px rgba(0, 0, 179, 0.1);
    border-radius: 5px;
    position: absolute;
    top: 102%;
    right: 0px;

  @include respond(phone) {
    padding-top: 60px;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        overflow-y: hidden;
        position: unset;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #f7f7f7;
  } 
  .emptySolicitud {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: #2b3b5b;
    margin: 1rem 0;
    svg {
      margin-bottom: 1rem;
    }

    svg path {
      fill: #2b3b5b;
    }
  }
}
.notifications {
  color: #234ba2;
  font-weight: 600;
  font-size: 20px;
  padding: 20px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  
  @include respond(phone) {
    padding: 10px;
    font-size: 16px;
  }
}

.emptySolicitudContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 3rem 0;
}
