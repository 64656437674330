.smallWorkerMapItem {
    min-width: 40%;
    max-width: 40%;
    display: flex;
    height: 100%;
    padding: 2rem;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #e0e0e0;
}

.title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    &_close {
        cursor: pointer;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        align-items: center;
        background-color: #fff;
        font-size: 15px;
        font-weight: 700;
    }
}

.carouselWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.workerCards {
    display: flex;
}

.dotsWrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-top: 2rem;
    height: 100%;
    align-items: flex-end;
    flex: 1;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.activeDot {
    background-color: #007bff;
}

.carouselContainer {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
}

.workerCard {
    flex: 0 0 100%; 
}


.workerCard {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 8px 10px -6px #0000001a;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    scrollbar-width: thin;
    transition: all .3s ease;
    width: 100%;

    &:hover {
        border: 1px solid #007bff;
    }

    &_name {
        font-weight: bold;
        color: #333;

    }

    &_item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        span {
            display: flex;
            text-align: left;
        }

        .iconButton {
            cursor: pointer;
            margin-right: 5px;
            color: #4a4a4a;
            min-width: 1.6rem;
            min-height: 1.6rem;


            &:hover {
                color: #007bff;
            }
        }
    }

    &_itemNoIcon {
        margin-top: 1.5rem;
        color: #555;
        flex: 1;

        &_title {
            font-weight: 600;
            color: #333;
            display: flex;
        }

        &_skill {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding-top: 0.5rem;
        }

        &_skillName {
            background-color: rgb(219 234 254);
            color: rgb(30 64 175);
            padding: 2px 5px;
            border-radius: 3rem;
            font-weight: 400;
        }
    }
}

.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    gap: 4rem;
}

.arrowButton {
    background: #007bff;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
}