@use "../../utils/mediaQuerys" as *;;

.newProject {
    gap: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include respond(phone) {
        min-width: 100%;
        max-width: 100%;
        border-radius: 0;
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    &_header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 2rem 0;
        border-bottom: 1px solid var(--color-mainBorder);
        background-color: #fff;

        @include respond(phone) {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            padding: 10px;
        }

        &_txtContainer {
            display: flex;
            font-size: 1.4rem;
            font-weight: 600;
            flex-direction: column;
            color: #7a7f85;
            text-align: left;

            @include respond(phone) {
                font-size: 14px;
            }

            &_title {
                font-weight: 600;
                font-size: 2rem;
                text-align: left;
                color: #333;
            }
        }
    }

    &_container {
        border: 1px solid var(--color-buttonBorder);
        background-color: #fff;
        padding: 2rem;
        border-radius: 0.75rem;

        @include respond(phone) {
            min-width: 0;
            height: 100%;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

    }

    &_containerSection {

        display: flex;

        &_hiden {
            display: none;
        }

        &_title {
            color: #2b3b5b;
            font-weight: 800;
            font-size: 11px;
            padding-bottom: 10px;
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #ebebeb;

            display: flex;
            align-items: center;
            grid-gap: 10px;
            gap: 10px;

            &_count {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #2b3b5b;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &_buttonContainer {
        display: flex;
        padding-top: 10px;
        border-top: 1px solid #ebebeb;
        gap: 20px;

        &.notShown {
            display: none;
        }
    }

    &_buttonDelete {
        display: flex;
        justify-content: center;
        width: 100%;

        &_button {
            background-color: var(--color-error);
            color: #fff;
            width: 100%;
            transition: all 0.3s ease-in-out;
            border-radius: 5px;
            font-size: 1.6rem;
            font-weight: 600;
            padding: 1.6rem 2.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;

        }
    }

    &_button {
        width: 100%;
        background: rgb(22 163 74);
        color: #ffffff;
        border-radius: 0.75rem;
        font-size: 1.6rem;
        font-weight: 500;
        padding: 1.6rem 2.4rem;
        cursor: pointer;


        svg path {
            fill: #ffffff;
        }

        &.disabled {
            background: #889abe;
            color: #fff;

            border: none;
            cursor: not-allowed;

            svg {
                display: none;
            }
        }
    }

    .lottie {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
    }
}

.close {
    color: #889abe;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    display: flex;
}

.details {
    margin-right: 5px;
}

.contentStyle {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    &_disabled {
        background: #e5e5e5;
        color: #888989;
        cursor: not-allowed;
    }

    svg path {
        fill: #fff;
    }

}

.rubrosIcon {
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.borderBottom {
    height: 0;
    margin-bottom: 20px;
    border: 1px solid #EDF0F7;
    z-index: 200;
}

.pasosTitle {
    font-size: 20px;
    font-weight: 700;
    color: #234BA2;
    margin-bottom: 10px;

    &_aclaracion {
        font-style: italic;
        font-size: 12px;
        font-weight: 400;
        color: #888989;
        display: inline;
        margin-left: 10px;
    }

    &_borderBottom {
        height: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 1px solid #EDF0F7;
        z-index: 200;
    }
}

.hiden {
    visibility: hidden;
}

.cancel {
    display: flex;
}