@use "../../../../../utils/mediaQuerys" as *;
.container {
  background: #fff;
  border-radius: 0.75rem;
  width: 60rem;
  height: 50rem;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;

  @include respond(phone) {
    min-width: 90vw;
    max-width: 90vw;
  }

  &_header {
    width: 100%;
    display: flex;
    padding: 1.6rem 2.4rem;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #edf0f7;
    font-size: 2rem;

    &_cancel {
      cursor: pointer;
      display: flex;
      color: rgb(107 114 128);

    }

    &_txt {
      display: flex;
      align-items: center;
    }
  }

  &_image {
    width: 100%;
    min-height: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f8f9;
    border: 1px solid hsl(0, 0%, 92%);
    border-radius: 7px;
    transition: height .5s;

    img {
      width: 100%;
      border-radius: 7px;
      object-fit: cover;
      height: 165px;

    }

    .container_animation {
      overflow: hidden;
    }

    &_empty {
      color: #727272;
      font-size: 13px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 17px;
        height: 17px;
        margin-right: 1rem;
      }
    }
  }

  &_footer {
    display: flex;
    justify-content: flex-end;
    padding: 1.6rem 2.4rem;
    gap: 1.6rem;
    border-top: 1px solid #edf0f7;
  }

  &_cancel {
    background-color: #fff;
    border: 1px solid rgb(209 213 219);
    border-radius: 0.75rem;
    padding: 0.8rem 1.6rem;
  }
  &_submit {
    color: #fff;
    border-radius: 0.7rem;
    font-weight: 600;
    background-color: rgb(37 99 235 );
    padding: 0.8rem 1.6rem;
    cursor: pointer;

    &.disabled {
      background-color: #ebebeb;
      border: 2px solid #ebebeb;
      cursor: not-allowed;
      color: #727272;
    }

  }
}

.newPortfolioModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &_overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.messageError {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 10px;
  color: #d6174a;
  padding: 0.3rem 2rem;
}