$resumen-bg-color: #f0f0f0;
$loader-light-color: #f7f7f7;
$loader-dark-color: #e0e0e0;

.resumenSkeleton {
  width: 100%;
  padding: 20px;
  background-color: $resumen-bg-color;
  border-radius: 8px;
  height: 500px;
  display: flex;
  flex-direction: column;
  max-width: 700px;

  &__header,
  &__body {
    background: linear-gradient(
      90deg,
      $loader-dark-color 25%,
      $loader-light-color 50%,
      $loader-dark-color 75%
    );
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1.5s infinite ease-in-out;
    margin-bottom: 10px;
  }

  &__header {
    height: 80px;
    width: 80px;
    margin-bottom: 16px;
    align-self: center;
    border-radius: 50%;
  }

  &__body {
    height: 200px;
    width: 100%;
  }
}

// Shimmer animation
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}