.container {
    background-color: rgb(249 250 251 );
    padding: 1.6rem;
    border-radius: 0.75rem;
    gap: 1rem;
    display: flex;
}

.header {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

    &_title {
        color: rgb(17 24 39);
        font-size: 1.6rem;
    }

    &_icon {
        margin-top: 0.2rem;
        color: rgb(37 99 235);
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
    }
}

.body {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    line-height: 1.5;

    &_text {
        color: rgb(75 85 99);
    }

}

.footer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
    color: rgb(37 99 235);
}