.container {
    display: flex;
    flex-direction: column;

    &_noDocus {
        width: 100%;
        border: 1px solid rgb(253, 230, 138);
        border-radius: 0.75rem;
        background-color: rgb(255, 251, 235);
        padding: 1.6rem;
        display: flex;
        gap: 1rem;
        color: rgb(180, 83, 9);

        &_icon {
            color: rgb(245, 158, 11);
            min-width: 20px;
            min-height: 20px;
            max-width: 20px;
            max-height: 20px;
        }
    }

    &_body {
        display: flex;
        gap: 1rem;
        flex-direction: column;
       

        &_item {
            display: flex;
            justify-content: space-between;
            width: 100%;
             padding: 2rem;
        background-color: rgb(249 250 251);
        border-radius: 0.75rem;

            &_label {
                display: flex;
                gap: 1rem;
                color: rgb(17 24 39);
                font-size: 1.6rem;
                font-weight: 500;
            }

            &_icon {
                color: rgb(37 99 235);
                gap: 1rem;
                display: flex;
                align-items: center;

                &.forbidden {
                    color: rgb(180 83 9);
                    pointer-events: none;
                }
            }
        }
    }

    &_budget {
        margin: 2rem 0;

        &_accessRequestBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(253, 230, 138);
            padding: 1.6rem;
            border-radius: 0.75rem;
            color: #fff;
            width: 100%;
            font-size: 1.6rem;
            font-weight: 500;
            gap: 1rem;
        }

        &_acceptedBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(37 99 235);
        padding: 1.6rem;
        border-radius: 0.75rem;
        color: #fff;
        width: 100%;
        font-size: 1.6rem;
        font-weight: 500;
        gap: 1rem;
        }

        &_sendBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(253, 230, 138);
        padding: 1.6rem;
        border-radius: 0.75rem;
        color: #fff;
        width: 100%;
        font-size: 1.6rem;
        font-weight: 500;
        gap: 1rem;
        }

        &_sentBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(37 99 235);
        padding: 1.6rem;
        border-radius: 0.75rem;
        color: #fff;
        width: 100%;
        font-size: 1.6rem;
        font-weight: 500;
        gap: 1rem;
        }

        &_deniedBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(255 251 235);
        padding: 1.6rem;
        border-radius: 0.75rem;
        color: rgb(180 83 9);
        width: 100%;
        font-size: 1.6rem;
        font-weight: 500;
        gap: 1rem;

        }

        &_sendBudget {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &_inputContainer {
                display: flex;
                flex-direction: column;
                width: 100%;

                &_header {
                    display: flex;
                    gap: 1rem;
                    flex-direction: column;
                    width: 100%;
                    gap: 1rem;
                    margin-bottom: 2rem;

                    &_title {
                        color: rgb(17 24 39);
                        font-size: 1.6rem;
                        font-weight: 500;
                    }

                    &_sub {
                        color: rgb(107 114 128);
                        font-weight: 400;
                        line-height: 1.5;
                    }
                }
            }

            &_label {
                border: 2px rgb(209 213 219 );
                border-style: dashed;
                padding: 3rem;
                border-radius: 0.75rem;
                display: flex;
                gap: 1rem;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                flex-direction: column;
                color: rgb(107 114 128);

                &_sub {
                    color: rgb(37 99 235);
                    font-weight: 500;
                }
            }

            &_file {
                border: 1px solid rgb(209 213 219);
                border-radius: 0.75rem;
                padding: 1.6rem;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &_itemContainer {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    color: rgb(17 24 39);
                    font-size: 1.6rem;
                    font-weight: 500;
                }

                &_icon {
                    background-color: rgb(239 246 255);
                    padding: 1rem;
                    border-radius: 0.75rem;
                    color: rgb(37 99 235);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &_delete {
                    background-color: transparent;
                }
            }
        }

    }
}