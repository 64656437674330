.container {
    width: 100%;
    padding: 20px;
    position: relative;
    transition: background-color 0.3s;

    &:hover {
        background-color: #f5f5f5;
    }

    &_isViewed {
        cursor: pointer;

        &_checked {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #3f66b3;
        }
    }

    &_header {
        display: flex;
        width: 100%;
        justify-content: space-between;

        &_title {
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;

            &_txt {
                margin-left: 10px;
                color: #2b3b5b;
            }
        }

        svg path {
            stroke: #3f66b3;
        }

        svg {
            min-width: 20px;
            max-width: 20px;
            min-height: 20px;
            max-height: 20px;
        }

    }

    &_body {
        display: flex;
        padding: 10px;
        align-items: center;

        &_image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 20px;
            border: 4px solid #ebebeb;
            position: relative;

            &_checked {
                width: 15px;
                height: 15px;
                position: absolute;
                bottom: -5px;
                right: -5px;
            }

            &_img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        &_message {
            display: flex;
            flex-direction: column;
            width: calc(100% - 70px);

            &_txt {
                display: inline;
                font-size: 12px;
                font-weight: 500;

                color: #2b3b5b;
            }

            &_user {
                cursor: pointer;
                font-size: 12px;
                font-weight: 700;
                color: #2b3b5b;
            }

            &_subTitle {
                font-size: 14px;
                font-weight: 500;
                display: flex;
            }

            &_buttons {
                display: flex;
                align-items: center;
                width: 100%;
                padding-top: 10px;
                font-size: 14px;
                justify-content: flex-end;
                font-weight: 500;

                &_icon {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
    }

    &_buttons {
        display: flex;
        align-items: center;
        gap: 10px;

        &_trash {
            display: flex;
            cursor: pointer;

            svg path {
                stroke: #000;
            }
        }
    }
}