.leaflet-div-icon {
    background: none;
    border: none;
}

.leaflet-popup-content-wrapper {
    border-left: 10px solid rgb(30 64 175);
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.leaflet-popup-tip-container {
    display: none;
}

.leaflet-popup-content {
    font-size: 13px;
    color: #333;
    font-weight: 500;
}

.leaflet-popup-close-button {
    color: #333;
    font-size: 16px;
}

.infoIcon {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.workerInfo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.workersInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;
}

.workersInfo__icon {
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(30 64 175);
    margin-right: 1rem;
}

.infoText {
    font-size: 1.3rem;
    color: #333;
    font-weight: 400;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.infoText__header {
    color: #333;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;

}

.infoText__header__city {
    color: #333;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 1.4rem;
}

.infoText__name {
    color: #333;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;
    font-size: 1.4rem;
}

.infoText__skill__container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;

}

.infoText__skill {
    background-color: rgb(219 234 254);
    color: rgb(30 64 175);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 0.5rem;
    border-radius: 3rem;
    margin-right: 0.5rem;
    width: fit-content;
    gap: 0.5rem;
}

.infoText__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0;
    margin-bottom: 0;
}

.infoText__seeMore {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}