@use "../../../../utils/mediaQuerys" as *;

.mainContainer {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 100%;
    padding: 20px;

    @include respond(phone) {
        padding-top: 6rem;
        padding-left: 0;
        padding-right: 0;
    }

    @include respond(tab-port, true) {
        padding-top: 8rem;
    }
}

.sectionWrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;

    @include respond(phone) {
        display: flex;
        flex-direction: column;
    }

}

.cards {

    @include respond(phone) {
        max-height: 100%;
        height: 100%;
        margin-top: 10px;
    }


    &_questions {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include respond(phone) {
            margin-left: 0;
            margin-top: 20px;

        }

        &_title {
            display: flex;
            padding-bottom: 1rem;
            justify-content: space-between;
            align-items: center;

            &_count {
                background-color: rgb(249 250 251);
                color: rgb(107 114 128);
                border-radius: 50%;
                padding: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.6rem;
                font-weight: 500;
            }

        }

        &_empty {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 2rem;
            text-align: center;
            align-items: center;

            &_icon {
                background-color: rgb(243, 244, 246);
                width: 6rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgb(156 163 175);
                height: 6rem;
                border-radius: 50%;
            }

            &_text {
                color: rgb(107 114 128);
                font-size: 1.6rem;
            }
        }

        &_answer {
            margin-top: 5px;
            font-style: italic;
            color: #555;
            border-top: 1px solid #ddd;
            padding-top: 10px;
        }

        &_container {
            display: flex;
            flex-direction: column;
            overflow: overlay;
            background-color: #fff;
            border-radius: 6px;
            border: 1px solid #edf0f7;
            box-shadow: var(--shadow);
            padding: 1.6rem;

            @include respond(phone) {
                max-height: 300px;
            }

            &_question {
                display: flex;
                flex-direction: column;
                border: 1px solid rgb(243 244 246);
                border-radius: 0.75rem;

                &_title {
                    font-weight: 500;
                    padding: 1rem;
                    background-color: rgb(249 250 251);
                }

                &_answer {
                    padding: 1rem;
                    background-color: #fff;
                }

                &_answerButtonContainer {
                    display: flex;
                    justify-content: space-between;
                    padding: 1rem;
                    background-color: rgb(249 250 251);
                }

                &_answerButton {
                    background-color: transparent;
                    color: rgb(37 99 235);
                    font-weight: 500;
                    border: none;
                }

                &_noAnswer {
                    display: flex;
                    gap: 0.5rem;
                    color: rgb(107 114 128);
                }

                &_answerEmpty {
                    display: flex;
                    gap: 0.5rem;
                    background-color: rgb(249 250 251);
                    color: rgb(107 114 128);
                    padding: 1rem;
                }

                &_input {
                    width: 100%;
                    padding: 1rem;
                    border: 1px solid rgb(229 231 235);
                    border-radius: 0.75rem;
                    margin-top: 1rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;

                    &_placeholder {
                        color: rgb(107 114 128);
                    }
                }
            }
        }

        &_button {
            background-color: transparent;
            color: rgb(37 99 235);
            font-weight: 500;
            border: none;
            cursor: pointer;
        }
    }

    &_datos {
        grid-column: span 2 / span 2;
        gap: 20px;

        @include respond(phone) {
            flex-direction: column;
            max-height: 100%;
            margin-top: 20px;
            height: 100%;
        }

        &_title {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 10px;
            display: flex;

            @include respond(phone) {
                font-size: 16px;
                text-align: center;
            }
        }

        &_imageContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;
            border-radius: 6px;
            aspect-ratio: 16/9;
            border: 1px solid #edf0f7;
            box-shadow: var(--shadow);


            &_image {
                width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }

        &_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            background-color: #fff;
            border-radius: 6px;
            border: 1px solid #edf0f7;

            &_header {
                padding: 2rem;
                border-bottom: 1px solid rgb(229, 231, 235);

                &_items {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 1rem;
                    gap: 2rem;
                }

                &_item {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    &_label {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        color: rgb(107, 114, 128);
                    }

                    &_value {
                        color: rgb(17, 24, 39);
                        font-size: 1.6rem;
                        font-weight: 500;
                    }

                    &_jobContainer {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 0.5rem;

                    }

                    &_job {
                        background-color: rgb(219, 234, 254);
                        color: rgb(30, 64, 175);
                        padding: 0.2rem 1rem;
                        border-radius: 3rem;
                        font-size: 1.2rem;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
                }
            }

            &_body {
                display: flex;
                width: 100%;
                flex-direction: column;
                padding: 2rem;

                @include respond(phone) {
                    gap: 5px;
                }
            }

            &_description {
                display: flex;
                flex-grow: 1;
                font-size: 16px;
                font-weight: 500;

                @include respond(phone) {
                    min-height: 100px;
                }
            }

            &_item {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                color: rgb(107 114 128);
                width: 100%;
                margin-bottom: 1rem;

                &_label {
                    display: flex;
                    font-weight: 700;
                    opacity: 0.6;
                    font-size: 14px;
                    gap: 10px;

                    @include respond(phone) {
                        font-size: 12px;
                        gap: 5px;
                    }

                    &_icon {
                        width: 20px;
                        height: 20px;

                        @include respond(phone) {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

                &_value {
                    font-size: 16px;
                    font-weight: 800;
                    padding-top: 10px;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    width: 100%;

                    @include respond(phone) {
                        font-size: 14px;
                    }

                }
            }

            &_documentation {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 2rem;
                padding: 2rem;
                background-color: #fff;
                border-radius: 6px;
                border: 1px solid #edf0f7;

                &_items {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }

                &_item {
                    background-color: rgb(249 250 251);
                    padding: 1.6rem;
                    border-radius: 0.5rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &_label {
                        display: flex;
                        gap: 0.5rem;
                        align-items: center;

                        &_icon {
                            background-color: rgb(219 234 254);
                            padding: 1rem;
                            border-radius: 0.5rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: rgb(37 99 235);
                        }
                    }

                    &_icon {
                        cursor: pointer;
                    }

                    &_docus {
                        &_title {
                            color: rgb(17 24 39);
                            font-size: 1.6rem;
                            margin-top: 2rem;
                        }

                        &_info {
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                        }

                        &_dates {
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                            background-color: rgb(239 246 255);
                            border: 1px solid rgb(219 234 254);
                            border-radius: 0.75rem;
                            padding: 1.6rem;
                            margin-top: 2rem;

                            &_title {
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;
                                color: rgb(107, 114, 128);
                            }

                            &_container {
                                display: flex;
                                align-items: center;
                                gap: 2rem;
                                flex-wrap: wrap;

                                @include respond(phone) {
                                    flex-direction: column;
                                    gap: 1rem;
                                    align-items: flex-start;
                                }
                            }
                        }

                        &_divider {
                            background-color: rgb(209, 213, 219);
                            display: flex;
                            align-self: center;
                            width: 0.1rem;
                            height: 2rem;

                            @include respond(phone) {
                                display: none;
                            }
                        }

                        &_dateItem {
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                        }

                        &_date {
                            color: rgb(17, 24, 39);
                            font-size: 1.6rem;
                            font-weight: 500;
                        }

                        &_daysLeft {
                            background-color: rgb(219 234 254);
                            padding: 0.6rem 1.2rem;
                            border-radius: 3rem;
                            color: rgb(37 99 235);
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 1.2rem;
                            gap: 0.5rem;
                            width: max-content;
                        }
                    }
                }
            }
        }
    }

    &_user {
        border-radius: 0.75rem;
        padding: 1.6rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid #edf0f7;
        background-color: #fff;

        &_title {
            font-size: 18px;
            padding: 20px;
            display: flex;
            color: rgb(107 114 128);
            line-height: 1.5;
        }

        &_location {
            padding: 2rem 0;
            border-bottom: 1px solid rgb(229 231 235);

            &_label {
                display: flex;
                gap: 0.5rem;
                color: rgb(107 114 128);
                margin-bottom: 1rem;
            }

            &_value {
                color: rgb(17 24 39);
                font-size: 1.6rem;
                font-weight: 500;
            }
        }

        &_dates {
            display: flex;
            flex-direction: column;
            line-height: 1.5;

            &_date {
                color: rgb(17 24 39);
                font-size: 1.6rem;
                font-weight: 500;
            }

            &_dateEnd {
                margin-top: 1.5rem;
            }
        }

        &_status {

            margin-top: 2rem;

            &_title {
                padding: 10px 20px;
                background: transparent;
                border-radius: 5px;
                color: #234ba2;
                border: 1px solid #234ba2;
                cursor: pointer;
                font-size: 1.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                font-weight: 500;
                transition: all 0.3s ease;

                svg path {
                    stroke: #234ba2;
                }

                &:hover {
                    background: #234ba2;
                    color: #fff;

                    svg path {
                        stroke: #fff;
                    }
                }


                @include respond(phone) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 5px 10px;
                    font-size: 14px;
                }

                &.sent {
                    background: rgb(255 251 235);
                    border: 1px solid rgb(253 230 138);
                    color: rgb(180 83 9);
                    pointer-events: none;

                    svg path {
                        stroke: rgb(180 83 9);
                    }
                }

                &.allowed {
                    border: 1px solid #4CE2A7;
                    color: #4CE2A7;
                    background: #d3ffe4;

                    svg path {
                        stroke: #4CE2A7;
                    }
                }

                &.denied {
                    background: #ffd9e5;
                    color: #ff3d71;
                    border: 1px solid #ff3d71;

                    svg path {
                        stroke: #ff3d71;
                    }
                }
            }
        }

        &_container {
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            cursor: pointer;
            border-bottom: 1px solid rgb(229 231 235);

            &_user {
                display: flex;
                align-items: center;

                &_socialNetworksContainer {
                    display: flex;
                    flex-direction: column;

                    &_icons {
                        padding-top: 1rem;
                        display: flex;
                    }

                    &_item {
                        display: flex;
                        margin-right: 10px;
                        padding: 10px;
                        border-radius: 50%;
                        background: #EDF0F7;
                        align-items: center;

                        &_label {
                            display: flex;

                            svg path {
                                fill: #889ABE;
                            }
                        }
                    }

                    &_title {
                        padding: 10px;
                        color: #2b3b5b;
                        font-size: 16px;
                    }
                }


                &_image {
                    min-width: 48px;
                    min-height: 48px;
                    max-width: 48px;
                    max-height: 48px;
                    object-fit: cover;
                    margin-right: 1rem;
                    border-radius: 50%;

                }

                &_name {
                    color: rgb(17 24 39);
                    font-weight: 500;
                    font-size: 1.6rem;
                }
            }


            &_label {
                font-weight: 600;
                margin-right: 10px;
            }


            &_value {
                color: #2b3b5b;
                font-weight: 400;
                font-size: 11px;
            }

        }
    }

}

.postulationModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    &_header {
        position: absolute;
        top: 15px;
        right: 20px;

        &_close {
            background-color: transparent;
            color: #000;
            font-size: 20px;
            font-weight: 700;
        }


    }

    &_body {
        background: #fff;
        border-radius: 6px;
        padding: 20px;
        width: 100%;
        max-width: 500px;
        overflow: auto;
        position: relative;

        &_title {
            font-weight: 800;
            font-size: 20px;
            text-align: center;
            padding-bottom: 20px;
            border-bottom: 1px solid #ebebeb;
            margin-bottom: 10px;
        }

        &_imageContainer {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        &_txt {
            font-size: 16px;
            padding-bottom: 20px;
            text-align: center;
            padding-top: 20px;
            font-weight: 600;
        }

    }
}

.editBackDrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    @include respond(phone) {
        align-items: baseline;
    }

}

.arrowIconUp {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.arrowIconDown {
    transform: rotate(-180deg);
    transition: transform 0.3s ease;
}