.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 90%;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 12px;

  h2 {
    font-size: 18px;
    margin: 0;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #3b82f6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;

  &:hover {
    color: #374151;
  }
}

.modalContent {
  margin-bottom: 24px;
}

.formGroup {
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }

  select, textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 14px;
    resize: none;

    &:focus {
      outline: none;
      border-color: #3b82f6;
      box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
    }

    &:disabled {
      background-color: #f3f4f6;
      cursor: not-allowed;
    }
  }

  textarea {
    min-height: 100px;
    resize: vertical;
    resize: none;
  }
}

.selectedCategories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.categoryTag {
  background-color: #e5e7eb;
  color: #374151;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    color: #6b7280;

    &:hover {
      color: #374151;
    }
  }
}

.starRating {
  display: flex;
  gap: 4px;

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.star {
  width: 24px;
  height: 24px;
  color: #d1d5db;

  &.active {
    color: #fbbf24;
    fill: #fbbf24;
  }

  &.disabled {
    color: #e5e7eb;
  }
}

.submitButton {
  width: 100%;
  padding: 10px 16px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover:not(:disabled) {
    background-color: #2563eb;
  }

  &:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
  }
}

@media (max-width: 640px) {
  .modal {
    padding: 16px;
  }

  .userInfo {
    h2 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  .avatar {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }

  .formGroup {
    label {
      font-size: 12px;
    }

    select, textarea {
      font-size: 12px;
    }
  }

  .star {
    width: 20px;
    height: 20px;
  }

  .submitButton {
    font-size: 14px;
  }
}