@use "../../../utils/mediaQuerys" as *;

.container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.75rem;
    border: 1px solid #e5e7eb;
    transition: all 0.3s;

    &:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgb(37, 99, 235);
    }

    &_image {
        width: 100%;
        display: flex;
        position: relative;

        &_img {
            min-width: 100%;
            max-width: 100%;
            min-height: 200px;
            max-height: 200px;
            object-fit: cover;
            border-radius: 0.75rem;
            cursor: pointer;

            @include respond(phone) {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
    }

    &_info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &_text {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            &_icons {
                display: flex;
                gap: 10px;
                margin-top: 5px;
                padding-left: 10px;
                align-items: center;
                margin-bottom: 10px;

                &_backGround {
                    background-color: #edf0f7;
                    border-radius: 50%;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            &_price {
                font-size: 16px;
                font-weight: 600;
            }

            &_pill {
                margin-top: 10px;
                margin-left: 10px;
                background-color: #234ba2;
                border-radius: 30px;
                width: 50px;
                height: 10px;
                margin-bottom: 10px;
            }

            &_title {
                font-size: 20px;
                font-weight: 700;
                padding: 0px 10px;

            }

            &_description {
                font-size: 14px;
                padding: 0px 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
                opacity: 0.8;
                font-weight: 500;
                border: none;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                white-space: normal;
                margin-bottom: 1.6rem;
            }
        }

        &_location {
            position: absolute;
            background-color: #234ba2;
            bottom: 0;
            right: 0;
            color: #fff;
            padding: 5px 20px;
            font-size: 14px;
            font-weight: 600;
        }

        &_dates {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: #234ba2;
            color: #fff;
            border-radius: 30px;
            padding: 5px 20px;
            font-size: 14px;
            font-weight: 600;
        }

        &_footer {
            display: flex;
            opacity: 0.6;

            &_clock {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 12px;
                font-weight: 600;
            }

            &_comments {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 12px;
                color: rgb(107 114 128);
            }
        }
    }

    &_buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        @include respond(phone) {
            padding: 10px;
            justify-content: center;
            border-top: 1px solid #ebebeb;
            border-left: none;
        }

        &_delete {
            position: absolute;
            right: 1rem;
            color: #fff;
            padding: 1rem;
            top: 1rem;
            border-radius: 9999px;
            background-color: #234ba2;
            display: flex;
            align-items: center;
            transition: all 0.3s;

            &:hover {
                background-color: #3f66b3;
            }
        }

        &_questions {
            padding: 10px;
            display: flex;

            &_answerBtn {
                background-color: rgb(37 99 235);
                padding: 0.4rem 1.2rem;
                color: #fff;
                border-radius: 0.7rem;
                position: relative;

                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                &_number {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    background-color: var(--color-error);
                    color: #fff;
                    padding: 0.5rem;
                    width: 2rem;
                    height: 2rem;
                    font-size: 1.2rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &_btn {
                width: 100%;
                background-color: #234ba2;
                padding: 5px 10px;
                color: #fff;
                border-radius: 5px;
            }
        }

        &_title {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 1rem;
            justify-content: space-between;
        }

        &_users {
            display: flex;
            width: 100%;

            @include respond(phone) {
                position: relative;
                bottom: auto;
            }

        }

        &_btn {
            width: 100%;
            background-color: #234ba2;
            padding: 5px 10px;
            color: #fff;

            @include respond(phone) {
                margin-top: 10px;
                border-radius: 5px;
            }

            &:nth-child(2) {
                border-bottom-right-radius: 5px;

            }
        }
    }
}