@use "../../../utils/mediaQuerys" as *;

.sectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .formSubtitle {
    color: rgb(107 114 128 );
    font-size: 1.6rem;
    margin-top: 0.8rem;
    margin-bottom: 3.2rem;
    text-align: center;
  }

  .mainContainer {
    position: relative;
  }

  .infoContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .dataContainer {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 3rem;

    &_imageWrapper {
      border-radius: 0.75rem;
      overflow: hidden;
      width: 9rem;
      height: 9rem;

      &_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
    }

    .dataInfo {
      display: flex;
      flex: 1;
      flex-direction: column;

      &_contactData {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @include respond(phone) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          gap: 1rem;
        }

        &_item {
          display: flex;
          gap: 1rem;
          align-items: center;
          color:rgb(75 85 99 );
        }
      }
    }

    .dataTitle {
      color: rgb(17 24 39 );
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 1.6rem;
    }

    .projectContainer {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .dataContainerBig {
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-top: 2.4rem;

   &_items {
    margin-top: 1.2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
   }

   &_itemsLugares {
    margin-top: 1.2rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.2rem;

    @include respond(phone) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
   }

    .dataTitle {
      color: #707070;
      font-size: 17px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 5px;

      &_item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 600;
        border-radius: 0.75rem;
        padding: 0.4rem 1.2rem;
        font-size: 14px;
        color:  rgb(29 78 216);
        background-color: rgb(239 246 255);
      }
    }

    .projectContainer {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }


  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 3.2rem;
    padding-top: 2.4rem;
    border-top: 1px solid rgb(243 244 246 );

    .btn {
      padding: 0.8rem 3.2rem;
      color: var(--color-white);
      font-size: 1.6rem;
      font-weight: 600;
      align-items: center;
      display: flex;
      background-color: var(--color-mainBlue);
      border-radius: 0.75rem;
      cursor: pointer;
      justify-content: center;
      gap: 10px;
      transition: all 0.2s;

      &:hover {
        background-color: #003667;
      }

    }
    .btnBack {
      background-color: #fff;
      padding: 0.8rem 2.4rem;
      display: flex;
      font-size: 1.6rem;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: #f7f7f7;
        border-radius: 0.75rem;
      }
    }
  }
}

.iconWrapper {
  color: rgb(22, 163, 74);
  background-color: rgb(220, 252, 231);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  width: 8rem;
  height: 8rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.loginAnimation {
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-delay: 2.15s;
  opacity: 0;

  .resumenContainer {
    transition: width 1s;
    width: 100%;
    transition: width 1s;
    transition: opacity 0.5s;
    opacity: 0;
    pointer-events: none;

  }
}

.resumenContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  width: 100%;
  padding: 2% 5%;

  

  &_resumen {
    width: 700px;
    display: flex;
    flex-direction: column;
    border: 1px solid #edf0f7;
    padding: 4rem;
    border-radius: 0.75rem;
    background-color: #fff;

    .title {
      font-size: 2.4rem;
      line-height: 2;
    }
  }

}

.imagesWrapper {
  position: relative;
  width: 100%;
  margin: 2rem 0;

}

.sectionTitle {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 600;
  color: rgb(17 24 39 );
}

.confettiContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}