@use "../../../../utils/mediaQuerys" as *;

.PerfilMain {
  width: 100%;
  height: 100%;
  height: fit-content;

  &.visualizer {
    width: 100%;
  }

  &_section {
    width: 100%;
  }

  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &_heading {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 15px;
    border-bottom: 1px solid #ebebeb;

    &First {
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }

    &Second {
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }

    h2 {
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      color: #234BA2;
    }

    &_visualizer {
      h2 {
        color: #3f66b3;
      }
    }
  }

  &_headerContainer {
    display: flex;
    color: #003667;
    justify-content: space-between;
    min-width: 100%;
    font-size: 14px;
    background-color: #fff;
    margin-bottom: 2.4rem;

    @include respond(phone) {
      padding: 10px;
      flex-wrap: wrap ;
    }

    &_filterContainer {
      gap: 30px;
      display: flex;

      @include respond(phone) {
        flex-wrap: wrap;
        gap: 5px;
      }
    }

    &_filterByDate {
      display: flex;
      position: relative;



      &_label {
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 5px 10px;

        @include respond(phone) {
          font-size: 12px;
        }

        &.active {
          border-bottom: 1px solid #003667;
          border-left: 1px solid #003667;
          border-top: 1px solid #003667;
        }
      }
    }

    &_filterByRubro {
      display: flex;
      position: relative;

        &.active {
          border: 1px solid #003667;
          border-radius: 5px;
        }
      }


    &_filterByCalification {
      display: flex;
      position: relative;

        &.active {
          border: 1px solid #003667;
          border-radius: 5px;
        }
      }

      &_startReview {

        &_button {
          border: 1px solid #637bbd;
          border-radius: 5px;
          padding: 5px 20px;
          cursor: pointer;

          @include respond(phone) {
            font-size: 12px;
            padding: 5px 10px;
            margin-top: 5px;
          }

          svg path {
            fill: #637bbd;
          }

          svg {
            margin-left: 10px;
          }
        }
        
      }
  }
}

.customSelect {

  &_rubro {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 5px 10px;

    @include respond(phone) {
      font-size: 12px;
    }

    &.active {
      border: 1px solid #003667;
      border-radius: 5px;
    }
  }

  &_calification {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 5px 10px;

    @include respond(phone) {
      font-size: 12px;
    }

    &.active {
      border: 1px solid #003667;
      border-radius: 5px;
    }
  }

  &_date {
    border: 1px solid #ebebeb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 10px;

    @include respond(phone) {
      font-size: 12px;
    }

    &.active {
      border: 1px solid #003667;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: 1px solid #ebebeb;
    }
  }

  &_customSelectOptions {
    left: 0;
    top: 37px;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #003667;
    border-radius: 5px;

  }

  &_customSelectOption {
    padding: 5px;
    display: block;
    cursor: pointer;
    color: #003667;
    font-weight: 400;

    &:hover {
      background-color: #f1f1f1;
    }

    &.titleDate {
      font-weight: 700;
    }
  }

  &_customSelectTrigger {
    display: flex;
    align-items: baseline;
    justify-content: center;
    cursor: pointer;

    &_txt {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    svg {
      display: flex;
    }

    svg path {
      fill: #003667;
    }

  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
}

.empty {
  width: 524px;
}

.arrowDownIcon {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.arrowUpIcon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}