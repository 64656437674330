@use "../../../utils/mediaQuerys" as *;

.workersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin-top: 1rem;

    @include respond(phone) {
        gap: 10px;
    }
}

.workerCard {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    width: calc(50% - 10px);
    box-shadow: var(--shadow);
    transition: all 0.3s ease;

    @include respond(phone) {
        width: 100%;
    }

    &:hover {
        border: 1px solid #007bff;
    }

    &_name {
        margin: 0 0 10px 0;
        font-weight: bold;
        color: #333;

        @include respond(phone) {
            font-size: 16px;
        }
    }

    &_item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .iconButton {
            cursor: pointer;
            margin-right: 5px;
            color: #4a4a4a;
            min-width: 1.6rem;
            min-height: 1.6rem;


            &:hover {
                color: #007bff;
            }
        }
    }

    &_itemNoIcon {
        margin-top: 1.5rem;
        color: #555;
        font-size: 14px;

        @include respond(phone) {
            font-size: 12px;
        }

        &_title {
            font-weight: 600;
            color: #333;
        }

        &_skill {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding-top: 0.5rem;
        }

        &_skillName {
            background-color: rgb(219 234 254);
            color: rgb(30 64 175);
            padding: 2px 5px;
            border-radius: 3rem;
            font-weight: 400;
        }
    }
}