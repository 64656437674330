@use "../../../utils/mediaQuerys" as *;

.mainContainer {
  width: 100%;
}

.sectionWrapper {
  background-color: #fff;

  &_header {
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond(phone) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem;
    }

    &_title {
      font-size: 2rem;
      font-weight: 600;

    }

    &_button {
      background-color: rgb(37 99 235 );
      color: #fff;
      padding: 0.8rem 1.6rem;
      border-radius: 0.75rem;
      font-size: 1.6rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 5px;

    }
  }
}

.readyContainer {
  display: block;

}

.notReady {
  display: none;
}

.skeletonContainer {
  display: flex;
  padding: 4rem;
  background: #fff;
}