@use "../../utils/mediaQuerys" as *;;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .buttonContainer {
    margin: 20px 0;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 100px;
  }
}

.searchResults {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 10px 0;

  @include respond(phone) {
    overflow-x: overlay;
  }

  &_container {
    display: flex;
    flex-direction: column;
  }

  &_title {
    font-size: 16px;
    font-weight: 700;

    @include respond(phone) {
      font-size: 12px;
    }
  }

  &_subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #b3b3b3;

    @include respond(phone) {
      font-size: 10px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  margin-bottom: 10px;
}

.usersContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;

  @include respond(phone) {
    grid-template-columns: repeat(1, 1fr);
  }

  @include respond(tab-port, true) {
    grid-template-columns: repeat(2, 1fr);
  }

  &_row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &_rowThreeItems {
    display: flex;
    width: 100%;
  }


  &_conditionalStyles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
  }

  &_userCard {
    display: flex;
    width: calc(25% - 10px);
    flex-wrap: wrap;
    align-items: center;
    padding-top: 10px;
    transition: 0.5s ease;
    cursor: pointer;
    position: relative;
    justify-content: space-around;


    &:hover {
      box-shadow: 0px 5px 30px 5px rgba(0, 0, 0, 0.1);
    }

    &_threeItems {
      padding-right: 14px;
      display: flex;
      flex-direction: column;
      width: calc(25% - 10px);
      flex-wrap: wrap;
      padding-top: 10px;
      align-items: center;
      transition: 0.5s ease;
      cursor: pointer;
      position: relative;

      &:hover {
        box-shadow: 0px 5px 30px 5px rgba(0, 0, 0, 0.1);
      }
    }

    &_projectsNumber {
      display: flex;
      width: 100%;
      justify-content: center;

      &_number {
        font-size: 10px;
        font-weight: 500;
        color: rgba(0, 0, 0, .55);
        margin-right: 5px;
      }

      &_txt {
        font-size: 10px;
        font-weight: 600;
        color: rgba(0, 0, 0, .55);
      }
    }

    &_email {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.55);
    }

    &_reputation {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2px;
    }

    &_reputationNumber {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;

      p {
        margin-right: 5px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, .55);
      }

      &_star {
        display: flex;
        margin-right: 3px;
      }
    }

    &_overlay {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      opacity: 0;
      transition: 0.5s ease;
      cursor: pointer;
      position: absolute;
      background-color: #fff;

      &:hover {
        opacity: 0.9;
        border-radius: 5px;
      }

      &_txt {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 22px;
        font-weight: 800;
        color: rgba(0, 0, 0, .55);
      }


    }

    &_infoContainer {
      display: flex;
      padding: 10px 20px 10px 20px;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      text-align: center;
    }

    &_name {
      font-size: 12px;
      font-weight: 600;
      word-spacing: 2px;
      width: 100%;
      color: rgba(0, 0, 0, .55);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_imageContainer {
      display: flex;
      width: 100px;
      height: 90px;
      background: #fff;
      border-radius: 50%;
      border: 2px solid #ebebeb;
      justify-content: center;
      align-items: center;
      padding: 1px;

      &_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}

.AllProjects {
  margin: 0px 0px 10px 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;

}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 50px;

}

.activeFilter {
  color: var(--color-mainBlue);
  font-size: 14px;
  font-weight: 700;
  line-height: 13px;
  margin: 3rem;
}

.sectionDivider {
  display: flex;
  line-height: 24.59px;
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  margin-bottom: 12px;
  white-space: nowrap;
}

.divider {
  display: flex;
  width: 73%;
  border-bottom: 1px solid #b3b5ba;
  align-self: center;
  margin-left: 20px;
}

.filter {
  @include respond(tab-port) {
    display: none;
  }
}

.imageProfile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.skillListHeader {
  display: flex;
  margin-left: 10px;
  padding: 15px;
  cursor: auto;
}

.skillListHeader {
  display: flex;
  margin-left: 10px;
  padding: 15px;
  cursor: pointer;
}

.alternateRow {
  background-color: #fff;
  cursor: pointer;
}

.rowContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.userContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.field {
  background-color: #f7f8f9;
  border: 2px solid #ebebeb;
  width: 100%;
  height: 40px;
  font-size: 13px;
  padding-right: 5%;
  padding-left: 5%;
}

.resetButton {
  background-color: transparent;
  margin-right: 10px;
  font-size: 12px;
  color: #234ba2;
  font-weight: 800;
  cursor: pointer;
}

.filterSvg {

  margin-left: 20px;
  position: absolute;
  width: 20px;
  height: 20px;

  path {
    fill: lightgray;
  }
}

.imageContainer {
  display: flex;
}

.name {
  display: flex;
  font-size: 12px;
  font-weight: 700;
  align-items: center;
}

.buttonAddUserContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 30px;
}

.buttonAddUser {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  background-color: #234BA2;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  svg path {
    fill: white;
  }
}

.reputationNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
}

.star {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.logo {
  margin-left: 10px;
}

.projectsNumber {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyResults {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  color: #b3b3b3;
}

.pagination {
  display: flex;
  justify-content: center;
}

.currentPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #3f66b3;
  font-weight: 800;
  border-radius: 50%;
  color: #fff;
}

.paginationButton {
  display: flex;
  align-items: center;
}

.paginationNumbers {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

}

.pageNumber {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
}

.paginationButton {
  background: none;
  font-size: 12px;
  font-weight: 600;
}

.noResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;


  &_container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: var(--Shadow);
  }

}