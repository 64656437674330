@use "../../../utils/mediaQuerys" as *;

.generalContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 201;
  border-radius: 8px;
  height: 80vh;
  width: 80vh;
  overflow: overlay;

  @include respond(phone) {
    width: 100%;
    height: 80%;
    border-radius: 0;
  }
}

.sectionContainer3 {
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &_logo {
    position: absolute;
    top: 20px;
  }


  h1 {
    margin: 0;
    color: #234ba2;
    font-weight: 800;
    font-size: 25px;
    line-height: 41px;
    text-align: center;

    @include respond(small-phone) {
      font-size: 20px;
    }
  }

  h3 {
    color: #959ca3;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;

    @include respond(small-phone) {
      font-size: 14px;
    }
  }
}

.buttonsContainer {
  display: flex;

  @include respond(small-phone) {
    padding-bottom: 10px;
  }
}

.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 201;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(phone) {
   align-items: flex-end;
  }

}

.headerContainer3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.closeButton {
  display: flex;
  background-color: transparent;
  align-items: center;
  color: #B3B5BA;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;

  @include respond(small-phone) {
    font-size: 14px;
  }
}

.textContainer {
  overflow-y: scroll;
  border: 1px solid rgba(151, 151, 151, 0.47);
  width: 100%;
  max-width: 980px;
  height: 203px;
  border-radius: 7px;
}

.text {
  padding: 0 60px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  //max-width: 700px;
  margin: 15px 0;
  text-align: left;

  ol {
    padding: 8px 16px 0;
  }
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  flex: 1;

input {
  background-color: #3f66b3;
}

input[type="checkbox"] {
  display: none;

  &:checked+label>span {
    color: #000000;
    opacity: 0.75;
  }

  &:checked+label:before {
    border: 2px solid #3f66b3;
    background: #3f66b3;
  }

  &:checked+label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 6px;
    opacity: 1;
    position: absolute;
    top: 8px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 12px;
  }
}
}

.next {
  text-align: right;
  margin-top: 10px;
  width: 200px;
  color: #9b9b9b;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  cursor: not-allowed;
  border: none;
  outline: none;
  background: transparent;

  &.active {
    cursor: pointer;
    color: #2f6aae;
  }

  & img {
    max-width: 18px;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    font-size: 20px;
    color: #9b9b9b;
    border: none;
    background-color: white;

    &:hover {
      color: #9b9b9b;
    }
  }
}

.inputHelper {
  position: relative;
  display: inline-block;

  &:before {
    content: "";
    display: block;
    position: absolute;
  }
}

.inputHelperCheckbox {
  padding-left: 30px;

  &:before {
    top: 0;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 2px solid #959ca3;
    border-radius: 3px;
  }
}

.terminosActive {
  &+label:before {
    background: #3f66b3;
  }

  &+label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 1;
    position: absolute;
    top: 2px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 12px;
  }
}

.accepted {
  padding-left: 40px;
  display: flex;
}

button.postularmeButton {
  width: 100%;
  border: none;
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41.8px;
  border-radius: 10px;
  background: #234BA2;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;

  @include respond(small-phone) {
    font-size: 14px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #9b9b9b;
    border-color: #9b9b9b;
  }
}

.acceptedSpan {
  color: #2b3b5b;
  font-weight: 600;
  font-size: 15px;
}

.iconPostularme {
  margin-right: 5px;
}

.trash {
  margin-top: 2px;
  margin-right: 5px;
}

.atention {
  text-align: center;
}