@use "../../utils/mediaQuerys" as *;

.overflowContainer {
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;

  @include respond(phone) {
    padding-top: 8rem;
  }

  @include respond(tab-port, true) {
    padding-top: 8rem;
  }

}

.allCategoriesContainer {
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  margin-top: 2rem;
  flex-direction: column;

}

.infoContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.initialStateContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 3rem;

  @include respond(tab-port) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  &_sideBar {
    grid-column: span 1 / span 1;

    &_search {
      padding: 2rem;
      background-color: #fff;
      border-radius: 0.75rem;
      border: 1px solid #edf0f7;
      box-shadow: var(--shadow);

      &_title {
        color: rgb(17 24 39);
        margin-bottom: 1.6rem;
      }
    }
  }

  &_content {
    grid-column: span 3 / span 3;

    &_header {
      display: flex;
      align-items: center;
      gap: 1rem;
      background: #fff;
      margin-bottom: 2rem;

      &_icon {
        background-color: rgb(254 249 195 );
        padding: 1rem;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_title {

        &_text {
        color: rgb(17 24 39);
        font-size: 2rem;
        font-weight: 600;
        } 
      }
      &_subtitle {
          color: rgb(107 114 128)
        }

      &_button {
        background-color: #fff;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        border: 1px solid rgb(229 231 235);
        font-weight: 500;

      }
    }

    &_body {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 2rem;

      @include respond(tab-port) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }
}

.loaderContainer {
  margin-top: 3.2rem;
}

.sideBarOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;

}