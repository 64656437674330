.errorMenu {
    box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.11);
    background-color: #fff;
    border: 1px solid rgb(229 231 235);   
    border-radius: 0.75rem;
}

.errorMenuHeader {
    border: 1px solid (254 226 226);
    background-color: rgb(254 242 242);
    padding: 1.2rem;
    display: flex;
    gap: 1rem;
    color:  rgb(153 27 27);
    align-items: center;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

.errorMenuTitle {
    font-weight: 500;
    font-size: 1.4rem;
}

.errorSection {
    max-height: 30rem;
    overflow-y: auto;
}

.errorSectionMenu {
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.2s;
    padding: 1.2rem;

    &:hover {
        background-color: rgb(249 250 251 );
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.errorItem {
    color: rgb(220 38 38);
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 0.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.errorItemBorder {
    min-height: 2rem;
    background-color: rgb(220 38 38);
    min-width: 0.5rem;
    border-radius: 9999px;
}

.errorSectionTitle {
    color: rgb(17 24 39);
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
}

.errorSectionContent {
    list-style: none;

}