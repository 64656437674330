@use "../../../utils/mediaQuerys" as *;

.flexContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.flexGap {
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
}

.calendarHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: rgb(107, 114, 128);

  &_title {
    font-size: 1.4rem;
    color: rgb(107, 114, 128);
    font-weight: 500;
  }
}

.calendarInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: rgb(239, 246, 255);
  border: 1px solid rgb(219, 234, 254);
  border-radius: 0.75rem;
  padding: 1.6rem;

  &_container {
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
  }

  &_dates {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    &_date {
      color: rgb(17, 24, 39);
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  &_daysLeft {
    background-color: rgb(219, 234, 254);
    padding: 0.6rem 1.2rem;
    border-radius: 3rem;
    color: rgb(37, 99, 235);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    gap: 0.5rem;
    width: max-content;
  }

  &_divider {
    background-color: rgb(209, 213, 219);
    display: flex;
    align-self: center;
    width: 0.1rem;
    height: 2rem;
  }
}

.formHeader {
  padding-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #edf0f7;

  &_title {
    display: flex;
    font-size: 1.4rem;
    font-weight: 600;
    flex-direction: column;
    color: #7a7f85;

    &_text {
      font-size: 2.5rem;
      color: #333;
    }
  }
}

.title {
  padding-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: 600;
  color: #333;
}

.infoBox {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: white;

}

.infoGrid {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 2rem;
  gap: 2rem;

  @include respond(phone) {
    flex-direction: column;
  }
}

.infoRow {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  gap: 2rem;

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}

.infoLabel {
  display: flex;
  font-size: 1.4rem;
  color: rgb(107 114 128);
  align-items: center;
  font-weight: 500;
  gap: 1rem;
}

.infoText {
  color: #333;
  font-size: 1.6rem;
  font-weight: 500;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

}

.dateText {
  color: #7a7f85;
}

.dateInfo {
  display: flex;
  font-size: 1rem;

  @include respond(phone) {
    flex-direction: column;
  }
}

.dateItem {
  display: flex;
  width: 140px;
  align-items: center;
  font-size: small;
  justify-content: center;
  gap: 0.5rem;
  margin: 0.30rem;
  border: solid 1px #EBEBEB;
  padding: 1px;
  border-radius: 0.5rem;
  background-color: #F7F8F9;
}

.infoRow2 {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  gap: 1rem;
}

.currency {
  color: #16a34a;
  font-size: 1.6rem;
  font-weight: 500;
  width: max-content;
}

.description {
  color: #aba7a7;
  font-size: 1.3rem;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textArea {
  background: #f7f8f9;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1.4rem;
  color: #333;
  width: 100%;
  height: 100px;
  resize: none;
  margin-top: 2rem;
}

.paused-tag {
  color: gray;
  font-size: 1rem;
  margin-left: 10px;
  background-color: rgba(128, 128, 128, 0.1);
  padding: 5px 10px;
  border-radius: 5px;
}