.dots {
    display: flex;
    justify-content: space-between;
    width: 24px;

    &_dot {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
    animation: pulse 0.6s ease-in-out infinite alternate;

    &.blackDots {
        background-color: #000;
    
    }

    &.blueDots {
        background-color: #3f66b3;
    
    }
    }
}


@keyframes pulse {
    to {
        transform: scale(0.5);
        opacity: 0.5;
    }
}