.header {
    position: fixed;
    top: 0;
    width: 100%;
    margin: auto;
    height: 60px;
    border-bottom: 1px solid #ebebeb;
    z-index: 2;
    background: #fff;
    display: flex;
    justify-content: space-between;
}

.logoContainer {
    display: flex;
    align-items: center;
    padding-left: 28px;
    cursor: pointer;

    &_logo {
        width: 100px;
        height: 30px;
    }
}

.nav {
    position: fixed;
    top: 0;
    width: 100%;
    margin: auto;
    height: 60px;
    border-bottom: 1px solid var(--color-mainBorder);
    z-index: 2;
    background: #fff;
    display: flex;
    

    &_body {
        justify-content: space-between;
        width: 100%;
        display: flex;
        max-width: 150rem;
        padding: 0 5%;
        margin: auto;
    }

    &_navItem {
        display: flex;
        padding: 10px;

        &_createButton {
            background: #234ba2;
            color: #fff;
            padding: 1rem;
            font-weight: 500;
            border-radius: 50%;
            width: 4rem;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            will-change: transform;


            &:hover {
                background: #2b3b5b;
                transform: scale(1.05);
            }
        }
    }
}

.navIcons {
    padding-right: 28px;
    display: flex;
    gap: 10px;

    &_navItemIcon {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        border-bottom: 3px solid transparent;
        position: relative;

        &.isActive {
            border-bottom: 3px solid #2b3b5b;
        }

        &_notification {
            position: relative;
            display: flex;
            padding: 10px;

            &:hover {
                background: #f5f5f5;
                border-radius: 50%;

            }

            svg path {
                fill: #2b3b5b;
            }
        }

    }

    &_navItemNotification {
        width: 16px;
        height: 16px;
        background-color: #f41079;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 21px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        font-weight: bolder;
    }
}
