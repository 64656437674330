@use "../../../utils/mediaQuerys" as *;

.download-btn {
  background-color: transparent;
  color: #234ba2;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  border: 1px solid #234ba2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  font-size: 1.4rem;
  width: 100%;

  @include respond(phone) {
    padding: 0.5rem;
    width: 100%;
  }

  &:hover {
    background-color: #234ba2;
    color: white;
    border: 1px solid #234ba2;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
      color: #234ba2;
      border: 1px solid #234ba2;
    }
  }

  &.preview-btn {
    background-color: rgb(37 99 235 );
    color: #fff;
    border: none;
    width: max-content;
    border-radius: 0.5rem;

    &:hover {
      background-color: rgb(37 99 235 );
      color: #fff;
      border: none;
    }
  }
}