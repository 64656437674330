.nuka-slide-container {
    width: 100%;
}


.nuka-wrapper {
    height: 100%;
}

.nuka-overflow {
    height: 100%;
}

