@use "../../../../utils/mediaQuerys" as *;

.container {
    width: 100%;
    display: flex;

    &_body {
        width: 100%;
        height: 100%;
    }

}

.emptyState {
    display: flex;
}