.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .loadingAnimation {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.1; }
    100% { opacity: 1; }
  }
  
  .loadingText {
    animation: blink 1s infinite;
    font-size: 16px;
    font-weight: 400;
    color: #4a4a4a;
  }

  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .spinner {
    width: 30px;
    height: 30px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite, blink 1.5s infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }