@use "../../../utils/mediaQuerys" as *;

.sectionContainer {
  display: flex;
  min-height: 100vh;
  overflow: hidden;

  @include respond(phone) {
    padding: 5%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;


  &_content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &_button {
    width: 20px;
    background-color: transparent;
    color: #fff;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
}

.background {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: linear-gradient(135deg, #234ba2 30%, #577bc1 100%);
  display: flex;
  align-items: center;
  justify-content: center;


  @include respond(tab-port) {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(8px);
    z-index: 1;
    opacity: 0.8;
  }

  &_component {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

}



.registerContainer {
  margin: 0 auto;
  display: flex;
  padding: 20px 30px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  &_body {
    width: 100%;
    min-height: 100%;
  }

}

.logo {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 120px;
  }

}


.copyright {
  width: 100%;
  padding: 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  opacity: 0.5;
}

.childrenContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
}

.registerContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loginAnimation {
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-delay: 2.15s;
  opacity: 0;

 
  .registerContainer_body {
    .registerContent {
      .childrenContainer {
        transition: opacity 2s;
        opacity: 0;
        pointer-events: none;
        position: relative;
      }

      .logo {
        transition: transform 0.8s linear, margin 0.8s 0.8s linear, width 0.8s 0.8s ease-in-out;
        transform: translate(0, 600%) translateZ(0) scale(4);
        backface-visibility: hidden;
        overflow: hidden;
        margin-left: 165px;
        width: 0px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }
  }

  .copyright {
    transition: all 1s;
    max-width: 300px;
    font-size: 15px;
    transform: translate(0, -250px);
  }

  .background {
    transition: width 1s;
    width: 0%;

  }
}
