@use "../utils/mediaQuerys" as *;

.sectionDivider {
  display: flex;
  line-height: 20px;
  font-weight: 700;
  width: 100%;
  justify-content: center;
}

.numbersContainer {
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 10px;
  color: #234ba2;
  border-right: 1px solid;
  border-left: 1px solid;
}

.numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 30px;
  border: 1px solid #234ba2;
}

.active {
  opacity: 0.3;
}

.arrowContainer {
  display: flex;
  justify-content: center;
  align-items: center;

}

.arrow {
  font-size: 25px;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }

  svg path {
    fill: #234ba2;
  }
}



.privateProjectsContainer, 
.publicProjectsContainer,
.quotationsContainer {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5rem;

    @include respond(small-phone) {
        grid-template-columns: repeat(1, 1fr);
    }

    @include respond(phone, true) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond(tab-port, true) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

