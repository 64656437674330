


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &_container {
        max-width: 90%;
        max-height: 90%;
        overflow-y: auto;
        background-color: #fff;
        border-radius: 18px;
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 2.5rem;

        &_footer {
            font-weight: 500;
            font-size: 1.4rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #333;

            &_help {
                display: flex;
                font-weight: 600;
                color: #234ba2;
                margin-left: 0.5rem;
            }
        }

        &_title {
            display: flex;
            font-size: 2.4rem;
            font-weight: 600;
            width: 100%;
            align-items: center;
            color: #333;
            justify-content: space-between;

        }

        &_close {
            font-size: 15px;
            cursor: pointer;
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #889abe;

            svg g g {
                stroke: #889abe;
            }
        }

        &_body {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        

            &_container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                padding: 2rem 0;
                width: 100%;
            }
        }
    }

}

.cardTitle {
    font-size: 1.6rem;
    font-weight: 600;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    
}

.cardBody {
    display: flex;
    flex-direction: column;
}



.cardFront {
    display: flex;
    flex-direction: column;
}

.cardFront {
    cursor: pointer;
    padding: 2.5rem;
    border-radius: 18px;
}

.cardItems {
    font-weight: 400;
    display: flex;
    flex-direction: column;
    list-style-position: inside;

    &_item {
        line-height: 2.5rem;
        color: rgb(107 114 128);
    }
}

.cardSubTitle {
    color: rgb(75 85 99);
    padding-bottom: 1rem;
}

.cardLogo {
    &_quotation {
        fill: #45A7FF;

    }

    &_workOffer {
        fill: #22af86;
    }
}