@use "../../utils/mediaQuerys" as *;
;

@keyframes onSelected {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.formContainer {
  border-radius: 7.5px;
  width: 100%;
  margin-top: 1rem;
  animation-name: onSelected;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;

}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 1.6rem;
  border: 2px solid rgb(229 231 235);
  border-radius: 0.75rem;
  text-align: center;
  font-weight: 600;
  transition: all 0.2s;
  will-change: transform;
  cursor: pointer;

  &.selected {
    border: 2px solid rgb(37 99 235);
    color: rgb(29 78 216);
    background-color: rgb(239 246 255);

    svg g {
      fill: rgb(37 99 235);
    }

  }

  &.notSelected {
    border: 2px solid rgb(229 231 235);
  }

  &:hover {
    opacity: 1;
  }
}

.workZoneSelector {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(phone) {
      flex-direction: column;
      gap: 2rem;
    }
  }

}

.emptyState {
  background-color: rgb(249 250 251);
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: rgb(107 114 128);
  padding: 1.6rem;
}

.btn {
  padding: 0.8rem 2.4rem;
  color: var(--color-white);
  outline: none;
  font-size: 15.5px;
  font-weight: 500;
  line-height: 17px;
  align-items: center;
  display: flex;
  background-color: var(--color-mainBlue);
  transition: all 0.2s;
  border-radius: 0.75rem;

  &:disabled {
    cursor: not-allowed;
    color: rgb(107 114 128);
    background-color: rgb(209 213 219);
  }

  &_skip {
    background-color: transparent;
    color: rgb(75 85 99);
    font-size: 15.5px;
    padding: 0.8rem 2.4rem;
    font-weight: 500;
    position: relative;
    transition: all 0.3s ease-in-out;
    margin-left: auto;

    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 2px;
      width: 0;
      background-color: #234ba2;
      transition: width 0.3s ease;
    }

    &:before {
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      right: 50%;
      transform: translateX(50%);
    }

    &:hover {
      color: #234ba2;

      &:before,
      &:after {
        width: 100px;
      }
    }
  }
}

.inputRow {
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @include respond(phone) {
    flex-direction: column;
    gap: 1rem;
  }

}

.isNotValid {
  border: 2px solid var(--color-error);
}

.selectTypeContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  &_container {
    position: absolute;
    z-index: 10;
  }

  p {
    display: block;
    width: 100%;
    justify-self: flex-start;
    margin: 0 auto;
    padding: 25px 25px;
    text-align: left;
    font-size: 14px;
    color: #6d7381;
    border-bottom: 1px solid rgba(#6d7381, 0.2);
  }

  span {
    font-weight: bold;
    color: black;
  }
}

.formGroup {
  position: relative;
  width: 100%;

  &_title {
    color: rgb(55 65 81);
    margin-bottom: 0.4rem;
    font-weight: 500;
  }

  &_inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    @include respond(phone) {
      flex-direction: column;
    }

    &_title {
      font-size: 1.8rem;
      font-weight: 600;
      color: rgb(17, 24, 39);
      width: 100%;
      padding-bottom: 20px;
    }

    &_form {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }


  &_lastOne {
    position: relative;
    width: 100%;
  }

  &_errorMsg {
    position: absolute;
    top: 100%;
    right: 0;
    color: #d6174a;
    font-size: 12px;
    z-index: 1;
  }
}

.lottieContainer {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  &_input {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
}

.skillsContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &_title {
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(17 24 39);
  }

  &_skillSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(239 246 255);
    color: rgb(29 78 216);
    border-radius: 0.75rem;
    gap: 0.5rem;
    padding: 0.4rem 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: #234ba2;
      color: #edf0f7;

    }
  }

  &_inputContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 0.75rem;
    border: solid 1px rgb(209, 213, 219);
    background: transparent;
    align-items: center;
    padding: 10px 15px;
    color: rgb(17 24 39);
    position: relative;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    &:focus-within {
      border: solid 1px #234ba2;
    }
  }

  &_containerSelected {
    width: 100%;
    display: flex;
    max-height: 140px;
    overflow: auto;
  }

  &_selected {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;

    &_emptyState {
      color: rgb(107 114 128);
      font-weight: 400;
      font-size: 1.4rem;
    }

  }

  &_listContainer {
    position: absolute;
    border: 1px solid rgb(229 231 235);
    border-radius: 0.75rem;
    z-index: 2;
    width: 100%;
    background-color: #fff;
    top: 102%;
    left: 0;
  }

  &_searchInputContainer {
    position: relative;
    padding: 1rem;
  }

  &_searchIcon {
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    color: rgb(156 163 175);
  }

  &_searchInput {
    border: 1px solid rgb(209 213 219);
    border-radius: 0.75rem;
    padding: 0.8rem 3.2rem;
    width: 100%;
  }

  &_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border: solid 1px #ebebeb;
    max-height: 20rem;
    overflow: overlay;
    gap: 0.2rem;

    &_item {
      padding: 0.8rem 1.2rem;
      cursor: pointer;
      transition: all 0.2s;
      font-size: 14px;
      font-weight: 500;
      position: relative;
      cursor: pointer;
      transition: all 0.2s;
      display: flex;
      justify-content: space-between;

      &:hover {
        background-color: rgb(249 250 251);
        border-radius: 0.75rem;
      }

      &_selected {
        color: rgb(29 78 216);
        background-color: rgb(239 246 255);
        border-radius: 0.75rem;

        &:hover {
          color: rgb(29 78 216);
          background-color: rgb(239 246 255);
          border-radius: 0.75;
        }
      }

      &_check {
        display: flex;
        background-color: rgb(37 99 235);
        color: #fff;
        border-radius: 9999px;
        padding: 0.2rem;
      }

    }

  }
}

.arrow {
  display: flex;
  cursor: pointer;

  &.arrowIconUp {
    transform: rotate(-180deg);
    transition: transform 0.3s ease;
  }

  &.arrowIconDown {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }
}