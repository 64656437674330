.contentContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.content {
  width: 50%;
  min-height: 100vh;
}

.resend {
  margin-top: 2rem;

}

.container {
  display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.hover {
  transition: all 0.2s;
  padding: 20px 0 0 0;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
  position: relative;
  font-size: 17.5px;
  font-weight: 600;
  line-height: 21px;
  color: var(--color-mainBlue);

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    width: 0px;
    height: 2px;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    background-color: var(--color-mainBlueBorder);
  }

  &:before {
    left: 50%;
  }

  &:after {
    right: 50%;
  }

  &:hover {
    &:before {
      width: 150px;
    }

    &:after {
      width: 150px;
    }
  }
}

.background {
  background: url("../../assets/images/foto-bienvenida.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.registerContainer {
  margin: 0 auto;
  display: flex;
  padding: 20px;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;

  @media (max-width: 1024px) {
    align-items: flex-start;
  }
}

.registerContent {
  width: 100%;

  &::after {
    content: "";
    display: block;
    width: 75%;
    height: 2px;
    background-color: #bfbfbf;
    opacity: 0.67;
    margin: 2.5rem auto 1.25rem;
  }
}

.logo {
  text-align: center;
  margin-bottom: 25px;

  & img {
    width: 120px;
  }
}

.title {
  color: #000000;
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 42.7px;
  padding-bottom: 4rem;
}

.copyright {
  max-width: 268px;
  margin-top: 50px;
  color: #707070;
  opacity: 0.47;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.pageContent {
 padding-bottom: 4rem;
}

.welcomeTitle {
  font-size: 1.9rem;
  margin-bottom: 2rem;
  font-weight: 600;
  color: var(--color-black);
}

.welcomeParagraph {
  color: var(--color-black);
  opacity: 0.6;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.welcomeParagraphSpam {
  color: var(--color-black);
  opacity: 0.6;
}

.btn {
  padding: 15px 30px;
  color: var(--color-white);
  outline: none;
  font-size: 15.5px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  width: 100%;
  display: block;
  border: 2px solid var(--color-mainBlueBorder);
  background-color: var(--color-mainBlue);
  transition: all 0.2s;
  border-radius: 7px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.hover {
  transition: all .2s;
  padding: 20px 0 0 0;
  width: 100%;
  transition: all 0.2s ease-in-out;
  position: relative;
  font-size: 15.5px;
  font-weight: 600;
  line-height: 17px;
  color: var(--color-mainBlue);
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    width: 0px;
    height: 2px;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    background-color: var(--color-mainBlueBorder);
  }

  &:before {
    left: 50%;
  }

  &:after {
    right: 50%;
  }

  &:hover {
    &:before {
      width: 100px;

    }

    &:after {
      width: 100px;

    }
  }
}

.confirmationContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &_options {
    display: flex;
    gap: 10px;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
  }

  &_radio {
    cursor: pointer;
    margin-right: 5px;
  }

  &_phoneContainer {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    gap: 10px;

    & input {
      min-width: 100%;
      padding: 10px;
      border: 1px solid #ebebeb;
      outline: none;
      border-radius: 5px;
    }
  }
}

.resendText {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;

}

.emailInputContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  &_emailResend {
    width: 100%;
  }

  &_emailOk {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    justify-content: space-between;

    &_text {
      border: 1px solid #ebebeb;
      border-radius: 5px;
      padding: 10px;
      width: 100%;
    }
  }

  &_notMyMail {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    padding: 10px;
  }

  &_newEmailResend {
    display: flex;
    width: 100%;
    gap: 20px;
  }

  &_emailInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    outline: none;
  }

  &_btnResend {
    border-radius: 5px;
    padding: 10px;
    background-color: transparent;
    color: var(--color-mainBlue);
    font-size: 14px;
    transition: all 0.2s;
    border: 1px solid var(--color-mainBlue);

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &:hover {
      background-color: var(--color-mainBlue);
      color: var(--color-white);
    }
  }
}