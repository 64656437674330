.container {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  background-color: #234ba2;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.menuContainer {
  background: #fff;
  width: 80vw;
  overflow-x: overlay;
  position: absolute;
  border-bottom-right-radius: 5px;
  padding: 20px 0px 20px 20px;
  z-index: 1;
  top: 100%;
  animation: slideIn 0.5s ease-out forwards;

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.subMenuContainer {
  display: flex;
  flex-direction: column;
  padding-left: 20px;

}

.logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 60px;
}

.menuButton {
  box-shadow: none;
  border: transparent;
  background: transparent;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  z-index: 1;

  svg g g line {
    stroke: #fff;

  }
}

.relativeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;

  &.isSelected {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #edf0f7;
  }
}

.overlay {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.rightIcons {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.profileContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  &_profileImage {
    border-radius: 5px;
    width: 40px;
    height: 40px;
    margin-right: 10px;


    &_img {
      border-radius: 5px;
      width: 40px;
      height: 40px;
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.2);
    }

    &_initials {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ccc;
      color: #fff;
      font-size: 1.5rem;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      text-transform: uppercase;
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.2);
    }
  }

  &_profileInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &_name {
      font-size: 16px;
      font-weight: 700;
      color: #2b3b5b;
    }

    &_email {
      font-size: 14px;
      font-weight: 500;
      color: #2b3b5b;
      opacity: 0.67;
    }
  }
}