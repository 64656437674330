.carousel .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    }

    .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
        top: 40%;

    }