@use "../../../utils/mediaQuerys" as *;

.MainModal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;


    &_container {
        border-radius: 18px;
        background-color: #ffffff;
        z-index: 2;
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 60vh;
        max-height: 60vh;
        width: 600px;

        @include respond(phone) {
            max-height: 70vh;
            max-width: 90vw;
            overflow: auto;
        }
    }

    &_header {
        padding: 20px 20px 0px 20px;
        display: flex;
        background: #fff;
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        position: relative;

        &_data {
            display: flex;
            width: 100%;
            border-bottom: 1px solid #edf0f7;
            padding-bottom: 10px;
            justify-content: space-between;
            align-items: center;
        }

        &_image {
            height: 100%;
            padding: 20px;
        }

        &_video {
            height: 100%;
            padding: 20px;
        }

        &_withVideoContainer {
            display: flex;
            background-image: url(../../../assets/svg/constructionCraneBackground.svg);
            width: 100%;
            height: 100%;
            background-repeat: round;
            cursor: pointer;
        }

        &_withVideo {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: start;

            p {
                font-size: 21px;
                font-weight: 600;
                text-align: center;
                color: #959CA3;
                background-color: white;
                border-radius: 18px;
                padding: 10px;
                border: 3px solid #959CA3;
            }
        }

        &_withImgContainer {
            display: flex;
            background-image: url(../../../assets/svg/constructionCraneBackground.svg);
            width: 100%;
            height: 100%;
            background-repeat: round;
            cursor: pointer;
        }

        &_withImg {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: start;

            p {
                font-size: 21px;
                font-weight: 600;
                text-align: center;
                color: #959CA3;
                background-color: white;
                border-radius: 18px;
                padding: 10px;
                border: 3px solid #959CA3;
            }
        }

        &_decline {
            cursor: pointer;
        }

        &_txtContainer {
            display: flex;
            flex-direction: column;
        }

        &_txt {
            font-size: 1.6rem;
            font-weight: 500;
            color: rgb(17 24 39);

            &_sub {
                color: rgb(75 85 99);
                font-weight: 400;
            }
        }

        &_imgContainer {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            color: #234ba2;
        }

        &_img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &_bodyWithVideoContainer {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &_bodyWithVideo {
        display: flex;
        width: 100%;
        max-height: 250px;
        padding: 10px;

        &_selectedVideo {
            object-fit: cover;
            width: 100%;
            border-radius: 5px;
        }

        &_txtInputContainer {
            display: flex;
            flex-direction: column;
            padding: 10px;
            width: 100%;
            height: 100%;
        }

        &_txtInput {
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            height: 100%;
            color: #2b3b5b;
            resize: none;
            border: 1px solid #bfbfbf;
            padding: 20px;
            border-radius: 11px;

            &_input {

                width: 100%;
                max-width: 100%;
                height: 100%;
            }
        }
    }

    &_bodyWithImageContainer {
        display: flex;
        width: 100%;
        max-height: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
    }

    &_bodyWithImage {
        display: flex;
        max-height: 250px;
        padding: 10px;

        @include respond(phone) {
            max-height: 20rem;
        }

        &_selectedImage {
            object-fit: cover;
            width: 100%;
            border-radius: 5px;
        }

        &_txtInputContainer {
            display: flex;
            flex-direction: column;
            padding: 10px;
            height: 100%;
        }

        &_txtInput {
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            height: 100%;
            color: #2b3b5b;
            resize: none;
            border: 1px solid #bfbfbf;
            padding: 20px;
            border-radius: 11px;

            &_input {

                width: 100%;
                max-width: 100%;
                height: 100%;
            }
        }
    }

    p {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: #959CA3;
    }

    &_buttonContainer {
        display: flex;
        justify-content: flex-end;
        border-top: 2px solid #edf0f7;
        padding: 10px;
    }

    &_optionsButtonContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        border-top: 2px solid #edf0f7;
        padding: 10px;
    }

    &_optionsIconContainer {
        display: flex;
        align-items: center;

        &_imgIconContainer {
            display: flex;
            align-items: center;
            padding: 10px;
            height: fit-content;
            cursor: pointer;

            &:hover {
                background-color: #f7f8f9;
                border-radius: 50%;
            }

        }

        &_videoIconContainer {
            display: flex;
            align-items: center;
            padding: 10px;
            height: fit-content;
            cursor: pointer;

            &:hover {
                background-color: #f7f8f9;
                border-radius: 50%;
            }

        }
    }

    &_optionsItemDisabled {

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        border-radius: 5px;
        font-size: 1.6rem;
        font-weight: 500;
        text-align: center;
        background-color: rgb(234 234 234);
        color: #959CA3;
        pointer-events: none;
        cursor: not-allowed;
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: scale(1.02);
        }

        svg path {
            fill: #959CA3;
        }
    }

    &_optionsItem {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: #234BA2;
        cursor: pointer;
        font-size: 1.6rem;
        padding: 1.2rem 2.4rem;
        gap: 1rem;
        font-weight: 500;
        color: var(--color-white);
        text-align: center;
        transition: all 0.3s ease-in;
        will-change: transform;

        &:hover {
            transform: scale(1.03);
        }
    }

    &_overlay {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.bodyTxt {
    padding: 20px;
    font-size: 14px;
    font-weight: 500;
    min-width: 350px;
    flex-grow: 1;
    width: 100%;

    @include respond(phone) {
        min-width: 300px;
    }

    &_input {
        resize: none;
        border: none;
        width: 100%;
        max-width: 100%;
        height: 100%;
        border: 2px solid transparent;
        padding: 1rem;
        font-size: 1.6rem;

        @include respond(phone) {
            min-height: 200px;
        }

        &:focus-within {
            border: 2px solid ;
            border-radius: 0.5rem;
        }

        &::placeholder {
            font-size: 1.6rem;
        }
    }
}

.margin {
    margin-right: 5px;
}

.edifyIcon {
    margin-right: 360px;
    margin-bottom: 80px;

}
