@mixin respond($breakpoint, $min: null) {
    @if $breakpoint == small-phone {
        @media only screen and (max-width: 480px) { @content };
    }
    @if $breakpoint == phone {
        @if $min == true {
            @media only screen and (min-width: 481px) and (max-width: 600px) { @content };
        } @else {
            @media only screen and (max-width: 600px) { @content };
        }
    }
    @if $breakpoint == tab-port {
        @if $min == true {
            @media only screen and (min-width: 601px) and (max-width: 900px) { @content };
        } @else {
            @media only screen and (max-width: 900px) { @content };
        }
    }
    @if $breakpoint == tab-land {
        @if $min == true {
            @media only screen and (min-width: 901px) and (max-width: 1200px) { @content };
        } @else {
            @media only screen and (max-width: 1200px) { @content };
        }
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 1800px) { @content };
    }
}

