@use "../../../utils/mediaQuerys" as *;

.MainModal {
  border-radius: 0.75rem;
  background-color: #fff;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include respond (phone) {
    max-width: 90vw;
  }

  &_closeButton {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    background: transparent;
    border: none;
    color: rgb(75 85 99);
  }

  &_title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    color: #234BA2;
    margin-bottom: 1.6rem;
    padding: 0 1rem;

    &_warning {
      color: rgb(161 98 7);
    }

    &_success {
      color: rgb(21 128 61);
    }

    &_error {
      color: rgb(185 28 28);
    }
  }

  p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #959CA3;
  }

  &_bodyContainer {
    padding: 2.4rem 2.4rem 1.6rem 2.4rem;
  }

  &_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    position: relative;

    @include respond (phone) {
      margin-top: 0px;
    }

  }

  &_options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    background-color: rgb(249 250 251);
    padding: 1.6rem 2.4rem;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;

    @include respond (phone) {
      flex-direction: column;
    }

    &_success {
      padding: 0.8rem 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      background-color: #234BA2;
      cursor: pointer;
      font-weight: 500;
      color: var(--color-white);
      text-align: center;
      transition: all 100ms ease-in-out;

      @include respond (phone) {
        padding: 10px;
        font-size: 16px;
      }

      &:hover {
        transform: scale(1.05);
      }

      &_warning {
        background-color: rgb(202 138 4);
      }

      &_success {
        background-color: rgb(22 163 74);
      }

      &_error {
        background-color: rgb(220 38 38);
      }
    }

    &_decline {
      border: 1px solid rgb(209 213 219);

      padding: 0.8rem 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      background-color: var(--color-white);
      cursor: pointer;
      font-weight: 500;
      color: rgb(55 65 81);
      text-align: center;
      transition: all 100ms ease-in-out;

      @include respond (phone) {
        padding: 10px;
        font-size: 16px;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &_overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 3;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.bodyTxt {
  font-size: 1.6rem;
  text-align: center;
  color: rgb(75 85 99);
  line-height: 1.5;
  padding: 0 1rem;
}

.margin {
  margin-right: 5px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.6rem;
  width: 5rem;
  height: 5rem;
  margin-left: auto;
  margin-right: auto;

}

.edifyIcon {
  width: 100%;
  display: flex;

  &_img {
    margin: 20px;
    width: 120px;
  }

}