.MainModal {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 77.5px;
    height: 74.3px;
    margin-bottom: 23px;
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #2b3b5b;
    margin-bottom: 5px;
  }
  p {
    margin-top: 25px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #2b3b5b;
  }
  &_options {
    margin-top: 32px;
    &item,
    &item_decline,
    &item_success {
      width: 381px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: #234ba2;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      color: var(--color-white);
      text-align: center;
      margin-top: 15px;
      transition: all 100ms ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
      &_success {
        width: 92.8px;
      }

      &_decline {
        font-weight: 600px;
        background-color: var(--color-white);
        color: #b3b5ba;
        margin-left: 18px;
      }
    }
  }
  &_overlay {
    width: 100%;
    height: 123vh;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 1000;
  }
}
.margin {
  margin-right: 5px;
}
.edifyIcon {
  margin-right: 360px;
  margin-bottom: 100px;
}
