@use "../../../utils/mediaQuerys" as *;


.container {
    padding: 1.6rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2rem;
        width: 100%;

        @include respond(phone) {
            flex-direction: column;
        }

        &_leftSide {
            display: flex;
            flex-direction: column;

            &_titleContainer {
                display: flex;
                align-items: center;
                gap: 1rem;
            }
        }

        &_rightSide {
            display: flex;
            flex-direction: column;

            @include respond(phone) {
                margin-top: 1.2rem;
            }

            &_currency {
                display: flex;
                gap: 0.5rem;
                color: rgb(17 24 39);
                font-size: 1.6rem;
                font-weight: 600;

            }

            &_jurisdiction {
                color: rgb(107 114 128 );
                text-align: right;
                line-height: 2;

                @include respond(phone) {
                    text-align: left;
                }
            }
        }

        &_description {
            margin-top: 0.4rem;
            color: rgb(75 85 99);
        }

        &_title {
            font-size: 1.6rem;
        }

        &_activeStatus {
            background-color: #e5e7eb;
            color: #000;
            padding: 0.4rem 1.2rem;
            border-radius: 9999px;
            display: flex;
            align-items: center;
            font-weight: 500;
            
            &_active {
                background-color: rgb(219 234 254);
                color: rgb(30 64 175);
                padding: 0.4rem 1.2rem;
                border-radius: 9999px;
                display: flex;
                align-items: center;
                font-weight: 500;
            }
        }
    }
    &_body {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 1.6rem;

        @include respond(phone) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &_box {
            background-color: rgb(249 250 251);
            border-radius: 0.75rem;
            padding: 1.2rem;

            &_title {
                color: rgb(107 114 128);
                line-height: 1.5;
            }

            &_value {
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 1.5;
            }

            &_categoryContainer {
                display: flex;
                flex-wrap: wrap;
                gap: 0.8rem;
                margin-top: 0.8rem;
            }

            &_category {
                background-color: rgb(224 231 255);
                color: rgb(37 99 235);
                padding: 0.4rem 0.8rem;
                border-radius: 9999px;
                font-size: 1.2rem;
                font-weight: 500;
                display: flex;
                align-items: flex-start;
                gap: 0.4rem;
            }
        }
    }

    &_button {
        color: #fff;
        background-color: rgb(37 99 235);
        padding: 0.8rem 1.6rem;
        border-radius: 0.75rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-top: 1.6rem;
        transition: all;

        &:hover {
            background-color: rgb(30 64 175);
        }
    }
}
