.EmptyState {
  display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem;

    svg {
      width: 50px;
      height: 50px;

    }
    h3 {
      font-size: 1.6rem;
      margin: 10px 0;
      font-weight: 500;
      color: #000;
      text-align: center;
    }
  }