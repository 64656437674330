@use "../../../utils/mediaQuerys" as *;

.container {
    display: flex;
    width: 100%;
    flex-direction: column;

    @include respond(phone) {
        padding-top: 60px;
    }

    @include respond(tab-port, true) {
        padding-top: 60px;
    }
}

.header {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    width: 100%;

    &_line {
        width: 100%;
        height: 1.2px;
        background-color: #d2d2d2;

    }

    &_title {
        font-size: 14px;
        font-weight: 700;
        color: #959CA3;
        text-align: right;
        margin-bottom: 0;

    }

    &_titleSortContainer {
        display: flex;
        align-items: center;
        font-size: 12px;
        position: relative;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: flex-end;

        &_items {
            display: flex;
        }
    }

    &_titleSort {
        font-size: 12px;
        font-weight: 500;
        width: max-content;

    }
}

.dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;

    &_button {
        cursor: pointer;
        border-radius: 0.75rem;
        font-size: 1.4rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        width: fit-content;
        background-color: transparent;
        gap: 5px;
        border: 1px solid transparent;
        padding: 0.5rem;

    }

    &_content {
        position: absolute;
        top: 100%;
        right: 0px;
        background-color: #ffffff;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 5px;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 100;

        &_option {
            width: 100%;
            padding: 10px;
            text-align: left;
            border: none;
            background-color: transparent;
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;
            color: #2b3b5b;

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

}

.stickyContainer {
    overflow: hidden;
    position: sticky;
    top: 5.9rem;
    transition: height 0.3s ease-in-out;
    z-index: 1;
}
.feed {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0 0 0.75rem 0.75rem;
    margin-bottom: 10px;
    transition: box-shadow 0.3s ease-in-out;
    background-color: #fff;
    padding: 20px;
    border: 1px solid var(--color-mainBorder);
    border-top: none;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);

    &_itemHeaderAvatar {
        border-radius: 50%;
        max-width: 50px;
        max-height: 50px;
        min-width: 50px;
        overflow: hidden;
        min-height: 50px;
        margin-right: 10px;

        &_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &_initials {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ccc;
            color: #fff;
            font-size: 1.5rem;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            text-transform: uppercase;
        }
    }

    &_buttonsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
        margin-top: 2rem;

        @include respond(phone) {
            margin-top: 2rem;
        }

        @include respond(tab-port, true) {
            margin-top: 2rem;
        }

        &_title {
            font-size: 2.4rem;
            font-weight: 600;
            margin-bottom: 0.8rem;
        }

        &_subtitle {
            font-size: 1.6rem;
            color: rgb(75 85 99);
            font-weight: 400;
            margin-bottom: 1rem;
            line-height: 1.2;
        }

        &_buttons {
            display: grid;
            padding: 1.6rem;
            gap: 2rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            @include respond(phone) {
                display: flex;
                flex-direction: column;
                padding: 1.6rem 0;
            }

            @include respond(tab-land, true) {
                display: flex;
                flex-direction: column;
            }

            &_willChange {
                white-space: nowrap;
            }
        }

        &_button {
            width: 100%;
            border: 1px solid var(--color-buttonBorder);
            border-radius: 11px;
            display: flex;
            align-items: center;
            padding: 1.6rem;
            text-align: center;
            color: #333;
            gap: 1rem;
            font-size: 1.4rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s;

            &_imageContainer {
                overflow: hidden;
                min-height: 12rem;
                height: 100%;
                min-width: 12rem;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            
            &.hoverObraPrivada:hover {
                border: 1px solid #234ba2;
                color: #234ba2;
                background-color: rgb(219 234 254);

            }

            &.hoverCotizacion:hover {
                border: 1px solid #45A7FF;
                color: #45A7FF;
                background-color: rgb(225 240 255);

            }

            &.hoverOfertaTrabajo:hover {
                border: 1px solid #22af86;
                color: #22af86;
                background-color: rgb(220 252 231);

                svg {
                    color: #22af86;
                }

            }

            &.hoverMuroEdify:hover {
                border: 1px solid #7000ff;
                color: #7000ff;
                background-color: rgb(243 232 255);

            }

            &_title {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                font-size: 1.6rem;
                color: rgb(17 24 39);
                font-weight: 600;
                margin-bottom: 0.4rem;
                text-align: left;
            }

            &_text {
                color: rgb(107 114 128);
                line-height: 1.4;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: left;
                
            }

            &_icon {
                display: flex;
                padding: 1.6rem;
                border-radius: 0.75rem;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &_muroEdify {
                    svg path {
                        fill: #7000ff;
                    }
                }

                &_obraPrivada {
                    background-color: rgb(239 246 255);
                }

                &_cotizacion {
                    background-color: rgb(240 248 255);
                }

                &_ofertaTrabajo {
                    background-color: rgb(240 253 244);
                    color: #22af86;
                }

                &_muroEdify {
                    background-color: rgb(253, 242, 255);
                }

            }

        }
    }

    &_header {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    &_input {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 0.75rem;
        border: 1px solid #d2d2d2;
        display: flex;
        align-items: center;

        &_item {
            background: none;
            border-radius: 5px;
            width: 100%;
            color: #959CA3;
            border: 0;
            font-weight: 400;
            resize: none;
            padding: 20px;
            cursor: pointer;

            &::placeholder {
                font-size: 1.2em;
                font-weight: 400;
                text-align: center;

                @include respond(phone) {
                    font-size: 12px;

                }
            }

            @include respond(phone) {
                padding: 10px;
            }
        }
    }

    &_itemHeader {
        display: flex;
        width: 100%;
        align-items: center;

        &_subTitleContainer {
            display: flex;
            width: 140px;
        }

        &_subTitle {
            font-size: 12px;
            height: 17px;
            padding-left: 3px;
            width: 100%;
            border-left: 3px solid #004F98;
            color: #2b3b5b;
            font-weight: 600;
        }

    }

    &_itemFooterContainer {
        display: flex;
        flex-direction: column;
        width: 200px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-left: 20px;

        @include respond(phone) {
            margin-left: 0;
        }

        &_buttons {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
            color: #2b3b5b;
        }
    }

    &_itemFooterLeft {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        align-items: center;
        width: 100%;
        justify-content: center;
        transition: all 0.3s;
        padding: 10px;

        &:hover {
            background: #F7F7F7;
            border-radius: 11px;
        }

        &_txt {
            margin-left: 5px;
            display: flex;
            align-items: center;
            color: #959CA3;
        }

        svg path {
            stroke: #000;
        }

        &_input {
            display: none;
        }
    }

    &_itemFooterRight {
        display: flex;
        cursor: pointer;
        font-size: 16px;
        align-items: center;
        font-weight: 500;
        width: 100%;
        justify-content: center;
        transition: all 0.3s;
        padding: 10px;

        &:hover {
            background: #F7F7F7;
            border-radius: 11px;
        }

        &_txt {
            margin-left: 5px;
            display: flex;
            align-items: center;
            color: #959CA3;
        }

        &_input {
            display: none;
        }

        svg path {
            stroke: #000;
        }
    }

    &_itemFooterEnd {
        display: flex;
        width: 100%;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background: #F7F7F7;
            border-radius: 11px;
        }

        &_button {
            display: flex;
            background: none;
        }
    }
}

.feedItemContainer {
    display: flex;
    flex-direction: column;

    &_empty {
        background-color: #fff;
        border-radius: 0.75rem;
        padding: 20px;
        border: 1px solid #edf0f7;
        box-shadow: var(--shadow);
        display: flex;
        flex-direction: column;
        align-items: center;

        &_icon {
            background-color: rgb(253, 242, 255);
            border-radius: 9999px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.6rem;
            color: #7000ff;
            margin-bottom: 1.6rem;
        }

        &_title {
            color: rgb(17 24 39);
            font-size: 1.8rem;
            line-height: 1.5;
            margin-bottom: 0.8rem;
        }

        &_text {
            color: rgb(75 85 99 );
            font-size: 1.4rem;
            margin-bottom: 1.6rem;
            text-align: center;
        }

        &_button {
            background-color: rgb(253, 242, 255);
            color: #7000ff;
            border-radius: 0.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.8rem 1.6rem;
            font-weight: 500;
            font-size: 1.6rem;
        }
    }
}