.container {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    margin-top: 20px;
    width: 8rem;
    border-right: 1px solid var(--color-mainBorder);
    position: sticky;
    top: 80px;
    transition: width 0.5s ease;

    &_fullWidth {
        width: 313px;
        transition: width 0.5s ease;
    }


}

.generalContainer {
    height: 100%;
    position: sticky;
    display: flex;
    top: 60px;
    flex-direction: column;
    align-items: center;
    transition: min-width 0.5s ease;

}

.logo {
    padding-top: 20px;
    padding-left: 30px;
    width: 100%;
    padding-bottom: 20px;


    &_icon {
        width: 115px;

        &.smallIcon {
            width: 29px;
        }
    }

}



.nav {

    &_userData {
        margin: 0 1rem;
        color: #2b3b5b;
        font-weight: 900;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        opacity: 1;
        transition: opacity 0.5s, clip-path 0.5s ease-in-out;
        clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);


        &_hide {
            opacity: 0;
            clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
        }

        &_name {
            display: flex;
        }

        &_status {
            font-size: 12px;
            font-weight: 500;
            color: #959ba3;
            display: flex;
            align-items: center;
            gap: 10px;

            &_circle {
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: #4CE2A7;
                position: absolute;
                bottom: 0;
                right: 1rem;
            }
        }
    }

    &_container {
        display: flex;
        width: 100%;
    }

    &_userDataContainer {
        width: 100%;
        border-bottom: 1px solid rgb(243 244 246);
    }

    &_imgContainer {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 2rem 0;
        position: relative;

        &:hover {
            background: #f7f7f7;
        }

        &.isActive {
            background: rgb(235, 236, 240);
        }

        &_image {
            position: relative;
            display: flex;
            min-width: 8rem;
            height: 60px;
            align-items: center;
            justify-content: center;
        }

        &_img {
            max-width: 50px;
            min-width: 50px;
            max-height: 50px;
            min-height: 50px;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
        }

        &_initials {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ccc;
            color: #fff;
            font-size: 1.5rem;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            text-transform: uppercase;
        }
    }

    &_list {
        list-style: none;
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
    }

    &_navItem {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        height: 5rem;
        color: rgb(156 163 175);

        &:hover {
            background: #f7f7f7;

        }


        &_lastItems {
            border-top: 1px solid rgb(243 244 246);
            height: 5rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-top: 5rem;
            transition: background-color 0.5s;

            &:hover {
                background: #f7f7f7;
            }


        }
    }

    &_navItemIcon {
        min-width: 8rem;
        position: relative;
        display: flex;
        justify-content: center;
        position: relative;

        svg path {
            fill: rgb(156 163 175);
        }

        svg {
            fill: rgb(156 163 175);
        }

        &_iconbadgeContainer {
           position: absolute;
            top: -5px;
            left: 25px; 
        }

        &_iconbadgeAnimation {
            background-color: rgb(129 140 248);
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 9999px;
            display: flex;
            position: absolute;
            top: 0;

            @keyframes ping {
                75%, 100% {
                    transform: scale(2);
                    opacity: 0;
                }
            }
        }

        &_iconbadge {
            background-color: rgb(99 102 241);
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 9999px;
            display: flex;
        }

    }

    &_navItemIconComunidad {
        min-width: 8rem;
        position: relative;
        display: flex;
        justify-content: center;

        svg path {
            fill: rgb(156 163 175);
        }
    }

    &_navItemIconWorkers {
        min-width: 8rem;
        position: relative;
        display: flex;
        justify-content: center;

        svg path {
            fill: #22af86;
        }

        svg {
            fill: #22af86;
        }
    }


    &_navItemIconChevron {
        min-width: 8rem;
        display: flex;
        justify-content: center;
        position: relative;
        color: rgb(156 163 175);
    }

    &_navItemText {
        opacity: 1;
        transition: opacity 0.5s, clip-path 0.5s ease-in-out;
        clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &.hideText {
            opacity: 0;
            clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
        }

        &_badge {
            background-color: rgb(99, 102, 241);
            border-radius: 9999px;
            padding: 0.5rem 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 11px;
            font-weight: bolder;
            margin-right: 1rem;
        }

        &_toggleMenu {
            color: rgb(156 163 175);
        }

        &_logout {
            font-size: 12px;
            opacity: 1;
            transition: opacity 0.5s;
            transition: background-color 0.5s;
            transition: color 0.5s;


            &.hideText {
                opacity: 0;
                transition: opacity 0.5s;
                font-size: 0;
            }

            &:hover {
                background-color: #2b3b5b;
                color: #fff;
            }

            svg {
                fill: #2b3b5b;
            }
        }
    }

    &_navItemNotification {
        width: 16px;
        height: 16px;
        background-color: #f41079;
        border-radius: 50%;
        position: absolute;
        top: -7px;
        right: 12px;
        font-size: 11px;
        text-align: center;
        color: #fff;
        font-weight: bolder;
    }

    &_createPublication {
        margin-top: 3.2rem;
        padding: 1.2rem 2.4rem;
        background-color: rgb(30 64 175);
        border-radius: 0.75rem;
        color: #fff;
        min-width: 283px;
        max-width: 283px;
    }
}

.activeHome {

    background: #edf0f7;
    color: #7000FF;

    svg path {
        fill: #7000FF;
    }

    &:hover {
        background: #edf0f7;
    }
}

.activeProfile {
    background: #edf0f7;
    color: #004F98;

    svg path {
        fill: #004F98;
    }

    &:hover {
        background: #edf0f7;
    }
}

.activePrivate {

    background: #edf0f7;
    color: #234BA2;

    svg path {
        fill: #234BA2;
    }

    &:hover {
        background: #edf0f7;
    }
}

.activeQuotation {
    color: #45A7FF;
    background: #edf0f7;

    svg path {
        fill: #45A7FF;
    }

    &:hover {
        background: #edf0f7;
    }
}

.activePublics {

    background: #edf0f7;
    color: #F5B048;

    svg path {
        fill: #F5B048;
    }

    &:hover {
        background: #edf0f7;
    }
}

.activeWorkers {
    background: #edf0f7;
    color: #22af86;

    &:hover {
        background: #edf0f7;
    }
}

.activeNotifications {
    border-left: 10px solid #E84F78;
}

.activeComunidad {
    background: rgb(235, 236, 240);
    color: #000;

    svg path {
        fill: #000;
    }

    &:hover {
        background: #edf0f7;
    }
}

.toolTipContainer {
    position: absolute;
    top: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.2rem;
}