@use "../../utils/mediaQuerys" as *;;

.tutorialModal {
    &_List {
        list-style: none;
        position: absolute;
        top: 0;
        right: -250px;
        height: 100%;
        flex-direction: column;
        display: flex;
        margin-top: 50px;
        width: 275px;

        &_item {
            display: flex;
            align-items: center;
            padding-bottom: 50px;

            &_checkMarkContainer {
                border-radius: 50%;
                width: 50px;
                height: 50px;
                background-color: #3f66b3;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                font-weight: 600;

                &.emptyCheckMark {
                    background-color: #fff;
                    border: 1px solid #3f66b3;
                    color: #3f66b3;
                    opacity: 0.3;
                }
            }

            &_txtContainer {
                &.notDone {
                    opacity: 0.3;
                }
            }

            &_txt {
                font-size: 14px;
                color: #fff;
                font-weight: 700;
                text-align: left;
                margin-left: 10px;
                max-width: 220px;


            }
        }
    }

    &_contentList {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &_seeMore {
            &_itemNotFlex {
                padding-top: 50px;
            }

            &_itemNotFlex4{
                padding-top: 30px;
            }

            &_item {
                display: flex;
                height: 100%;
                position: relative;
                padding-top: 50px;

                @include respond(phone) {
                    flex-direction: column;
                }

                &_itemBackGround {
                    width: 100%;
                    height: 100%;
                }

                &_img {
                    width: 250px;
                    border-radius: 11px;
                    object-fit: scale-down;
                    border: 1px solid #3f66b3;
                    box-shadow: -2px 8px 26px 6px rgba(0, 0, 0, 0.3);

                    @include respond(phone) {
                        width: 150px;
                    }
                }

                &_img2 {
                    width: 400px;
                    height: 320px;
                    border-radius: 11px;
                    object-fit: cover;
                    border: 1px solid #3f66b3;
                    box-shadow: -2px 8px 26px 6px rgba(0, 0, 0, 0.3);

                    @include respond(phone) {
                        width: 250px;
                        height: 200px;
                    }
                }

                &_img3 {
                    width: 500px;
                    height: 360px;
                    border-radius: 11px;
                    object-fit: scale-down;
                    border: 1px solid #3f66b3;
                    box-shadow: -2px 8px 26px 6px rgba(0, 0, 0, 0.3);

                    @include respond(phone) {
                        width: 250px;
                        height: 200px;
                    }
                }

                &_img4 {
                    width: 350px;
                    height: 265px;
                    border-radius: 11px;
                    object-fit: cover;
                    border: 1px solid #3f66b3;
                    box-shadow: -2px 8px 26px 6px rgba(0, 0, 0, 0.3);

                    @include respond(phone) {
                        width: 250px;
                        height: 200px;
                    }
                }

                &_text {
                    width: 100%;
                    min-height: 100%;
                    font-size: 14px;
                    padding-right: 40px;

                    @include respond(phone) {
                        padding-right: 0;
                        font-size: 12px;
                    }

                    &_p {
                        margin-top: 140px;

                        @include respond(phone) {
                            margin-top: 20px;
                            font-size: 12px;
                        }
                    }

                    &_p3 {
                        padding-top: 40px;
                        padding-left: 20px;

                        @include respond(phone) {
                            padding-top: 20px;
                            padding-left: 0;
                            font-size: 12px;
                        }
                    }
                }

                &_stepsContainer {
                    display: flex;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    justify-content: center;
                    padding-top: 20px;
                    align-items: center;

                    &_button {
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: 700;
                        color: #3f66b3;
                    }

                }
            }
        }

        &_item {
            margin: 10px;
            padding: 10px;
            border-radius: 5px;
            transition: all 0.3s ease;
            box-shadow: -2px 1px 20px -6px rgba(0, 0, 0, 0.1);

            &_header {
                display: flex;
                width: 100%;
                justify-content: space-between;

                svg path {
                    fill: #003667;
                }
            }

            &_headerTxt {
                font-size: 18px;
                text-align: left;
                color: #003667;
                font-weight: 700;


            }

            &_subText {
                padding-top: 20px;
                font-size: 12px;
                color: #d2d2d2;
                font-weight: 500;
                text-align: left;
            }

            &_seeMore {
                color: #003667;
                font-size: 12px;
                font-weight: 500;
                text-align: left;
                padding-top: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                    margin-left: 10px;
                }
            }
        }
    }
}