
.featureBox {
    margin-top: 2rem;
  }

.feature {
    padding: 1.6rem;
    background-color: rgb(238 242 255);
    border-radius: 0.75rem;
    display: flex;
    gap: 1.6rem;
    align-items: flex-start;
    margin-top: 1.6rem;

    &:first-child {
      margin-top: 0;
    }

    &_icon {
      color: rgb(37 99 235);
      margin-top: 0.2rem;
      background-color: rgb(224 231 255);
      border-radius: 9999px;
      padding: 1rem;
      display: flex;
    }

    &_body {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }

    &_title {
      font-size: 1.6rem;
      color: rgb(17 24 39);
      font-weight: 600;
    }

    &_description {
      color: rgb(75 85 99);
      font-size: 1.6rem;
      font-weight: 500;
    }

    &_line {
        position: absolute;
        top: 0;
        left: 3rem;
        height: 100%;
        width: 0.5rem;
        background-image: linear-gradient(
        to bottom,
        #c7d2fe,
        rgba(199, 210, 254, 0)
    );
    }

    &_subTitle {
        color: rgb(107 114 128);
        font-size: 1.6rem;
        padding-left: 6.4rem;
        margin-bottom: 1.6rem;
        font-weight: 500;
    }
  }

  .featureRelative {
    position: relative;
    margin-top: 1.6rem;

    &_margin {
        margin-left: 6.4rem;
        margin-bottom: 1.6rem;
    }
  }

  .featureGreen {
    background-image: linear-gradient(
        to bottom right,
        rgba(236, 253, 245, 0.8), 
        rgba(236, 253, 245, 0)   
    );
    border: 1px solid rgb(209 250 229 / 0.5);
    padding: 1.6rem;
    border-radius: 0.75rem;
    display: flex;
    gap: 1.6rem;
    align-items: flex-start;

    &_icon {
        background-color: rgb(209 250 229);
        border-radius: 9999px;
        padding: 1rem;
        display: flex;
        color: rgb(5 150 105);
        margin-top: 0.2rem;
    }
  }