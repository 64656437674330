.Toastify__toast-body {
    padding: 0;
    flex-direction: column;
    position: relative;
    display: flex;
}

.Toastify__toast {
    padding: 0;
}

.Toastify__toast-icon {
    display: none;
}

.Toastify__close-button {
    padding: 10px;
}

.Toastify__toast--firstToast .Toastify__progress-bar {
    background-color: #234ba2;
}

.Toastify__toast--secondToast .Toastify__progress-bar {
    background-color: #4ce2a7;
}

.Toastify__toast--thirdToast .Toastify__progress-bar {
    background-color: #f7b801;
}