.subProyectos {
  width: 60rem;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 20px;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }

}

.emptyDisclaimer {
  color: #2b3b5b;
  font-weight: 500;
  font-size: 11px;
}

.wrapper {
  width: 100%;
  padding-bottom: 20px;

  &_subTitle {
    color: #2B3B5B;
    line-height: 14px;
    font-weight: 800;
    font-size: 10px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #ebebeb;
  }
}

.closeButton {
  background-color: transparent;
  border: none;
  display: flex;
}

.saveButton {
  background: #234ba2;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 10px;
  width: 100%;
}
