.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;

    .popup-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
      border-bottom: 1px solid #ccc;

      .title {
        font-size: 1.8rem;
        font-weight: 600;
        display: flex;
        align-items: center;
      }

      .cancel-btn {
        background-color: transparent;
        color: #889abe;

      }
    }

    .popup-form {
      padding-top: 2rem;
    }

    .form-group {
      margin-bottom: 15px;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 600;
      }

      input,
      select {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;

        &:focus {
          outline: none;
          border-color: #234ba2;
        }

        &::placeholder {
          color: #ccc;
        }
      }

      .custom-select {
        position: relative;
        cursor: pointer;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
        background-color: #fff;

        &:hover {
          border-color: #234ba2;
        }

        .selected-option {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #333;

          &.placeholder {
            color: #ccc;
          }

        }

        .options-list {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 4px;
          margin-top: 4px;
          max-height: 200px;
          overflow-y: auto;
          z-index: 10;

          .option {
            padding: 8px;
            font-size: 14px;
            color: #333;
            cursor: pointer;

            &:hover {
              background-color: #f5f5f5;
            }
          }
        }
      }
      .quantity-control {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;

        .quantity-btn {
          padding: 8px 16px;
          border-radius: 4px;
          font-weight: 600;
          cursor: pointer;
          display: flex;
          background-color: #f5f5f5;

          &:hover {
            background-color: #e0e0e0;
          }
        }

        .input {
          text-align: center;
        }

        .quantity {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;

      button {
        padding: 8px 16px;
        border-radius: 4px;
        font-weight: 600;
        cursor: pointer;
      }


      .accept-btn {
        background-color: #2c5282;
        color: white;
        width: 100%;
        transition: all 0.3s;
        will-change: transform;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          transform: scale(1.02);
        }

        &:disabled {
          background-color: #889abe;
          cursor: not-allowed;
          opacity: 0.5;

          &:hover {
            transform: none;
          }
        }
      }
    }
  }
}
