@use "../../../utils/mediaQuerys" as *;

.quotation-details {
  display: flex;
  flex-direction: column;
  padding: 2rem; 
  width: 100%;

  @include respond(phone) {
    padding-top: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include respond(tab-port, true) {
    padding-top: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.header {
  text-align: center;
  margin-bottom: 16px;
}

.main-content {
  display: flex;
  flex-direction: column;

}

.header {
  padding-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 2rem 0;
  gap: 4rem;
  border-bottom: 1px solid var(--color-mainBorder);

  @include respond(phone) {
    flex-direction: column;
    align-items: stretch;
  }

  .title {
    display: flex;
    font-size: 1.4rem;
    font-weight: 600;
    flex-direction: column;
    color: #7a7f85;
    text-align: left;
    flex-basis: 80%;

    .text {
      display: flex;
      font-size: 2.5rem;
      color: #333;
    }
  }
}

.body {
  display: flex;
  width: 100%;

  @include respond(phone) {
    flex-direction: column;
  }

  @include respond(tab-port, true) {
    flex-direction: column
  }
}

.left-section {
  flex: 3;
  display: flex;
  flex-direction: column;

}

.general-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.materials-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 16px;

}

.iva-notice {
  margin-top: 8px;
  font-size: 12px;
  color: #888;
}

/* Derecha: Botones y presupuestos recibidos */
.budget-modal{
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  box-shadow: var(--shadow);
  flex-basis: 20%;

}

.actions button {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #234ba2;
  color: #234ba2;
  border-radius: 0.5rem;
  align-items: center;
  background: transparent;
  transition: all 0.2s ease-in-out;

  &.edit {

    &:hover {
      border: 1px solid #4167B3;
      background-color: #4167B3;
      color: white;
    }
  }

  &.pause {
    &:hover {
      border: 1px solid #F5B048;
      background-color: #F5B048;
      color: #fff;
    }
  }

  &.delete {
    &:hover {
      border: 1px solid #E84F78;
      background-color: #E84F78;
      color: white;
    }
  }

  &.reactivate {
    &:hover {
      border: 1px solid #4CAF50;
      background-color: #4CAF50;
      color: white;
    }
  }

  &.showBudgets {
    position: relative;

    span {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: #234ba2;
      color: white;
      border-radius: 50%;
      padding: 0.5rem 0.8rem;
      font-size: 1.2rem;
    }
    &:hover {
      border: 1px solid #234ba2;
      background-color: #234ba2;
      color: white;
    }
  }
}

.received-quotes {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.75rem;
  padding: 2rem;
  width: 40rem;
  max-width: 90%;

  .received-quotes-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
  }
}

.received-title {
  padding-bottom: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: #333;
    display: flex;
    justify-content: space-between;

    .close {
      font-size: 1.6rem;
      color: #234ba2;
      cursor: pointer;
    }
}

.received-quote {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;

  .quote-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .user-info {
      display: flex;
      align-items: center;
      background-color: #edf0f7;
      border-radius: 8px;
      padding: 1rem;

      .profile-image {
        min-height: 30px;
        min-width: 30px;
        max-height: 30px;
        max-width: 30px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1rem;
        border: 1px solid #e0e0e0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
     .user-name {
        font-size: 1.4rem;
        font-weight: 500;
        color: #889abe;
      }
    }

    .duration {
      display: flex;
      gap: 1rem;
      font-size: 1.4rem;
      color: #333;
    }

    .download {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-size: 1.4rem;
      border: 1px solid #234ba2;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      color: #234ba2;
      background-color: transparent;
      transition: all 0.2s ease-in-out;

      &:hover {
        border: 1px solid #4167B3;
        background-color: #4167B3;
        color: white;
      }
    }
  }
}

.arrow {
  font-size: 2rem;
  color: #007bff;
  font-weight: bold;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #666;
}

.empty-state p {
  padding-top: 1rem;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 500;
}

.paused-tag {
  color: #889abe;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.8rem 1.2rem;
  min-width: max-content;
  border-radius: 0.5rem;
  background-color: #edf0f7;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include respond(phone) {
    display: none;
  }

}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}



