@use "../../utils/mediaQuerys" as *;;

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 60vw;

    @include respond(phone) {
      max-width: 90vw;
      padding: 20px;
    }
  }
  
  .modal-body {
    position: relative;
    color: cadetblue;
  }
  
  .x-button {
    background: none;
    border: none;
    font-size: 1.4rem;
    color: #889abe;
    font-weight: 600;

    @include respond(phone) {
      font-size: 14px;
    }
  }
  
  .submit-button {
    margin-top: 20px;
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upload-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  