
.skills {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.6rem;
}

.skill {
  padding: 1.6rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_icon {
    color: rgb(37 99 235);
    min-width: 2.4rem

  }
}
