.tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
    z-index: 10;
    top: 100%;
    width: max-content;


    &.fromNav {
        left: 100%;
        width: 300px;
        padding: 10px;
        border-radius: 18px;
    }

    &.fromHeaderNav {
        right: 0;
    }
}

.tooltipCompletionText {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    padding-top: 10px;
}

.tooltipList {
    list-style: none;
    padding: 5px;
}

.tooltipListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid #fff;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #444;
    }
}